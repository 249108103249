import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import { TEXT_POST_TYPE } from 'later/utils/constants';
import { getProfile } from 'later/utils/global-search/get-profile';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type { SearchCommandQuery } from 'command-palette/types';
import type AuthService from 'later/services/auth';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';

@command('create-draft-for-profile')
export class CreateDraftForProfileCommand extends Command {
  @service declare auth: AuthService;
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;

  public searchTerms = ['draft', 'post', 'publish', 'schedule', 'make', 'create', 'media', 'profile', 'calendar'];

  protected action(query: SearchCommandQuery): void | Promise<void> {
    const profile = getProfile({ auth: this.auth, store: this.store, query });
    this.selectedSocialProfiles.selectExactly([profile]);
    this.router.transitionTo('cluster.schedule.calendar.post.new', TEXT_POST_TYPE, {
      queryParams: {
        draft: true
      }
    });
  }

  public getTitle(): string {
    return `Create Draft`;
  }

  public getDescription(query: SearchCommandQuery): string {
    const profile = getProfile({ auth: this.auth, store: this.store, query });
    const displayName = profile?.displayName;
    return `Create a draft for ${bold(displayName)}`;
  }

  public getIcon(query: SearchCommandQuery): string {
    const profile = getProfile({ auth: this.auth, store: this.store, query });
    const icon = `social-${profile.get('profileType')}`;

    return icon;
  }
}

export default CreateDraftForProfileCommand;
