import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type { Transition } from 'shared/types';

export default class SurveysRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  get socialIdentity(): SocialIdentityModel | undefined {
    const identity = this.group?.socialIdentities.firstObject;
    return identity;
  }

  get influencerId(): string | undefined {
    return this.router.currentRoute.params.influencer_id;
  }

  redirect(_model: unknown, transition: Transition): void {
    if (!this.groupSlug || !this.socialIdentity) {
      this.router.transitionTo('/');
      return;
    }

    const { survey_slug } = transition.to.params;
    const { queryParams } = transition.to;

    this.router.transitionTo(
      'cluster.partnerships.profile.surveys.survey',
      this.groupSlug,
      this.socialIdentity.id,
      survey_slug as string,
      { queryParams }
    );
  }
}
