import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class InvalidCharacterRejector extends BaseValidator {
  invalidCharacters = ['>', '<'];

  validate(_value, options, model) {
    if (!model.get('socialProfile.isYoutube')) {
      return true;
    }

    let value = _value;

    if (options?.field === 'keywordTags') {
      value = model.keywordTags?.toString();
    }

    if (!value || !value.length) {
      return true;
    }

    const invalidCharactersFound = this.invalidCharacters.filter((invalidCharacter) => {
      return value.includes(invalidCharacter);
    });

    const hasInvalidValue = invalidCharactersFound.length > 0;
    if (hasInvalidValue) {
      return this.createErrorMessage('invalidCharacter', undefined, { characters: invalidCharactersFound.join(', ') });
    }

    return true;
  }
}
