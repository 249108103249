import { A, isArray } from '@ember/array';
import { typeOf, isNone, isEmpty, isBlank } from '@ember/utils';
import Model from '@ember-data/model';
import moment from 'moment';

import type MutableArray from '@ember/array/mutable';
import type { Moment } from 'moment';
import type { Maybe } from 'shared/types';

export { queryListToString, buildQueryArray, enforceSingleProfile };

export type DateFilter = {
  startDate?: number;
  endDate?: number;
};

export type QueryListItem =
  | MutableArray<QueryListItem>
  | Maybe<string>
  | number
  | Date
  | Moment
  | Model
  | { [key: string]: QueryListItem };

function buildQueryArray(
  labelIds: number[],
  dateFilter: Maybe<string | DateFilter>,
  searchString: string,
  usedUnused: string,
  mediaTypeFilter: string,
  socialProfileIds: number[],
  groupId: Maybe<string>
): MutableArray<QueryListItem> {
  const queryArray: MutableArray<QueryListItem> = A();

  if (!isEmpty(labelIds)) {
    queryArray.addObject({
      labelIds
    });
  }

  if (!isEmpty(dateFilter)) {
    if (typeOf(dateFilter) === 'string') {
      queryArray.addObject({
        dateFilter
      });
    } else if (typeof dateFilter === 'object') {
      if (typeof dateFilter?.endDate === 'number') {
        queryArray.addObject({
          earliestDate: dateFilter.endDate
        });
      }

      if (typeof dateFilter?.startDate === 'number') {
        queryArray.addObject({
          latestDate: dateFilter.startDate
        });
      }
    }
  }

  if (!isEmpty(searchString)) {
    queryArray.addObject({
      searchString
    });
  }

  if (!isEmpty(mediaTypeFilter)) {
    queryArray.addObject({
      mediaTypeFilter
    });
  }

  // Note: a social profile param only makes sense if usedUnused is set
  let ignoreSocialProfile = true;
  if (!isEmpty(usedUnused)) {
    queryArray.addObject({
      usedUnused
    });
    ignoreSocialProfile = false;
  }

  if (!isEmpty(socialProfileIds)) {
    const socialProfileId = enforceSingleProfile(socialProfileIds);

    if (!isBlank(socialProfileId) && !ignoreSocialProfile) {
      queryArray.addObject({
        socialProfileId
      });
    }
  }

  if (!isEmpty(groupId)) {
    queryArray.addObject({
      groupId
    });
  }

  return queryArray;
}

function queryListToString(queryList: Maybe<QueryListItem>): string {
  if (isNone(queryList)) {
    return '';
  } else if (typeof queryList === 'string') {
    return queryList;
  } else if (typeof queryList === 'number') {
    return queryList.toString();
  } else if (queryList instanceof Date) {
    return queryListToString(queryList.getTime());
  } else if (moment.isMoment(queryList)) {
    return queryList.unix().toString();
  } else if (isMutableArray(queryList)) {
    return (
      '[' +
      queryList
        .map((item /*, index, enumerable*/) => queryListToString(item))
        .sort()
        .join() +
      ']'
    );
  } else if (queryList.constructor) {
    // Note: handles this situation for tests and production
    if (queryList instanceof Model) {
      const modelName = queryList.get('constructor.modelName' as keyof Model);
      return modelName + ':' + queryList.get('id');
    } else if (typeof queryList === 'object') {
      const strArray = [];
      for (const key in queryList) {
        if (!Object.prototype.hasOwnProperty.call(queryList, key)) {
          continue;
        }

        strArray.push(key + ':' + queryListToString(queryList[key]));
      }
      return '{' + strArray.sort().join() + '}';
    }
  }

  return queryList.toString();
}

function isMutableArray(queryList: QueryListItem): queryList is MutableArray<QueryListItem> {
  return Array.isArray(queryList);
}

function enforceSingleProfile(socialProfileIds: number[] | number): number {
  // Note: queries can only filter by a single social profile at a time
  if (isArray(socialProfileIds)) {
    return socialProfileIds[0];
  }
  return socialProfileIds;
}
