import { assert } from '@ember/debug';
import Service from '@ember/service';

import type AccountModel from 'later/models/account';
import type UserModel from 'later/models/user';
import type { EmptyObject } from 'type-fest';

/**
 * A registry of all events that can be triggered and
 * the data that will be provided to the handler.
 *
 * Each event must be typed and must have a unique name.
 * The name must be in the format of `namespace:event-name`.
 */
interface EventRegistry {
  'test:empty-object': EmptyObject;
  'test:undefined': undefined;
  'test:data': { id: string; name: string };
  'account:updated': AccountModel;
  'auth:setup-complete': { user: UserModel; account: AccountModel };
  'social-listening:data-is-ready': { social_listening_search_id: string };
  'social-listening:cache-cleared': { social_listening_search_id: string };
}

type EventName = keyof EventRegistry;
type EventHandler<T extends EventName> = (event: EventInstance<T>) => void;
export type EventInstance<T extends EventName> = { name: T; data: EventRegistry[T] };

class EventMap {
  #events: Map<EventName, unknown[]> = new Map();

  get<T extends EventName>(eventName: T): EventHandler<T>[] | undefined {
    if (this.#events.has(eventName)) {
      return this.#events.get(eventName) as EventHandler<T>[];
    }
    return undefined;
  }

  set<T extends EventName>(eventName: T, eventHandler: EventHandler<T>): void {
    if (this.#events.has(eventName)) {
      this.#events.get(eventName)?.push(eventHandler);
    } else {
      this.#events.set(eventName, [eventHandler]);
    }
  }

  delete<T extends EventName>(eventName: T, handler: EventHandler<T>): void {
    const events = this.#events.get(eventName)?.filter((existingHandler) => existingHandler !== handler) ?? [];
    this.#events.set(eventName, events);
  }
}

/**
 * A service that allows for listening to and triggering events.
 * All events must be typed and can be found in the {@link EventRegistry} interface.
 *
 * @remarks This service should be preferred over using the `Ember.Evented` mixin.
 */
export default class EventsService extends Service {
  #events: EventMap = new EventMap();

  /**
   * Registers a handler for the given event.
   * If the handler is already registered, an assertion is thrown.
   *
   * @remarks The exact same function must be passed to the `off` method to remove the handler.
   * It is recommended to assign the handler to a variable rather than passing an anonymous function.
   *
   * @example
   * Passing a const method.
   * ```ts
   * const handler = (event) => {
   *  // do something when the `test:empty-object` event is triggered
   * }
   * this.events.on('test:empty-object', handler);
   * ```
   *
   * @example
   * Passing an object method.
   * ```ts
   * this.events.on('test:empty-object', this.handler);
   * ```
   */
  on<T extends EventName>(eventName: T, handler: EventHandler<T>): void {
    assert(
      `Attempting to register duplicate handler for event |${eventName}|`,
      !this.#events.get(eventName)?.includes(handler)
    );
    this.#events.set(eventName, handler);
  }

  /**
   * Removes a handler for the given event.
   * If the handler is not registered, nothing happens.
   *
   * **The handler must be the same function that was registered when calling `on`.**
   *
   * @example
   * Passing a const.
   * ```ts
   * const handler = (event) => {...}
   * this.events.on('test:empty-object', handler);
   * ...
   * this.events.off('test:empty-object', handler);
   * ```
   *
   * @example
   * Passing an object method.
   * ```ts
   * this.events.on('test:empty-object', this.handler);
   * ...
   * this.events.off('test:empty-object', this.handler);
   * ```
   */
  off<T extends EventName>(eventName: T, handler: EventHandler<T>): void {
    this.#events.delete(eventName, handler);
  }

  /**
   * Triggers the given event with the given data.
   * If the event is not registered or it has no handlers, nothing happens.
   *
   * @example
   * ```ts
   * this.events.trigger('test:empty-object', {...});
   * ```
   */
  trigger<T extends EventName>(eventName: T, data: EventRegistry[T]): void {
    this.#events.get(eventName)?.forEach((handler) => {
      handler({ data, name: eventName });
    });
  }

  /**
   * Returns whether the given handler is registered for the given event.
   * If the event is not registered, `false` is returned.
   *
   * @example
   * ```ts
   * const handler = (event) => {...}
   * this.events.on('test:empty-object', handler);
   * ...
   * this.events.has('test:empty-object', handler); // true
   * ```
   *
   */
  has<T extends EventName>(eventName: T, handler: EventHandler<T>): boolean {
    return this.#events.get(eventName)?.includes(handler) ?? false;
  }
}

declare module '@ember/service' {
  interface Registry {
    events: EventsService;
  }
}
