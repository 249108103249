import { belongsTo } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';

import GenericAttachment from 'later/models/generic-attachment';

import type LinkinbioBlockModel from 'later/models/linkinbio-block';

export default class LinkinbioAttachmentModel extends GenericAttachment {
  @belongsTo('linkinbio-block') declare linkinbioBlock: LinkinbioBlockModel;

  bulkUpdate = collectionAction<LinkinbioAttachmentModel[], void>({
    path: 'bulk_update',
    type: 'PUT',
    before(this: LinkinbioAttachmentModel, attachments: LinkinbioAttachmentModel[]) {
      return {
        record_type: this.recordType,
        record_id: this.recordId,
        name: this.name,
        linkinbio_attachments: attachments.map((attachment) => {
          return {
            ...(attachment.id && {
              id: attachment.id
            }),
            key: attachment.key,
            content_type: attachment.contentType,
            processing_bucket: attachment.processingBucket,
            data: attachment.data
          };
        })
      };
    },
    after(this: LinkinbioAttachmentModel) {
      this.store
        .peekAll('linkinbio-attachment')
        .filter(
          (attachment) => (attachment.get('isNew') || attachment.get('isDeleted')) && attachment.name === this.name
        )
        .invoke('unloadRecord');
    }
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-attachment': LinkinbioAttachmentModel;
  }
}
