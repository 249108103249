/**
 * @module Utils
 */

import { isEmpty } from '@ember/utils';
import fbGraph from 'fbgraph';
import RSVP from 'rsvp';

import config from 'later/config/environment';

/**
 * @extends Utils
 */

const DEFAULT_API_VERSION = config.APP.facebookGraphVersion;

/**
 * Wrapper for calls using the fbgraph package. The purpose is to increase maintainability by
 * reducing the number of places fbGraph is called directly while at the same time performing
 * common actions (setting token and api version for now) as well as wrapping the callback style
 * responses in a promise.
 *
 * @param {string} url The url name to call with the fbgraph package.
 * @param {Record<string, any>} [params={}] The parameters to send along with the graph call.
 * @param {Record<string, any>} [config={}] Custom configuration for this specific call.
 * @param {string} [config.method='get'] The REST method to use for this call.
 * @param {string|null} [config.token=null] The access token to use for this call, gets reset to previous after call is made.
 * @param {string|null} [config.backupToken=null] The backup access token to use for this call.
 * @param {string} [config.version=DEFAULT_API_VERSION] Which version of the Graph API to use for this call, will be reset default.
 * @returns {Promise<any>}
 */
function graphCall(
  url,
  params = {},
  { method = 'get', token = null, backupToken = null, version = DEFAULT_API_VERSION } = {}
) {
  const previousToken = fbGraph.getAccessToken();
  return new RSVP.Promise((resolve, reject) => {
    fbGraph.setVersion(version);

    if (token) {
      fbGraph.setAccessToken(token);
    }

    fbGraph[method](url, params, (error, response) => {
      // Note: Empty FB responses can indicate errors.
      // Retry with backup token to make sure.
      if (
        backupToken &&
        (error || (response?.data && isEmpty(response?.data)) || (response?.media && isEmpty(response?.media?.data)))
      ) {
        fbGraph.setAccessToken(backupToken);
        fbGraph[method](url, params, (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      } else {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      }
    });

    fbGraph.setVersion(DEFAULT_API_VERSION);
    fbGraph.setAccessToken(previousToken);
  });
}

export default graphCall;
