import { A } from '@ember/array';
//import NativeArray from '@ember/array/-private/native-array';
import Transform from '@ember-data/serializer/transform';

/**
 * @ArrayTransform
 * Deserialize undefined or null values to empty array
 */
export default class ArrayTransform extends Transform {
  deserialize(value: unknown[]): unknown[] {
    return A(Array.isArray(value) ? value : []);
  }

  serialize(value: unknown[]): unknown[] {
    return A(Array.isArray(value) ? value : []);
  }
}

// TODO: need to bump ember-data types to 4.0 before we can extend the registry
// declare module 'ember-data/types/registries/transform' {
//   export default interface ModelRegistry {
//     array: NativeArray<any>;
//   }
// }
