import Model, { attr } from '@ember-data/model';

import type LinkinbioAttachmentModel from 'later/models/linkinbio-attachment';

interface StyleBlockData {
  button_background: string;
  button_box_shadow: string;
  button_outline: string;
  button_shape: string;
  button_text_color: string;
  page_background: string;
  page_background_image_identifier: string;
  page_background_type: string;
  page_font_family: string;
  page_text_color: string;
  social_link_icon_color: string;
}

export default class LinkinbioThemeModel extends Model {
  @attr() declare createdAt: string;
  @attr() declare linkinbioAttachments: LinkinbioAttachmentModel[];
  @attr() declare name: string;
  @attr() declare premium: boolean;
  @attr() declare style: StyleBlockData;
  @attr() declare updatedAt: string;

  get isDefaultTheme(): boolean {
    return this.name === 'Light';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-theme': LinkinbioThemeModel;
  }
}
