import ApplicationAdapter from './application';

export default class ListenerAdapter extends ApplicationAdapter {
  namespace = 'api/v2/social_listening_searches';
  serializer = 'listener';

  urlForCreateRecord(): string {
    return `${this.namespace}`;
  }

  urlForQuery(): string {
    return `${this.namespace}`;
  }

  urlForDeleteRecord(id: string): string {
    return `${this.namespace}/${id}`;
  }

  urlForUpdateRecord(id: string): string {
    return `${this.namespace}/${id}`;
  }
}
