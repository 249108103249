import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class KeywordTagLength extends BaseValidator {
  validate() {
    if (!this.model.socialProfile.get('isYoutube')) {
      return true;
    }

    const isValid = this.model.keywordTags?.every((tag) => tag.length <= 100);

    return isValid || this.createErrorMessage('keywordTagTooLong');
  }
}
