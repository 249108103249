import { fetch } from 'later/utils/fetch';
import { hours } from 'shared/utils/time';

import type { Maybe } from 'shared/types';

type Base64String = string;
type EncodedJWTHeader = Base64String;
type EncodedJWTPayload = Base64String;
type JWTSignature = string;

export type EncodedJWT = `${EncodedJWTHeader}.${EncodedJWTPayload}.${JWTSignature}`;

export type NexusChannelModel = 'user' | 'cluster';
type NexusChannelURL = `/${NexusChannelModel}/${string}`;
type NexusChannel = [NexusChannelModel, string];

interface NexusTokenResponse {
  token: EncodedJWT;
}

export interface DecodedJWT {
  header: {
    alg: string;
  };
  payload: {
    iss: string;
    iat: number;
    jti: string;
    sub: number;
    exp: number;
    channels: NexusChannelURL[];
  };
  signature: JWTSignature;
}

interface INexusToken {
  encodedToken: EncodedJWT;
  token: DecodedJWT;
  expiry: Maybe<Date>;
  hasRequiredChannels(channels: NexusChannel[]): boolean;
}

/**
 * Sanitize a base64 encoded string by removing any trailing `=` characters.
 *
 * @remarks This is necessary because the nexus token provided by the API
 * does not use "=" to pad to payload. The token still decodes correctly,
 * but the equality check fails.
 */
// eslint-disable-next-line @latermedia/later-linting/avoid-numerals-in-name
function sanitizeBase64(encodedString: string): string {
  return encodedString.replace(/=/g, '');
}

// eslint-disable-next-line @latermedia/later-linting/avoid-numerals-in-name
function isBase64(testString: string): boolean {
  if (testString === '' || testString.trim() === '') {
    return false;
  }

  function reEncode(testString: string): string {
    const decoded = window.atob(testString);
    const encoded = window.btoa(decoded);
    return sanitizeBase64(encoded);
  }

  try {
    const sanitized = sanitizeBase64(reEncode(testString));
    return sanitized == sanitizeBase64(testString);
  } catch (err) {
    return false;
  }
}

export function decodeJWT(encodedToken: EncodedJWT): DecodedJWT {
  const [header, payload, signature] = encodedToken.split('.');

  return {
    header: JSON.parse(window.atob(header)),
    payload: JSON.parse(window.atob(payload)),
    signature
  };
}

export default class NexusToken implements INexusToken {
  #encodedToken: EncodedJWT;
  #defaultExpiry: Date;

  constructor(encodedToken: EncodedJWT) {
    this.#encodedToken = encodedToken;

    const userSessionEstimate = hours(4);
    this.#defaultExpiry = userSessionEstimate;
  }

  get encodedToken(): EncodedJWT {
    return this.#encodedToken;
  }

  get token(): DecodedJWT {
    return decodeJWT(this.#encodedToken);
  }

  get expiry(): Date {
    try {
      const { payload } = this.token;
      const expiry = payload.exp;

      if (!expiry) {
        return this.#defaultExpiry;
      }

      const expiryDate = new Date(expiry * 1000);

      return expiryDate;
    } catch (error) {
      return this.#defaultExpiry;
    }
  }

  hasRequiredChannels(channels: [NexusChannelModel, string][]): boolean {
    const { channels: channelUrls } = this.token.payload;

    return channels.every(([model, id]) => {
      const url: NexusChannelURL = `/${model}/${id}`;
      return channelUrls.includes(url);
    });
  }

  static async build(encodedToken?: EncodedJWT): Promise<NexusToken> {
    if (encodedToken) {
      return new NexusToken(encodedToken);
    }

    const { token }: NexusTokenResponse = await fetch('/api/tokens/nexus_token.json');
    return new NexusToken(token);
  }

  static cacheKey(userId: string): string {
    return `nexus_token_${userId}`;
  }

  static isValidEncodedToken(encodedToken: unknown): encodedToken is EncodedJWT {
    if (!encodedToken || typeof encodedToken !== 'string') {
      return false;
    }

    const parts = encodedToken.split('.');

    if (parts.length !== 3) {
      return false;
    }

    const [header, payload, signature] = parts;

    if (!isBase64(header) || !isBase64(payload) || typeof signature !== 'string') {
      return false;
    }

    return true;
  }
}
