import { isBlank } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class ObjectTransform extends Transform {
  deserialize(value) {
    return isBlank(value) ? {} : value;
  }

  serialize(value) {
    return isBlank(value) ? {} : value;
  }
}
