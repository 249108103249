import { computed } from '@ember/object';
import { alias, filterBy, mapBy, max } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';

import type { AsyncBelongsTo } from '@ember-data/model';
import type GroupModel from 'later/models/group';
import type MediaItemModel from 'later/models/media-item';
import type UserModel from 'later/models/user';

export default class ContributorModel extends Model {
  @attr('number') declare submissionCount?: number;
  @attr('number') declare approvedCount?: number;

  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;

  @alias('user.name') declare name: UserModel['name'];
  @alias('user.email') declare email: UserModel['email'];
  @alias('user.avatarUrl') declare avatarUrl: UserModel['avatarUrl'];

  @filterBy('submissions', 'pending') declare unapprovedSubmissions: MediaItemModel[];
  @mapBy('submissions', 'createdTime') declare submissionCreated: number[];
  @max('submissionCreated') declare mostRecentSubmission: number;

  @computed('unapprovedSubmissions.[]')
  get unapprovedSubmissionCount(): number {
    return this.get('unapprovedSubmissions').length;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    contributor: ContributorModel;
  }
}
