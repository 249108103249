import { isEmpty } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class UsesOwnLibUrl extends BaseValidator {
  validate() {
    if (this.model.get('socialProfile.isInstagram') || this.model.get('socialProfile.isTiktok')) {
      const linkinbioUrlString = this.model.get('socialProfile.linkinbioUrl')?.replace('https://', '');
      const linkinbioPostUrl = this.model.get('linkinbioPost')
        ? this.model.get('linkinbioPost.linkUrl')
        : this.model.get('linkUrl');

      if (!isEmpty(linkinbioPostUrl) && !isEmpty(linkinbioUrlString) && linkinbioPostUrl.includes(linkinbioUrlString)) {
        return this.createErrorMessage('usesOwnLibUrlWarning');
      }
    }

    return true;
  }
}
