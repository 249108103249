import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';

import promiseHash from 'later/utils/promise-hash';

@classic
export default class AddRoute extends Route {
  @service intl;
  @service store;

  queryParams = {
    media: {
      refreshModel: true
    },
    text: {
      refreshModel: true
    },
    url: {
      refreshModel: true
    }
  };

  extensionCaption = null; //Title of source page
  extensionMediaUrl = null; //URL of image being added
  extensionPostUrl = null; //URL where extension was triggered

  model({ media, text, url }) {
    this.setProperties({
      extensionMediaUrl: media,
      extensionCaption: text,
      extensionPostUrl: url
    });

    const groups = this.store.peekAll('group').filterBy('contributor', false);
    const currentGroup = groups.get('firstObject');

    return promiseHash({
      mediaItem: this._createExtensionMediaItem(currentGroup),
      groups,
      currentGroup
    });
  }

  setupController(controller, models) {
    controller.set('mediaItem', models.mediaItem);
    controller.set('groups', models.groups);
    controller.set('currentGroup', models.currentGroup);
  }

  _createExtensionMediaItem(group) {
    const mediaNotes = `${this.extensionCaption}\n\n${this.intl.t('extension.media_notes_from')} ${
      this.extensionPostUrl
    }`;
    return this.store.createRecord('media-item', {
      group,
      defaultCaption: mediaNotes,
      processingUrl: this.extensionMediaUrl,
      sourceUrl: this.extensionPostUrl
    });
  }
}
