import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class MediaItemSerializer extends ApplicationSerializer {
  attrs = {
    contribution: { serialize: false },
    createdTime: { serialize: false },
    duplicatedMediaItemId: { serialize: false },
    failed: { serialize: false },
    fileSize: { serialize: false },
    highResUrl: { serialize: false },
    imageBucket: { serialize: false },
    imageKey: { serialize: false },
    imageUrl: { serialize: false },
    labels: { serialize: 'ids', deserialize: 'ids' },
    largeThumbnailUrl: { serialize: false },
    lowResUrl: { serialize: false },
    medResUrl: { serialize: false },
    medThumbnailUrl: { serialize: false },
    notesWhenApproved: { serialize: false },
    processing: { serialize: false },
    publishingDim: { serialize: false },
    publishingFileSize: { serialize: false },
    publishingUrl: { serialize: false },
    rawPostIds: { serialize: false },
    smallThumbnailUrl: { serialize: false },
    socialProfileIds: { serialize: false },
    tempHeight: { serialize: false },
    tempWidth: { serialize: false },
    thumbBucket: { serialize: false },
    thumbKey: { serialize: false },
    thumbUrl: { serialize: false },
    userId: { serialize: false },
    videoBucket: { serialize: false },
    videoDuration: { serialize: false },
    videoDurationMilliseconds: { serialize: false },
    videoFrameRate: { serialize: false },
    videoKey: { serialize: false },
    videoUrl: { serialize: false },
    workingUrl: { serialize: false }
  };
}
