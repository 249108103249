import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

import { SAVED_CAPTION_MAX_DISPLAY_LENGTH, SAVED_CAPTION_TRIM_LENGTH } from 'later/utils/constants';
import { timestamp } from 'later/utils/time-format';

import type GroupModel from 'later/models/group';
import type UserModel from 'later/models/user';

const Validations = buildValidations({
  content: validator('presence', {
    presence: true,
    ignoreBlank: true,
    messageKey: 'errors.captionEmpty'
  })
});

export default class SavedCaptionModel extends Model.extend(Validations) {
  @attr('string', { defaultValue: '' }) declare content: string;
  @attr('number') declare readonly createdTime: number;
  @attr('number') declare lastUsedTime: number;
  @attr('number', { defaultValue: 0 }) declare timesUsed: number;
  @attr('string', { defaultValue: '' }) declare title: string;

  @belongsTo('group') declare group: GroupModel;
  @belongsTo('user') declare user: UserModel;

  get trimmedContent(): string {
    return this.content.length >= SAVED_CAPTION_MAX_DISPLAY_LENGTH
      ? this.content.slice(0, SAVED_CAPTION_TRIM_LENGTH) + '...'
      : this.content;
  }

  /**
   * Increment the number of times the caption has been used and update lastUsedTime timestamp.
   */
  updateLastUsed(): void {
    this.timesUsed += 1;
    this.lastUsedTime = timestamp();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'saved-caption': SavedCaptionModel;
  }
}
