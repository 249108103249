import { none } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';

export default class SharePlanModel extends Model {
  @attr('number') declare readonly createdTime?: number;
  @attr('number') declare readonly expiresTime?: number;
  @attr('string') declare readonly planUrl?: string;
  @attr('number') declare readonly processedTime?: number;
  @attr('string') declare socialProfileId?: string;

  @none('processedTime') declare isProcessing: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'share-plan': SharePlanModel;
  }
}
