import Service, { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { isString } from 'lodash';
import { TrackedArray } from 'tracked-built-ins';

import type CacheService from 'later/services/cache';

export default class QueryPersistenceService extends Service {
  @service declare cache: CacheService;

  updateGroup(namespace: string, groupId?: string): void {
    const currentGroupId = this.#getPersistedValue(namespace, 'groupId');

    if (currentGroupId !== groupId) {
      this.clear(namespace);
    }

    this.#setPersistedValue(namespace, 'groupId', groupId);
  }

  clear(namespace: string): void {
    ['usedUnused', 'mediaTypeFilter', 'searchString', 'labels', 'socialProfileIds', 'dateFilter'].forEach((key) => {
      const fullKey = this.#cacheKey(namespace, key);
      this.cache.remove(fullKey);
    });
  }

  getUsedUnused(namespace: string, defaultValue?: string): string {
    return this.#getPersistedValue(namespace, 'usedUnused', defaultValue);
  }

  setUsedUnused(namespace: string, value: string): void {
    this.#setPersistedValue(namespace, 'usedUnused', value);
  }

  getMediaTypeFilter(namespace: string, defaultValue?: string): string {
    return this.#getPersistedValue(namespace, 'mediaTypeFilter', defaultValue);
  }

  setMediaTypeFilter(namespace: string, value: string): void {
    this.#setPersistedValue(namespace, 'mediaTypeFilter', value);
  }

  getSearchString(namespace: string, defaultValue?: string): string {
    return this.#getPersistedValue(namespace, 'searchString', defaultValue);
  }

  setSearchString(namespace: string, value: string): void {
    this.#setPersistedValue(namespace, 'searchString', value);
  }

  getLabelIds(namespace: string, defaultValue?: string): TrackedArray<number> {
    const labelIds = this.#getPersistedArrayValue(namespace, 'labels', defaultValue)
      .filter((labelId) => !isBlank(labelId))
      .map((labelId: string) => parseInt(labelId));

    return new TrackedArray(labelIds);
  }

  setLabelIds(namespace: string, value: TrackedArray<number>): void {
    this.#setPersistedArrayValue(namespace, 'labels', value);
  }

  getSocialProfileIds(namespace: string, defaultValue?: string): TrackedArray<number> {
    const profileIds = this.#getPersistedArrayValue(namespace, 'socialProfileIds', defaultValue)
      .filter((socialProfileId) => !isBlank(socialProfileId))
      .map((socialProfileId: string) => parseInt(socialProfileId));

    return new TrackedArray(profileIds);
  }

  setSocialProfileIds(namespace: string, value: TrackedArray<number>): void {
    this.#setPersistedArrayValue(namespace, 'socialProfileIds', value);
  }

  getDateFilter(namespace: string, defaultValue?: string): string {
    return this.#getPersistedValue(namespace, 'dateFilter', defaultValue);
  }

  setDateFilter(namespace: string, value: string): void {
    this.#setPersistedValue(namespace, 'dateFilter', value);
  }

  #cacheKey(namespace: string, key: string): string {
    return 'queryList:' + namespace + ':' + key;
  }

  #getPersistedValue(namespace: string, key: string, defaultValue = ''): string {
    const fullKey = this.#cacheKey(namespace, key);
    const cachedValue = this.cache.retrieve(fullKey);
    return isString(cachedValue) ? cachedValue : defaultValue;
  }

  #setPersistedValue(namespace: string, key: string, value?: string): void {
    const fullKey = this.#cacheKey(namespace, key);
    if (value) {
      this.cache.add(fullKey, value, { expiry: this.cache.maxExpiryDate(), persist: true });
    } else {
      this.cache.remove(fullKey);
    }
  }

  #getPersistedArrayValue(namespace: string, key: string, defaultValue?: string): TrackedArray<string> {
    const persistedValueString = this.#getPersistedValue(namespace, key, defaultValue);
    const persistedValueArray = typeof persistedValueString === 'string' ? persistedValueString.split(',') : [];

    return new TrackedArray(persistedValueArray);
  }

  #setPersistedArrayValue(namespace: string, key: string, value: TrackedArray): void {
    const val = value.join(',');
    this.#setPersistedValue(namespace, key, val);
  }
}

declare module '@ember/service' {
  interface Registry {
    'query-persistence': QueryPersistenceService;
  }
}
