import { assert } from '@ember/debug';
import moment from 'moment';

import { convert } from 'later/utils/time-format';
import { escapeValue } from 'social-listening/services/download-csv';

import type { TimeZone } from 'later/services/user-config';
import type { Moment } from 'moment-timezone';
import type { Bins } from 'shared/types/analytics-data';
import type { CSVData } from 'shared/utils/download-csv';

export type UnixTimestamp = number;
export type TimeseriesValue = number | null;

export type TimeseriesMeta = Record<string, unknown>;

/**
 * Note: unix time is always in seconds
 */
export type TimeseriesPoint = {
  time: UnixTimestamp;
  value: TimeseriesValue;
  meta: TimeseriesMeta;
};
export type TimeseriesData = Array<TimeseriesPoint>;

export const DEFAULT_TIMESERIES_META: TimeseriesMeta = {};

export type SimpleTimeseriesPoint = [UnixTimestamp, TimeseriesValue];
export type SimpleTimeseries = Array<SimpleTimeseriesPoint>;

export type TimeseriesConfig = {
  startDate: Moment;
  endDate: Moment;
  timeZone: TimeZone['identifier'];
  emptyMeta?: TimeseriesMeta;
  isNullDataSeries?: boolean;
};

/**
 * Represents a timeseries data structure with various operations and transformations.
 *
 * @example
 * // Create a new timeseries instance
 * const ts = new Timeseries(timeseriesData, {
 *   startDate: moment('2022-01-01'),
 *   endDate: moment('2022-01-31'),
 *   timeZone: 'America/New_York',
 *   emptyMeta: { sentimentLabel: 'positive' }
 * });
 *
 * // Check if a time value is in milliseconds
 * const isMilliseconds = Timeseries.isTimeInMilliseconds(1643625600000);
 *
 * // Convert timeseries data to a simple format
 * const simpleTimeseries = ts.toSimpleTimeseries('seconds');
 *
 * // Convert timeseries data to CSV format
 * const csvData = ts.toCSVData();
 *
 * // Check if all data points are missing
 * const isAllDataMissing = ts.isAllDataMissing();
 *
 * @remarks
 * - `timeseriesData` must be of type `TimeseriesData`.
 * - `time` must be a Unix timestamp in seconds (not milliseconds).
 * - `emptyMeta` is optional and defaults to an empty object. It is used for storing extra meta information for each point in the timeseries.
 * - The following processing is done on every timeseries when it is initialized:
 *   - Sort chronologically
 *   - Filter by date range
 *   - Fill missing data points in the date range.
 * - The original unsorted, unfiltered, unfilled data can be accessed using the `inputTimeseriesData` property.
 */
export default class Timeseries {
  inputTimeseriesData: TimeseriesData;
  #timeseriesData: TimeseriesData;
  startDate: Moment;
  endDate: Moment;
  timeZone: TimeZone['identifier'];

  #isSortedChronologically: boolean = false;
  #isFilteredToDateRange: boolean = false;
  #isFilledMissingData: boolean = false;

  get timeseriesData(): TimeseriesData {
    return this.#timeseriesData;
  }

  constructor(timeseriesData: TimeseriesData, config: TimeseriesConfig) {
    assert('startDate must be a moment object', config.startDate?.isValid());
    assert('endDate must be a moment object', config.endDate?.isValid());
    assert('timeseriesData must be of type TimeseriesData', this.#validateTimeseriesData(timeseriesData));

    this.inputTimeseriesData = timeseriesData;
    this.#timeseriesData = timeseriesData;
    this.startDate = config.startDate.tz(config.timeZone);
    this.endDate = config.endDate.tz(config.timeZone);
    this.timeZone = config.timeZone;

    this.#sortChronological().#fillMissingData(config.emptyMeta);
    if (config.isNullDataSeries) {
      this.#createMissingDataTimeseries();
    }
    this.#filterToDateRange();
  }

  static isTimeInMilliseconds(time: number): boolean {
    return Math.abs(Date.now() - time) < Math.abs(Date.now() - time * 1000);
  }

  toSimpleTimeseries(timeUnit: 'seconds' | 'milliseconds' = 'milliseconds'): SimpleTimeseries {
    return this.#timeseriesData.map(({ time, value }) => {
      if (timeUnit === 'milliseconds') {
        return [time * 1000, value];
      }

      return [time, value];
    });
  }

  toCSVData(): CSVData[] {
    return this.#timeseriesData.map((point) => {
      const date = moment(point.time * 1000).format('MMM D, YYYY');
      return [escapeValue(date), point.value];
    });
  }

  isAllDataMissing(): boolean {
    return (
      !this.#timeseriesData ||
      this.#timeseriesData.every(({ value }) => value === null) ||
      this.#timeseriesData.length === 0
    );
  }

  #validateTimeseriesData(data: TimeseriesData): boolean {
    if (!Array.isArray(data)) {
      return false;
    }
    return data.every(({ time, value, meta }) => {
      const isTimeValid = typeof time === 'number' && !Timeseries.isTimeInMilliseconds(time);
      const isValueValid = typeof value === 'number' || value === null;
      const isMetaValid = meta !== undefined;
      return isTimeValid && isValueValid && isMetaValid;
    });
  }

  #fillMissingData(emptyMeta: TimeseriesMeta = DEFAULT_TIMESERIES_META, secondsBetweenPoints = 86400): Timeseries {
    assert('Data must be sorted chronologically before filling missing data points', this.#isSortedChronologically);

    // Note: Generate empty bins separated by secondsBetweenPoints in the given time period
    const oneDayInSeconds = convert.day().toSeconds();
    const oneHourInSeconds = convert.hour().toSeconds();
    const isDailyData = secondsBetweenPoints >= oneDayInSeconds;

    const startMomentShifted = isDailyData ? this.startDate.startOf('day') : this.startDate;
    const endMomentShifted = isDailyData ? this.endDate.endOf('day') : this.endDate;

    const bins: Bins[] = [];

    let currentTime = startMomentShifted.unix();
    let isPrevDST = startMomentShifted.isDST();
    let isCurrDST;

    while (currentTime <= endMomentShifted.unix()) {
      isCurrDST = moment(currentTime * 1000)
        .tz(this.timeZone)
        .isDST();

      let end = currentTime + secondsBetweenPoints;

      if (isPrevDST && !isCurrDST) {
        currentTime += oneHourInSeconds;
        end += oneHourInSeconds;
      } else if (!isPrevDST && isCurrDST) {
        currentTime -= oneHourInSeconds;
        end -= oneHourInSeconds;
      }

      bins.push({
        start: currentTime,
        end,
        count: null
      });

      currentTime = end;
      isPrevDST = isCurrDST;
    }

    // Note: determine which bins have data points
    this.#timeseriesData.forEach(({ time }) => {
      bins.forEach((bin) => {
        if (time >= bin.start && time < bin.end) {
          bin.count ? bin.count++ : (bin.count = 1);
        }
      });
    });

    // Note: create a new array with missing data points
    const missingPoints: TimeseriesData = [];
    bins.forEach((bin) => {
      if (!bin.count) {
        missingPoints.push({ time: (bin.start + bin.end) / 2, value: null, meta: emptyMeta });
      }
    });

    this.#timeseriesData = [...this.#timeseriesData, ...missingPoints].sort(
      ({ time }, { time: timePrev }) => time - timePrev
    );
    this.#isFilledMissingData = true;
    return this;
  }

  #sortChronological(): Timeseries {
    this.#timeseriesData = this.#timeseriesData?.sortBy('value');
    this.#isSortedChronologically = true;
    return this;
  }

  #filterToDateRange(): Timeseries {
    this.#timeseriesData = this.#timeseriesData.filter(
      ({ time }) => this.startDate.unix() <= time && time <= this.endDate.unix()
    );
    this.#isFilteredToDateRange = true;
    return this;
  }

  #createMissingDataTimeseries(): Timeseries {
    let startIndex: number | null = null;
    let lastNonNullValue: number | null = null;

    this.#timeseriesData.forEach((datapoint, i) => {
      // Hit a valid null value, store previous index
      if (datapoint.value === null && startIndex === null && lastNonNullValue !== null && i > 0) {
        startIndex = i - 1;

        // Found end of null band, process increases and apply to array
      } else if (datapoint.value !== null && startIndex !== null && lastNonNullValue !== null) {
        const numberNullElements = i - startIndex;
        const stepIncrease = (datapoint.value - lastNonNullValue) / numberNullElements;

        this.#timeseriesData[startIndex].value = lastNonNullValue;
        for (let j = startIndex + 1; j < i; j++) {
          this.#timeseriesData[j].value = lastNonNullValue + stepIncrease * (j - startIndex);
        }

        startIndex = null;
        lastNonNullValue = datapoint.value;

        // Store last non-null value and set current value to null
      } else if (datapoint.value !== null) {
        lastNonNullValue = datapoint.value;
        datapoint.value = null;
      }
    });

    return this;
  }
}
