/**
 * @module Utils
 */
import { isNone } from '@ember/utils';

/**
 * Replace http urls for https
 *
 * @method secureUrl
 *
 * @returns {String} The secure version of the URL
 */

function secureUrl(prop) {
  if (isNone(prop)) {
    return '';
  }
  return prop.replace('http://', 'https://');
}

export { secureUrl };
