import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { SegmentEventTypes } from 'later/utils/constants/segment-events';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type AlertsService from 'later/services/alerts';
import type DialogManagerService from 'later/services/dialog-manager';
import type ErrorsService from 'later/services/errors';
import type PostService from 'later/services/schedule/post';
import type SegmentService from 'later/services/segment';
import type { Maybe } from 'shared/types';

type NavigableType = GramModel;

export default class PostNavigationService extends Service {
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare router: RouterService;
  @service declare dialogManager: DialogManagerService;
  @service declare intl: IntlService;
  @service('schedule/post') declare schedulePost: PostService;
  @service declare segment: SegmentService;

  @tracked currentPost: Maybe<NavigableType>;
  @tracked posts = new TrackedArray<NavigableType>();
  @tracked narrowArrows = false;

  get firstNavigableItem(): boolean {
    return this.index === 0 || this.index === -1;
  }

  get index(): number {
    return this.posts?.findIndex((item) => item.id == this.currentPost?.id);
  }

  get lastNavigableItem(): boolean {
    return this.index === this.posts?.length - 1 || this.index === -1;
  }

  get needsNarrowArrows(): boolean {
    return this.narrowArrows;
  }

  clearPosts(): void {
    this.posts = new TrackedArray<NavigableType>();
  }

  setCurrentPost(post: NavigableType): void {
    this.currentPost = post;
  }

  setPosts(posts: NavigableType[]): void {
    this.posts = new TrackedArray(posts);
  }

  setNarrowArrows(isNarrowArrows: boolean): void {
    this.narrowArrows = isNarrowArrows;
  }

  #findNextModel(isPositive: boolean): GramModel | undefined {
    const index = this.posts.findIndex((item) => item.id === this.currentPost?.id);
    const isInvalidIndex = (index === 0 && !isPositive) || (index === this.posts.length - 1 && isPositive);
    const nextPostIndex = index + (isPositive ? 1 : -1);

    if (index === undefined || isInvalidIndex) {
      return;
    }

    return this.posts[nextPostIndex];
  }

  #trackSegmentEvents(isPositive: boolean): void {
    const { NavigatedToNextPost, NavigatedToPreviousPost } = SegmentEventTypes;
    if (isPositive) {
      this.segment.track(NavigatedToNextPost);
    } else {
      this.segment.track(NavigatedToPreviousPost);
    }
  }

  navigate = task(async (route: string, options: { isPositive: boolean; backTo: string | undefined }) => {
    const { isPositive, backTo } = options;
    this.#trackSegmentEvents(isPositive);

    if (!this.currentPost) {
      return;
    }

    const shouldProceed = await this._handleUnsavedChanges.perform();

    if (shouldProceed) {
      // Note: We use 'next' to allow this.posts to update in the event that the currentPost is modified
      next(() => {
        const model = this.#findNextModel(isPositive);
        if (!model) {
          return;
        }
        this.currentPost = model;
        this.router.transitionTo(route, model.get('id'), {
          queryParams: { backTo }
        });
      });
    }
  });

  _handleUnsavedChanges = task(async () => {
    if (
      this.currentPost?.get('hasDirtyAttributes') ||
      this.currentPost?.postMediaItems.any((postMediaItem) => postMediaItem.get('hasDirtyAttributes'))
    ) {
      if (this.currentPost?.hasError) {
        this.alerts.alert(this.intl.t('alerts.posts.edit.arrow_navigation.validation_error.message'), {
          title: this.intl.t('alerts.posts.edit.arrow_navigation.validation_error.title'),
          preventDuplicates: true
        });
        return false;
      }

      const didConfirm = await this.dialogManager.confirmation(
        this.intl.t('posts.edit.arrow_navigation.confirm_save.title'),
        {
          description: this.intl.t('posts.edit.arrow_navigation.confirm_save.message'),
          cancelButton: this.intl.t('shared_phrases.discard_changes'),
          confirmButton: this.intl.t('shared_phrases.save_changes')
        }
      );

      if (!didConfirm) {
        await this.schedulePost.rollbackPost.perform(this.currentPost);
        return true;
      }

      try {
        await this.schedulePost.save.perform(this.currentPost);
        return true;
      } catch (error) {
        this.errors.handleAdapter(error);
      }
    }
    return true;
  });
}

declare module '@ember/service' {
  interface Registry {
    'post-navigation': PostNavigationService;
  }
}
