import { inject as service } from '@ember/service';
import BaseValidator from 'ember-cp-validations/validators/base';

const MAX_WIDTH_WITHOUT_REDUCED_QUALITY = 1440;

export default class VideoQualityReduced extends BaseValidator {
  @service intl;

  validate(value, _, model) {
    const media = model || model.mediaItem;
    const isVideo = media.get('isVideo');
    const width = media.get('croppedWidth') || media.get('width');

    if (!isVideo) {
      return true;
    }

    if (width > MAX_WIDTH_WITHOUT_REDUCED_QUALITY) {
      const platform = model.get('gram.socialProfile.profileType');
      return this.createErrorMessage('videoReducedQualityWarning', null, {
        platform: platform ? this.intl.t(`shared_words.${platform}`) : this.intl.t('app.entities.social_profile')
      });
    }

    return true;
  }
}
