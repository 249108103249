import { isPresent } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import { MEDIA_LABEL_NAMES } from 'later/utils/constants';

@classic
class MentionsRegram extends BaseValidator {
  validate(value, _, model) {
    const labelNames = model.get('firstMediaItem.labelNames') || [];
    const sourceUsername = model.get('firstMediaItem.sourceUsername');
    const isOwnPost = model.get('isOwnPost');
    const isPosted = model.get('isPosted');
    const shouldCheckForMention = isPresent(sourceUsername) && model.get('socialProfile.isInstagram') && !isOwnPost;

    if (isPosted) {
      return true;
    }

    if (labelNames.includes(MEDIA_LABEL_NAMES.UNSPLASH)) {
      return true;
    }

    if (
      !shouldCheckForMention ||
      labelNames.includes(MEDIA_LABEL_NAMES.TAGS) ||
      labelNames.includes(MEDIA_LABEL_NAMES.MENTIONS)
    ) {
      return true;
    }
    return this.checkForMention(sourceUsername, value);
  }

  checkForMention(username, caption) {
    const formattedCaption = caption.toLowerCase();
    const prependUsername = username[0] !== '@' ? '@' : '';
    const formattedUser = prependUsername + username.toLowerCase();
    const idxOfMention = formattedCaption.indexOf(formattedUser);
    let errorMessage;

    if (idxOfMention !== -1) {
      const nextCharacter = formattedCaption[idxOfMention + formattedUser.length];
      const previousCharacter = formattedCaption[idxOfMention - 1];
      const regEx = /[^a-zA-Z0-9_.]/g;
      const whitespaceRegEx = /\s/;

      if (!whitespaceRegEx.test(previousCharacter)) {
        errorMessage = 'error_mentions_before';
      } else if (regEx.test(nextCharacter) || nextCharacter === undefined) {
        return true;
      } else {
        errorMessage = 'error_mentions_after';
      }
    } else {
      errorMessage = 'error_mentions_regram';
    }

    return this.createErrorMessage(errorMessage, null, { username });
  }
}

export default MentionsRegram;
