import { merge } from 'lodash';

interface GroupData {
  data: {
    group_id?: string;
  };
}

/**
 * Adds the current Group's Id to the provided object.
 */
export function addGroupId(
  groupId?: string,
  _someObject: Record<string, unknown> = {}
): Record<string, unknown> & GroupData {
  const groupData: GroupData = { data: { group_id: groupId } };

  return merge(groupData, _someObject);
}
