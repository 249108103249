import { reads } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { enqueueTask } from 'ember-concurrency';

import type SocialProfileModel from 'later/models/social-profile';
import type AnalyticsService from 'later/services/analytics';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type KeenApiService from 'later/services/analytics/keen-api';
import type ErrorsService from 'later/services/errors';
import type { Moment } from 'moment';
import type { BinnedTimeseriesDataPoint, TimeseriesDataPoint } from 'shared/types/analytics-data';
import type {
  LIBBlockClicksOverTime,
  LIBClicksOverTime,
  GenericClicksParams,
  MediaItemWithLIBData
} from 'shared/types/analytics-data/keen';

/**
 * @class AnalyticsKeenService
 * @extends Service
 */
export default class KeenAnalyticsService extends Service {
  @service declare analytics: AnalyticsService;
  @service declare errors: ErrorsService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/keen-api') declare keenApi: KeenApiService;

  /**
   * Default data point for Short Link clicks
   */
  defaultClickPoint: BinnedTimeseriesDataPoint = {
    sampled_at: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    count: null
  };

  /**
   * Default data point for page views
   */
  defaultPageViewPoint: TimeseriesDataPoint = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  /**
   * Default data point for block clicks
   */
  defaultBlockClickPoint: LIBBlockClicksOverTime = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  /**
   * Default data point for button clicks
   */
  defaultButtonClickPoint: TimeseriesDataPoint = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  /**
   * Default data point for lib clicks over time
   */
  defaultClickOverTimePoint: TimeseriesDataPoint = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  @reads('analytics.socialProfile') declare socialProfile: SocialProfileModel;

  get linkinbioPageId(): string {
    const id = this.analytics.currentLinkinbioPage?.get('id');
    if (!id) {
      this.errors.log(new Error('No linkinbio page id found'));
      return '';
    }
    return id;
  }
  /**
   * Default start date for data calls in this service
   */
  get startDate(): Moment {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  /**
   * Default end date for data calls in this service
   */
  get endDate(): Moment {
    return this.helpersAnalytics.createMomentInTz();
  }

  /**
   * Returns Linkin.bio page views over time.
   */
  getLibPageViewsOverTime = enqueueTask(
    async (
      startDate = this.startDate,
      endDate = this.endDate,
      linkinbioPageId = this.linkinbioPageId,
      forceRefresh = false
    ) => {
      const pageViews = await this.keenApi.getLibPageViewsOverTime.linked().perform(linkinbioPageId, forceRefresh);

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        pageViews,
        this.defaultPageViewPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio App Block clicks daily.
   */
  getDailyAppClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dailyButtonClicks = await this.keenApi.getDailyAppClicks
        .linked()
        .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        dailyButtonClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio button clicks daily.
   */
  getDailyButtonClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dailyButtonClicks = await this.keenApi.getDailyButtonClicks
        .linked()
        .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        dailyButtonClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio button group clicks daily.
   */
  getDailyButtonGroupClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const libDailyButtonGroupClicks = await this.keenApi.getDailyButtonGroupClicks.linked().perform({
        datasetName,
        linkinbioPageId,
        startDate,
        endDate,
        forceRefresh
      });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        libDailyButtonGroupClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio Contact Collection Block clicks daily.
   */
  getDailyContactCollectionClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dailyButtonClicks = await this.keenApi.getDailyContactCollectionClicks
        .linked()
        .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        dailyButtonClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio Featured Banner Block clicks daily.
   */
  getDailyFeaturedBannerClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dailyFeaturedBannerClicks = await this.keenApi.getDailyFeaturedBannerClicks
        .linked()
        .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        dailyFeaturedBannerClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio featured media daily.
   */
  getDailyCustomFeaturedMediaClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const libDailyCustomFeaturedMediaClicks = await this.keenApi.getDailyCustomFeaturedMediaClicks.linked().perform({
        datasetName,
        linkinbioPageId,
        startDate,
        endDate,
        forceRefresh
      });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        libDailyCustomFeaturedMediaClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio featured media daily.
   */
  getDailyMultiItemBannerItemClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const libDailyMultiItemBannerItemClicks = await this.keenApi.getDailyMultiItemBannerItemClicks.linked().perform({
        datasetName,
        linkinbioPageId,
        startDate,
        endDate,
        forceRefresh
      });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        libDailyMultiItemBannerItemClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio Tiktok post clicks daily.
   */
  getDailyTiktokPostClicks = enqueueTask(
    async ({
      datasetName,
      socialProfile,
      linkinbioPostId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams & {
      linkinbioPostId: string;
    }) => {
      const dailyTiktokPostClicks = await this.keenApi.getDailyTiktokPostClicks.linked().perform({
        datasetName,
        socialProfile,
        linkinbioPostId,
        startDate,
        endDate,
        forceRefresh
      });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        dailyTiktokPostClicks,
        this.defaultClickOverTimePoint as LIBClicksOverTime,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio social link clicks daily.
   */
  getDailySocialLinkClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dailySocialLinkClicks = await this.keenApi.getDailySocialLinkClicks
        .linked()
        .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        dailySocialLinkClicks,
        this.defaultBlockClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio button clicks over time.
   */
  getLibButtonClicksOverTime = enqueueTask(
    async (
      startDate = this.startDate,
      endDate = this.endDate,
      linkinbioPageId = this.linkinbioPageId,
      forceRefresh = false
    ) => {
      const pageViews = await this.keenApi.getLibButtonClicksOverTime.linked().perform(linkinbioPageId, forceRefresh);

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().endOf('day').unix(),
        pageViews,
        this.defaultButtonClickPoint,
        'time'
      );
    }
  );

  /**
   * Returns Linkin.bio click through percentage over time.
   */
  getLibClickThroughPercentageOverTime = enqueueTask(async (linkinbioPageId: string, forceRefresh = false) => {
    const libPageViewsOverTime = await this.getLibPageViewsOverTime.perform(
      this.startDate,
      this.endDate,
      linkinbioPageId,
      forceRefresh
    );
    const libClicksOverTime = await this.getLibClicksOverTime.perform(
      this.startDate,
      this.endDate,
      linkinbioPageId,
      forceRefresh
    );
    const libButtonClicksOverTime = await this.getLibButtonClicksOverTime.perform(
      this.startDate,
      this.endDate,
      linkinbioPageId,
      forceRefresh
    );

    return libClicksOverTime?.map(({ time, value: clickCount }, index) => {
      const buttonClicks = libButtonClicksOverTime?.[index]?.value;
      const pageViews = libPageViewsOverTime?.[index]?.value;

      const isClicksMissing = isEmpty(clickCount) && isEmpty(buttonClicks);
      if (isEmpty(pageViews) || isClicksMissing) {
        return { time, value: null };
      }

      if (pageViews === 0 || !pageViews) {
        return { time, value: 0 };
      }

      const totalClicks = (clickCount ?? 0) + (buttonClicks ?? 0);
      return { time, value: Number((totalClicks / pageViews) * 100) };
    });
  });

  /**
   * Returns Linkin.bio clicks count over time for the given media item.
   */
  getLibLifespanClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    if (!mediaItem) {
      return;
    }

    const clicksOverTime: BinnedTimeseriesDataPoint[] = await this.keenApi.getLibLifespanClicksOverTime
      .linked()
      .perform(mediaItem, forceRefresh);
    const startDate = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
    const endDate = startDate.clone().add(2, 'weeks');
    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.unix(),
      clicksOverTime,
      this.defaultClickPoint,
      'sampled_at'
    );
  });

  /**
   * Returns Linkin.bio page clicks count over time for the given media item.
   */
  getLibPageClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    if (!mediaItem) {
      return;
    }

    const clicksOverTime: BinnedTimeseriesDataPoint[] = await this.keenApi.getLibPageClicksOverTime
      .linked()
      .perform(mediaItem, forceRefresh);
    const startDate = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
    const endDate = startDate.clone().add(2, 'weeks');

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.unix(),
      clicksOverTime,
      this.defaultClickPoint,
      'sampled_at'
    );
  });

  /**
   * Returns Linkin.bio embedded clicks count over time for the given media item.
   */
  getLibEmbeddedClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    if (!mediaItem) {
      return;
    }

    const clicksOverTime: BinnedTimeseriesDataPoint[] = await this.keenApi.getLibEmbeddedClicksOverTime
      .linked()
      .perform(mediaItem, forceRefresh);
    const startDate = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
    const endDate = startDate.clone().add(2, 'weeks');

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.unix(),
      clicksOverTime,
      this.defaultClickPoint,
      'sampled_at'
    );
  });

  /**
   * Returns Linkin.bio clicks count over time.
   */
  getLibClicksOverTime = enqueueTask(
    async (
      startDate = this.startDate,
      endDate = this.endDate,
      linkinbioPageId = this.linkinbioPageId,
      forceRefresh = false
    ) => {
      const clicksOverTime = await this.keenApi.getLibClicksOverTimeDailyTotals
        .linked()
        .perform(linkinbioPageId, forceRefresh);

      if (isEmpty(clicksOverTime)) {
        return clicksOverTime;
      }

      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.clone().add(1, 'day').unix(),
        clicksOverTime,
        this.defaultClickOverTimePoint,
        'time'
      );
    }
  );

  /**
   * Returns Short Link clicks count over time of the given media item
   * Note: endpoint is not cached, so is forceRefresh true by default
   */
  getShortLinkClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    const clicksOverTime: BinnedTimeseriesDataPoint[] = await this.keenApi.getShortLinkClicksOverTime
      .linked()
      .perform(mediaItem, forceRefresh);

    if (!isEmpty(clicksOverTime)) {
      const start = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
      const end = start.clone().add(24, 'hours');
      const secondsBetweenPoints = 3600;

      return this.helpersAnalytics.fillData(
        start.unix(),
        end.unix(),
        clicksOverTime,
        this.defaultClickPoint,
        'sampled_at',
        secondsBetweenPoints
      );
    }
    return clicksOverTime;
  });

  /**
   * Returns linkinbio page views sorted by country for analytics standard users.
   */
  getLibPageViewsByCountry = enqueueTask(async (linkinbioPageId = this.linkinbioPageId, forceRefresh = false) => {
    return await this.keenApi.getLibPageViewsByCountry.linked().perform(linkinbioPageId, forceRefresh);
  });

  /**
   * Returns linkinbio page views sorted by city for analytics standard users.
   */
  getLibPageViewsByCity = enqueueTask(async (linkinbioPageId = this.linkinbioPageId, forceRefresh = false) => {
    return await this.keenApi.getLibPageViewsByCity.linked().perform(linkinbioPageId, forceRefresh);
  });

  /**
   * Returns the total media clicks in the last 4 months.
   */
  getMediaTotalClicks = enqueueTask(
    async (startDate = this.startDate, endDate = this.endDate, forceRefresh = false) => {
      return await this.keenApi.getMediaTotalClicks.perform(startDate, endDate, forceRefresh);
    }
  );

  generateDefaultLibClicksPoint(ids: [string, number][]): LIBClicksOverTime {
    return {
      time: undefined,
      value: ids.map((id) => ({
        'media.id': id[0],
        'linkinbio_post.id': id[1],
        result: undefined
      }))
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/keen-analytics': KeenAnalyticsService;
  }
}
