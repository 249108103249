import { URL_VALIDATION_REGEX } from 'later/utils/constants';

/**
 * Checks if url is valid
 *
 * @param url Url to check
 *
 * @returns True, if url is valid
 */

const isValidUrl = (url: string): boolean => (url ? URL_VALIDATION_REGEX.test(url) : false);

export { isValidUrl };
