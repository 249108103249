import { later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import { SECONDARY_MODAL_TARGETS, SECONDARY_MODAL_TYPES, NO_PUBLISHING_METHOD_COMPONENTS } from 'later/utils/constants';
import uuid from 'shared/utils/uuid';

import type AuthService from 'later/services/auth';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';

export default class SecondaryModalService extends Service {
  @service declare auth: AuthService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;

  @tracked declare openSecondaryModalId?: string;
  @tracked declare type?: string;
  @tracked declare location?: string;
  @tracked isTransitioning = false;
  @tracked publishingMethodSetupModalComponent = NO_PUBLISHING_METHOD_COMPONENTS.INDEX;

  get hasPublishingMethod(): boolean {
    return this.auth.currentSocialProfile.hasPublishingMethod;
  }

  shouldReopenSetupPublishingMethodModal(type: string): boolean {
    return this.#hasSocialProfilesWithNoPublishingMethod() && type !== SECONDARY_MODAL_TYPES.PUBLISHING_METHOD_SETUP;
  }

  isModalOpen(id?: string, location?: string): boolean {
    if (id && location) {
      return this.openSecondaryModalId === id && this.location === location;
    }
    return !isEmpty(this.openSecondaryModalId);
  }

  addModal(id: string, type: string, location?: string): void {
    if (id) {
      this.openSecondaryModalId = id;
      this.type = type;
      this.location = location;
    }
  }

  removeModal(): void {
    this.openSecondaryModalId = undefined;
    this.location = undefined;
    this.type = undefined;
  }

  isClickedFromFirstComment(id: string): boolean {
    return this.openSecondaryModalId === id && this.location === SECONDARY_MODAL_TARGETS.FIRST_COMMENT;
  }

  isActivityModalActive(id: string, location: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.openSecondaryModalId === id && this.type === SECONDARY_MODAL_TYPES.ACTIVITY && isSameLocation;
  }

  isHashtagSuggestionsModalActive(id: string, location: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.openSecondaryModalId === id && this.type === SECONDARY_MODAL_TYPES.SMART_HASHTAGS && isSameLocation;
  }

  isSavedCaptionsModalActive(id: string, location: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.openSecondaryModalId === id && this.type === SECONDARY_MODAL_TYPES.SAVED_CAPTIONS && isSameLocation;
  }

  isCaptionWriterModalActive(id: string, location: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.openSecondaryModalId === id && this.type === SECONDARY_MODAL_TYPES.CAPTION_WRITER && isSameLocation;
  }

  isHighPerformerActive(id: string, location: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.openSecondaryModalId === id && this.type === SECONDARY_MODAL_TYPES.HIGH_PERFORMER && isSameLocation;
  }

  isPublishingMethodSetupModalActive(location: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.type === SECONDARY_MODAL_TYPES.PUBLISHING_METHOD_SETUP && isSameLocation;
  }

  isLinkinbioSetupActive(id: string, location?: string): boolean {
    const isSameLocation = location ? this.location === location : true;
    return this.openSecondaryModalId === id && this.type === SECONDARY_MODAL_TYPES.LINKINBIO_SETUP && isSameLocation;
  }

  toggleModal(id: string, type: string, location?: string): void {
    if (this.openSecondaryModalId !== id || (type && this.type !== type)) {
      this.removeModal();
      this.addModal(id, type, location);
    } else if (location && this.location !== location) {
      this.location = location;
      this.#setSecondaryModalTransitioning();
    } else {
      if (this.shouldReopenSetupPublishingMethodModal(type)) {
        this.removeModal();
        this.#addPublishingMethodSetupModal();
      } else {
        this.removeModal();
      }
    }
  }

  #addPublishingMethodSetupModal(): void {
    this.addModal(
      uuid(),
      SECONDARY_MODAL_TYPES.PUBLISHING_METHOD_SETUP,
      SECONDARY_MODAL_TARGETS.PUBLISHING_METHOD_SETUP
    );
    this.setPublishingMethodSetupModalComponent(NO_PUBLISHING_METHOD_COMPONENTS.INDEX);
  }

  setPublishingMethodSetupModalComponent(componentName = NO_PUBLISHING_METHOD_COMPONENTS.INDEX): void {
    this.publishingMethodSetupModalComponent = componentName;
  }

  #setSecondaryModalTransitioning(): void {
    this.isTransitioning = true;
    later(() => {
      this.isTransitioning = false;
    }, 1000);
  }

  #hasSocialProfilesWithNoPublishingMethod(): boolean {
    return this.selectedSocialProfiles.profiles.some((profile) => !profile.hasPublishingMethod);
  }
}

declare module '@ember/service' {
  interface Registry {
    'secondary-modal': SecondaryModalService;
  }
}
