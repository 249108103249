import { isNone } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class YoutubeAudienceExists extends BaseValidator {
  validate() {
    if (this.get('model.socialProfile.isYoutube')) {
      return isNone(this.get('model.madeForKids')) ? this.createErrorMessage('audienceExists') : true;
    }
    return true;
  }
}
