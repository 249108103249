import { inject as service } from '@ember/service';

import ApplicationAdapter from 'later/adapters/application';
import { addGroupId } from 'later/utils/add-group-id';

import type AuthService from 'later/services/auth';
import type RSVP from 'rsvp';

export default class LabelAdapter extends ApplicationAdapter {
  @service declare auth: AuthService;

  ajax(url: string, type: string, hash?: Record<string, unknown>): RSVP.Promise<any> {
    const groupId = this.get('auth').get('currentGroup')?.get('id');
    return super.ajax(url, type, addGroupId(groupId, hash));
  }
}
