import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ArrayProxy from '@ember/array/proxy';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type SocialIdentityModel from 'later/models/social-identity';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';

export default class LabsRoute extends Route {
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare auth: AuthService;

  beforeModel(): void {
    if (!this.auth.currentAccount.canLabs) {
      this.alerts.warning(this.intl.t('alerts.labs.no_access'));
      this.router.transitionTo('cluster');
    }
  }

  async model(): Promise<ArrayProxy<SocialIdentityModel>> {
    const socialIdentity = await this.store.findAll('social-identity');
    return socialIdentity;
  }
}
