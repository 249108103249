import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n{{#if this.showMonthlyRewardAnimation}}\n  <div class=\"tRW--animationContainer\" {{did-insert this.setRewardsInLocalStorage}}>\n    {{#each (repeat 40) as |confetti index|}}\n      <div class=\"tRW--confetti__{{add index 1}}\"></div>\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n{{#if this.showMonthlyRewardAnimation}}\n  <div class=\"tRW--animationContainer\" {{did-insert this.setRewardsInLocalStorage}}>\n    {{#each (repeat 40) as |confetti index|}}\n      <div class=\"tRW--confetti__{{add index 1}}\"></div>\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"later/components/schedule/rewards-animation.hbs","parseOptions":{"srcName":"later/components/schedule/rewards-animation.hbs"}});
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { timeUntilEndOfMonth } from 'later/utils/time-format';

import type AccountModel from 'later/models/account';
import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type PostService from 'later/services/schedule/post';

interface RewardsAnimationComponentArgs {
  currentAccount: AccountModel;
}
export default class RewardsAnimationComponent extends Component<RewardsAnimationComponentArgs> {
  @service declare localStorageManager: LocalStorageManagerService;
  @service('schedule/post') declare schedulePost: PostService;

  @tracked animationSeen = false;

  get showMonthlyRewardAnimation(): boolean {
    return this.createdEnoughPostsForMonthlyReward && !this.seenMonthlyRewardAnimation;
  }

  get createdEnoughPostsForMonthlyReward(): boolean {
    return this.args.currentAccount.postsCreatedThisMonth === this.schedulePost.postCountRewardThreshold;
  }

  get seenMonthlyRewardAnimation(): boolean {
    return this.animationSeen || Boolean(this.localStorageManager.getItem('seen-reward-animation'));
  }

  @action
  setRewardsInLocalStorage(): void {
    later(() => {
      this.animationSeen = true;
      this.localStorageManager.setItem('seen-reward-animation', true, timeUntilEndOfMonth('hours'));
    }, 3000);
  }
}
