import Service, { inject as service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import { isString, toString, isObjectLike } from 'lodash';

/* eslint-disable import/no-duplicates */
import type { SafeString } from '@ember/template/-private/handlebars';
import type FlashObject from 'ember-cli-flash/flash/object';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type { CustomMessageInfo, MessageOptions } from 'ember-cli-flash/services/flash-messages';
import type ErrorsService from 'later/services/errors';

// The official types for FlashObject are incomplete, so we amend it here.
export interface AlertMessage extends FlashObject {
  message: string;
  title: string;
  type: 'success' | 'warning' | 'alert' | 'danger' | 'info';
}

export default class AlertsService extends Service {
  @service declare errors: ErrorsService;
  @service declare flashMessages: FlashMessageService;

  get queue(): AlertMessage[] {
    return this.flashMessages.queue as AlertMessage[];
  }

  get testingDefaultOptions(): Partial<MessageOptions> {
    if (isTesting()) {
      return {
        timeout: 0
      };
    }

    return {};
  }

  clear(): void {
    this.flashMessages.clearMessages();
  }

  add(args: CustomMessageInfo): void {
    this.flashMessages.add({ ...args, ...this.testingDefaultOptions });
  }

  warning(
    message: string | SafeString,
    { sticky = true, preventDuplicates = false, qaCustomClassName = '', ...rest }: Partial<MessageOptions> = {}
  ): void {
    if (this._isValid(!!preventDuplicates, message)) {
      this.flashMessages.warning(message as string, {
        sticky,
        qaCustomClassName,
        ...rest,
        ...this.testingDefaultOptions
      });
    }
  }

  alert(
    message: string | SafeString,
    {
      sticky = false,
      timeout = 5000,
      preventDuplicates = false,
      qaCustomClassName = '',
      ...rest
    }: Partial<MessageOptions> = {}
  ): void {
    if (this._isValid(!!preventDuplicates, message)) {
      this.flashMessages.alert(message as string, {
        sticky,
        qaCustomClassName,
        timeout,
        ...rest,
        ...this.testingDefaultOptions
      });
    }
  }

  info(
    message: string | SafeString,
    { sticky = false, preventDuplicates = false, qaCustomClassName = '', ...rest }: Partial<MessageOptions> = {}
  ): void {
    if (this._isValid(!!preventDuplicates, message)) {
      this.flashMessages.info(message as string, { sticky, qaCustomClassName, ...rest, ...this.testingDefaultOptions });
    }
  }

  success(
    message: string | SafeString,
    { sticky = false, preventDuplicates = false, qaCustomClassName = '', ...rest }: Partial<MessageOptions> = {}
  ): void {
    if (this._isValid(!!preventDuplicates, message)) {
      this.flashMessages.success(message as string, {
        sticky,
        qaCustomClassName,
        ...rest,
        ...this.testingDefaultOptions
      });
    }
  }

  upgrade(
    message: string,
    {
      sticky = true,
      preventDuplicates = false,
      qaCustomClassName = '',
      type = 'alert',
      ...rest
    }: Partial<MessageOptions> = {}
  ): void {
    if (this._isValid(!!preventDuplicates, message)) {
      this.add({ message, sticky, qaCustomClassName, type, isUpgrade: true, ...rest, ...this.testingDefaultOptions });
    }
  }

  _isValid(preventDuplicates: boolean, message: string | SafeString): boolean {
    if ((!isString(message) && !isObjectLike(message)) || toString(message).includes('[Object object]')) {
      this.errors.log(new TypeError(`Alert message is not a string`));
      return false;
    }

    if (preventDuplicates) {
      return !this.queue.some((flashMessage) => flashMessage.message.toString() === message.toString());
    }

    return true;
  }
}

declare module '@ember/service' {
  interface Registry {
    alerts: AlertsService;
  }
}
