import ObjectProxy from '@ember/object/proxy';
import RSVP from 'rsvp';

export default function resolveObjectProxy(model) {
  return new RSVP.Promise(async (resolve, reject) => {
    if (model instanceof ObjectProxy) {
      try {
        const awaitedModel = await model;
        resolve(awaitedModel);
      } catch (error) {
        reject(error);
      }
    }

    resolve(model);
  });
}
