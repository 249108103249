import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { task, type TaskForAsyncTaskFunction } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type SubscriptionsService from 'later/services/subscriptions';

export default class AiCreditsService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare subscriptions: SubscriptionsService;

  @tracked remainingAICredits?: number;

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this.fetchUsagesRemaining.perform();
  }

  get aiCreditCountForPlan(): number {
    return this.subscriptions.subscriptionPlan?.numberOfAiCredits || 0;
  }

  fetchUsagesRemaining = task(async () => {
    try {
      const rawResponse = await this.auth.currentAccount.usagesRemaining({ feature: 'ai_credits' });

      if (rawResponse.errors?.length) {
        throw rawResponse.errors;
      }
      this.remainingAICredits = rawResponse.usages_remaining;
    } catch (error) {
      this.errors.log('fetchUsagesRemaining Error: ', error);
    }
  });

  @action
  optimisticallyRefundCredit(): void {
    if (this.remainingAICredits) {
      this.remainingAICredits = this.remainingAICredits + 1;
    }
  }

  performWithOptimisticCreditUsage = task(
    async (
      event: Event | undefined,
      task: TaskForAsyncTaskFunction<unknown, (...args: unknown[]) => Promise<unknown>>,
      ...args: unknown[]
    ) => {
      await task.perform(event, ...args);
      if (this.remainingAICredits) {
        this.remainingAICredits = this.remainingAICredits - 1;
      }
    }
  );

  performWithAiCreditSync = task(
    async (
      event: Event | undefined,
      task: TaskForAsyncTaskFunction<unknown, (...args: unknown[]) => Promise<unknown>>,
      ...args: unknown[]
    ) => {
      await task.perform(event, ...args);
      this.fetchUsagesRemaining.perform();
    }
  );
}

declare module '@ember/service' {
  interface Registry {
    'ai-credits': AiCreditsService;
  }
}
