import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type { Transition } from 'shared/types';

export default class PartnershipsRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  get socialIdentity(): SocialIdentityModel | undefined {
    const identity = this.group?.socialIdentities.firstObject;
    return identity;
  }

  redirect(_model: unknown, transition: Transition): void {
    if (!this.groupSlug || !this.socialIdentity) {
      this.router.transitionTo('/');
      return;
    }

    const { campaign_slug, feature, feature_slug } = transition.to.params;
    const { queryParams } = transition.to;

    switch (feature) {
      case 'feature-review':
        if (feature_slug) {
          this.router.transitionTo(
            'cluster.partnerships.profile.view-campaigns.campaign.product-review.review',
            this.groupSlug,
            this.socialIdentity.id,
            campaign_slug as string,
            feature_slug
          );
          break;
        }
        this.router.transitionTo(
          'cluster.partnerships.profile.view-campaigns.campaign.product-review',
          this.groupSlug,
          this.socialIdentity.id,
          campaign_slug as string
        );
        break;
      case 'submit-status':
        this.router.transitionTo(
          'cluster.partnerships.profile.view-campaigns.campaign.status',
          this.groupSlug,
          this.socialIdentity.id,
          campaign_slug as string
        );
        break;
      case 'submit-content':
        this.router.transitionTo(
          'cluster.partnerships.profile.view-campaigns.campaign.deliverables',
          this.groupSlug,
          this.socialIdentity.id,
          campaign_slug as string,
          {
            queryParams: {
              queryParams
            }
          }
        );
        break;
      default:
        if (campaign_slug === 'manage-campaigns') {
          this.router.transitionTo(
            'cluster.partnerships.profile.manage-campaigns',
            this.groupSlug,
            this.socialIdentity.id
          );
        } else if (campaign_slug === 'unsubscribe') {
          this.router.transitionTo('cluster.partnerships.profile.unsubscribe', this.groupSlug, this.socialIdentity.id);
        } else {
          this.router.transitionTo(
            'cluster.partnerships.profile.view-campaigns.campaign',
            this.groupSlug,
            this.socialIdentity.id,
            campaign_slug as string,
            {
              queryParams: {
                queryParams
              }
            }
          );
        }
    }
  }
}
