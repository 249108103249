import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

@classic
export default class AnalyticsReportAdapter extends ApplicationAdapter {
  urlForFindRecord(id, modelName, snapshot) {
    const baseUrl = this.buildURL(modelName, id, snapshot);
    let previewQuery = '';
    if (snapshot?.adapterOptions?.query?.preview) {
      previewQuery = '?preview=true';
    }

    return `${baseUrl}${previewQuery}`;
  }
}
