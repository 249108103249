import { A } from '@ember/array';
import { get } from '@ember/object';

// A utility function for filtering `items` by whether they have a field
// that matches `query`, case-insensitively. If `items` or `fields` is blank,
// returns an empty Array. If `query` is blank, returns all items.
export default function filterByFields(items = [], fields = [], query = '') {
  if (items.length === 0 || fields.length === 0) {
    return A();
  }

  if (query.length === 0) {
    return A(items);
  }

  const results = items.filter((item) =>
    fields.some((field) => {
      const targetVal = get(item, field) ?? '';
      const target = targetVal.toString().toLowerCase();
      return target.indexOf(query.toLowerCase()) > -1;
    })
  );

  return A(results);
}
