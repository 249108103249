import Service, { inject as service } from '@ember/service';
import { isBlank, isEmpty, isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { TrackedObject } from 'tracked-built-ins';

import { NULL_VALUE } from 'later/utils/constants';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import { queryListToString, buildQueryArray, enforceSingleProfile } from 'later/utils/format-query';

import type MutableArray from '@ember/array/mutable';
import type ArrayProxy from '@ember/array/proxy';
import type Store from '@ember-data/store';
import type LabelModel from 'later/models/label';
import type MediaItemModel from 'later/models/media-item';
import type SegmentService from 'later/services/segment';
import type { QueryListItem, DateFilter } from 'later/utils/format-query';
import type { Maybe } from 'shared/types';

type QueryStatus = {
  loading?: boolean;
  loadingMore?: boolean;
  started?: boolean;
  completed?: boolean;
  lastTime?: number;
};

type QueryArrayParams = {
  labelIds: number[];
  dateFilter?: string;
  searchString: string;
  usedUnused: string;
  mediaTypeFilter: string;
  socialProfileIds: number[];
  groupId: Maybe<string>;
  filterDatesHash?: DateFilter;
};

export type QueryWithFilterParams = QueryArrayParams & {
  labels?: LabelModel[];
  page?: string;
  successCallback?: () => unknown;
  failureCallback?: () => unknown;
  loadMore?: boolean;
  limit: number;
  trackEvent?: boolean;
};

/**
 * This service is used to query and fetch media items from the backend.
 */
export default class MediaItemQueryService extends Service {
  @service declare segment: SegmentService;
  @service declare store: Store;

  @tracked finishedLoadingQueryListData = [];
  @tracked queryListStartQuery = 0;
  @tracked queryListQuery = 0;
  @tracked queryStatuses: { [key: string]: QueryStatus | object } = new TrackedObject({});

  getQueryStatus(queryList: QueryListItem): QueryStatus {
    const queryListString = queryListToString(queryList);
    let queryStatus = this.queryStatuses[queryListString];

    if (isBlank(queryStatus)) {
      queryStatus = {
        queryListString
      };
      this.queryStatuses[queryListString] = queryStatus;
    }

    return queryStatus;
  }

  isLoading(queryList: QueryListItem): boolean {
    const queryStatus = this.getQueryStatus(queryList);
    return queryStatus.loading === true;
  }

  isLoadingMore(queryList: QueryListItem): boolean {
    const queryStatus = this.getQueryStatus(queryList);
    return queryStatus.loadingMore === true;
  }

  hasQueryStarted(queryList: QueryListItem): boolean {
    const queryStatus = this.getQueryStatus(queryList);
    return queryStatus.started === true;
  }

  isCompleted(queryList: QueryListItem): boolean {
    const queryStatus = this.getQueryStatus(queryList);
    return queryStatus.completed === true;
  }

  /**
   * Get the Unix timestamp of the earliest item in the query results
   */
  lastTimeQuery(queryList: QueryListItem): number {
    const queryStatus = this.getQueryStatus(queryList);
    return queryStatus.lastTime ?? 0;
  }

  loadQuery(queryList: QueryListItem): void {
    const queryStatus = this.getQueryStatus(queryList);
    queryStatus.started = true;
    queryStatus.loading = true;
  }

  loadMoreQuery(queryList: QueryListItem): void {
    const queryStatus = this.getQueryStatus(queryList);
    queryStatus.loadingMore = true;
  }

  finishQuery(queryList: QueryListItem, lastTime?: number): void {
    const queryStatus = this.getQueryStatus(queryList);
    queryStatus.loading = false;
    queryStatus.loadingMore = false;

    if (!isBlank(lastTime)) {
      queryStatus.lastTime = lastTime;
    }
  }

  completeQuery(queryList: QueryListItem): void {
    const queryStatus = this.getQueryStatus(queryList);
    queryStatus.completed = true;
  }

  queryArrayFromParams(params: QueryArrayParams): MutableArray<QueryListItem> {
    const { groupId, labelIds, searchString, dateFilter, usedUnused, mediaTypeFilter, socialProfileIds } = params;
    return buildQueryArray(labelIds, dateFilter, searchString, usedUnused, mediaTypeFilter, socialProfileIds, groupId);
  }

  matchedMediaItem(mediaItem: MediaItemModel, params: QueryArrayParams, _queryListString: string): boolean {
    const { groupId, labelIds, searchString, dateFilter, mediaTypeFilter, usedUnused, socialProfileIds } = params;
    let { filterDatesHash } = params;

    if (!isBlank(dateFilter) && typeof dateFilter == 'string' && isBlank(filterDatesHash)) {
      filterDatesHash = this.#getFilterDateHash(dateFilter);
    }
    const filterDateEarliest: number | undefined = filterDatesHash?.endDate;
    const filterDateLatest: number | undefined = filterDatesHash?.startDate;

    const queryListString = isBlank(_queryListString)
      ? queryListToString(
          buildQueryArray(labelIds, dateFilter, searchString, usedUnused, mediaTypeFilter, socialProfileIds, groupId)
        )
      : _queryListString;

    const lowestDate = this.lastTimeQuery(queryListString);
    const socialProfileId = enforceSingleProfile(socialProfileIds);

    const isCorrectGroup = isBlank(groupId) || mediaItem.get('group').get('id') === groupId;
    const isWithinDateRange =
      (!filterDateEarliest || mediaItem.get('createdTime') > filterDateEarliest) &&
      (!filterDateLatest || mediaItem.get('createdTime') < filterDateLatest);
    const isAboveLowestDate = isBlank(lowestDate) || mediaItem.get('createdTime') >= lowestDate;
    const matchesSearchTerm = isBlank(searchString) || mediaItem.matchesSearchTerm(searchString);
    const passesMediaTypeFilter =
      isBlank(mediaTypeFilter) ||
      (mediaTypeFilter === 'image' && mediaItem.isImage) ||
      (mediaTypeFilter === 'video' && mediaItem.isVideo) ||
      (mediaTypeFilter === 'gif' && mediaItem.isGif);
    const passesUsedUnusedFilter =
      isBlank(usedUnused) ||
      (isBlank(socialProfileId)
        ? (usedUnused === 'used' && mediaItem.get('hasPosts')) ||
          (usedUnused === 'unused' && !mediaItem.get('hasPosts'))
        : mediaItem.get('socialProfileIds').includes(socialProfileId) === (usedUnused === 'used'));
    const hasMatchingLabels =
      isEmpty(labelIds) ||
      mediaItem
        .get('labelIds')
        .filter((labelId) => labelIds.includes(parseInt(labelId)))
        .get('length') === labelIds.get('length');

    return (
      isCorrectGroup &&
      isWithinDateRange &&
      isAboveLowestDate &&
      matchesSearchTerm &&
      passesMediaTypeFilter &&
      passesUsedUnusedFilter &&
      hasMatchingLabels
    );
  }

  filterMediaItems(mediaItems: MutableArray<MediaItemModel>, params: QueryArrayParams): MediaItemModel[] {
    const queryArray = this.queryArrayFromParams(params);
    const queryListString = queryListToString(queryArray);

    return mediaItems.filter((mediaItem) => this.matchedMediaItem(mediaItem, params, queryListString));
  }

  matchedMediaItems(params: QueryArrayParams): MediaItemModel[] {
    return this.filterMediaItems(this.store.peekAll('media-item'), params);
  }

  queryWithFilters(_params: QueryWithFilterParams): void {
    const params = this.#sanitizeFilterParams(_params);
    const {
      dateFilter,
      failureCallback,
      filterDatesHash,
      groupId,
      labelIds,
      limit,
      loadMore,
      mediaTypeFilter,
      searchString,
      socialProfileIds,
      successCallback,
      trackEvent,
      usedUnused
    } = params;

    const queryArray = buildQueryArray(
      labelIds,
      dateFilter,
      searchString,
      usedUnused,
      mediaTypeFilter,
      socialProfileIds,
      groupId
    );
    const queryListString = queryListToString(queryArray);
    this.incrementProperty('queryListStartQuery');

    if (this.#isQueryFulfilled(queryListString, params)) {
      if (trackEvent) {
        const loadedMediaItems = this.matchedMediaItems(params);
        const numCurrentlyMatched = loadedMediaItems.get('length');
        this.#triggerFilterSegmentEvents(numCurrentlyMatched, params);
      }

      if (!isNone(successCallback)) {
        successCallback();
      }

      this.#bumpQueryNumber();
      return;
    }

    this.loadQuery(queryListString);

    let lowestDate: number | undefined;
    if (loadMore) {
      this.loadMoreQuery(queryListString);
      lowestDate = this.lastTimeQuery(queryListString);
    }

    const socialProfileId = enforceSingleProfile(socialProfileIds);

    this.store
      .query('media-item', {
        group_id: groupId,
        start_date: lowestDate,
        date_filter_latest: filterDatesHash?.endDate ?? undefined,
        date_filter_earliest: filterDatesHash?.startDate ?? undefined,
        type: 'labels',
        label_ids: labelIds,
        media_search: searchString,
        filter_by: usedUnused,
        media_type_filter: mediaTypeFilter,
        social_profile_id: socialProfileId,
        limit
      })
      .then(
        (moreMedia) => {
          this.#onQueryFulfilled(moreMedia, params, queryListString);

          if (!isNone(successCallback)) {
            successCallback();
          }

          this.#bumpQueryNumber();
        },
        () => {
          // TODO: check for 204 status response
          this.finishQuery(queryListString);
          this.completeQuery(queryListString);
          this.#bumpQueryNumber();

          if (!isNone(failureCallback)) {
            failureCallback();
          }
        }
      );
  }

  #bumpQueryNumber(): void {
    this.incrementProperty('queryListQuery');
  }

  #getFilterDateHash(dateFilter: string | undefined): DateFilter {
    // Note: startDate is the latest (chronological) date, endDate is the earliest (chronological) date --G
    if (dateFilter === 'week') {
      return {
        startDate: undefined,
        endDate: moment().subtract(7, 'days').startOf('day').unix()
      };
    } else if (dateFilter === 'month') {
      return {
        startDate: moment().subtract(7, 'days').startOf('day').unix(),
        endDate: moment().subtract(1, 'month').startOf('day').unix()
      };
    } else if (dateFilter === '3months') {
      return {
        startDate: moment().subtract(1, 'month').startOf('day').unix(),
        endDate: moment().subtract(3, 'months').startOf('day').unix()
      };
    } else if (dateFilter === '6months') {
      return {
        startDate: moment().subtract(3, 'months').startOf('day').unix(),
        endDate: moment().subtract(6, 'months').startOf('day').unix()
      };
    }
    return { startDate: undefined, endDate: undefined };
  }

  #isQueryFulfilled(queryListString: string, params: QueryWithFilterParams): boolean {
    const { loadMore } = params;
    return (
      this.isCompleted(queryListString) ||
      this.isLoading(queryListString) ||
      this.isLoadingMore(queryListString) ||
      (this.hasQueryStarted(queryListString) && !loadMore)
    );
  }

  #onQueryFulfilled(
    moreMedia: ArrayProxy<MediaItemModel>,
    queryParams: QueryWithFilterParams,
    queryListString: string
  ): void {
    const { searchString, trackEvent, limit } = queryParams;

    if (trackEvent) {
      const numMatchedMedia = this.matchedMediaItems(queryParams).get('length');
      this.#triggerFilterSegmentEvents(numMatchedMedia, queryParams);
    }

    // Note: apply filter tag for search term
    if (!isBlank(searchString)) {
      moreMedia.forEach((item) => {
        item.addSearchTerm(searchString);
      });
    }

    const lastObject = moreMedia.sortBy('createdTime:desc').get('lastObject');
    const lastTime = lastObject?.get('createdTime') ?? undefined;
    this.finishQuery(queryListString, lastTime);

    // Note: check to see if we are completely done with query list
    const serverLimitMax = 100;
    const numResults = moreMedia.get('length');

    if (numResults === 0 || (numResults < limit && numResults < serverLimitMax)) {
      this.completeQuery(queryListString);
    }
  }

  #sanitizeFilterParams(params: QueryWithFilterParams): QueryWithFilterParams {
    const { labelIds, labels, dateFilter, filterDatesHash, limit } = params;
    params.trackEvent = params.trackEvent ?? true;

    if (isBlank(limit) || limit < 0 || limit > 50) {
      params.limit = 50;
    }

    if (!isBlank(dateFilter) && isBlank(filterDatesHash)) {
      params.filterDatesHash = this.#getFilterDateHash(dateFilter);
    }

    if (labels !== undefined && isBlank(labelIds)) {
      params.labelIds = labels.map((label) => parseInt(label.get('id')));
    }

    return params;
  }

  #triggerFilterSegmentEvents(numResults: number, filterParams: QueryWithFilterParams): void {
    const { labelIds, dateFilter, searchString, groupId, page, mediaTypeFilter, usedUnused, socialProfileIds } =
      filterParams;
    const mediaUsage = usedUnused ?? 'all';
    const filterName = mediaTypeFilter ?? 'all';

    let socialProfilesPlatforms = this.store
      .peekAll('social-profile')
      .filter(
        (socialProfile) => !isBlank(socialProfileIds) && socialProfileIds.includes(parseInt(socialProfile.get('id')))
      )
      .mapBy('profileType');

    socialProfilesPlatforms = [...new Set(socialProfilesPlatforms)].sort();

    const labels = this.store
      .peekAll('label')
      .filter((label) => !isBlank(labelIds) && labelIds.includes(parseInt(label.get('id'))));

    const systemLabels = labels.filterBy('isSystemLabel', true);
    const nonSystemLabels = labels.filterBy('isSystemLabel', false);
    const filteredEventPayload = {
      group_id: groupId ?? NULL_VALUE,
      search_string: searchString,
      date_range: dateFilter ?? NULL_VALUE,
      num_results: numResults,
      media_type_filter: filterName,
      media_usage: mediaUsage,
      label_count: nonSystemLabels.get('length'),
      system_label_count: systemLabels.get('length'),
      social_platforms: socialProfilesPlatforms.map((platform) => platform || '')
    };

    if (page === 'side-library') {
      this.segment.track(SegmentEventTypes.FilteredSideLibrary, filteredEventPayload);
    } else {
      this.segment.track(SegmentEventTypes.FilteredMediaLibrary, filteredEventPayload);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'media-item-query': MediaItemQueryService;
  }
}
