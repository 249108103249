/**
 * Toggles a class on an HTML element.
 *
 *
 * @param elementSelector A CSS selector for the element to toggle the class on. E.g., this could be a class (.className) or an ID (#idName).
 *
 * @param className The class to toggle on the element.
 *
 * @param shouldAddClass A boolean that determines whether the class should be added or removed.
 *
 */
const toggleClass = (elementSelector: string, className: string, shouldAddClass: boolean): void => {
  const targetElement = document.querySelector<HTMLElement>(elementSelector);

  shouldAddClass ? targetElement?.classList.add(className) : targetElement?.classList.remove(className);
};

export default toggleClass;
