import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';

import type { ExternalCampaignFields, ManageCampaignsTabOptions, RequiredInfluencerData } from 'influencers/types';
import type Brand from 'later/models/brand';
import type InfluencerModel from 'later/models/influencer';
import type PartnershipsAttachment from 'later/models/partnerships-attachment';
import type SocialIdentityModel from 'later/models/social-identity';

interface ManageInfluencersPayload {
  status: ManageCampaignsTabOptions;
}
interface ManageInfluencersResponse {
  influencers: InfluencerModel[];
}

export default class ArchivedCampaignModel extends Model {
  @belongsTo('brand') declare brand: Brand;
  @belongsTo('socialIdentity') declare socialIdentity: SocialIdentityModel;
  @hasMany('partnershipsAttachment') declare campaignImages: PartnershipsAttachment[];

  @attr('string') declare name: string;
  @attr('string') declare about: string;
  @attr('string') declare minMonetaryCompensation: string;
  @attr('string') declare maxMonetaryCompensation: string;
  @attr('string') declare otherCompensation: string;
  @attr('string') declare deadline: string;
  @attr('string') declare deliverables: string;
  @attr('string') declare startDate: string;
  @attr('string') declare endDate: string;
  @attr('string') declare goal: string;
  @attr('string') declare keywords: string;
  @attr('boolean') declare hasApplied: boolean;
  @attr('boolean', { defaultValue: true }) declare visible: boolean;
  @attr('boolean', { defaultValue: false }) declare archived: boolean;
  @attr('string') declare origin: 'Mavrck' | 'Later';
  @attr declare externalCampaignFields?: ExternalCampaignFields;
  @attr('string') declare requiredInfluencerData?: RequiredInfluencerData;

  inactiveInfluencers: number[] = [];

  influencers: (this: Model) => Promise<{ influencers: InfluencerModel[]; inactive_influencers: number[] }> =
    async function () {
      try {
        const response = await fetch(`/api/v2/campaigns/${this.id}/influencers`, { method: 'GET' });
        if (response.ok) {
          return await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    };

  manageInfluencers = memberAction<ManageInfluencersPayload, ManageInfluencersResponse>({
    type: 'GET',
    path: 'influencers/manage'
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'archived-campaign': ArchivedCampaignModel;
  }
}
