import { reads, notEmpty, alias } from '@ember/object/computed';
import { isNone, isEmpty } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

import { type SocialProfileType, URL_VALIDATION_REGEX, type PostType } from 'later/utils/constants';

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type GramModel from 'later/models/gram';
import type LinkinbioPostLinkModel from 'later/models/linkinbio-post-link';
import type SocialProfileModel from 'later/models/social-profile';
import type { Moment } from 'moment';
import type { Maybe } from 'shared/types';

const Validations = buildValidations({
  linkUrl: [
    validator('format', {
      allowBlank: true,
      type: 'url',
      description: 'Link',
      regex: URL_VALIDATION_REGEX
    }),
    validator('uses-own-lib-url', {
      isWarning: true,
      dependentKeys: ['socialProfile.linkinbioUrl']
    })
  ]
});

export default class LinkinbioPostModel extends Model.extend(Validations) {
  isLinkinbioPost = true;

  @belongsTo('gram', { async: true }) declare gram: AsyncBelongsTo<GramModel>;
  @belongsTo('socialProfile', { async: true }) declare socialProfile: AsyncBelongsTo<SocialProfileModel>;

  @hasMany('linkinbio-post-link', { async: false }) declare linkinbioPostLinks: AsyncHasMany<LinkinbioPostLinkModel>;

  @attr('string') declare caption?: string | null;
  @attr('number') declare readonly createdTime?: number;
  @attr('string') declare readonly highResUrl?: string;
  @attr('string') declare readonly largeThumbnailUrl?: string;
  @attr('string') declare linkUrl?: string | null;
  @attr('string') declare readonly lowResUrl?: string;
  @attr('string') declare readonly medResUrl?: string;
  @attr('string') declare readonly medThumbnailUrl?: string;
  @attr('string') declare mediaId?: string | null;
  @attr('string') declare mediaType?: 'image' | 'video';
  @attr('string') declare networkType: SocialProfileType;
  @attr('string') declare processingUrl?: string | null;
  @attr('number') declare postedTime?: number;
  @attr('string') declare postType: PostType;
  @attr('string') declare readonly smallThumbnailUrl?: string | null;

  /**
   * True for posts that have left Later or have been sourced from instagram
   * These posts may not necessarily be posted on Instagram
   * (eg. It may have come from a post that was opened in instagram but not published)
   *
   * also see {@link LinkinbioPostModel.isPostedOnIg}
   */
  @notEmpty('postedTime') declare isPosted: boolean;

  @alias('gram.id') declare gramId: string;
  @alias('postedTime') declare displayTime: number;

  @reads('gram.scheduledTime') declare scheduledTime: number;

  get createdMoment(): Moment | undefined {
    const postedTime = this.postedTime || this.gram.get('postedTime');

    if (!postedTime) {
      return;
    }

    return moment(postedTime * 1000);
  }

  /**
   * True for posts that are confirmed to be posted on IG
   */
  get isPostedOnIg(): boolean {
    return this.isPosted && !isEmpty(this.mediaId);
  }

  get formattedIntendedPostedTime(): string | undefined {
    if (this.scheduledTime) {
      return moment.unix(this.scheduledTime).format('MMM D, YYYY');
    }
    return this.createdMoment?.format('MMM D, YYYY');
  }

  get medThumbnailOrProcessingUrl(): Maybe<string> {
    if (!isNone(this.medThumbnailUrl)) {
      return this.medThumbnailUrl;
    }
    return this.processingImageUrl;
  }

  get previewGridImageUrl(): Maybe<string> {
    if (window.devicePixelRatio >= 2) {
      return this.medThumbnailOrProcessingUrl;
    }
    return this.processingImageUrl;
  }

  get highResOrProcessingUrl(): Maybe<string> {
    if (!isNone(this.highResUrl)) {
      return this.highResUrl;
    }
    return this.processingImageUrl;
  }

  get lowResOrProcessingUrl(): Maybe<string> {
    if (!isNone(this.lowResUrl)) {
      return this.lowResUrl;
    }
    return this.processingImageUrl;
  }

  get processingImageUrl(): Maybe<string> {
    if (this.get('isNew')) {
      return this.processingUrl;
    } else if (this.id) {
      return '/api/v2/linkinbio_posts/' + this.id + '/processing_image';
    }

    return;
  }

  get hasMultiLinks(): boolean {
    return this.activeLinks.length > 0;
  }

  get activeLinks(): LinkinbioPostLinkModel[] {
    return this.linkinbioPostLinks.filter((link) => !link.get('isDeleted'));
  }

  get isUnverified(): boolean {
    return isEmpty(this.mediaId) && !isEmpty(this.get('gram')?.get('postedTime'));
  }

  get hasDirtyLinks(): boolean {
    return this.linkinbioPostLinks.any((link) => link.get('hasDirtyAttributes'));
  }

  get isEmptyPost(): boolean {
    return this.get('isNew') && !this.linkUrl && this.linkinbioPostLinks.length === 0;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-post': LinkinbioPostModel;
  }
}
