/* eslint-disable unicorn/no-null */
import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { preciseRound } from '@latermedia/ember-later-analytics/utils';
import { isNumber } from 'lodash';
import moment from 'moment';

import { filterTimeSeries } from 'later/utils/array-filters';

import type TableService from 'later/services/analytics/formatters/table';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type { Moment } from 'moment';
import type { FormattedDynamoTiktokPost, DefaultTiktokPostRow } from 'shared/types/analytics-data/media';

export default class TiktokPostsService extends Service {
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/formatters/table') declare tableFormatters: TableService;

  /**
   * Key names to be used to form table columns
   */
  names = ['reach', 'likeCount', 'commentCount', 'shareCount'];

  get defaultRow(): DefaultTiktokPostRow {
    return {
      id: null,
      url: null,
      instagramLink: null,
      type: null,
      isVideo: false,
      isCarousel: false,
      isDeleted: false,
      createdAt: '',
      createdAtTime: '',
      timestampLongFormat: '',
      createdAtUnix: null,
      caption: '-',
      engagement: this.tableFormatters.buildDefaultTableObject(),
      reach: this.tableFormatters.buildDefaultTableObject(),
      likeCount: this.tableFormatters.buildDefaultTableObject(),
      commentCount: this.tableFormatters.buildDefaultTableObject(),
      videoViews: this.tableFormatters.buildDefaultTableObject(),
      shareCount: this.tableFormatters.buildDefaultTableObject(),
      totalTimeWatched: this.tableFormatters.buildDefaultTableObject(),
      fullVideoWatchedRate: this.tableFormatters.buildDefaultTableObject()
    };
  }

  /**
   * Takes an array of posts, formats each post for the
   * posts table according to the given post key names
   *
   * @returns Array of posts formatted for the posts table
   */
  processPosts(
    posts: FormattedDynamoTiktokPost[] = [],
    startDate: Moment,
    endDate: Moment,
    analyticsStandard: boolean
  ): FormattedDynamoTiktokPost[] {
    if (isEmpty(posts)) {
      return posts;
    }

    const filteredPosts = analyticsStandard
      ? filterTimeSeries(posts, startDate.unix(), endDate.unix(), 'createdTime')
      : posts;

    return filteredPosts
      .map((post) => {
        const isCarousel = post.type === 'carousel';
        const values = this.tableFormatters.getValues(this.names, post, filteredPosts);
        const tableRow = this.tableFormatters.buildTableRow(values);

        const videoViewsSanitized = post.type === 'video' && post.videoViews ? post.videoViews : 'N/A';

        const ONE_MILLISECOND = 1000;
        const totalTimeWatched = post.totalTimeWatched ? post.totalTimeWatched * ONE_MILLISECOND : 'N/A';

        const engagementPercent = post.engagement * 100;
        const fullVideoWatchedRate = post.fullVideoWatchedRate ? post.fullVideoWatchedRate : 'N/A';
        const fullVideoWatchedRatePercent = post.fullVideoWatchedRate ? post.fullVideoWatchedRate * 100 : 'N/A';

        const processedPost = Object.assign(
          {},
          {
            id: post.id,
            url: post.thumbUrl,
            createdAt: this.helpersAnalytics.createMomentInTz(post.createdTime).format('MMM D, YYYY') || '-',
            createdAtTime: this.helpersAnalytics.createMomentInTz(post.createdTime).format('LT') || '-',
            timestampLongFormat:
              this.helpersAnalytics.createMomentInTz(post.createdTime).format('MMM D, YYYY hh:mm:ss A') || null,
            createdAtUnix: post.createdTime,
            caption: post.caption || null,
            engagement: {
              value: this.tableFormatters.isValid(post.engagement) ? `${engagementPercent.toFixed(2)}%` : '-',
              CSVValue: this.tableFormatters.isValid(post.engagement) ? `${preciseRound(engagementPercent, 2)}%` : null,
              raw: this.tableFormatters.isValid(post.engagement) ? Number(post.engagement) : null,
              scaled: this.tableFormatters.createElementWidthAttr(post.engagement)
            },
            type: post.type,
            isVideo: post.type === 'video',
            isCarousel,
            isDeleted: post.isDeleted,
            videoViews: {
              value: isPresent(videoViewsSanitized) ? videoViewsSanitized : '-',
              CSVValue: this.tableFormatters.isValid(videoViewsSanitized) ? post.videoViews : null,
              raw: this.tableFormatters.isValid(videoViewsSanitized) ? post.videoViews : null,
              scaled: this.tableFormatters.scaleValue(
                this.tableFormatters.getValidValues(posts, 'videoViews'),
                post.videoViews ?? 0
              )
            },
            totalTimeWatched: {
              value: moment.utc(totalTimeWatched).format('HH[h]:mm[m]:ss[s]'),
              CSVValue: this.tableFormatters.isValid(totalTimeWatched) ? totalTimeWatched : null,
              raw: this.tableFormatters.isValid(totalTimeWatched) ? Number(totalTimeWatched) : null,
              scaled: this.tableFormatters.createElementWidthAttr(totalTimeWatched)
            },
            fullVideoWatchedRate: {
              value:
                this.tableFormatters.isValid(fullVideoWatchedRate) && isNumber(fullVideoWatchedRatePercent)
                  ? `${fullVideoWatchedRatePercent.toFixed(2)}%`
                  : '-',
              CSVValue: this.tableFormatters.isValid(fullVideoWatchedRate)
                ? `${preciseRound(fullVideoWatchedRatePercent, 2)}%`
                : null,
              raw: this.tableFormatters.isValid(fullVideoWatchedRate) ? Number(post.fullVideoWatchedRate) : null,
              scaled: this.tableFormatters.createElementWidthAttr(fullVideoWatchedRate)
            }
          },
          ...tableRow
        );

        return EmberObject.create(processedPost);
      })
      .reverse();
  }
}
