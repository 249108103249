import Service, { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';

import { INSTAGRAM_PRODUCT_TAG_KEY } from 'later/utils/constants';

import type InstagramProductCatalogService from 'later/services/social/instagram-product-catalog';
import type FacebookProduct from 'shared/utils/facebook-product';

export interface ProductTag {
  x: number;
  y: number;
  [INSTAGRAM_PRODUCT_TAG_KEY]: string;
  product?: FacebookProduct;
}

export default class InstagramProductTaggingService extends Service {
  @service('social/instagram-product-catalog') declare instagramProductCatalog: InstagramProductCatalogService;

  getRawTags(productTags: ProductTag[]): ProductTag[] {
    return productTags.map((tag) => ({
      x: tag.x,
      y: tag.y,
      [INSTAGRAM_PRODUCT_TAG_KEY]: tag[INSTAGRAM_PRODUCT_TAG_KEY]
    }));
  }

  findTagIndex(productTags: ProductTag[], tag: ProductTag): number {
    return productTags.findIndex(
      (productTag) => productTag[INSTAGRAM_PRODUCT_TAG_KEY] == tag[INSTAGRAM_PRODUCT_TAG_KEY]
    );
  }

  removeTag(productTags: ProductTag[], tag: ProductTag): void {
    const index = this.findTagIndex(productTags, tag);
    if (index >= 0) {
      productTags.removeAt(index);
    }
  }

  getProductTags = task(async (rawTags: ProductTag[] = [], businessAccountToken: string) => {
    return await all((rawTags ?? []).map((rawTag) => this.getProductTag.perform(rawTag, businessAccountToken)));
  });

  getProductTag = task(async (tag: ProductTag, businessAccountToken: string) => {
    const product = await this.instagramProductCatalog.fetchAndCacheProduct.perform(
      tag[INSTAGRAM_PRODUCT_TAG_KEY],
      businessAccountToken
    );

    return {
      x: tag.x,
      y: tag.y,
      [INSTAGRAM_PRODUCT_TAG_KEY]: tag[INSTAGRAM_PRODUCT_TAG_KEY],
      product
    };
  });
}

declare module '@ember/service' {
  interface Registry {
    'social/instagram-product-tagging': InstagramProductTaggingService;
  }
}
