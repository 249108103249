import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import moment from 'moment';

import type { AsyncBelongsTo } from '@ember-data/model';
import type LinkinbioPostModel from 'later/models/linkinbio-post';

@classic
export default class LinkinbioPostLinkModel extends Model {
  @belongsTo('linkinbio-post', { async: true }) declare linkinbioPost: AsyncBelongsTo<LinkinbioPostModel>;

  @attr('number') declare x?: number;
  @attr('number') declare y?: number;
  @attr('string', { defaultValue: '' }) declare linkUrl: string;
  @attr('string') declare createdAt?: string;
  @attr('string') declare title?: string;

  get createdTime(): string {
    return moment(this.createdAt).format('x');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-post-link': LinkinbioPostLinkModel;
  }
}
