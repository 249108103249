/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { task, keepLatestTask, restartableTask } from 'ember-concurrency';

import { getLastValue, getSumOfExtractedValues } from 'analytics/utils/array-filters';
import formatMediaEngagementFromDynamo from 'analytics/utils/formatters/format-media-engagement-from-dynamo';
import createIgHashtagFromDynamo from 'analytics/utils/formatters/ig-hashtag-from-dynamo';
import createIgStoryFromDynamo from 'analytics/utils/formatters/ig-story-from-dynamo';
import 'later/types/typedef';
import hashCode from 'shared/utils/hash-code';

/**
 * @class AnalyticsDynamoService
 * @extends Service
 */
export default class DynamoAnalyticsService extends Service {
  @service('analytics/dynamo-api') dynamoApi;
  @service('analytics/helpers-analytics') helpersAnalytics;
  @service analytics;
  @service('analytics/media-analytics') mediaAnalytics;
  @service('analytics/formatters/table/stories') formattersStoriesTable;

  /**
   * The current social profile
   *
   * @property socialProfile
   * @type {SocialProfile}
   */
  get socialProfile() {
    return this.analytics.socialProfile;
  }

  /**
   * Default start date for data calls in this service
   *
   * @property startDate
   * @type {(Moment|Date)}
   * @default
   */
  get startDate() {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  /**
   * Default end date for data calls in this service
   *
   * @property endDate
   * @type {(Moment|Date)}
   * @default
   */
  get endDate() {
    return this.helpersAnalytics.createMomentInTz();
  }

  /**
   * Gets dynamo profile counts object
   * containing media, followed and following counts
   *
   * @property getDynamoProfileCounts
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {IGProfileCount}
   */
  getDynamoProfileCounts = task(async (forceRefresh = false) => {
    return {
      mediaCount: getLastValue(
        await this.getMediaCountOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'media_count'
      ),
      followedByCount: getLastValue(
        await this.getFollowersOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'followers'
      ),
      followsCount: getLastValue(
        await this.getFollowingOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'follows_count'
      )
    };
  });

  /**
   * Gets tiktok dynamo profile counts object
   * containing media, followed and following counts
   *
   * @property getTiktokDynamoProfileCounts
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {IGProfileCount}
   */
  getTiktokDynamoProfileCounts = task(async (forceRefresh = false) => {
    return {
      followedByCount: getLastValue(
        await this.getFollowersOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'followers'
      ),
      likesCount: getSumOfExtractedValues(
        await this.getLikesOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'likes'
      ),
      commentsCount: getSumOfExtractedValues(
        await this.getCommentsOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'comments'
      ),
      sharesCount: getSumOfExtractedValues(
        await this.getSharesOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'shares'
      ),
      videoViewsCount: getSumOfExtractedValues(
        await this.getVideoViewsOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'video_views'
      ),
      profileViewsCount: getSumOfExtractedValues(
        await this.getProfileViewsOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'profile_views'
      )
    };
  });

  /**
   * Gets Facebook dynamo profile counts object
   * containing media, followed and following counts
   *
   * @property getFacebookDynamoProfileCounts
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {FBProfileCount}
   */
  getFacebookDynamoProfileCounts = task(async (forceRefresh = false) => {
    return {
      reachCount: getSumOfExtractedValues(
        await this.getReachOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'reach'
      ),
      pageLikeCount: getLastValue(
        await this.getFacebookPageLikesOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'page_likes'
      ),
      pageViewCount: getSumOfExtractedValues(
        await this.getFacebookPageViewsOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'page_views'
      ),
      reactionCount: getSumOfExtractedValues(
        await this.getFacebookReactionsOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'reactions'
      ),
      engagementCount: getSumOfExtractedValues(
        await this.getFacebookEngagementOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'engagement'
      ),
      impressionCount: getSumOfExtractedValues(
        await this.getImpressionsOverTime.linked().perform(this.startDate, this.endDate, forceRefresh),
        'impressions'
      )
    };
  });

  /**
   * Gets all meta data for given property name.
   * Note: no network request is made, so forceRefresh is true by default
   *
   * @property getMeta
   * @param {String} propertyName The name of the property in the meta cache
   * @param {Number} [mediaId=null] The id of the media item (if there is one)
   * @param {String} [metaSubName=null] The sub name of the meta object (if there is one)
   *
   * @returns {Meta} The meta data object
   */
  getMeta = task(async (propertyNames, mediaId = null) => {
    const [endpoint, ...extraParams] = propertyNames;
    const mediaObj = mediaId ? { mediaId } : {};
    const hashObj = { endpoint, ...extraParams, ...mediaObj };

    const stringifiedParams = JSON.stringify({
      dataType: 'meta',
      ...hashObj,
      socialProfileId: this.analytics?.currentSocialProfile?.id
    });
    const hashedKey = `dynamoApi_${hashCode(stringifiedParams)}`;

    return await this.dynamoApi.cache.retrieve(hashedKey);
  });

  /**
   * Returns Instagram profile views count over time.
   *
   * @property getProfileViewsOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGPageView>} Instagram profile views count over time.
   */
  getProfileViewsOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const profileViewsOverTime = profileInsights.map(({ end_time, profile_views }) => ({
      time: end_time,
      profile_views
    }));

    return profileViewsOverTime;
  });

  /**
   * Returns Instagram website clicks over time.
   *
   * @property getWebsiteClicksOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGPageClick>} Instagram website clicks over time.
   */
  getWebsiteClicksOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const websiteClicksOverTime = profileInsights.map(({ end_time, website_clicks }) => ({
      time: end_time,
      website_clicks
    }));

    return websiteClicksOverTime;
  });

  /**
   * Returns Instagram impressions count over time.
   *
   * @property getImpressionsOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGImpression>} Instagram impressions count over time.
   */
  getImpressionsOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const impressionsOverTime = profileInsights.map(({ end_time, impressions }) => ({
      time: end_time,
      impressions
    }));

    return impressionsOverTime;
  });

  /**
   * Returns Facebook page likes count over time.
   *
   * @property getFacebookPageLikesOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<FBPageLikes>} Facebook page likes count over time.
   */
  getFacebookPageLikesOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const facebookPageLikesOverTime = profileInsights.map(({ end_time, page_likes }) => ({
      time: end_time,
      page_likes: page_likes ?? null
    }));

    return facebookPageLikesOverTime;
  });

  /**
   * Returns Facebook page views count over time.
   *
   * @property getFacebookPageViewsOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<FBPageViews>} Facebook page views count over time.
   */
  getFacebookPageViewsOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const facebookPageViewsOverTime = profileInsights.map(({ end_time, page_views }) => ({
      time: end_time,
      page_views: page_views ?? null
    }));

    return facebookPageViewsOverTime;
  });

  /**
   * Returns Facebook reactions count over time.
   *
   * @property getFacebookReactionsOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<FBReactions>} Facebook reactions count over time.
   */
  getFacebookReactionsOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const facebookReactionsOverTime = profileInsights.map(({ end_time, reactions }) => ({
      time: end_time,
      reactions: reactions ?? null
    }));

    return facebookReactionsOverTime;
  });

  /**
   * Returns Facebook engagement count over time.
   *
   * @property getFacebookEngagementOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<FBEngagement>} Facebook engagement count over time.
   */
  getFacebookEngagementOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const facebookEngagementOverTime = profileInsights.map(({ end_time, engagement }) => ({
      time: end_time,
      engagement: engagement ?? null
    }));

    return facebookEngagementOverTime;
  });

  /**
   * Returns Instagram reach count over time.
   *
   * @property getReachOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGReach>} Instagram reach count over time.
   */
  getReachOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);

    const reachOverTime = profileInsights.map(({ end_time, reach }) => ({ time: end_time, reach }));

    return reachOverTime;
  });

  /**
   * Returns Instagram media count over time.
   *
   * @property getMediaCountOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGMediaCount>} Instagram media count over time.
   */
  getMediaCountOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileCounts.linked().perform(startDate, endDate, forceRefresh);

    const mediaCountOverTime = profileCounts.map(({ time, media_count }) => ({ time, media_count }));

    return mediaCountOverTime;
  });

  /**
   * Returns Instagram following count over time.
   *
   * @property getFollowingOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGFollowingCount>} Instagram following count over time.
   */
  getFollowingOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileCounts.linked().perform(startDate, endDate, forceRefresh);

    const followingOverTime = profileCounts.map(({ time, follows_count }) => ({ time, follows_count }));

    return followingOverTime;
  });

  /**
   * Returns Instagram/Tiktok followers count over time.
   *
   * @property getFollowersOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGFollowerCount>} Instagram followers count over time.
   */
  getFollowersOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileCounts.linked().perform(startDate, endDate, forceRefresh);
    const followersOverTime = profileCounts.map(({ time, followed_by_count }) => ({
      time,
      followers: followed_by_count
    }));

    return followersOverTime;
  });

  /**
   * Returns Tiktok Likes count over time.
   *
   * @property getLikesOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGFollowerCount>} Tiktok Likes count over time.
   */
  getLikesOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);
    const likesOverTime = profileCounts.map(({ end_time, likes }) => ({
      time: end_time,
      likes
    }));

    return likesOverTime;
  });

  /**
   * Returns Tiktok Comments count over time.
   *
   * @property getCommentsOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGFollowerCount>} Tiktok Comments count over time.
   */
  getCommentsOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);
    const commentsOverTime = profileCounts.map(({ end_time, comments }) => ({
      time: end_time,
      comments
    }));

    return commentsOverTime;
  });

  /**
   * Returns Tiktok Shares count over time.
   *
   * @property getSharesOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<IGFollowerCount>} Tiktok Shares count over time.
   */
  getSharesOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);
    const sharesOverTime = profileCounts.map(({ end_time, shares }) => ({
      time: end_time,
      shares
    }));

    return sharesOverTime;
  });

  /**
   * Returns Tiktok VideoViews count over time.
   *
   * @property getVideoViewsOverTime
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array.<TiktokVideoViewsCount>} Tiktok VideoViews count over time.
   */
  getVideoViewsOverTime = task(async (startDate, endDate, forceRefresh = false) => {
    const profileCounts = await this.dynamoApi.getProfileInsights.linked().perform(startDate, endDate, forceRefresh);
    const videoViewsOverTime = profileCounts.map(({ end_time, video_views }) => ({
      time: end_time,
      video_views
    }));

    return videoViewsOverTime;
  });

  /**
   * Fetches a story for the given mediaId.
   *
   * @property getStoryById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {UntypedLaterModel<'IgStory'> | null} The story associated with the given mediaId
   */
  getStoryById = task(async (mediaId, forceRefresh = false) => {
    const stories = await this.dynamoApi.getPostsByIds.linked().perform([mediaId], forceRefresh);

    if (stories && stories.length) {
      return createIgStoryFromDynamo(
        stories.find((story) => story.id === mediaId),
        this.socialProfile
      );
    }

    return null;
  });

  /**
   * Returns Instagram hashtags
   *
   * @property getFormattedHashtags
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<IgPost>} Instagram hashtags in the given time range.
   */
  getFormattedHashtags = keepLatestTask(async (startDate, endDate, forceRefresh = false) => {
    const result = await this.dynamoApi.getHashtags.linked().perform(startDate, endDate, forceRefresh);
    const hashtags = result && Object.keys(result).length ? result : [];

    const uniqueMediaIds = this._getMediaIdsFromHashtags(hashtags);
    const fetchedMedia = await this.mediaAnalytics.getPostsByIds.linked().perform(uniqueMediaIds, forceRefresh);

    return Object.keys(hashtags).map((hashtagName) => {
      const values = hashtags[hashtagName];
      const media = values.media.map((referencedItem) => fetchedMedia.find((item) => item.id === referencedItem.id));

      return createIgHashtagFromDynamo(values, hashtagName, media);
    });
  });

  /**
   * Returns Instagram stories
   *
   * @property getFormattedStories
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<IgPost>} Instagram stories in the given time range.
   */
  getFormattedStories = restartableTask(async (startDate, endDate, limit, cursor = null) => {
    const result = await this.dynamoApi.getPaginatedStories.linked().perform(startDate, endDate, limit, cursor);
    const { data, cursors } = result;
    const stories = !data || data.length === 0 ? [] : data;

    return { data: stories.map((story) => createIgStoryFromDynamo(story, this.socialProfile)), cursors };
  });

  /**
   * Returns all Instagram stories in a selected time range for analytics standard users for use with Export CSV.
   *
   * @property continueLoadingInstagramStoriesData
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(Number)} limit Maximum number of posts to return per page
   * @param {(String)} cursor Current pagination cursor position
   * @param {(Array<IgPost>)} accumulator Current accumulated posts
   *
   * @return {Array<IgPost>} Instagram posts in the given time range.
   */
  continueLoadingInstagramStoriesData = task(async (startDate, endDate, limit, cursor, accumulator = []) => {
    const { cursors, data } = await this.getFormattedStories.perform(startDate, endDate, limit, cursor);
    accumulator.push(...data);

    if (cursors.next) {
      return await this.continueLoadingInstagramStoriesData.perform(
        startDate,
        endDate,
        limit,
        cursors.next,
        accumulator
      );
    }

    const processedStories = this.formattersStoriesTable.processStories([...accumulator].reverse(), startDate, endDate);

    return await { graphData: processedStories };
  });

  /**
   * Returns All Country data from ProfileInsightsLifetime
   * over 3 month period
   *
   * @property getCountries
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<CountryResponse>} Formatted countries response
   */
  getCountries = task(async (forceRefresh = false) => {
    return await this._getProfileInsightsProperty.linked().perform('country', forceRefresh);
  });

  /**
   * Returns All City data from ProfileInsightsLifetime
   * over 3 month period
   *
   * @property getCities
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<CityResponse>} Formatted cities response
   */
  getCities = task(async (forceRefresh = false) => {
    return await this._getProfileInsightsProperty.linked().perform('city', forceRefresh);
  });

  /**
   * Returns All Languages data from ProfileInsightsLifetime
   * over 3 month period
   *
   * @property getLanguages
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<LocaleResponse>} Formatted languages response
   */
  getLanguages = task(async (forceRefresh = false) => {
    return await this._getProfileInsightsProperty.linked().perform('locale', forceRefresh);
  });

  /**
   * Returns All Media Saves data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaSaves
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media saves response
   */
  getMediaSaves = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'saved', forceRefresh);
  });

  /**
   * Returns All Media Shares data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaShares
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media Shares response
   */
  getMediaShares = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'shares', forceRefresh);
  });

  /**
   * Returns All Media Likes data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaLikes
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media likes response
   */
  getMediaLikes = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'like_count', forceRefresh);
  });

  /**
   * Returns All Media Comments data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaComments
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media comments response
   */
  getMediaComments = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'comment_count', forceRefresh);
  });

  /**
   * Returns All Media Views data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaViews
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media views response
   */
  getMediaViews = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'video_views', forceRefresh);
  });

  /**
   * Returns All Media Plays data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaPlays
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media Plays response
   */
  getMediaPlays = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'plays', forceRefresh);
  });

  /**
   * Returns All Media Plays data from getMediaEngagements
   * --REELS ONLY
   * over 3 month period
   *
   * @property getMediaPlays
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media Total Plays response
   */
  getTotalMediaPlays = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'total_plays', forceRefresh);
  });

  /**
   * Returns All Media Impressions data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaImpressions
   * @property {Media} media
   * @param {Boolean} isStory
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media impressions response
   */
  getMediaImpressions = task(async (media, isStory, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'impressions', isStory, forceRefresh);
  });

  /**
   * Returns All Media Reach data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaReach
   * @property {Media} media
   * @param {Boolean} isStory
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media reach response
   */
  getMediaReach = task(async (media, isStory, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'reach', isStory, forceRefresh);
  });

  /**
   * Returns All Media Replies data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaReplies
   * @property {Media} media
   * @param {Boolean} isStory
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getMediaReplies = task(async (media, isStory, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'replies', isStory, forceRefresh);
  });

  /**
   * Returns All Media Repin Count data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaRepins
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media repin count response
   */
  getMediaRepins = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'repin_count', forceRefresh);
  });

  /**
   * Returns All Media Retweet data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaRetweets
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getMediaRetweets = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'retweet_count', forceRefresh);
  });

  /**
   * Returns All Media Favorites data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getMediaFavorites = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'favorite_count', forceRefresh);
  });

  /**
   * Returns All Facebook Media Reach data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaReach = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'reach', forceRefresh);
  });

  /**
   * Returns All Facebook Media Clicks data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaClicks = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'clicks', forceRefresh);
  });

  /**
   * Returns All Facebook Media Clicks Unique data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaClicksUnique = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'clicks_unique', forceRefresh);
  });

  /**
   * Returns All Media Comments data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaComments
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media comments response
   */
  getFacebookMediaComments = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'comment_count', forceRefresh);
  });

  /**
   * Returns All Facebook Media Likes data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaLikes = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'like_count', forceRefresh);
  });

  /**
   * Returns All Facebook Media Reactions data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaReactions = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'reactions', forceRefresh);
  });

  /**
   * Returns All Facebook Media Video Views Unique data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaVideoViewsUnique = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'video_views_unique', forceRefresh);
  });

  /**
   * Returns All Facebook Media Shares data from getMediaEngagements
   * over 3 month period
   *
   * @property getMediaFavorites
   * @property {Media} media
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} Formatted media replies response
   */
  getFacebookMediaShares = task(async (media, forceRefresh = false) => {
    return await this._getMediaEngagementsProperty.linked().perform(media, 'shares', forceRefresh);
  });

  /**
   * Returns All Gender-Age data from ProfileInsightsLifetime
   * over 3 month period
   *
   * @property getGenderAgeData
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {GenderAgeDataPoint} Formatted gender-age response
   */
  getGenderAgeData = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsightsLifetime
      .linked()
      .perform(forceRefresh, startDate, endDate);
    const genderAgeData = profileInsights.map((insightDay) => ({
      time: insightDay.end_time,
      audienceGenderAge: insightDay.audience_gender_age
    }));

    return genderAgeData;
  });

  /**
   * Returns All Gender data from ProfileInsightsLifetime
   * over 3 month period
   *
   * @property getGenderData
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {GenderDataPoint} Formatted gender response
   */
  getGenderData = task(async (startDate, endDate, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsightsLifetime
      .linked()
      .perform(forceRefresh, startDate, endDate);
    const genderData = profileInsights.map((insightDay) => ({
      time: insightDay.end_time,
      audienceGender: insightDay.audience_genders
    }));

    return genderData;
  });

  /**
   * Returns All Followers Online data from ProfileInsightsLifetime
   * over 3 month period
   *
   * @property getFollowersOnline
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {FollowerOnlinePoint} Formatted followers online response
   */
  getFollowersOnline = task(async (forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsightsLifetime.linked().perform(forceRefresh);

    const followersOnline = profileInsights.map((insightDay) => ({
      time: insightDay.end_time,
      day: moment(insightDay.end_time * 1000).isoWeekday(),
      onlineFollowersMap: insightDay.online_followers_map
    }));

    return followersOnline;
  });

  /**
   * Extracts the given property from the getProfileInsights result
   *
   * @property _getProfileInsightsProperty
   * @param {String} property The property name
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {any} resulting extracted data structure
   * @protected
   */
  _getProfileInsightsProperty = task(async (property, forceRefresh = false) => {
    const profileInsights = await this.dynamoApi.getProfileInsightsLifetime.linked().perform(forceRefresh);

    const list = profileInsights.map((insightDay) => insightDay['audience_' + property]);

    return list;
  });

  /**
   * Extracts the given property from the getMediaEngagements result
   *
   * @property _getMediaEngagementsProperty
   * @param {Media} media Media
   * @param {String} property The property name
   * @param {Boolean} [isStory=false] If media item is a story
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<BinnedTimeseriesDataPoint>} resulting extracted data structure
   * @protected
   */
  _getMediaEngagementsProperty = task(async (media, property, isStory = false, forceRefresh = false) => {
    if (!media) {
      return null;
    }

    const engagements = await this.dynamoApi.getMediaEngagements.linked().perform(media, isStory, forceRefresh);

    if (!engagements) {
      return null;
    }

    const formattedEngagements = engagements.map((item) => formatMediaEngagementFromDynamo(item));

    return formattedEngagements.map((engagement) => {
      const count = engagement[property];

      // Note: Bugs LG-5675 & LG-5755, -1 value is coming from IG
      // Solution is to temporarily clear values
      return {
        sampled_at: engagement.sampled_at,
        count: count < 0 ? null : count
      };
    });
  });

  /** Returns unique media ids for the given hashtags object.
   *
   * @method _getMediaIdsFromHashtags
   * @param {Object} hashtags Raw hashtags response from dynamo
   *
   * @returns {Array<String>} List of unique media ids
   * @protected
   */
  _getMediaIdsFromHashtags(hashtags) {
    if (!hashtags || hashtags.length === 0) {
      return [];
    }

    const mediaIds = Object.keys(hashtags).map((key) => hashtags[key].media.map((item) => item.id));
    const flattenedMediaIds = [].concat(...mediaIds);
    const uniqueMediaIds = [...new Set(flattenedMediaIds)];

    return uniqueMediaIds;
  }
}
