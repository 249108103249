import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isNone, isEmpty } from '@ember/utils';
import AdapterError from '@ember-data/adapter/error';
import { tracked } from '@glimmer/tracking';
import { didCancel, task } from 'ember-concurrency';
import moment from 'moment';
import RSVP from 'rsvp';

import { canMediaItemsAltText } from 'later/utils/alt-text-validation';
import { areStringsEqual } from 'later/utils/compare-strings';
import {
  NULL_VALUE,
  PostType,
  POST_COUNT_REWARD_THRESHOLD,
  TEXT_POST_TYPE,
  TIKTOK_MAX_IMAGES
} from 'later/utils/constants';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import formatCaption from 'later/utils/formatters/caption';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import promiseHash from 'later/utils/promise-hash';
import { convert, timestamp } from 'later/utils/time-format';
import { buildRestrictionsInfoString } from 'later/utils/translation-paths';
import { SocialPlatformType } from 'shared/types/social-profile';
import copyPostToProfile from 'shared/utils/copy-post-to-profile';

import type MutableArray from '@ember/array/mutable';
import type { RouteModel } from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type { SafeString } from '@ember/template/-private/handlebars';
import type Store from '@ember-data/store';
import type { EventApi } from '@fullcalendar/common';
import type { Task, TaskInstance } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type GeneratedCaptionModel from 'later/models/generated-caption';
import type GramModel from 'later/models/gram';
import type GroupModel from 'later/models/group';
import type LinkinbioPostModel from 'later/models/linkinbio-post';
import type LinkinbioPostLinkModel from 'later/models/linkinbio-post-link';
import type MediaItemModel from 'later/models/media-item';
import type PostActivityModel from 'later/models/post-activity';
import type PostMediaItemModel from 'later/models/post-media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type BttpService from 'later/services/calendar/bttp';
import type DialogManagerService from 'later/services/dialog-manager';
import type ErrorsService from 'later/services/errors';
import type MediaLibraryService from 'later/services/media-library';
import type ProgressBarService from 'later/services/progress-bar';
import type ScheduleService from 'later/services/schedule';
import type PostsPendingApprovalService from 'later/services/schedule/posts-pending-approval';
import type SecondaryModalService from 'later/services/secondary-modal';
import type SegmentService from 'later/services/segment';
import type SegmentEventsService from 'later/services/segment-events';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type SubscriptionsService from 'later/services/subscriptions';
import type { Maybe, Transition } from 'shared/types';

type PostCreationObject = {
  calendarDrop: boolean;
  caption?: string;
  errorPost?: GramModel;
  originalPMI: PostMediaItemModel[];
  scheduledTime: number;
  socialProfile: SocialProfileModel;
  isDraft?: boolean;
};

export type PostValidationError = {
  isUpgrade?: boolean;
  link?: string;
  linkModel?: GroupModel;
  linkText?: string;
  location?: string;
  message: SafeString | string;
  socialProfile?: SocialProfileModel;
  title?: string;
  upgradeText?: string;
};

type CloseModalOptions = {
  /** Route path (dot-notation) to return to */
  backTo?: string;
  /** Route model to use for params */
  model?: RouteModel;
  /** Query params for transition */
  queryParams?: {
    [key: string]: string;
  };
};

type ChangeMediaEventArgs = {
  media_edit_type: Maybe<string>;
  previous_post_type: Maybe<string>;
  edited_post_type: Maybe<string>;
};

export interface PostObject {
  post: GramModel;
  postMediaItems: PostMediaItemModel[];
  socialProfile: SocialProfileModel;
  errorPost?: GramModel;
  mediaItem?: MediaItemModel;
}

type CopyPostArgs = {
  sourcePost: GramModel;
  targetProfiles: string[];
};

type TransitionArgs =
  | [string]
  | [string, RouteModel]
  | [string, { queryParams: Record<string, string> }]
  | [string, RouteModel, { queryParams: Record<string, string> }];

function getTransitionArgs(route: string, model?: RouteModel, queryParams?: Record<string, string>): TransitionArgs {
  let qp;
  if (queryParams) {
    qp = { queryParams };
    if (model) {
      return [route, model, qp];
    }
    return [route, qp];
  }

  if (model) {
    return [route, model];
  }

  return [route];
}

export default class PostService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service('calendar/bttp') declare bttp: BttpService;
  @service declare dialogManager: DialogManagerService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare mediaLibrary: MediaLibraryService;
  @service declare router: RouterService;
  @service declare schedule: ScheduleService;
  @service declare progressBar: ProgressBarService;
  @service('schedule/posts-pending-approval') declare postsPendingApproval: PostsPendingApprovalService;
  @service declare secondaryModal: SecondaryModalService;
  @service declare segment: SegmentService;
  @service declare segmentEvents: SegmentEventsService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: Store;
  @service declare subscriptions: SubscriptionsService;

  @tracked changedPostMediaItems = false;
  @tracked shouldRenableClickTracking = false;
  @tracked copyPostConfig?: CopyPostArgs;

  changeMediaEventArgs = {
    media_edit_type: undefined,
    previous_post_type: undefined,
    edited_post_type: undefined
  } as ChangeMediaEventArgs;

  originalPostMediaItems = [] as PostMediaItemModel[];
  postCountRewardThreshold = POST_COUNT_REWARD_THRESHOLD;

  get hasNoSocialProfile(): boolean {
    return isEmpty(this.auth.currentGroup.socialProfiles);
  }

  get shouldTrackBttp(): boolean {
    // Note: The criteria for whether or not BTTP posting should be tracked is the same as the criteria
    // for determining if BTTP slots are displayed
    return this.bttp.shouldShow;
  }

  get socialProfile(): Maybe<SocialProfileModel> {
    return this.selectedSocialProfiles.firstProfile as Maybe<SocialProfileModel>;
  }

  destroyPost = task(async (post: GramModel) => {
    try {
      if (this.#isDraft(post)) {
        const profileType = post.get('socialProfile')?.get('profileType');
        this.segment.track(SegmentEventTypes.DeletedDraft, {
          profile_type: profileType ?? NULL_VALUE,
          has_media: !post.isEmptyDraft
        });
      }
      this.progressBar.start('.fc-view-harness');
      const libPostUrl = post.linkinbioPost?.get('linkUrl');
      if (!isEmpty(libPostUrl)) {
        const linkinbioPost = await objectPromiseProxy(post.linkinbioPost);
        await linkinbioPost.deleteRecord();
        await linkinbioPost.save();
      }
      await post.deleteRecord();
      await post.save();
      this.schedule.updatePostLimit.perform();
    } catch (error) {
      this.errors.handleAdapter(error, post);
    } finally {
      this.progressBar.done('.fc-view-harness');
    }
  });

  /**
   * Given a post, returns the correct instance of the connected linkinbio post.
   * Handles destroying additional instances of the linkinbio post, as well as
   * reloading the post record when a saved linkinbio post is not immediately available
   *
   * @param post
   */
  _getValidLinkinbioPost = task(async (post: GramModel) => {
    let linkinbioPost = this.store
      .peekAll('linkinbio-post')
      .find((linkinbioPost) => linkinbioPost.get('gram').get('id') === post.get('id') && linkinbioPost.get('id'));
    const sentinalLinkinbioPost = this.store
      .peekAll('linkinbio-post')
      .find((linkinbioPost) => linkinbioPost.get('gram').get('id') === post.get('id') && !linkinbioPost.get('id'));

    if (!linkinbioPost && sentinalLinkinbioPost) {
      await post.reload();

      linkinbioPost = this.store
        .peekAll('linkinbio-post')
        .find((linkinbioPost) => linkinbioPost.get('gram').get('id') === post.get('id') && linkinbioPost.get('id'));
    }

    if (linkinbioPost && sentinalLinkinbioPost) {
      sentinalLinkinbioPost.destroyRecord();
      post.set('linkinbioPost', linkinbioPost);
    }

    return linkinbioPost;
  });

  // Note: Ensure generatedCaptions have persisted associations to the saved post
  _associateGeneratedCaptions = task(async (post: GramModel): Promise<void> => {
    const generatedCaptions = await post.get('generatedCaptions');
    const promises = generatedCaptions.map(async (caption: GeneratedCaptionModel) => {
      try {
        caption.gram = post;
        await caption.save();
      } catch (error) {
        this.errors.handleAdapter(error);
      }
    });
    await Promise.all(promises);
  });

  _trackPostActivities = task(async (post: GramModel): Promise<void> => {
    const postActivities = await post.get('postActivities');
    const promises = postActivities.map(async (postActivity: PostActivityModel) => {
      try {
        this.segment.track(SegmentEventTypes.AddedCommentToPost, {
          post_id: postActivity.get('gram').get('id')
        });
      } catch (error) {
        this.errors.log(error);
      }
    });
    await Promise.all(promises);
  });

  _handleLinkinbioPost = task(async (post: GramModel, isNewLinkinbioPost: Maybe<boolean>) => {
    const socialProfile = post.get('socialProfile');
    if (socialProfile?.get('isInstagram') || socialProfile?.get('isTiktok')) {
      const linkinbioPost = await this._getValidLinkinbioPost.perform(post);

      if (
        !linkinbioPost ||
        (isNewLinkinbioPost && !linkinbioPost.get('linkUrl') && !linkinbioPost.get('linkinbioPostLinks').get('length'))
      ) {
        return;
      }

      const linksToDelete = linkinbioPost
        .get('linkinbioPostLinks')
        .filter((link: LinkinbioPostLinkModel) => link.get('isDeleted'));
      const updatedLinkinbioPost = await this._updateLinkinbioPosts.perform(
        post,
        linkinbioPost,
        linksToDelete,
        isNewLinkinbioPost
      );

      this.store
        .peekAll('linkinbio-post-link')
        .filter((link) => link.get('linkinbioPost').get('id') === updatedLinkinbioPost.get('id'))
        .forEach((link) => link.rollbackAttributes());

      this.store
        .peekAll('linkinbio-post')
        .filter(
          (libPost) =>
            libPost.get('gram').get('id') === updatedLinkinbioPost.get('gram').get('id') || libPost.get('isNew')
        )
        .forEach((libPost) => libPost.rollbackAttributes());

      if (isNewLinkinbioPost) {
        this.#handleNewLibPost(post, updatedLinkinbioPost);
      }
    }
  });

  rollbackPost = task(async (post: GramModel) => {
    try {
      await post.postMediaItems.filterBy('hasDirtyAttributes').invoke('rollbackAttributes');
      this.originalPostMediaItems.filterBy('hasDirtyAttributes').invoke('rollbackAttributes');
      this.originalPostMediaItems.filterBy('isDeleted').invoke('rollbackAttributes');
      post.postMediaItems.setObjects(this.originalPostMediaItems);

      const linkinbioPost = post.get('linkinbioPost');
      if (linkinbioPost?.get('hasDirtyAttributes')) {
        const libPost = await objectPromiseProxy(post.get('linkinbioPost'));
        await libPost.rollbackAttributes();
      }

      await post.rollbackAttributes();
    } catch (error) {
      this.errors.handleAdapter(error);
    }
  });

  save = task(async (post: GramModel) => {
    this.progressBar.start('.fc-view-harness');

    try {
      const isNewPost = post.get('isNew');
      const savedPost = await this._savePost.perform({ post, isNew: isNewPost });
      if (savedPost && !savedPost.isError) {
        this.#displaySuccessAlert(post, isNewPost);
      }
    } catch (error) {
      // Note: we want to stop the page progress bar before leaving this function
      // but still want to throw the exception for callers to deal with
      this.progressBar.done('.fc-view-harness');
      throw error;
    }

    this.progressBar.done('.fc-view-harness');
  });

  savePosts = task(async (postModels: PostObject[]) => {
    try {
      this.progressBar.start('.fc-view-harness');
      const posts = postModels.mapBy('post');
      const socialProfiles = postModels.mapBy('socialProfile');

      this.segmentEvents.trackMultiSchedule(posts, socialProfiles, 'save');

      const isNew = postModels[0].post.get('isNew');

      const promises = [] as TaskInstance<GramModel | undefined>[];
      postModels.forEach(({ post, errorPost }) => {
        if (!errorPost) {
          promises.push(this._savePost.perform({ post, isNew: post.get('isNew'), isMultiProfile: true }));
        }
      });
      const savedPosts = await RSVP.allSettled(promises);
      const savedPostsArray: GramModel[] = [];
      savedPosts.forEach((savedPostResult) => {
        if (savedPostResult?.state === 'fulfilled' && savedPostResult?.value) {
          savedPostsArray.push(savedPostResult.value);
        }
      });

      this.#displaySuccessAlert(savedPostsArray, isNew);
    } catch (error) {
      this.errors.show(error);
    } finally {
      this.progressBar.done('.fc-view-harness');
    }
  });

  _savePost = task(
    async ({ post, isNew, isMultiProfile }: { post: GramModel; isNew: boolean; isMultiProfile?: boolean }) => {
      const linkinbioPost = post.get('linkinbioPost');
      const isNewLinkinbioPost = linkinbioPost?.get('isNew');
      try {
        await this.#willSave(post);
        await this._associateGeneratedCaptions.perform(post);
        const savedPost = await post.save();
        if (isNew) {
          await this._trackPostActivities.perform(post);
        }
        await this.#didSave({
          post: savedPost,
          isNew,
          isMultiProfile
        });
        await this._handleLinkinbioPost.perform(savedPost, isNewLinkinbioPost);
        return savedPost;
      } catch (error) {
        if (error instanceof AdapterError) {
          this.errors.handleAdapter(error, post);
        } else {
          this.errors.show(error);
        }
        return;
      }
    }
  );

  _updateLinkinbioPosts = task(
    async (
      post: GramModel,
      linkinbioPost: LinkinbioPostModel,
      linksToDelete: LinkinbioPostLinkModel[],
      isNewLinkinbioPost: Maybe<boolean>
    ) => {
      const shouldDelete =
        linksToDelete.get('length') === linkinbioPost.get('linkinbioPostLinks').get('length') &&
        !linkinbioPost.get('linkUrl') &&
        !isNewLinkinbioPost;

      if (shouldDelete) {
        await linkinbioPost.destroyRecord();
      } else {
        await linksToDelete.map((link) => link.save());
      }

      if (post.caption != linkinbioPost.caption) {
        linkinbioPost.set('caption', post.caption);
        await linkinbioPost.save();
      }

      if (post.isPosted && !linkinbioPost.postedTime) {
        linkinbioPost.set('postedTime', post.postedTime);
        await linkinbioPost.save();
      }

      return linkinbioPost;
    }
  );

  updateTime: Task<void, [event: EventApi, dateTime: number]> = task(async (event: EventApi, dateTime: number) => {
    const post = await this.store.findRecord('gram', event.id);
    post.set('scheduledTime', dateTime);
    const nowish = moment().subtract(5, 'minutes').unix();
    const scheduledInThePast = dateTime > 0 && dateTime < nowish;
    const isDraft = post.get('isDraft');

    if (post.verified === true) {
      const error = this.intl.t('calendar.already_posted_cannot_reschedule');
      this.errors.show(error);
      post.rollbackAttributes();
      post.notifyPropertyChange('scheduledTime');
      return;
    }

    if (post.get('mediaItem')?.get('processing') === true) {
      alert(this.intl.t('calendar.media_processing'));
      post.rollbackAttributes();
      post.notifyPropertyChange('scheduledTime');
      return;
    }

    // Note: we only want to show a confirmation dialog for "Publish Now?" prompt if
    // the post isn't a draft
    if (scheduledInThePast && !isDraft) {
      const isPinterestVideo = post.get('socialProfile')?.get('isPinterest') && post?.isVideo;
      const title = isPinterestVideo
        ? this.intl.t('shared_phrases.submit_now')
        : this.intl.t('calendar.cannot_schedule_in_past');
      try {
        await this.dialogManager.confirm(title, {
          description: post.pastScheduleAlert,
          confirmButton: isPinterestVideo
            ? this.intl.t('shared_phrases.submit_now')
            : this.intl.t('shared_phrases.publish_now')
        });
        post.set('scheduledTime', moment().unix());
        await post.save();
      } catch (adapterError) {
        post.rollbackAttributes();
        post.notifyPropertyChange('scheduledTime');
        this.errors.handleAdapter(adapterError, post);
      }
    }
    if (!this.#isDraft(post) && this.shouldTrackBttp && this.isBttpPost(dateTime)) {
      post.set('scheduledForBttp', true);
      const socialProfile = (await post.socialProfile) as SocialProfileModel;
      this.#trackBttpSchedule(dateTime, socialProfile);
    } else {
      post.set('scheduledForBttp', false);
    }
    await this.save.perform(post);
  });

  #buildBaseTranslationPath(post: GramModel, isPostNew: boolean): Maybe<string> {
    const basePath = isPostNew ? 'alerts.posts.new' : 'alerts.posts.edit';
    const socialProfile = post.get('socialProfile');
    const profileType = socialProfile?.get('accountType');
    const platform = profileType === SocialPlatformType.Youtube ? 'youtube_short' : profileType?.toLowerCase();

    if (!platform) {
      this.errors.log(`Missing platform for post ${post.id}`, {
        post: JSON.stringify(post),
        socialProfile: JSON.stringify(socialProfile),
        profileType: socialProfile?.get('profileType') || ''
      });
      return;
    }

    if (post.isPinterest) {
      const mediaType = post?.isVideo ? 'video' : 'image';
      return `${basePath}.${platform}.${mediaType}`;
    }
    if (post.isTiktok && post.isPosted) {
      return `${basePath}.${platform}.posted`;
    }
    if (post.isNotificationPost) {
      return `${basePath}.${platform}.notification`;
    }

    return `${basePath}.${platform}`;
  }

  #displaySuccessAlert(post: GramModel | GramModel[], isPostNew: boolean): void {
    if (isPostNew && this.#isSubmittedForApproval(post)) {
      return this.#displaySuccessAlertForApproval(post);
    }

    // Note: if the post is a draft, or is an array containing a draft, we show
    // a different success message with a different call-to-action button within it.
    // As of now, there's no way to create a regular post alongside a draft post
    // in a single user action, therefore using the `any` Array method is safe:
    // if it contains a draft post, treat them all like draft posts.
    if (this.#isDraft(post)) {
      return this.#displaySuccessAlertForDraft(post);
    }

    if (Array.isArray(post)) {
      if (post.length) {
        return this.alerts.success(this.intl.t('alerts.post.multi.new_alert.message'), {
          title: this.intl.t('alerts.post.multi.new_alert.title')
        });
      }
    } else if (!Array.isArray(post)) {
      const translationPath = this.#buildBaseTranslationPath(post, isPostNew);

      if (translationPath) {
        const message = this.intl.t(`${translationPath}.message`);
        const title = this.intl.t(`${translationPath}.title`);
        this.alerts.success(message, { title, timeout: 5000 });
      }
    }
  }

  #displaySuccessAlertForApproval(post: GramModel | GramModel[]): void {
    const isMulti = Array.isArray(post);
    const title = this.intl.t('alerts.posts.new.approval.title', { count: isMulti ? post.length : 1 });
    const message = this.intl.t('alerts.posts.new.approval.message');

    return this.alerts.success(message, { title, timeout: 5000, preventDuplicates: true });
  }

  // TODO: Please ensure this is properly transitioning users to the calendar based on the
  // proper targetDate (aka. `scheduledTime` on the post)
  #displaySuccessAlertForDraft(post: GramModel | GramModel[]): void {
    const isMulti = Array.isArray(post);
    const draftPost = isMulti ? post[0] : post;
    if (!draftPost) {
      return;
    }
    // Note: the `targetDate` param needs to be in milliseconds - multiply by 1000
    const targetDate = draftPost.scheduledTime ? draftPost.scheduledTime * 1000 : undefined;
    const title = this.intl.t('alerts.posts.new.draft.title', { count: isMulti ? post.length : 1 });
    const message = this.intl.t('alerts.posts.new.draft.message');
    const options: Record<string, boolean | (() => void) | string> = {
      preventDuplicates: true
    };

    // Note: if the created post is a draft then we provide a CTA in the success alert
    // to take users to the calendar so they can view the post there. However, we don't
    // show the action if we're already on the calendar.
    const currentlyViewingCalendar = this.router.currentRouteName.includes('calendar');
    if (!currentlyViewingCalendar) {
      options.actionText = this.intl.t('media_library.view_on_calendar');
      options.action = () =>
        this.router.transitionTo('cluster.schedule.calendar', {
          queryParams: { targetDate }
        });
    }

    return this.alerts.success(message, { title, timeout: 5000, ...options });
  }

  async #didSave({
    post,
    isNew,
    isMultiProfile
  }: {
    post: GramModel;
    isNew: boolean;
    isMultiProfile?: boolean;
  }): Promise<void> {
    post
      .get('postMediaItems')
      .filter(({ id }) => id === null)
      .invoke('deleteRecord');

    if (isNew) {
      post
        .get('postActivities')
        .filter(({ id }) => id === null)
        .invoke('deleteRecord');
    }

    const scheduledTimeDirty = !!post.changedAttributes().scheduledTime;
    const unverifiedPostRescheduled = !isNew && !!post.changedAttributes().postedTime;
    const newScheduledTime = isNew || scheduledTimeDirty;

    // Note: save account to update postsCreatedThisMonth for 20th post reward
    if (
      this.auth.currentAccount.postsCreatedThisMonth &&
      this.auth.currentAccount.postsCreatedThisMonth <= POST_COUNT_REWARD_THRESHOLD - 1
    ) {
      this.schedule.updatePostLimit.perform();
    }
    await this.segmentEvents.trackAppliedTransformation(post);
    if (isNew && !isMultiProfile) {
      await this.segmentEvents.trackSavedPostEvent(post, unverifiedPostRescheduled);
    }
    if (
      !this.#isDraft(post) &&
      post.scheduledTime &&
      newScheduledTime &&
      this.shouldTrackBttp &&
      this.isBttpPost(post.scheduledTime)
    ) {
      const socialProfile = await post.socialProfile;
      this.#trackBttpSchedule(post.scheduledTime, socialProfile);
    }
    if (post.approvalSubmitted) {
      this.postsPendingApproval.fetchCount.perform();
    }
  }

  async validateUnverifiedTiktokAccount(socialProfile: SocialProfileModel): Promise<PostValidationError | boolean> {
    if (socialProfile.isTiktokUnverifiedAccount) {
      const group = await socialProfile.get('group');
      return {
        socialProfile,
        title: '',
        message: this.intl.t('tiktok.errors.multi_unverified.message'),
        link: 'social_profiles',
        linkModel: group,
        linkText: this.intl.t('calendar.modal.errors.refresh_here')
      };
    }

    return true;
  }

  #filterAndDestroyRecords(
    records: MutableArray<PostMediaItemModel> | PostMediaItemModel[]
  ): Promise<PromiseSettledResult<PostMediaItemModel>[]> {
    return Promise.allSettled(
      records.filter((record: PostMediaItemModel) => !isNone(record.id) && record.isDeleted).invoke('destroyRecord')
    );
  }

  #handleNewLibPost(post: GramModel, linkinbioPost: LinkinbioPostModel): void {
    const linkUrls = [];
    const links = [] as LinkinbioPostLinkModel[];
    const socialProfile = linkinbioPost.get('socialProfile');
    const linkinbioPage = socialProfile.get('linkinbioPage');

    if (linkinbioPost.get('linkinbioPostLinks').get('length') > 0) {
      linkinbioPost.get('linkinbioPostLinks').forEach((link: LinkinbioPostLinkModel) => {
        if (!link.get('isNew')) {
          linkUrls.push(link.get('linkUrl'));
          links.push(link);
        }
      });
    } else {
      linkUrls.push(linkinbioPost.get('linkUrl'));
    }

    this.segment.track(SegmentEventTypes.AddedLinkinbioToPost, {
      page_id: linkinbioPage?.get('id') ?? null,
      post_id: post.id,
      slug: linkinbioPage?.get('slug') || '',
      social_profile_id: socialProfile.get('id') || '',
      social_platform: socialProfile.get('profileType') || '',
      non_later_post: !linkinbioPost.get('gramId'),
      urls: JSON.stringify(linkUrls),
      num_links: linkUrls.length,
      location: 'calendar'
    });
  }

  isBttpPost(rawTimeStamp: Maybe<number>): boolean | void {
    if (!rawTimeStamp || !this.shouldTrackBttp || !this.bttp.roundedTimeSlots.length) {
      return;
    }

    const timeStamp = moment(rawTimeStamp * 1000);
    const finalTimeStamp = timeStamp.utc();

    const timeStampMinutes = finalTimeStamp.minute();
    let roundedMinutes = timeStampMinutes;

    if (timeStampMinutes < 15) {
      roundedMinutes = 0;
    } else if (timeStampMinutes >= 15 && timeStampMinutes < 45) {
      roundedMinutes = 30;
    } else if (timeStampMinutes >= 45) {
      roundedMinutes = 60;
    }

    const postedTimeAsMinuteOfWeek = moment
      .duration({
        days: finalTimeStamp.day(),
        hours: finalTimeStamp.hour(),
        minutes: timeStampMinutes + (roundedMinutes - timeStampMinutes)
      })
      .asMinutes();

    return this.bttp.roundedTimeSlots.some((bttpTimeSlotStart: number) => {
      const bttpTimeSlotEnd = bttpTimeSlotStart + 60;
      return postedTimeAsMinuteOfWeek >= bttpTimeSlotStart && postedTimeAsMinuteOfWeek <= bttpTimeSlotEnd;
    });
  }

  #isSubmittedForApproval(post: GramModel | GramModel[]): boolean {
    return Boolean(
      (!Array.isArray(post) && post.isApprovalSubmitted) ||
        (Array.isArray(post) && post.length && post.any((post) => Boolean(post.isApprovalSubmitted)))
    );
  }

  #isDraft(post: GramModel | GramModel[]): boolean {
    return Boolean(
      (!Array.isArray(post) && post.isDraft) || (Array.isArray(post) && post.length && post.any((post) => post.isDraft))
    );
  }

  #trackBttpSchedule(rawTimeStamp: number, socialProfile: Maybe<SocialProfileModel>): void {
    this.segment.track(SegmentEventTypes.ScheduledAtBestTime, {
      social_profile_handle: socialProfile?.get('nickname') ?? NULL_VALUE,
      time: moment(rawTimeStamp * 1000).format()
    });
  }

  #defaultPostCaption(canAltText: boolean, mediaItem: Maybe<MediaItemModel>, existingCaption?: string): string {
    if (!mediaItem) {
      return existingCaption || '';
    }

    let postCaption = existingCaption || mediaItem.defaultCaption || '';
    const mediaDescription = `Media Description: ${mediaItem.altText}`;

    if (!canAltText && mediaItem.altText && !postCaption.includes(mediaDescription)) {
      postCaption = postCaption
        ? `${postCaption}\nMedia Description: ${mediaItem.altText}`
        : `Media Description: ${mediaItem.altText}`;
    }
    return postCaption;
  }

  async #willSave(post: GramModel): Promise<void> {
    const linkinbioPost = await objectPromiseProxy(post.linkinbioPost);
    const libPostUrl = linkinbioPost?.linkUrl;
    const socialProfile = post.get('socialProfile');
    const isNewPost = post.get('isNew');
    // Note: PMI with no ordering are invalid media
    post
      .get('postMediaItems')
      .filter(({ ordering }) => ordering === null)
      .invoke('deleteRecord');
    // Note: post url will overwrite lib post url on save, so need to update
    // when lib post url exists or was erased and is an empty string
    if (!isNone(libPostUrl) && post.linkUrl !== libPostUrl) {
      post.set('linkUrl', libPostUrl);
    }
    // Note: posts cannot be scheduled more than 1 minute in the past unless
    // they're a draft post
    if (!post.isPosted && !post.isDraft && post.moment && post.moment < moment().subtract(1, 'minutes')) {
      post.set('scheduledTime', timestamp() + convert.minutes(5).toSeconds());
    }
    if (post.isPinterest && !post.linkUrl) {
      post.set('clickTracking', false);
    }
    if (!this.#isDraft(post) && this.shouldTrackBttp && this.isBttpPost(post.scheduledTime)) {
      post.set('scheduledForBttp', true);
    } else {
      post.set('scheduledForBttp', false);
    }

    if (
      linkinbioPost &&
      socialProfile?.get('isInstagram') &&
      !isEmpty(post.postType) &&
      post.postType !== linkinbioPost.postType
    ) {
      linkinbioPost.postType = post.postType;
    }

    if (isNewPost) {
      try {
        await this.schedule.updatePostLimit.perform();
      } catch (error) {
        if (!didCancel(error)) {
          // Note: Re-throw the non-cancelation error
          throw error;
        }
      }
    } else {
      try {
        // Note: Original PMI that are deleted may need to be destroyed
        await this.#filterAndDestroyRecords(this.originalPostMediaItems);
        await this.#filterAndDestroyRecords(post.postMediaItems);
      } catch (error) {
        this.errors.log(error);
      }
    }
    formatCaption(post);
  }

  isValidTiktokMedia(mediaItem: MediaItemModel, postMediaItems: PostMediaItemModel[], isAddingMedia: boolean): boolean {
    return (
      (mediaItem?.isVideo && postMediaItems.length < 2 && !isAddingMedia) ||
      (mediaItem?.isImage && postMediaItems.length < TIKTOK_MAX_IMAGES)
    );
  }

  /**
   * Close the post modal and transition away from current route.
   */
  closeModal({ backTo, model, queryParams }: CloseModalOptions = {}): void {
    const isListView = this.router.currentRouteName?.includes('list');
    const defaultBack = isListView ? 'cluster.schedule.list' : 'cluster.schedule.calendar';
    const route = backTo ?? defaultBack;

    this.secondaryModal.removeModal();
    this.mediaLibrary.selectedMedia.clear();
    this.setUnsavedReplaceMedia(false);

    // Note: Must convert to a [string] since TS can't infer the type correctly
    // when spreading a union type. `TransitionArgs` is the correct type.
    // Relevant issue: https://github.com/microsoft/TypeScript/issues/49802
    const transitionArgs = getTransitionArgs(route, model, queryParams) as unknown as [string];

    this.router.transitionTo(...transitionArgs);
  }

  confirmThumbnailExists(post: GramModel): void {
    if (post && !isNone(post.smallThumbnailUrl) && !post.isDeleted) {
      const image = new Image();
      image.src = post.smallThumbnailUrl;
      image.onerror = () =>
        post.checkImages().catch((errorResponse) => {
          if (
            errorResponse.code === 404 ||
            errorResponse.errors.some((error: Response) => String(error.status) === '404')
          ) {
            post.deleteRecord();
          }
        });
    }
  }

  createCarousel(
    selectedMedia: Maybe<MediaItemModel[]>,
    {
      caption,
      socialProfile,
      scheduledTime = timestamp(),
      calendarDrop = false,
      originalPMI = [],
      errorPost,
      isDraft = false
    }: PostCreationObject
  ): Promise<PostObject> {
    const mediaItem = selectedMedia?.[0];
    let postMediaItems;
    const canAltText = canMediaItemsAltText(socialProfile, selectedMedia);

    const postCaption = this.#defaultPostCaption(canAltText, mediaItem, caption);

    const post = this.store.createRecord('gram', {
      autoPublish:
        socialProfile?.defaultAutoPublish &&
        (socialProfile.canTiktokAutoPublish ||
          (socialProfile?.canInstagramAutoPublish && this.auth.currentAccount.canIgCarouselAutoPublish)),
      calendarDrop,
      caption: postCaption,
      createdTime: timestamp(),
      isDraft: socialProfile?.hasPublishingMethod ? isDraft : true,
      scheduledTime,
      socialProfile
    });

    if (originalPMI.length) {
      postMediaItems = originalPMI.map((postMediaItem, ordering) => post.createPMIFromPMI({ postMediaItem, ordering }));
    } else {
      postMediaItems = selectedMedia?.map((mediaItem, ordering) =>
        post.createPMIFromMediaItem({ mediaItem, ordering })
      );
    }

    if (postMediaItems) {
      this.setOriginalPostMediaItems(postMediaItems);
      post.postMediaItems = postMediaItems;
    }

    return promiseHash({
      mediaItem,
      post,
      postMediaItems,
      socialProfile,
      errorPost
    });
  }

  createSingleMedia(
    mediaItem: Maybe<MediaItemModel>,
    {
      caption,
      socialProfile,
      scheduledTime = timestamp(),
      calendarDrop = false,
      originalPMI = [],
      errorPost,
      isDraft = false
    }: PostCreationObject
  ): Promise<PostObject> {
    const canAltText = canMediaItemsAltText(socialProfile, mediaItem);

    const postCaption = this.#defaultPostCaption(canAltText, mediaItem, caption);

    const post = this.store.createRecord('gram', {
      autoPublish: socialProfile?.defaultAutoPublish && socialProfile?.canSelectAutoPublish,
      calendarDrop,
      caption: postCaption,
      createdTime: timestamp(),
      gramType: mediaItem?.mediaType,
      isDraft: socialProfile?.hasPublishingMethod ? isDraft : true,
      postType: this.#getPostType(mediaItem, socialProfile),
      mediaItem: mediaItem ?? null,
      scheduledTime,
      socialProfile
    });
    let postMediaItems = [];
    if (originalPMI.length) {
      postMediaItems = originalPMI.map((postMediaItem, ordering) => post.createPMIFromPMI({ postMediaItem, ordering }));
    } else {
      const postMediaItem = post.createPMIFromMediaItem({
        mediaItem,
        ordering: 0
      });
      postMediaItems = [postMediaItem];
    }
    post.postMediaItems = postMediaItems;
    this.setOriginalPostMediaItems(postMediaItems);
    return promiseHash({
      mediaItem,
      post,
      postMediaItems,
      socialProfile,
      errorPost
    });
  }

  createTextPost({
    socialProfile,
    scheduledTime = timestamp(),
    caption = '',
    calendarDrop = false,
    errorPost,
    isDraft = false
  }: PostCreationObject): Promise<PostObject> {
    const post = this.store.createRecord('gram', {
      autoPublish: socialProfile.defaultAutoPublish && socialProfile.canInstagramAutoPublish,
      calendarDrop,
      caption,
      createdTime: timestamp(),
      gramType: TEXT_POST_TYPE,
      isDraft: socialProfile?.hasPublishingMethod ? isDraft : true,
      postType: socialProfile?.isYoutube ? PostType.YoutubeShort : '',
      scheduledTime,
      socialProfile
    });
    this.setOriginalPostMediaItems([]);
    return promiseHash({ post, postMediaItems: [] as PostMediaItemModel[], socialProfile, errorPost });
  }

  copyPost(sourcePost: GramModel, targetProfiles: string[], queryParams: Record<string, unknown>): void {
    this.copyPostConfig = { sourcePost, targetProfiles };

    const isListView = this.router.currentRouteName?.includes('list');
    const isDraft = sourcePost.isDraft || this.router.currentRoute?.parent?.params?.draft;
    Object.assign(queryParams, { isCopiedPost: true, draft: isDraft });

    // Note: ensure the model refreshes when transitioning from within another copied post
    const parentRouteModelId = this.router.currentRoute?.parent?.params?.media_id;
    const isFromCopyRoute = parentRouteModelId?.includes('copy') ?? false;
    const routeModelId = isFromCopyRoute ? parentRouteModelId + '$' : 'copy';

    if (targetProfiles.length > 1) {
      const targetURL = isListView ? 'cluster.schedule.list.post.multi' : 'cluster.schedule.calendar.post.multi';
      this.router.transitionTo(targetURL, routeModelId, { queryParams });
    } else {
      const targetURL = isListView ? 'cluster.schedule.list.post.new' : 'cluster.schedule.calendar.post.new';
      this.router.transitionTo(targetURL, routeModelId, { queryParams });
    }
  }

  async createPostFromConfig(scheduledTime: number, draft: false): Promise<PostObject | Transition> {
    const targetProfileId = this.copyPostConfig?.targetProfiles.firstObject;
    if (!this.copyPostConfig || !targetProfileId) {
      this.errors.log('Could not create post from source, missing config');
      return this.router.transitionTo('cluster.schedule.calendar');
    }

    const { sourcePost } = this.copyPostConfig;

    const targetProfile = await this.store.findRecord('social-profile', targetProfileId);
    const originalMediaItem = await sourcePost.get('mediaItem');
    const copyPostParams = {
      scheduledTime
    };
    const copiedPost = await copyPostToProfile(sourcePost, targetProfile, copyPostParams);
    const canAltText = canMediaItemsAltText(targetProfile, originalMediaItem);
    copiedPost.caption = this.#defaultPostCaption(canAltText, originalMediaItem, copiedPost.caption);
    copiedPost.isDraft = sourcePost.isDraft || draft;

    this.setOriginalPostMediaItems(copiedPost.postMediaItems);
    return promiseHash({
      mediaItem: originalMediaItem,
      post: copiedPost,
      // Note: postMediaItems must be an array
      postMediaItems: copiedPost.postMediaItems.toArray(),
      socialProfile: targetProfile
    });
  }

  displayValidationAlert({ message, title, upgradeText, location }: PostValidationError): void {
    if (upgradeText) {
      this.alerts.upgrade(message as string, { title, upgradeText, location });
    } else {
      this.alerts.warning(message, { title });
    }
  }

  isMediaAllowed(
    id: string,
    socialProfile: SocialProfileModel,
    post: GramModel,
    postMediaItems: PostMediaItemModel[],
    isAddingMedia: boolean
  ): boolean {
    const newMedia = this.store.peekRecord('media-item', id);
    if (!newMedia) {
      return false;
    }

    const isValid = this.validateSingleMedia(
      newMedia,
      {
        socialProfile,
        isReels: post.isReel
      },
      postMediaItems,
      isAddingMedia
    );
    if (isValid !== true) {
      const validationErrors = isValid as PostValidationError;
      if (validationErrors.message && validationErrors.title) {
        this.alerts.warning(validationErrors.message, { title: validationErrors.title });
      }
      return false;
    }
    return true;
  }

  rollbackPostMediaItems(post: GramModel, originalPMI: PostMediaItemModel[]): void {
    post.get('postMediaItems').clear();
    post.get('postMediaItems').addObjects(originalPMI);
  }

  setChangeMediaEventArgs(newItems: PostMediaItemModel[]): void {
    this.setUnsavedReplaceMedia(true);

    if (this.originalPostMediaItems.length < newItems.length) {
      this.changeMediaEventArgs.media_edit_type = 'added_media';
    } else if (this.originalPostMediaItems.length > newItems.length) {
      this.changeMediaEventArgs.media_edit_type = 'removed_media';
    } else {
      this.changeMediaEventArgs.media_edit_type = 'replaced_media';
    }

    const firstObject = this.originalPostMediaItems.firstObject ? this.originalPostMediaItems.firstObject : undefined;
    const originalType = firstObject ? firstObject.mediaType : TEXT_POST_TYPE;
    const newType = firstObject ? firstObject.mediaType : TEXT_POST_TYPE;

    this.changeMediaEventArgs.previous_post_type = this.originalPostMediaItems.length > 1 ? 'carousel' : originalType;
    this.changeMediaEventArgs.edited_post_type = newItems.length > 1 ? 'carousel' : newType;
  }

  setOriginalPostMediaItems(originalPostMediaItems: PostMediaItemModel[]): void {
    this.originalPostMediaItems = originalPostMediaItems.compact();
  }

  setUnsavedReplaceMedia(changedPostState: boolean): void {
    if (!changedPostState) {
      // Note: set all changeMediaEventArgs values to undefined
      Object.keys(this.changeMediaEventArgs).forEach(
        (value) => (this.changeMediaEventArgs[value as keyof ChangeMediaEventArgs] = undefined)
      );
    }
    this.changedPostMediaItems = changedPostState;
  }

  validateCarousel(
    selectedMedia: MediaItemModel[],
    postProps: { socialProfile: SocialProfileModel; isMultiProfile?: boolean; isReels?: boolean }
  ): PostValidationError | boolean {
    const selectedImages = selectedMedia.filterBy('isImage');
    const selectedVideos = selectedMedia.filterBy('isVideo');
    const { socialProfile } = postProps;
    if (
      (!selectedImages.length && socialProfile.isPinterest) ||
      ((selectedVideos.length > 0 || selectedImages.length > TIKTOK_MAX_IMAGES) && socialProfile?.isTiktok)
    ) {
      return {
        socialProfile,
        title: this.intl.t(`alerts.posts.carousel.invalid_media.${socialProfile.profileType}.title`),
        message: this.intl.t(`alerts.posts.carousel.invalid_media.${socialProfile.profileType}.message`)
      };
    } else if (selectedMedia.any((item) => item.isGif) && !socialProfile.canPostGif) {
      if (socialProfile?.isInstagram) {
        return {
          socialProfile,
          title: this.intl.t('alerts.posts.carousel.invalid_media.instagram.title'),
          message: this.intl.t('alerts.posts.carousel.invalid_media.instagram.message')
        };
      }
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.cant_autoschedule_gif.title'),
        message: this.intl.t('alerts.calendar.cant_autoschedule_gif.message', {
          platform: socialProfile.localizedAccountType
        })
      };
    } else if (
      !this.auth.hasDevices &&
      socialProfile?.isInstagram &&
      !this.auth.currentAccount.canIgCarouselAutoPublish
    ) {
      return {
        socialProfile,
        message: this.intl.t('alerts.posts.modal.auto_publish.not_eligible')
      };
    } else if (!socialProfile?.hasPostsLeft && postProps.isMultiProfile) {
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.out_of_posts.title'),
        message: this.intl.t('alerts.calendar.out_of_posts.message'),
        upgradeText: this.intl.t('shared_phrases.view_my_plan'),
        isUpgrade: true,
        location: 'hit post limit alert'
      };
    }
    const containsImage = selectedMedia.some((media) => media?.isImage);
    const canCreateCarousel = !socialProfile.isYoutube && (socialProfile?.isInstagram || containsImage);
    const message = buildRestrictionsInfoString(socialProfile, 'alerts.posts.carousel');

    if (!canCreateCarousel) {
      return {
        socialProfile,
        title: '',
        message: this.intl.t(message)
      };
    }
    return true;
  }

  validateSingleMedia(
    mediaItem: MediaItemModel,
    postProps: {
      socialProfile: SocialProfileModel;
      isMultiProfile?: boolean;
      isReels?: boolean;
    },
    postMediaItems: PostMediaItemModel[],
    isAddingMedia: boolean
  ): PostValidationError | boolean {
    const { socialProfile } = postProps;
    if (this.hasNoSocialProfile) {
      return true;
    } else if (mediaItem?.isGif && !socialProfile?.canPostGif) {
      if (socialProfile.isInstagram) {
        return {
          socialProfile,
          title: this.intl.t('alerts.posts.carousel.invalid_media.instagram.title'),
          message: this.intl.t('alerts.posts.carousel.invalid_media.instagram.message')
        };
      }
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.cant_autoschedule_gif.title'),
        message: this.intl.t('alerts.calendar.cant_autoschedule_gif.message', {
          platform: socialProfile.localizedAccountType
        })
      };
    } else if (mediaItem?.isImage && !socialProfile?.canPostImage) {
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.cant_schedule_image.title'),
        message: this.intl.t('alerts.calendar.cant_schedule_image.message', {
          platform: socialProfile.localizedAccountType
        })
      };
    } else if (socialProfile?.isFacebook && postProps.isReels && !mediaItem?.isVideo) {
      return {
        socialProfile,
        title: this.intl.t('alerts.posts.fb_reels.title'),
        message: this.intl.t('alerts.posts.fb_reels.message')
      };
    } else if (mediaItem?.isVideo && socialProfile.isLinkedin && socialProfile.isLinkedinPersonalProfile) {
      return {
        socialProfile,
        title: this.intl.t('alerts.linkedin.errors.video_not_supported_title'),
        message: this.intl.t('alerts.linkedin.errors.video_not_supported_message')
      };
    } else if (mediaItem?.isVideo && socialProfile.isPinterest && !socialProfile.isBusiness) {
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.pinterest_business.replace.title'),
        message: htmlSafe(this.intl.t('alerts.calendar.pinterest_business.replace.message'))
      };
    } else if (mediaItem?.isVideo && !socialProfile.canPostVideo) {
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.video_not_supported.title'),
        message: this.intl.t('alerts.calendar.video_not_supported.message', {
          account_type: socialProfile.get('accountType')
        })
      };
    } else if (!socialProfile?.hasPostsLeft && postProps.isMultiProfile) {
      return {
        socialProfile,
        title: this.intl.t('alerts.calendar.out_of_posts.title'),
        message: this.intl.t('alerts.calendar.out_of_posts.message'),
        upgradeText: this.intl.t('shared_phrases.view_my_plan'),
        isUpgrade: true,
        location: 'hit post limit alert'
      };
    } else if (socialProfile?.isMissingLinkedinPermissions) {
      return {
        title: this.intl.t('alerts.linkedin.errors.missing_permissions.title'),
        message: htmlSafe(this.intl.t('alerts.linkedin.errors.missing_permissions.message'))
      };
    } else if (
      socialProfile.isTiktok &&
      postMediaItems?.length &&
      !this.isValidTiktokMedia(mediaItem, postMediaItems, isAddingMedia)
    ) {
      return {
        socialProfile,
        title: this.intl.t(`alerts.posts.carousel.invalid_media.${socialProfile.profileType}.title`),
        message: this.intl.t(`alerts.posts.carousel.invalid_media.${socialProfile.profileType}.message`)
      };
    }
    return true;
  }

  getPostLimitTitle(socialProfileType: string): string {
    if (areStringsEqual(socialProfileType, 'twitter')) {
      return this.intl.t('post.get_more_posts.no_tweets_left');
    } else if (areStringsEqual(socialProfileType, 'pinterest')) {
      return this.intl.t('post.get_more_posts.no_pins_left');
    }
    return this.intl.t('post.get_more_posts.no_posts_left', {
      socialProfile: this.intl.t(`shared_words.${socialProfileType}`)
    });
  }

  async getPostLimitDescription(socialProfileType: string): Promise<string> {
    const planType = this.subscriptions.planType as keyof typeof this.subscriptions.postLimitUpgradeMap;
    const targetPlanType = this.subscriptions.postLimitUpgradeMap[planType];

    await this.subscriptions.getSubscriptionPlans(targetPlanType);
    const targetPlan = this.subscriptions.subscriptionPlans.find((plan) => plan.planType === targetPlanType);

    const nextPostLimit = areStringsEqual(targetPlanType, 'advanced')
      ? this.intl.t('plans.feature_mappings.unlimited').toLowerCase()
      : targetPlan?.numberOfPosts;

    let baseTranslationString = `post.get_more_posts.${this.auth.currentAccount.canTrialPlan ? 'trial' : 'upgrade'}`;

    if (areStringsEqual(socialProfileType, 'twitter') || areStringsEqual(socialProfileType, 'pinterest')) {
      baseTranslationString += `_${socialProfileType}`;
    }

    return this.intl.t(baseTranslationString, {
      numPosts: nextPostLimit
    });
  }

  #getPostType(mediaItem: Maybe<MediaItemModel>, socialProfile: SocialProfileModel): string {
    if (socialProfile?.isYoutube) {
      return PostType.YoutubeShort;
    }

    if ((socialProfile?.isFacebook || socialProfile?.isInstagram) && mediaItem?.isVideo) {
      return PostType.Reel;
    }
    return PostType.Standard;
  }
}

declare module '@ember/service' {
  interface Registry {
    'schedule/post': PostService;
  }
}
