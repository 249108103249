/* eslint ember/no-unused-services: 0 */
import Controller, { inject as controllerInject } from '@ember/controller';
import { action, computed } from '@ember/object';
import { alias, notEmpty, not, or, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import AdapterError from '@ember-data/adapter/error';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import NProgress from 'nprogress';

import { areStringsEqual } from 'later/utils/compare-strings';
import { CALENDAR_VIEW_TYPES } from 'later/utils/constants';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import { UnknownRouteError } from 'shared/errors/unknown-route';
import { systemLabelsFromPostMediaItems } from 'shared/utils/segment/system-labels';
import uuid from 'shared/utils/uuid';

import type ComputedProperty from '@ember/object/computed';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type PostMediaItemModel from 'later/models/post-media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type DialogManagerService from 'later/services/dialog-manager';
import type EditorService from 'later/services/editor';
import type ErrorsService from 'later/services/errors';
import type OnboardingService from 'later/services/onboarding';
import type ScheduleService from 'later/services/schedule';
import type SegmentService from 'later/services/segment';
import type SegmentEventsService from 'later/services/segment-events';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { Maybe, UntypedController } from 'shared/types';

const { MONTH, WEEK } = CALENDAR_VIEW_TYPES;

// Note: Work around for the issue where the default type is 'controllerInject<never>(name: never): ComputedProperty<never, never>'
type ControllerInjection = (name: string) => ComputedProperty<Controller>;
const controllerInjectWithName = controllerInject as unknown as ControllerInjection;

export default class StoriesController extends Controller {
  @controllerInjectWithName('cluster.schedule') declare parent: UntypedController;
  @service declare auth: AuthService;
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare dialogManager: DialogManagerService;
  @service declare editor: EditorService;
  @service declare errors: ErrorsService;
  @service declare onboarding: OnboardingService;
  @service declare router: RouterService;
  @service declare schedule: ScheduleService;
  @service declare segment: SegmentService;
  @service declare segmentEvents: SegmentEventsService;
  @service declare store: StoreService;

  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare userConfig: UserConfigService;

  declare model: GramModel[];

  @tracked educationBoxChecked = false;
  @tracked showTutorialModal = false;
  @tracked showStoriesWizard = false;
  @tracked storyVideoMaxLength = 60375;
  @tracked tutorialsToggled = false;
  @tracked selectedStory?: GramModel;

  @reads('auth.currentAccount.canInstagramStories') declare canInstagramStories: boolean;
  @alias('auth.currentUserModel') declare currentUser: UserModel;
  @alias('userConfig.currentTimeZone.identifier') declare timeZoneIdentifier: string;
  @alias('selectedSocialProfiles.profiles') declare profiles: SocialProfileModel[];
  @alias('selectedSocialProfiles.firstProfile') declare socialProfile: SocialProfileModel;
  @alias('parent.isDragging') declare isDragging: boolean;
  @notEmpty('selectedStory') declare showStoryEditor: boolean;
  @or('hasUnsavedStory', 'hasUnsavedStoryPMIs') declare hasUnsavedChanges: boolean;
  @alias('selectedStory.hasDirtyAttributes') declare hasUnsavedStory: boolean;
  @not('auth.hasDevices') declare noDeviceConnected: boolean;
  @not('canInstagramStories') declare showStoriesUpsell: boolean;
  @not('currentUser.receivedInstagramStoriesEducation') declare showStoriesEducation: boolean;

  @computed('model.@each.active')
  get activePosts(): GramModel[] {
    return this.model.filterBy('active');
  }

  @computed('hasUnsavedChanges', 'selectedStory.isNew')
  get notNewAndHasChanges(): boolean {
    return this.hasUnsavedChanges && !this.selectedStory?.isNew;
  }

  @computed('selectedStory.postMediaItems.@each.hasDirtyAttributes')
  get hasUnsavedStoryPMIs(): boolean {
    if (this.selectedStory?.hasPostMediaItems) {
      return this.selectedStory.postMediaItems.any((pmi) => pmi.get('hasDirtyAttributes'));
    }
    return false;
  }

  get needsDeviceSetup(): boolean {
    return !this.socialProfile.canInstagramAutoPublish;
  }

  @computed('isDragging', 'showStoryEditor')
  get showNewStoryDropZone(): boolean {
    return this.isDragging && !this.showStoryEditor;
  }

  get targetDate(): Maybe<string> {
    return this.router.currentRoute.queryParams.targetDate;
  }

  @computed('timeZoneIdentifier')
  get currentTimeZoneIdentifier(): string {
    return isEmpty(this.timeZoneIdentifier) ? moment.tz.guess() : this.timeZoneIdentifier;
  }

  @computed('activePosts.@each.startTime', 'selectedSocialProfiles.profiles.[]')
  get timelineStories(): GramModel[] {
    const posts = this.activePosts.filterBy('startTime'); //They just need to have a scheduled or posted_time -imack
    return posts.filter(
      (post) =>
        isPresent(this.socialProfile) &&
        post.belongsTo('socialProfile').id() === this.socialProfile.get('id') &&
        post.isInstagramStory
    );
  }

  @computed('activePosts.@each.startTime')
  get calendarPosts(): GramModel[] {
    const posts = this.activePosts.filterBy('startTime'); //They just need to have a scheduled or posted_time -imack
    return posts.filter(
      (post) =>
        isPresent(this.socialProfile) &&
        post.belongsTo('socialProfile').id() === this.socialProfile.get('id') &&
        !post.isInstagramStory &&
        !post.isInstagramReel
    );
  }

  @computed('selectedStory.postMediaItems.@each.{isVideo,videoDuration}', 'storyVideoMaxLength')
  get invalidLengthStoryVideos(): PostMediaItemModel[] {
    const videoTooLongStories = this.selectedStory?.postMediaItems.filter(
      (pmi) => (pmi.videoDuration ?? 0) * 1000 > this.storyVideoMaxLength
    );

    return videoTooLongStories ?? [];
  }

  @action
  openMediaEditor(postMediaItem: PostMediaItemModel): void {
    try {
      this.editor.editPMICrop(postMediaItem);
    } catch (error) {
      if (error instanceof UnknownRouteError) {
        return this.alerts.alert(this.intl.t('alerts.editor.errors.unknown_route.message'), {
          title: this.intl.t('alerts.editor.errors.unknown_route.title')
        });
      }

      throw error;
    }
  }

  @action
  finishStoriesWizard(): void {
    this.currentUser.set('receivedInstagramStoriesEducation', true);
    this.currentUser.save();
    this.set('showStoriesWizard', false);
  }

  @action
  cancelStoriesWizard(): void {
    if (!this.currentUser.receivedInstagramStoriesEducation) {
      this.onboarding.confirmClose().then(() => this.set('showStoriesWizard', false));
    } else {
      this.set('showStoriesWizard', false);
    }
  }

  @action
  toggleTutorials(): void {
    this.toggleProperty('tutorialsToggled');
  }

  @action
  updateTargetDate(dateInCurrentRange: number): void {
    this.router.replaceWith(this.router.currentRouteName, {
      queryParams: {
        ...this.router.currentRoute.queryParams,
        targetDate: dateInCurrentRange
      }
    });
  }

  @action
  toggleTutorialModal(): void {
    this.toggleProperty('showTutorialModal');
  }

  @action
  addToStory(mediaItemIds: string[]): void {
    const story = this.selectedStory;

    if (story && !story.get('isPosted')) {
      mediaItemIds.forEach((mediaItemId: string) => {
        const mediaItem = this.store.peekRecord('media-item', mediaItemId);
        if (!isNone(mediaItem)) {
          if (mediaItem.get('isGif')) {
            this.alerts.warning(
              this.intl.t('alerts.calendar.gif_not_supported.message', {
                account_type: this.socialProfile.accountType
              }),
              {
                title: this.intl.t('alerts.calendar.gif_not_supported.title')
              }
            );
          } else {
            const currentPMICount = story.postMediaItems.length;
            this.store.createRecord('post-media-item', {
              mediaItem,
              mediaType: mediaItem.get('mediaType'),
              ordering: currentPMICount,
              tempId: uuid(),
              gram: story
            });
          }
        }
      });

      this.parent.send('deselectAll');
    }
  }

  @action
  dropIntoNewStory(droppedItemIds: string, timeDroppedTo = moment().unix()): void {
    if (isNone(droppedItemIds)) {
      return;
    }

    const ids = droppedItemIds.split(',');
    const socialProfile = this.get('socialProfile');

    if (socialProfile.get('hasPostsLeft')) {
      const mediaItem = this.store.peekRecord('media-item', ids[0]);
      const autoPublish =
        socialProfile.defaultAutoPublish && socialProfile.canInstagramAutoPublish && !socialProfile.isCreator;
      const story = this.store.createRecord('gram', {
        autoPublish,
        createdTime: moment().unix(),
        socialProfile,
        scheduledTime: timeDroppedTo,
        mediaItem,
        isStory: true
      });

      ids.forEach((mediaItemId: string): void => {
        const mediaItem = this.store.peekRecord('media-item', mediaItemId);
        if (!isNone(mediaItem)) {
          if (mediaItem.get('isGif')) {
            this.alerts.warning(
              this.intl.t('alerts.calendar.gif_not_supported.message', {
                account_type: this.socialProfile.accountType
              }),
              {
                title: this.intl.t('alerts.calendar.gif_not_supported.title')
              }
            );
          } else {
            const currentPMICount = story.get('postMediaItems').get('length');
            const postMediaItem = this.store.createRecord('post-media-item', {
              mediaItem,
              mediaType: mediaItem.get('mediaType'),
              ordering: currentPMICount,
              tempId: uuid()
            });
            story.get('postMediaItems').addObject(postMediaItem);
          }
        }
      });

      this.parent.send('deselectAll');

      if (story.get('postMediaItems').get('length') < 1) {
        // in case all of the dragged items are gifs
        story.deleteRecord();
      } else {
        this.set('selectedStory', story);
      }
    } else {
      this.alerts.upgrade(this.intl.t('alerts.calendar.out_of_posts.message'), {
        title: this.intl.t('alerts.calendar.out_of_posts.title'),
        feature: 'plans',
        location: 'stories too many posts',
        actionText: this.intl.t('shared_phrases.view_my_plan')
      });
    }
  }

  @action
  dropNewStoryOnTimeline(dragEvent: DragEvent, timeDroppedTo: number): void {
    const droppedItemIds = dragEvent?.dataTransfer?.getData('text');

    if (isEmpty(this.selectedStory)) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const nowish = moment().subtract(5, 'minutes').unix();

      if (timeDroppedTo < nowish) {
        this.dialogManager
          .confirm(self.intl.t('stories.cant_schedule_in_past'), {
            description: self.intl.t('stories.schedule_for_current_time'),
            confirmButton: self.intl.t('shared_words.continue')
          })
          .then(() => {
            self.send('dropIntoNewStory', droppedItemIds, moment().unix());
          });
      } else {
        self.send('dropIntoNewStory', droppedItemIds, timeDroppedTo);
      }
    } else {
      this.alerts.info(this.intl.t('alerts.stories.cannot_create_story.message'), {
        title: this.intl.t('alerts.stories.cannot_create_story.title')
      });
    }
  }

  @action
  openStory(storyId: string): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const storyToOpen = this.store.peekRecord('gram', storyId);

    if (isNone(storyToOpen)) {
      //shouldn't happen; just in case --GP
      return;
    }

    if (this.selectedStory) {
      const openStory = this.selectedStory;
      if (openStory.get('isNew')) {
        this.dialogManager
          .confirm(this.intl.t('shared_phrases.are_you_sure'), {
            description: this.intl.t('posts.new.close_confirm'),
            confirmButton: this.intl.t('shared_words.discard'),
            cancelButton: this.intl.t('shared_words.no')
          })
          .then((/*results*/) => {
            openStory.destroyRecord().then(() => {
              self.set('selectedStory', storyToOpen);
            });
          });
      } else if (
        openStory.get('dirtyType') === 'updated' ||
        openStory.get('postMediaItems').any((pmi) => pmi.get('dirtyType') === 'updated') ||
        openStory.get('postMediaItems').any((pmi) => pmi.get('isNew'))
      ) {
        this.dialogManager
          .confirm(this.intl.t('shared_phrases.close_warning'), {
            description: this.intl.t('shared_phrases.changes_not_saved'),
            confirmButton: this.intl.t('shared_words.leave')
          })
          .then((/*results*/) => {
            openStory.rollbackAttributes();
            openStory.get('postMediaItems').forEach((pmi) => pmi.rollbackAttributes());
            self.send('clearSelectedStory');
            self.set('selectedStory', storyToOpen);
          })
          .catch(() => {
            // Do nothing
          });
      } else {
        self.set('selectedStory', storyToOpen);
      }
    } else {
      this.set('selectedStory', storyToOpen);
    }
  }

  @action
  discardChangesAndClose(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const openStory = this.selectedStory;

    if (isEmpty(openStory) || !this.notNewAndHasChanges) {
      return;
    }

    if (
      openStory?.get('isNew') ||
      openStory?.get('dirtyType') === 'updated' ||
      openStory?.get('postMediaItems').any((pmi) => pmi.get('dirtyType') === 'updated') ||
      openStory?.get('postMediaItems').any((pmi) => pmi.get('isNew'))
    ) {
      this.dialogManager
        .confirm(this.intl.t('shared_phrases.are_you_sure'), {
          description: this.intl.t('stories.story_editor.discard_changes_confirm')
        })
        .then((/*results*/) => {
          if (openStory.get('isNew')) {
            openStory.destroyRecord();
          } else {
            openStory.rollbackAttributes();
            openStory.get('postMediaItems').forEach((pmi) => pmi.rollbackAttributes());
            self.send('clearSelectedStory');
          }
        });
    }
  }

  @action
  deleteStory(story: GramModel): void {
    this.send('clearSelectedStory');

    const isNewStory = story.isNew;
    const postCreatedThisMonth = story.createdTime >= moment().utc().startOf('month').unix();

    story.deleteRecord();
    story.save().then(() => {
      if (!isNewStory && postCreatedThisMonth) {
        this.schedule.updatePostLimit.perform();
      }
    });

    if (story.isCarouselPost && story.hasPostMediaItems) {
      // Note: on delete, we need to update the postCount for each postMediaItem
      story.postMediaItems.forEach((postMediaItem) => {
        const posts = postMediaItem.get('mediaItem').get('rawPostIds');
        const currentPostId = parseInt(story.id);
        if (posts) {
          posts.removeObject(currentPostId);
        }
      });
    }
  }

  @action
  saveStory(story: GramModel, successFunction: () => unknown): void {
    NProgress.start();

    story
      .get('postMediaItems')
      .filter((pmi) => pmi.isRemoved && !pmi.isNew)
      .map((pmi) => pmi.destroyRecord());
    const isNewStory = story.get('isNew');

    story
      .save()
      .then(async (story) => {
        if (isNewStory) {
          this.schedule.updatePostLimit.perform();
          await this.trackScheduleEvent(story);
        }
        await this.segmentEvents.trackAppliedTransformation(story);
        this.send('clearSelectedStory');
        story
          .get('postMediaItems')
          .filter((pmi) => pmi.id === null)
          .forEach((pmi) => pmi.deleteRecord());

        if (typeof successFunction === 'function') {
          successFunction();
        }
        NProgress.done();
      })
      .catch((error) => {
        if (error instanceof AdapterError) {
          this.errors.handleAdapter(error, story);
        }
        // Error handling needs to be improved; this is a temp fix until we can log the error using the errors service

        this.send('clearSelectedStory');
        NProgress.done();
      });
  }

  @action
  clearSelectedStory(): void {
    this.selectedStory = undefined;
  }

  @action
  updateStoryTime(storyId: string, storyStartTime: string): void {
    const story = this.store.peekRecord('gram', storyId);
    if (story) {
      story.set('scheduledTime', storyStartTime);
      story.save();
    }
  }

  // Note: Async is a workaround here to make `willTransition` work in the `stories` route. Once
  // https://latergramme.atlassian.net/browse/WHI-1104 is completed, this should be refactored into a task.
  async resetOpenStory(): Promise<void> {
    if (this.selectedStory && this.hasUnsavedChanges) {
      const story = this.selectedStory;

      if (story.get('isNew')) {
        await story.destroyRecord();
        this.selectedStory = undefined;
      } else {
        await story.rollbackAttributes();
        await story.get('postMediaItems').every(async (pmi) => await pmi.rollbackAttributes());
      }
    }
  }

  @action
  viewPreviewGrid(): void {
    this.router.transitionTo('cluster.schedule.preview-grid');
  }

  @action
  viewWeekCalendar(): void {
    this.router.transitionTo('cluster.schedule.calendar', { queryParams: { calendarView: WEEK } });
  }

  @action
  viewMonthCalendar(): void {
    this.router.transitionTo('cluster.schedule.calendar', { queryParams: { calendarView: MONTH } });
  }

  async trackScheduleEvent(story: GramModel): Promise<void> {
    let storyDuration;
    const postMediaItems = story.get('postMediaItems');

    const uniqueMediaItems = postMediaItems.filter((item) => item.id !== null);

    const pmiTimes = postMediaItems.map((pmi) => pmi.get('storySecondDuration') ?? 0);

    const trimmedVideoStories = postMediaItems.filter(
      (pmi) => pmi.isVideo && (pmi.trimArray.firstObject || pmi.trimArray.lastObject)
    );

    if (isEmpty(pmiTimes)) {
      storyDuration = 0;
    } else {
      storyDuration = pmiTimes.reduce((total, pmiTime) => total + pmiTime);
    }

    this.segment.track(SegmentEventTypes.ScheduledStory, {
      auto_publish: story.get('autoPublish'),
      system_label: await systemLabelsFromPostMediaItems(uniqueMediaItems),
      duration: storyDuration,
      has_caption: isPresent(story.get('caption')),
      media_item_count: uniqueMediaItems.length,
      photo_text_present: !areStringsEqual(story.get('imageText'), 'null_value'),
      photo_text: story.get('imageText'),
      profile: story?.socialProfile?.get('nickname') || '',
      story_id: story.get('id'),
      video_trim: trimmedVideoStories.length ? true : false,
      ...(story.scheduledTime && { scheduled_time: story.scheduledTime })
    });
  }
}
