import { reads } from '@ember/object/computed';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

// Twitter: https://developer.twitter.com/en/docs/media/upload-media/uploading-media/media-best-practices.html
const MB = 1024 * 1024;
const GB = 1024 * MB;
const TWITTER_MAX_GIF_SIZE_BYTES = 15 * MB;

//LinkedIn: Video: https://www.linkedin.com/help/linkedin/answer/106595/video-sharing-troubleshooting-guide?lang=en
//Image: https://www.linkedin.com/help/linkedin/answer/100983
const LINKEDIN_MAX_IMAGE_SIZE_BYTES = 5 * MB;
const LINKEDIN_MAX_VIDEO_SIZE_BYTES = 5 * GB;

//TikTok: Video: https://bytedance.feishu.cn/docs/doccnq3SeHrZkncd0AdxL69MCEe#DMh0RO
//Image: https://business-api.tiktok.com/portal/docs?id=1803630424390658
const TIKTOK_MAX_VIDEO_SIZE_BYTES = GB;
const TIKTOK_MAX_IMAGE_SIZE_BYTES = 20 * MB;
@classic
export default class FileSize extends BaseValidator {
  @reads('model.mediaItem.publishingFileSize') fileSize;
  @reads('model.isAutoPublish') isAutoPublish;
  @reads('model.isGif') isGif;
  @reads('model.isImage') isImage;
  @reads('model.isVideo') isVideo;
  @reads('model.gram.socialProfile') socialProfile;

  validate() {
    if (this.socialProfile?.get('isTwitter') && this.isGif && this.fileSize > TWITTER_MAX_GIF_SIZE_BYTES) {
      return this.createErrorMessage('invalidGifFileSizeTwitter');
    } else if (this.socialProfile?.get('isLinkedin')) {
      if (this.isVideo && this.fileSize > LINKEDIN_MAX_VIDEO_SIZE_BYTES) {
        return this.createErrorMessage('invalidVideoFileSizeLinkedin');
      } else if (this.isImage && this.fileSize > LINKEDIN_MAX_IMAGE_SIZE_BYTES) {
        return this.createErrorMessage('invalidImageFileSizeLinkedin');
      }
    } else if (this.socialProfile?.get('isTiktok') && this.isAutoPublish) {
      if (this.isVideo && this.fileSize > TIKTOK_MAX_VIDEO_SIZE_BYTES) {
        return this.createErrorMessage('invalidVideoFileSizeTiktok');
      } else if (this.isImage && this.fileSize > TIKTOK_MAX_IMAGE_SIZE_BYTES) {
        return this.createErrorMessage('invalidImageFileSizeTiktok');
      }
    }

    return true;
  }
}
