import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ArrayProxy from '@ember/array/proxy';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type ContentPillarModel from 'later/models/content-pillar';
import type AuthService from 'later/services/auth';

export default class ContentPillarsRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  model(): ArrayProxy<ContentPillarModel> {
    return this.store.query('content-pillar', { group_id: this.auth.currentGroup.id });
  }

  redirect(): void {
    if (!this.auth.currentAccount.canContentPillars || !this.auth.currentAccount.rolloutContentPillars) {
      this.router.transitionTo('cluster.schedule');
    }
  }
}
