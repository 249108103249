import { isEmpty } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import moment from 'moment-timezone';

import type { AsyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';

type TestNotification = (payload?: Record<string, unknown>) => Promise<{ message: string }>;

export default class DeviceModel extends Model {
  @attr('number') declare createdTime?: number;
  @attr('string') declare gcmRegistrationId?: string;
  @attr('string') declare name?: string;
  @attr('string') declare notificationToken?: string;
  @attr('string') declare ownerEmail?: string;
  @attr('string') declare warningMessages?: string;

  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;
  @hasMany('social-profile', { async: true }) declare socialProfiles: AsyncHasMany<SocialProfileModel>;

  get isAndroid(): boolean {
    return !isEmpty(this.gcmRegistrationId);
  }

  get isIOS(): boolean {
    return !isEmpty(this.notificationToken);
  }

  get hasNotificationToken(): boolean {
    return this.isAndroid || this.isIOS;
  }

  get addedAt(): string | null {
    if (!this.createdTime) {
      return null;
    }

    return moment.unix(this.createdTime).format('MMM Do YYYY');
  }

  testNotification = memberAction<never, TestNotification>({
    path: 'test'
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    device: DeviceModel;
  }
}
