import Service, { inject as service } from '@ember/service';
import { enqueueTask } from 'ember-concurrency';

import type SocialProfileModel from 'later/models/social-profile';
import type { UntypedService } from 'shared/types';

export default class InstagramApiService extends Service {
  @service declare analytics: UntypedService;
  @service declare instagram: UntypedService;
  @service declare cache: UntypedService;

  get socialProfile(): SocialProfileModel {
    return this.analytics.socialProfile;
  }

  clearCache(): void {
    const cacheKeyPrefix = 'instagramApi';
    this.cache.clearCacheByKeyword(cacheKeyPrefix);
  }

  /**
   * Returns current Instagram User.
   * Fetches the user from Instagram if it's
   * not available locally.
   */
  getUser = enqueueTask(async (forceRefresh = false) => {
    const cacheKey = `instagramApi_user_${this.socialProfile.id}`;
    const cachedUser = this.cache.retrieve(cacheKey);

    if (!cachedUser || forceRefresh) {
      const user = await this.instagram.fetchSelf(this.socialProfile);

      this.cache.add(cacheKey, user, { expiry: this.cache.expiry(1, 'day'), persist: false });
      return user;
    }

    return cachedUser;
  });

  /**
   * Gets all media. Fetches media from Instagram if it's
   * not available locally.
   */
  getMedia = enqueueTask(async (forceRefresh = false) => {
    const cacheKey = `instagramApi_media_${this.socialProfile.id}`;
    const cachedMedia = this.cache.retrieve(cacheKey);

    if (!cachedMedia || forceRefresh) {
      const { posts } = await this.instagram.fetchRecentMedia(this.socialProfile, {
        count: 30
      });

      this.cache.add(cacheKey, posts, { expiry: this.cache.expiry(1, 'day'), persist: false });
      return posts;
    }
    return cachedMedia;
  });
}

declare module '@ember/service' {
  interface Registry {
    'analytics/instagram-api': InstagramApiService;
  }
}
