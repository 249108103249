/**
 * @module Helpers
 */

/**
 * @class LaterHelpers
 * @extends Helpers
 */

/**
 * Converts profile type to class string for styling.
 *
 * @method social-profile-class
 * @param {String} profileType Profile type to be converted
 *
 * @return {String} Class string for styling
 */
import { helper as buildHelper } from '@ember/component/helper';

export default buildHelper(([profileType] /*, hash*/) => {
  if (profileType === 'instagram') {
    return 'is--ig';
  } else if (profileType === 'twitter') {
    return 'is--twitter';
  } else if (profileType === 'pinterest') {
    return 'is--pinterest';
  }

  return 'is--ig';
});
