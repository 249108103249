/**
 * Native JS replacement of RSVP.hash
 *
 * @method promiseHash
 *
 * @param {Object} promises
 *
 * @returns {Promise} The returned promise is fulfilled with a hash that has the same key names as the promises object argument.
 * If any of the values in the object are not promises, they will simply be copied over to the fulfilled object
 */
export default async function promiseHash(hash) {
  const promises = Object.keys(hash).map(async (key) => ({ [key]: await hash[key] }));
  const resolved = await Promise.all(promises);
  return resolved.reduce((hash, part) => ({ ...hash, ...part }), {});
}
