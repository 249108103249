import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type LabelModel from 'later/models/label';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';

export default class MediaLabelsService extends Service {
  @service declare auth: AuthService;
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare errors: ErrorsService;
  @service declare segment: SegmentService;
  @service declare store: Store;

  get currentGroup(): GroupModel | null {
    return this.auth?.currentGroup;
  }

  addNewLabel = task(
    async (label: string, labelList = new TrackedArray<LabelModel>(), createdFromLabelMedia = false) => {
      let sanitizedLabel = label;
      sanitizedLabel = label.replace(/,/g, ''); // commas not allowed in labels
      sanitizedLabel = label.replace(/\^/g, ''); // ignore carrots given special nature

      try {
        if (this.currentGroup?.labelList.includes(sanitizedLabel)) {
          this.alerts.alert(this.intl.t('alerts.media_items.labels.already_exists.message'), {
            title: this.intl.t('alerts.media_items.labels.already_exists.title')
          });
        } else {
          const newLabel = await this.store.createRecord('label', {
            tagName: sanitizedLabel,
            group: this.currentGroup
          });
          await newLabel.save();
          if (createdFromLabelMedia) {
            newLabel.increaseTaggedCount();
          }
          this.segment.track('created-label', {
            group_id: this.currentGroup?.id || null,
            label: sanitizedLabel
          });
          labelList.addObject(newLabel);
        }
      } catch (error) {
        this.errors.log(error);
      }
    }
  );

  deleteLabel(label: LabelModel): void {
    try {
      label.destroyRecord();
    } catch (adapterError) {
      this.errors.handleAdapter(adapterError, label);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'media-labels': MediaLabelsService;
  }
}
