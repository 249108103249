import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class MentionCount extends BaseValidator {
  validate() {
    if (this.get('model.socialProfile.isInstagram') && this.model.mentionsLeft < 0) {
      const mentionsAboveLimit = Math.abs(this.model.mentionsLeft);
      return this.createErrorMessage('exceeding_mention_limit', null, { value: mentionsAboveLimit });
    }
    return true;
  }
}

export default MentionCount;
