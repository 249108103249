import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import type SeamlessCheckoutManagerService from 'later/services/seamless-checkout-manager';

@command('update-plan')
export class UpdatePlanCommand extends Command {
  @service declare seamlessCheckoutManager: SeamlessCheckoutManagerService;

  public searchTerms = [
    'manage',
    'plans',
    'update',
    'addon',
    'billing',
    'yearly',
    'monthly',
    'mailing',
    'address',
    'card',
    'credit',
    'change',
    'limit',
    'access',
    'restrict',
    'settings'
  ];

  protected action(): void | Promise<void> {
    this.seamlessCheckoutManager.upgrade({ feature: 'user' });
  }

  public getTitle(): string {
    return `Update Your Plan`;
  }

  public getDescription(): string {
    return `Manage your addons and other plan details`;
  }

  public getIcon(): string {
    return 'settings';
  }
}

export default UpdatePlanCommand;
