import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';

export default class IndexRoute extends Route {
  @service declare router: RouterService;

  redirect(): void {
    this.router.transitionTo('cluster.schedule.calendar');
  }
}
