import { isNone } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import type GroupModel from 'later/models/group';
import type MediaItemModel from 'later/models/media-item';
import type { Maybe } from 'shared/types';

export default class LabelModel extends Model {
  @tracked selected = false;

  @attr('string') declare tagName: Maybe<string>;
  @attr('number', { defaultValue: 0 }) declare taggedCount: number;

  @belongsTo('group') declare group: GroupModel;
  @hasMany('mediaItem') declare mediaItems: MediaItemModel[];

  get canonicalTagName(): string {
    if (!this.tagName) {
      return '';
    }

    return this.isSystemLabel ? this.tagName.slice(1) : this.tagName;
  }

  get trimmedTagName(): string {
    return this.canonicalTagName.length >= 25 ? this.canonicalTagName.slice(0, 22) + '...' : this.canonicalTagName;
  }

  get shortTagName(): string {
    return this.canonicalTagName.length >= 13 ? this.canonicalTagName.slice(0, 10) + '...' : this.canonicalTagName;
  }

  get isSystemLabel(): boolean {
    return isNone(this.tagName) ? false : this.tagName.slice(0, 1) === '^';
  }

  increaseTaggedCount(): void {
    this.incrementProperty('taggedCount');
  }

  decreaseTaggedCount(): void {
    // Note: stop from going to -1
    if (this.taggedCount > 0) {
      this.decrementProperty('taggedCount');
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    label: LabelModel;
  }
}
