import { isString, isNumber } from 'lodash';

const isTypedArray = <T>(value: unknown, memberAssertion: (member: unknown) => member is T): value is T[] =>
  Array.isArray(value) && value.every((i) => memberAssertion(i));

const isStringArray = (value: unknown): value is string[] => isTypedArray<string>(value, isString);

const isNumberArray = (value: unknown): value is number[] => isTypedArray<number>(value, isNumber);

export { isStringArray, isNumberArray };
