import moment from 'moment';

import type IntlService from 'ember-intl/services/intl';

// specific dates for "Exact Times" and "Within Times" were set by UX. Can be found in the spec
// https://www.notion.so/latershome/Last-Updated-Indicator-for-Link-in-Bio-3c0cc61ffcb347b1951fde0cd44e56af?pvs=4#66f329bec2534c8ba0bd5c5ecb22fa3a
const formattedLastUpdatedTime = (time: number, intl: IntlService): string => {
  const currentTime = moment();
  const selectedTime = time;
  const momentLastUpdatedTime = moment.unix(selectedTime).format('YYYY/MM/DD');
  const diffInDays = currentTime.diff(momentLastUpdatedTime, 'days');
  const diffInWeeks = currentTime.diff(momentLastUpdatedTime, 'weeks');
  let diffInMonths;
  let diffInYears;
  const isToday = currentTime.isSame(momentLastUpdatedTime, 'days');
  const isYesterday = diffInDays === 1;
  const isWithinOneWeek = diffInDays < 7 && (!isToday || !isYesterday);

  const exactMonths = [
    [27, 32],
    [59, 63],
    [89, 94],
    [119, 125],
    [149, 156],
    [179, 185],
    [209, 214],
    [239, 245],
    [269, 274],
    [299, 305],
    [329, 335]
  ];

  const exactYears = [
    [364, 367],
    [729, 732]
  ];

  const withinMonths = [
    [31, 60],
    [62, 90],
    [93, 120],
    [124, 150],
    [155, 180],
    [184, 210],
    [213, 240],
    [244, 270],
    [273, 300],
    [304, 330],
    [334, 365]
  ];

  const withinWeeks = [
    [7, 14],
    [14, 21],
    [21, 28]
  ];

  const withinYears = [
    [366, 730],
    [731, 10000]
  ];

  const findDateRange = (array: number[][]): number | undefined => {
    return array.findIndex(([a, b]: [number, number]) => diffInDays > a && diffInDays < b) + 1;
  };

  const isWithinDateRanges = (array: number[][]): boolean => {
    return array.some(([a, b]: [number, number]) => diffInDays > a && diffInDays < b);
  };

  const isExactWeek = (): boolean => {
    const weeksInDaysWithinAMonth = [7, 14, 21];
    return weeksInDaysWithinAMonth.includes(diffInDays);
  };

  if (isToday) {
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('shared_words.today').toLowerCase()
    });
  } else if (isYesterday) {
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('shared_words.yesterday').toLowerCase()
    });
  } else if (isWithinOneWeek) {
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.days_ago', { count: diffInDays })
    });
  } else if (isExactWeek()) {
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.exact_week_ago', { count: diffInWeeks })
    });
  } else if (isWithinDateRanges(withinWeeks)) {
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.over_week_ago', { count: diffInWeeks, time: 'week' })
    });
  } else if (isWithinDateRanges(exactMonths)) {
    diffInMonths = findDateRange(exactMonths);
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.exact_month_ago', { count: diffInMonths })
    });
  } else if (isWithinDateRanges(withinMonths)) {
    diffInMonths = findDateRange(withinMonths);
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.over_date_ago', { count: diffInMonths, time: 'month' })
    });
  } else if (isWithinDateRanges(exactYears)) {
    diffInYears = findDateRange(exactYears);
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.exact_year_ago', { count: diffInYears })
    });
  } else if (isWithinDateRanges(withinYears) || diffInDays > 731) {
    diffInYears = findDateRange(withinYears);
    return intl.t('linkinbio.last_updated.last_updated', {
      timeAgo: intl.t('linkinbio.last_updated.over_date_ago', { count: diffInYears, time: 'year' })
    });
  }
  return '';
};

export default formattedLastUpdatedTime;
