export default [
  'F.13-17',
  'F.18-24',
  'F.25-34',
  'F.35-44',
  'F.45-54',
  'F.55-64',
  'F.65+',
  'M.13-17',
  'M.18-24',
  'M.25-34',
  'M.35-44',
  'M.45-54',
  'M.55-64',
  'M.65+',
  'U.25-34'
];
