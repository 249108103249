/**
 * @module Services
 */
import EmberObject from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import classic from 'ember-classic-decorator';
import { TrackedArray } from 'tracked-built-ins';

import type SocialProfileModel from 'later/models/social-profile';

@classic
class State extends EmberObject {
  @tracked activePage: string | null = null;
  @tracked hasRevoked: boolean | null = null;
  @tracked isAdminOfPage: boolean | null = null;
  @tracked isCorrectPage: boolean | null = null;
  @tracked nameOfPage: string | null = null;
  @tracked currentModal = 'credentialsStatus';
}

/**
 * This service manages credentials to 3rd party platforms across the app
 *
 * @class Credentials
 * @extends Service
 */
export default class CredentialsService extends Service {
  @tracked socialProfile: SocialProfileModel | null = null;
  @tracked failedConnections: TrackedArray<string> = new TrackedArray([]);
  @tracked state: State | null = null;
  @tracked source = null;

  initState(socialProfile: SocialProfileModel, source: any): void {
    if (socialProfile) {
      this.source = source;
      this.socialProfile = socialProfile;
      this.state = State.create({ isConnecting: !socialProfile.hasProfessionalAccount });
    }
  }

  updateFailedConnections(socialProfile: SocialProfileModel, didFail = true): void {
    if (didFail) {
      this.failedConnections.pushObject(socialProfile.id);
    } else {
      this.failedConnections.removeObject(socialProfile.id);
    }
  }

  updateActivePage(payload: string): void {
    if (this.state) {
      this.state.activePage = payload;
    }
  }

  updateIsCorrectPage(payload: boolean): void {
    if (this.state) {
      this.state.isCorrectPage = payload;
    }
  }

  updateNameOfPage(payload: string): void {
    if (this.state) {
      this.state.nameOfPage = payload;
    }
  }

  updateCurrentModal(payload: string): void {
    if (this.state) {
      this.state.currentModal = payload;
    }
  }

  updateIsAdminOfPage(payload: boolean): void {
    if (this.state) {
      this.state.isAdminOfPage = payload;
    }
  }

  updateHasRevoked(payload: boolean): void {
    if (this.state) {
      this.state.hasRevoked = payload;
    }
  }
}
