import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class CampaignSerializer extends ApplicationSerializer {
  attrs = {
    campaignImages: {
      key: 'campaign_images',
      embedded: 'always',
      serialize: 'records'
    }
  };
}
