enum Step {
  TeamsSetup = 'teamsSetup',
  BeginOnboarding = 'beginOnboarding',
  ScheduleFirstPost = 'scheduleFirstPost',
  Empty = 'empty',
  ScheduleFirstPostSuccessful = 'scheduleFirstPostSuccessful',
  FinishedOnboarding = 'closedOnboardingVideo',
  NotInitialized = 'notInitialized'
}

export default Step;
