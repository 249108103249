import Service, { inject as service } from '@ember/service';
import { enqueueTask } from 'ember-concurrency';

import getThreeMonthsOfPosts from 'later/utils/array-filters/get-three-months-of-posts';

import type InstagramApiService from './instagram-api';
import type { UntypedService } from 'shared/types';

export default class InstagramAnalyticsService extends Service {
  @service declare userConfig: UntypedService;
  @service('analytics/instagram-api') declare instagramApi: InstagramApiService;

  /**
   * Reads the currentTimeZone identifier as defined in the UserConfig service
   *
   * @property timeZoneIndentifier
   */
  get timeZoneIdentifier(): string {
    return this.userConfig.currentTimeZone.identifier;
  }

  /**
   * Returns Instagram posts for free users
   *
   * @param forceRefresh
   * @returns Instagram posts in the given time range
   */
  getPosts = enqueueTask(async (forceRefresh = false) => {
    const posts = await this.instagramApi.getMedia
      .linked()
      .perform(forceRefresh)
      .catch(() => []);

    return getThreeMonthsOfPosts(posts, this.timeZoneIdentifier);
  });

  /**
   * Returns current Instagram User
   *
   * @param forceRefresh
   * @returns formatted current Instagram User
   */
  getUser = enqueueTask(async (forceRefresh = false) => {
    return await this.instagramApi.getUser.linked().perform(forceRefresh);
  });
}

declare module '@ember/service' {
  interface Registry {
    'analytics/instagram-analytics': InstagramAnalyticsService;
  }
}
