import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

@classic
export default class ContentPillarAdapter extends ApplicationAdapter {
  // Note: This is required as there is no back-end route implemented to handle coalesced calls.
  // We only use the 'show' route for reloading models in the event of a nexus failure.
  coalesceFindRequests = false;
}
