import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import { ErrorSeverity } from 'later/services/errors';
import redirect from 'shared/utils/redirect';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type SessionService from 'later/services/-private/session';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type { Transition } from 'shared/types';

export default class IndexRoute extends Route {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare errors: ErrorsService;
  @service declare store: StoreService;

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'user.login');
  }

  redirect(): void {
    if (!this.auth.currentAccount) {
      this.auth.logout.perform();
    }

    let group;

    if (this.cache.retrieve<string>('last_group_id')) {
      group = this.store.peekAll('group').findBy('id', this.cache.retrieve<string>('last_group_id'));
    }

    if (isNone(group)) {
      group = this.auth.lastGroup;
    }

    if (isNone(group)) {
      // Temporary log to track down cause of redirecting to the `nogroup` route
      try {
        this.errors.log(
          'No group found, index route',
          {
            localGroupIds: this.store.peekAll('group').mapBy('id'),
            lastGroupId: this.cache.retrieve('last_group_id') || 'none',
            localStorage: { ...window.localStorage }
          },
          ErrorSeverity.Info
        );
      } catch (error) {
        this.errors.log('Could not log nogroup error, index route', { error }, ErrorSeverity.Info);
      }

      this.router.transitionTo('nogroup');
    } else if (group.contributor) {
      this.router.transitionTo('cluster.collect.contribute', group.get('slug'));
    } else if (this.session.hasRedirectUrl) {
      const { redirectUrl } = this.session;
      this.session.clearRedirectUrl();
      redirect(redirectUrl);
    } else {
      this.router.transitionTo('cluster.schedule.calendar', group.get('slug'));
    }
  }
}
