import Model, { attr, belongsTo } from '@ember-data/model';

import type Campaign from 'later/models/campaign';
import type Influencer from 'later/models/influencer';

export default class CampaignApplicationModel extends Model {
  @belongsTo('campaign') declare brand: Campaign;
  @attr('string') declare personalNote: string;
  @attr('string') declare seenAt: string;
  @attr('string') declare appliedAt: string;
  @attr('boolean') declare readonly alreadyInCampaign: boolean;
  @attr declare influencer: Influencer;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'campaign-application': CampaignApplicationModel;
  }
}
