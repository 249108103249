import Model, { attr, belongsTo } from '@ember-data/model';

import type GramModel from 'later/models/gram';
import type UserModel from 'later/models/user';

export enum PostActivityType {
  Approved = 'approved',
  Comment = 'comment',
  Submitted = 'submitted'
}

type PostActivityTypes = `${PostActivityType}`;

export default class PostActivityModel extends Model {
  @belongsTo('gram') declare gram: GramModel;
  @belongsTo('user') declare group: UserModel;

  @attr('string') declare activityType: PostActivityTypes;
  @attr('number') declare createdTime: number;
  @attr('number') declare userId: number;
  @attr('string') declare userName: string;
  @attr('number') declare postId: number;
  @attr('string') declare message: string;

  get adjustedCreatedTime(): number {
    return this.createdTime * 1000;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'post-activity': PostActivityModel;
  }
}
