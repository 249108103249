/**
 * @module Utils
 */
import { assert } from '@ember/debug';

/**
 * @class TimeFormat
 * @extends Utils
 */

/**
 * Number of milliseconds in a second.
 *
 * @property MILLISECONDS_PER_SECOND
 * @type {Number}
 */
const MILLISECONDS_PER_SECOND = 1000;

/**
 * Number of seconds in a minute.
 *
 * @property SECONDS_PER_MINUTE
 * @type {Number}
 */
const SECONDS_PER_MINUTE = 60;

/**
 * Number of minutes in an hour.
 *
 * @property MINUTES_PER_HOUR
 * @type {Number}
 */
const MINUTES_PER_HOUR = 60;

/**
 * Number of hours in a day.
 *
 * @property HOURS_PER_DAY
 * @type {Number}
 */
const HOURS_PER_DAY = 24;

/**
 * Number of days in a week.
 *
 * @property DAYS_PER_WEEK
 * @type {Number}
 */
const DAYS_PER_WEEK = 7;

/**
 * Returns an object with functions that can be used to convert the given number
 * of milliseconds into the desired metric time (e.g. seconds, minutes, hours, days...)
 *
 * @param {Number} num The number of milliseconds to convert
 */
const milliseconds = (num) => {
  assert('An argument must be provided', num);
  return {
    toSeconds: () => num / MILLISECONDS_PER_SECOND,
    toMinutes: () => milliseconds(num).toSeconds() / SECONDS_PER_MINUTE,
    toHours: () => milliseconds(num).toMinutes() / MINUTES_PER_HOUR,
    toDays: () => milliseconds(num).toHours() / HOURS_PER_DAY,
    toWeeks: () => milliseconds(num).toDays() / DAYS_PER_WEEK
  };
};

/**
 * Returns an object with functions that can be used to convert the given number
 * of seconds into the desired metric time (e.g. milliseconds, minutes, hours...)
 *
 * @param {Number} num The number of seconds to convert
 */
const seconds = (num) => {
  assert('An argument must be provided', num);
  return {
    toMilliseconds: () => num * MILLISECONDS_PER_SECOND,
    toMinutes: () => num / SECONDS_PER_MINUTE,
    toHours: () => seconds(num).toMinutes() / MINUTES_PER_HOUR,
    toDays: () => seconds(num).toHours() / HOURS_PER_DAY,
    toWeeks: () => seconds(num).toDays() / DAYS_PER_WEEK
  };
};

/**
 * Alias for `minutes(1)`
 */
const minute = () => minutes(1);

/**
 * Returns an object with functions that can be used to convert the given number
 * of minutes into the desired metric time (e.g. milliseconds, seconds, hours...)
 *
 * @param {Number} num [num=1] num The number of minutes to convert
 */
const minutes = (num = 1) => ({
  toMilliseconds: () => num * minutes().toSeconds() * MILLISECONDS_PER_SECOND,
  toSeconds: () => num * SECONDS_PER_MINUTE,
  toHours: () => num / MINUTES_PER_HOUR,
  toDays: () => minutes(num).toHours() / HOURS_PER_DAY,
  toWeeks: () => minutes(num).toDays() / DAYS_PER_WEEK
});

/**
 * Alias for `hours(1)`
 */
const hour = () => hours(1);

/**
 * Returns an object with functions that can be used to convert the given number
 * of hours into the desired metric time (e.g. milliseconds, seconds, minutes...)
 *
 * @param {Number} num [num=1] num The number of hours to convert
 */
const hours = (num = 1) => ({
  toMilliseconds: () => num * hours().toSeconds() * MILLISECONDS_PER_SECOND,
  toSeconds: () => num * hours().toMinutes() * SECONDS_PER_MINUTE,
  toMinutes: () => num * MINUTES_PER_HOUR,
  toDays: () => num / HOURS_PER_DAY,
  toWeeks: () => hours(num).toDays() / DAYS_PER_WEEK
});

/**
 * Alias for `days(1)`
 */
const day = () => days(1);

/**
 * Returns an object with functions that can be used to convert the given number
 * of days into the desired metric time (e.g. seconds, minutes, hours...)
 *
 * @param {Number} num [num=1] num The number of days to convert
 */
const days = (num = 1) => ({
  toMilliseconds: () => num * days().toSeconds() * MILLISECONDS_PER_SECOND,
  toSeconds: () => num * days().toMinutes() * SECONDS_PER_MINUTE,
  toMinutes: () => num * days().toHours() * MINUTES_PER_HOUR,
  toHours: () => num * HOURS_PER_DAY,
  toWeeks: () => num / DAYS_PER_WEEK
});

/**
 * Alias for `weeks(1)`
 */
const week = () => weeks(1);

/**
 * Returns an object with functions that can be used to convert the given number
 * of weeks into the desired metric time (e.g. milliseconds, seconds, minutes, hours...)
 *
 * @param {Number} [num=1] num The number of weeks to convert
 */
const weeks = (num = 1) => ({
  toMilliseconds: () => num * weeks().toSeconds() * MILLISECONDS_PER_SECOND,
  toSeconds: () => num * weeks().toMinutes() * SECONDS_PER_MINUTE,
  toMinutes: () => num * weeks().toHours() * MINUTES_PER_HOUR,
  toHours: () => num * weeks().toDays() * HOURS_PER_DAY,
  toDays: () => num * DAYS_PER_WEEK
});

/**
 * The object to expose the conversion functions
 *
 * @property convert
 */
const convert = {
  milliseconds,
  seconds,
  minute,
  minutes,
  hour,
  hours,
  day,
  days,
  week,
  weeks
};

/**
 * Formats a number of seconts into a mm:ss format
 *
 * @method timeFormat
 * @param {Number} seconds
 * @private
 *
 * @returns {String} mm:ss formatted string
 */
const timeFormat = (seconds) => {
  const minutes = Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60);
  const remainingSeconds =
    Math.floor(seconds - minutes * 60) < 10
      ? '0' + Math.floor(seconds - minutes * 60)
      : Math.floor(seconds - minutes * 60);
  return `${minutes}:${remainingSeconds}`;
};

/**
 * Returns difference between now and the end of the month
 * in a unit of measurement that is passed in
 * Supported intervals include years, months, weeks, days, hours, minutes, and seconds
 *
 * @method timeUntilEndOfMonth
 * @param {String} interval
 *
 * @returns {Integer} difference in interval
 */
const timeUntilEndOfMonth = (interval) => {
  const endOfMonth = moment().endOf('month');
  const now = moment();

  return endOfMonth.diff(now, interval);
};

/**
 * Returns seconds since epoch otherwise known as a unix timestamp
 *
 * @method timestamp
 * @param {Date} date
 *
 * @returns {Integer} unix timestamp (seconds since epoch)
 */
const timestamp = (date = new Date()) => Math.round(date.getTime() / 1000);

export {
  MILLISECONDS_PER_SECOND,
  SECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
  HOURS_PER_DAY,
  DAYS_PER_WEEK,
  convert,
  timeFormat,
  timeUntilEndOfMonth,
  timestamp
};
