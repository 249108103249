import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import type SeamlessCheckoutManagerService from 'later/services/seamless-checkout-manager';

@command('see-all-plans')
export class SeeAllPlansCommand extends Command {
  @service declare seamlessCheckoutManager: SeamlessCheckoutManagerService;

  public searchTerms = ['plans', 'change', 'downgrade', 'upgrade', 'access', 'settings'];

  protected action(): void | Promise<void> {
    this.seamlessCheckoutManager.upgrade();
  }

  public getTitle(): string {
    return `See Available Plans`;
  }

  public getDescription(): string {
    return `See what plans are available for you`;
  }

  public getIcon(): string {
    return 'monetary';
  }
}

export default SeeAllPlansCommand;
