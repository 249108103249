import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type MavelyAuthService from 'later/services/mavely-auth';
import type { MavelyProfile } from 'later/utils/mavely-profile-index';

@command('force-deauthentication-mavely-profiles')
export class ForceDeauthenticationMavelyProfilesCommand extends Command {
  @service declare auth: AuthService;
  @service declare mavelyAuth: MavelyAuthService;
  @service declare alerts: AlertsService;

  public searchTerms = ['mavely', 'profile', 'force', 'deauthentication', 'auth', 'refresh', 'debug'];

  protected async action(): Promise<void> {
    const group = this.auth.currentGroup;

    const mavelyProfiles = this.mavelyAuth.mavelyProfiles
      .toArray()
      .map((mavelyProfile) => ({ ...mavelyProfile, authenticated: false }) satisfies MavelyProfile);

    this.mavelyAuth.mavelyProfiles.add(group, mavelyProfiles);

    this.alerts.info('<span class="u--text--bold">🐞 Debug</span>: Forced un-authentication of Mavely Profiles.');
  }

  public getTitle(): string {
    return `Force deauthentication of Mavely Profiles`;
  }

  public getDescription(): string {
    return 'Forcefully deauthenticate all Mavely profiles. This action can be used to test profile refresh functionality.';
  }

  public getIcon(): string {
    return 'lock';
  }
}

export default ForceDeauthenticationMavelyProfilesCommand;
