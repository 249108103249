import { reads, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Model, { attr } from '@ember-data/model';
import moment from 'moment';

import formatRegramCaption from 'later/utils/formatters/caption-from-regram';

import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type { InstagramMediaType } from 'shared/types/instagram';
import type { CarouselMediaItem, MentionChild, MentionRawType, MentionType } from 'shared/types/mentions';

export default class MentionModel extends Model {
  @service declare auth: AuthService;

  @attr('string') declare readonly caption?: string;
  @attr('number') declare readonly commentId?: number;
  @attr('string') declare readonly commentText?: string;
  @attr('string') declare readonly commentUsername?: string;
  @attr({ defaultValue: () => [] }) declare readonly children: MentionChild[];
  @attr('string') declare readonly mediaType: InstagramMediaType;
  @attr('string') declare readonly mediaUrl: string;
  @attr('string') declare readonly permalink: string;
  @attr('number') declare readonly timestamp: number;
  @attr('string') declare readonly type: MentionRawType;
  @attr('string') declare readonly username: string;
  @attr('number') declare readonly uid: number;

  @reads('auth.currentSocialProfile') declare currentSocialProfile: SocialProfileModel;
  @reads('mediaUrl') declare standardResUrl: string;
  @reads('mediaUrl') declare standardResVideoUrl: string;
  @reads('permalink') declare link: string;
  @reads('timestamp') declare createdTime: number;
  @equal('downcasedMediaType', 'video') declare isVideo: boolean;
  @equal('downcasedMediaType', 'carousel_album') declare isCarouselPost: boolean;

  get mentionType(): MentionType | undefined {
    if (!this.type) {
      return;
    }
    return this.type === 'mentioned_comment' ? 'comment' : 'caption';
  }

  get carouselMediaItems(): CarouselMediaItem[] {
    return this.children.map((child) => ({
      standardResVideoUrl: child.media_type.toLowerCase() === 'video' ? child.media_url : undefined,
      standardResUrl: child.media_url,
      mediaType: child.media_type.toLowerCase(),
      id: child.id,
      captionText: this.caption,
      link: this.link
    }));
  }

  get captionText(): string {
    return formatRegramCaption(this);
  }

  get displayText(): string | undefined {
    return this.commentText || this.caption;
  }

  get mentionerUsername(): string {
    return this.commentUsername || this.username;
  }

  get dateString(): string | undefined {
    return this.timestamp ? moment.unix(this.timestamp).format('MMM Do YYYY') : undefined;
  }

  get yearMonthTimeStamp(): string {
    return moment.unix(this.timestamp).format('YYYY-MM');
  }

  get downcasedMediaType(): string {
    return this.mediaType.toLowerCase();
  }

  get secureStandardResUrl(): string {
    return this.secureUrl(this.mediaUrl);
  }

  get secureStandardResVideoUrl(): string {
    return this.secureUrl(this.mediaUrl);
  }

  secureUrl(url?: string): string {
    if (isNone(url)) {
      return '';
    }
    return url.replace('http://', 'https://');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    mention: MentionModel;
  }
}
