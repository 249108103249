import moment from 'moment';

const isWithinDates = (start: Date, end: Date, dateRange: { start: Date; end: Date }): boolean => {
  const currentStart = moment(start);
  const currentEnd = moment(end);
  const startDateWithinRange =
    currentStart.isSameOrAfter(dateRange.start) || currentStart.isBetween(dateRange.start, dateRange.end);
  const endDateWithinRange =
    currentEnd.isSameOrBefore(dateRange.end) || currentEnd.isBetween(dateRange.start, dateRange.end);

  return startDateWithinRange && endDateWithinRange;
};

export default isWithinDates;
