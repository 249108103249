import { inject as service } from '@ember/service';
import Command from 'command-palette/command/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import type RouterService from '@ember/routing/router-service';
import type AuthService from 'later/services/auth';

@command('navigate-to-billing-info')
export class NavigateToBillingInfoCommand extends Command {
  @service declare auth: AuthService;
  @service declare router: RouterService;

  public searchTerms = [
    'navigate',
    'go to',
    'billing info',
    'subscription',
    'credit card',
    'settings',
    'update',
    'change'
  ];

  protected action(): void | Promise<void> {
    this.router.transitionTo('account.subscription.billing');
  }

  public getTitle(): string {
    return `View Your Billing Info`;
  }

  public getDescription(): string {
    return `Manage billing information for ${bold(this.auth.currentUserModel?.email)}`;
  }

  public getIcon(): string {
    return 'monetary';
  }
}

export default NavigateToBillingInfoCommand;
