import config from 'later/config/environment';

/**
 * Checks if the current environment is production
 */
const isProduction = (): boolean => isEnv('production');

/**
 * Checks if the current environment lazy loads translations
 */
const isLazyLoad = (): boolean => config.APP.lazyLoadTranslations === 'true';

/**
 * Checks if the current environment matches the specified environment
 */
const isEnv = (env: string): boolean => env === config.APP.deployTarget;

export { isProduction, isLazyLoad, isEnv };
