import { isPresent } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import { isValidUrl } from 'later/utils/is-valid-url';

@classic
class UrlFormat extends BaseValidator {
  validate() {
    if (this.get('model.socialProfile.isPinterest') && isPresent(this.get('model.linkUrl'))) {
      return isValidUrl(this.get('model.linkUrl')) ? true : this.createErrorMessage('invalid_url');
    } else if (
      (this.get('model.socialProfile.isInstagram') || this.get('model.socialProfile.isTiktok')) &&
      isPresent(this.get('model.linkinbioPost')) &&
      isPresent(this.get('model.linkinbioPost.linkUrl'))
    ) {
      return isValidUrl(this.get('model.linkinbioPost.linkUrl')) ? true : this.createErrorMessage('invalid_url');
    }
    return true;
  }
}

export default UrlFormat;
