import { inject as service } from '@ember/service';
import { isNone, isBlank } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import moment, { tz } from 'moment';

import { isProduction } from 'later/utils/is-env';
import createMomentInTz from 'shared/utils/time-helpers/create-moment-in-tz';

import type { AsyncBelongsTo } from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import type SocialProfileModel from 'later/models/social-profile';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';
import type { Maybe } from 'shared/types';

export interface EnableDisableUpdateAPIActionResponse {
  analytics_report: AnalyticsReportModel;
}

export interface ReportPreview {
  profileAverageEngagement?: Maybe<string>;
  industryAverageEngagement?: Maybe<string>;
  industryName?: string;
}

interface RawReportPreview {
  profile_average_engagement?: Maybe<string>;
  industry_average_engagement?: Maybe<string>;
  industry_name?: string;
}

export default class AnalyticsReportModel extends Model {
  @service declare intl: IntlService;
  @service declare userConfig: UserConfigService;

  @belongsTo('socialProfile', { async: true }) declare socialProfile: AsyncBelongsTo<SocialProfileModel>;

  @attr('string') declare name: string;
  @attr('number') declare updatedTime: number;
  @attr('number') declare dataUpdatedTime: number;
  @attr('string') declare s3SignedUrl: string;
  @attr('boolean') declare autoUpdateEnabled: boolean;
  @attr('string') declare reportStatus: string;
  @attr('string') declare slug: string;
  @attr('boolean') declare enabled: boolean;
  @attr('boolean') declare benchmarkEngagementEnabled: boolean;
  @attr preview: Maybe<RawReportPreview>;

  get dataUpdatedMomentInTz(): Moment | null {
    if (!this.dataUpdatedMoment) {
      return null;
    }

    return this.dataUpdatedMoment.tz(this.userConfig.currentTimeZone?.identifier || tz.guess());
  }

  get formattedUpdatedTime(): string | undefined {
    return this.reportUpdatedToday
      ? this.intl.t('media_library.today')
      : this.dataUpdatedMomentInTz?.format('MMM D, YYYY');
  }

  get formattedUpdatedTimeLowerCase(): string | undefined {
    return this.reportUpdatedToday
      ? this.intl.t('media_library.today').toLowerCase()
      : this.dataUpdatedMomentInTz?.format('MMM D, YYYY');
  }

  get reportUpdatedToday(): boolean | undefined {
    const todayMoment = createMomentInTz(null, this.userConfig.currentTimeZone?.identifier || '');
    return this.dataUpdatedMomentInTz?.isSame(todayMoment, 'day');
  }

  get updatedMoment(): Moment | null {
    if (isNone(this.updatedTime)) {
      return null;
    }
    return moment(this.updatedTime * 1000);
  }

  get dataUpdatedMoment(): Moment | null {
    if (isNone(this.dataUpdatedTime)) {
      return null;
    }
    return moment(this.dataUpdatedTime * 1000);
  }

  get isPlaceholder(): boolean {
    return isNone(this.s3SignedUrl) && isNone(this.id);
  }

  get hasSignedUrl(): boolean {
    return !isBlank(this.s3SignedUrl);
  }

  get url(): string {
    const subDomain = isProduction() ? 'report' : 'report-staging';
    return `https://${subDomain}.later.com/${this.slug}`;
  }

  async updateReportData(): Promise<EnableDisableUpdateAPIActionResponse> {
    return await apiAction(this, {
      method: 'PUT',
      path: 'update_report_data'
    });
  }

  async disableReport(): Promise<EnableDisableUpdateAPIActionResponse> {
    return await apiAction(this, {
      method: 'PUT',
      path: 'disable'
    });
  }

  async enableReport(): Promise<EnableDisableUpdateAPIActionResponse> {
    return await apiAction(this, {
      method: 'PUT',
      path: 'enable'
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'analytics-report': AnalyticsReportModel;
  }
}
