import Service, { inject as service } from '@ember/service';
import { didCancel, keepLatestTask } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

import { timestamp } from 'later/utils/time-format';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type MediaItemModel from 'later/models/media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type MediaLibraryService from 'later/services/media-library';
import type SegmentService from 'later/services/segment';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type { SocialPlatformType } from 'shared/types/social-profile';

type SideLibraryBehavior = 'calendar' | 'stories' | 'preview' | 'list' | null | undefined;

interface CanScheduleConfig {
  numPosts: number;
  location: string;
}

export default class ScheduleService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare mediaLibrary: MediaLibraryService;
  @service declare segment: SegmentService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare router: RouterService;

  @tracked isDraggingEmptyPost = false;
  @tracked isDragging = false;
  @tracked isQuickScheduleWizardVisible = false;
  @tracked sideLibraryBehavior: SideLibraryBehavior = null;

  get account(): AccountModel {
    return this.auth.currentAccount;
  }

  get socialProfilesSelectedForScheduling(): SocialPlatformType[] {
    return this.selectedSocialProfiles.profiles.map((profile) => profile.accountType);
  }

  async canSchedule(socialProfile: SocialProfileModel, { numPosts, location }: CanScheduleConfig): Promise<boolean> {
    try {
      await this.updatePostLimit.perform();
      if (socialProfile.hasLimitedPosts && socialProfile.postsLeft < numPosts) {
        this.alerts.upgrade(
          this.intl.t('alerts.calendar.out_of_posts.message', {
            number_posts_remaining: socialProfile.postsLeft
          }),
          {
            title: this.intl.t('alerts.calendar.out_of_posts.title'),
            feature: 'plans',
            location,
            upgradeText: this.intl.t('shared_phrases.view_my_plan')
          }
        );
        return false;
      }
      return true;
    } catch (error) {
      if (!didCancel(error)) {
        this.errors.log(error);
      }
      return false;
    }
  }

  async canScheduleMulti(): Promise<boolean> {
    for (const socialProfile of this.selectedSocialProfiles.profiles) {
      const canSchedule = await this.canSchedule(socialProfile, {
        numPosts: 1,
        location: 'calendar out of posts'
      });
      if (!canSchedule) {
        return false;
      }
    }
    return true;
  }

  async startSchedulingNewPost(mediaItems: MediaItemModel[], location: string): Promise<void> {
    performance.mark('BeginPostV2');

    if (mediaItems.length === 0) {
      throw new Error('Cannot schedule a post without media');
    }

    const firstMediaItem = mediaItems[0];
    const isMultiImagePost = mediaItems.length > 1;

    if (isMultiImagePost) {
      this.segment.track('attempted-multi-photo-post');
      if (!this.account.canCarousel) {
        this._displayCarouselUpgradeAlert();
        return;
      }
      this.mediaLibrary.selectedMedia.addObjects(mediaItems);
    }

    this.segment.track('started-scheduling-post', {
      area: location,
      type: firstMediaItem.mediaType,
      is_multi_profile: this.socialProfilesSelectedForScheduling.length > 1,
      multi_profile_types: this.socialProfilesSelectedForScheduling,
      number_of_images: mediaItems.filter((mediaItem) => mediaItem.mediaType === 'image').length,
      number_of_videos: mediaItems.filter((mediaItem) => mediaItem.mediaType === 'video').length
    });

    if (this.selectedSocialProfiles.hasMultipleSelected) {
      this.#scheduleNewMultiProfilePost(firstMediaItem);
      return;
    }

    this.#scheduleNewSingleProfilePost(firstMediaItem, this.selectedSocialProfiles.firstProfile);
  }

  setDragging(isDragging: boolean): void {
    this.set('isDragging', isDragging);
  }

  setSideLibraryBehavior(behavior: SideLibraryBehavior): void {
    this.set('sideLibraryBehavior', behavior);
  }

  updatePostLimit = keepLatestTask(async () => {
    await this.account.reload();
  });

  _displayCarouselUpgradeAlert(): void {
    const upgradeMessage = this.account.canTrialPlan
      ? this.intl.t('alerts.calendar.cannot_schedule_carousel_free.message_trial')
      : this.intl.t('alerts.calendar.cannot_schedule_carousel_free.message');

    this.alerts.upgrade(upgradeMessage, {
      title: this.intl.t('alerts.calendar.cannot_schedule_carousel_free.title'),
      type: 'info',
      feature: 'multiPhoto',
      location: 'multi photo schedule flash message',
      upgradeText: this.intl.t('shared_phrases.upgrade_plan'),
      upgradeQaClassName: 'qa--carouselUpsell_upgradePlan_Btn'
    });
  }

  async #scheduleNewSingleProfilePost(mediaItem: MediaItemModel, socialProfile?: SocialProfileModel): Promise<void> {
    const calendarOrList = this.router.currentRouteName.includes('list') ? 'list' : 'calendar';

    if (socialProfile) {
      const canSchedule = await this.canSchedule(socialProfile, {
        numPosts: 1,
        location: 'calendar out of posts'
      });
      if (!canSchedule) {
        return;
      }
    }

    this.router.transitionTo(`cluster.schedule.${calendarOrList}.post.new`, mediaItem.id, {
      queryParams: {
        backTo: `cluster.schedule.${calendarOrList}`,
        scheduledTime: timestamp()
      }
    });
  }

  async #scheduleNewMultiProfilePost(mediaItem: MediaItemModel): Promise<void> {
    const canSchedule = await this.canScheduleMulti();
    const calendarOrList = this.router.currentRouteName.includes('list') ? 'list' : 'calendar';
    if (!canSchedule) {
      return;
    }

    this.router.transitionTo(`cluster.schedule.${calendarOrList}.post.multi`, mediaItem.id, {
      queryParams: {
        backTo: `cluster.schedule.${calendarOrList}`,
        scheduledTime: timestamp()
      }
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    schedule: ScheduleService;
  }
}
