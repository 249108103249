import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class LinkinbioPageSerializer extends ApplicationSerializer {
  attrs = {
    linkinbioBlocks: {
      key: 'linkinbio_blocks',
      embedded: 'always',
      serialize: 'records'
    }
  };
}
