import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type AuthService from 'later/services/auth';

export default class PlannerRoute extends Route {
  @service declare router: RouterService;
  @service declare auth: AuthService;

  redirect(): void {
    if (this.auth.currentAccount.canHideAiFeatures) {
      this.router.transitionTo('cluster');
      return;
    }

    this.router.transitionTo('cluster.planner.content-pillars');
  }
}
