import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class PublishingMethod extends BaseValidator {
  validate(value, _, model) {
    const { isNonPublishedPost } = model;

    if (isNonPublishedPost) {
      return this.createErrorMessage('selectPublishingMethod');
    }
    return true;
  }
}
