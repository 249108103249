import Component from '@glimmer/component';

export default class PreventBackspaceComponent extends Component {
  constructor(...args) {
    super(...args);

    document.addEventListener('keyup', this._preventBackspace);
  }

  willDestroy(...args) {
    super.willDestroy(...args);
    document.removeEventListener('keyup', this._preventBackspace);
  }

  _preventBackspace(evt) {
    if (evt.key !== 'Backspace') {
      return;
    }

    const matches = (el, selector) =>
      (
        el.matches ||
        el.matchesSelector ||
        el.msMatchesSelector ||
        el.mozMatchesSelector ||
        el.webkitMatchesSelector ||
        el.oMatchesSelector
      ).call(el, selector);

    const validBackspaceElements = [
      'input:not([readonly]):not([type=checkbox]):not([type=radio])',
      'textarea',
      '[contenteditable]',
      '[contenteditable=true]'
    ];

    const isMatch = validBackspaceElements.some((element) => matches(evt.target, element));

    if (!isMatch) {
      evt.preventDefault();
    }
  }
}
