import type StoreService from '@ember-data/store';
import type { SearchCommandQuery } from 'command-palette/types';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';

export function getProfile(params: {
  auth: AuthService;
  store: StoreService;
  query: SearchCommandQuery;
}): SocialProfileModel {
  const nickname = params.query.tokens.mentions[0];
  if (!nickname) {
    return params.auth.currentSocialProfile;
  }

  const profile = params.store.peekAll('social-profile').find((profile) => {
    return profile.nickname?.startsWith(nickname);
  });

  if (!profile) {
    return params.auth.currentSocialProfile;
  }

  return profile;
}
