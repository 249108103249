import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment-timezone';

import { fetch, nestedObjectToQueryString } from 'later/utils/fetch';
import { preciseRound } from 'later/utils/number-helpers';
import { MILLISECONDS_PER_SECOND } from 'later/utils/time-format';

import type { AsyncBelongsTo } from '@ember-data/model';
import type AccountModel from 'later/models/account';
import type AuthService from 'later/services/auth';
import type { TRIAL_TYPES } from 'later/utils/constants';
import type { Moment } from 'moment';
import type { MakeOptional, Maybe, ValueOfType } from 'shared/types';

interface UpcomingChanges {
  additional_ai_credits: PendingSubscriptionChange;
  additional_social_sets: PendingSubscriptionChange;
  additional_users: PendingSubscriptionChange;
  base: PendingSubscriptionChange;
}

interface PendingSubscriptionChange {
  quantity: Maybe<number>;
  plan_id: Maybe<string>;
  active_at: Maybe<number>;
}

interface SubscriptionChange {
  quantity: Maybe<number>;
  planId: Maybe<string>;
  activeAt: Maybe<number>;
  activeAtMoment: Maybe<Moment>;
}

export type ProrateOptions = MakeOptional<
  {
    additional_social_sets: number;
    additional_users: number;
    additional_ai_credits: number;
    plan_id: string;
    tax_id?: string;
  },
  'tax_id'
>;

export default class SubscriptionModel extends Model {
  @service declare auth: AuthService;

  @attr('boolean', { defaultValue: true }) declare active: boolean;
  @attr('number') declare activeUntilTime: number;
  @attr('number') declare amount: number; // In dollars
  @attr('boolean', { defaultValue: false }) declare automaticTaxEnabled: boolean;
  @attr('number') declare upcomingAmount: number; // In dollars
  @attr('number') declare billingPeriodEndTime: number;
  @attr('boolean', { defaultValue: false }) declare cancelled: boolean;
  @attr('number') declare createdAt: number;
  @attr('string') declare frequency: string;
  @attr('string') declare name: string;
  @attr('string') declare status: string;
  @attr('string')
  declare providerStatus:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid';

  @belongsTo('account', { async: true }) declare account: AsyncBelongsTo<AccountModel>;

  @attr('number', { defaultValue: 0 }) declare includedAiCredits: number;
  @attr('number', { defaultValue: 1 }) declare includedUsers: number;
  @attr('number', { defaultValue: 1 }) declare includedSocialSets: number;
  @attr('number', { defaultValue: 0 }) declare additionalAiCredits: number;
  @attr('number', { defaultValue: 0 }) declare additionalUsers: number;
  @attr('number', { defaultValue: 0 }) declare additionalSocialSets: number;

  @attr('number') declare aiCreditsModifiedTime: number;
  @attr('number') declare planModifiedTime: number;
  @attr('number') declare socialSetsModifiedTime: number;
  @attr('number') declare usersModifiedTime: number;
  @attr('number') declare trialExpiresTime: number;
  @attr('string') declare trialType: ValueOfType<typeof TRIAL_TYPES>;
  @attr('number') declare trialEpoch: number;

  @attr() declare upcomingChanges: UpcomingChanges;

  get upcomingAiCreditsChanges(): SubscriptionChange {
    const additionalAiCredits = this.upcomingChanges?.additional_ai_credits || {};
    const { quantity, plan_id: planId, active_at: activeAt } = additionalAiCredits;
    return {
      quantity,
      planId,
      activeAt,
      activeAtMoment: isNumber(activeAt) ? moment(activeAt * MILLISECONDS_PER_SECOND) : undefined
    };
  }

  get upcomingUserChanges(): SubscriptionChange {
    const additionalUsers = this.upcomingChanges?.additional_users || {};
    const { quantity, plan_id: planId, active_at: activeAt } = additionalUsers;
    return {
      quantity,
      planId,
      activeAt,
      activeAtMoment: isNumber(activeAt) ? moment(activeAt * MILLISECONDS_PER_SECOND) : undefined
    };
  }

  get upcomingSocialSetChanges(): SubscriptionChange {
    const additionalSocialSets = this.upcomingChanges?.additional_social_sets || {};
    const { quantity, plan_id: planId, active_at: activeAt } = additionalSocialSets;
    return {
      quantity,
      planId,
      activeAt,
      activeAtMoment: isNumber(activeAt) ? moment(activeAt * MILLISECONDS_PER_SECOND) : undefined
    };
  }

  get upcomingPlanChanges(): SubscriptionChange {
    const plan = this.upcomingChanges?.base || {};
    const { quantity, plan_id: planId, active_at: activeAt } = plan;
    return {
      quantity,
      planId,
      activeAt,
      activeAtMoment: isNumber(activeAt) ? moment(activeAt * MILLISECONDS_PER_SECOND) : undefined
    };
  }

  @computed('hasUpcomingUserChanges', 'hasUpcomingSocialSetChanges', 'hasUpcomingPlanChanges')
  get hasUpcomingChanges(): boolean {
    return this.hasUpcomingUserChanges || this.hasUpcomingSocialSetChanges || this.hasUpcomingPlanChanges;
  }

  get hasUpcomingAiCreditsChanges(): boolean {
    return !isEmpty(this.upcomingChanges?.additional_ai_credits);
  }

  get hasUpcomingUserChanges(): boolean {
    return !isEmpty(this.upcomingChanges?.additional_users);
  }

  get hasUpcomingSocialSetChanges(): boolean {
    return !isEmpty(this.upcomingChanges?.additional_social_sets);
  }

  get hasUpcomingPlanChanges(): boolean {
    return !isEmpty(this.upcomingChanges?.base);
  }

  get isGracePeriod(): boolean {
    return this.activeUntilTime > moment().unix();
  }

  get upcomingAdditionalAiCredits(): number {
    return this.upcomingAiCreditsChanges.quantity ?? this.additionalAiCredits;
  }

  get upcomingAdditionalSocialSets(): number {
    return this.upcomingSocialSetChanges.quantity ?? this.additionalSocialSets;
  }

  get upcomingAdditionalUsers(): number {
    return this.upcomingUserChanges.quantity ?? this.additionalUsers;
  }

  get upcomingTotalSocialSets(): number {
    const upcomingRemovedSocialSets = this.upcomingSocialSetChanges.quantity ?? 0;
    return this.totalSocialSets - upcomingRemovedSocialSets;
  }

  get upcomingTotalUsers(): number {
    const upcomingRemovedUsers = this.upcomingUserChanges.quantity ?? 0;
    return this.totalUsers - upcomingRemovedUsers;
  }

  @computed('includedUsers', 'additionalUsers')
  get totalUsers(): number {
    return this.includedUsers + this.additionalUsers;
  }

  @computed('includedSocialSets', 'additionalSocialSets')
  get totalSocialSets(): number {
    return this.includedSocialSets + this.additionalSocialSets;
  }

  @computed('totalUsers', 'auth.users.length')
  get availableUsers(): number {
    return Math.max(0, this.totalUsers - this.auth.users.length);
  }

  @computed('totalSocialSets', 'auth.socialSets.length')
  get availableSocialSets(): number {
    // Note: type cast is needed here as this.auth.socialSets.length has type of number | ComputedProperty<number>
    return Math.max(0, this.totalSocialSets - (this.auth.socialSets.length as number));
  }

  get amountInCents(): number {
    return (this.amount ?? 0) * 100;
  }

  get upcomingAmountInCents(): number {
    return (this.upcomingAmount ?? 0) * 100;
  }

  get createdDate(): string {
    return moment.unix(this.createdAt).format('MMM D, YYYY');
  }

  @computed('activeUntilTime')
  get activeUntil(): string {
    return moment.unix(this.activeUntilTime).format('MMM D, YYYY');
  }

  get downcasedStatus(): string {
    return this.status.toLowerCase();
  }

  get isActive(): boolean {
    return this.downcasedStatus === 'active';
  }

  get isCancelled(): boolean {
    return this.downcasedStatus === 'cancelled';
  }

  get isExpired(): boolean {
    return this.downcasedStatus === 'expired';
  }

  get isPastDue(): boolean {
    return this.providerStatus === 'past_due' && !this.isCancelled;
  }

  get monthlyRate(): string {
    const rate = this.frequency === 'per year' ? this.amountInCents / 12 : this.amountInCents;

    return preciseRound(rate, 2);
  }

  get renewalDate(): string {
    return moment.unix(this.billingPeriodEndTime).format('MMM D, YYYY');
  }

  get aiCreditsModifiedMoment(): Moment {
    return moment.unix(this.aiCreditsModifiedTime);
  }

  get planModifiedMoment(): Moment {
    return moment.unix(this.planModifiedTime);
  }

  get socialSetsModifiedMoment(): Moment {
    return moment.unix(this.socialSetsModifiedTime);
  }

  get usersModifiedMoment(): Moment {
    return moment.unix(this.usersModifiedTime);
  }

  get trialExpiryMoment(): Maybe<Moment> {
    return this.trialExpiresTime ? moment.unix(this.trialExpiresTime) : null;
  }

  get trialDaysRemaining(): Maybe<number> {
    // Round up days remaining to display accurate number.
    // i.e. There is always "1" day between today and tomorrow. Not "0.4"
    return this.trialExpiryMoment ? Math.ceil(this.trialExpiryMoment.diff(moment(), 'days', true)) : null;
  }

  get hasActiveTrial(): boolean {
    if (!this.trialExpiryMoment) return false;

    return this.trialExpiryMoment > moment();
  }

  prorate(payload: ProrateOptions): Promise<{ immediate_amount: number; future_amount_time: number }> | void {
    if (this.hasActiveTrial) {
      return;
    }

    return fetch(`/api/v2/subscriptions/${this.id}/prorate?${nestedObjectToQueryString(payload)}`);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subscription: SubscriptionModel;
  }
}
