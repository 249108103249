import Model, { attr, belongsTo } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';

import type ContentPillarModel from 'later/models/content-pillar';
import type GroupModel from 'later/models/group';

export type RawContentIdea = {
  created_at: number;
  generated_title: string;
  id: number;
  platform_error: string;
  platform_warning: string;
  title: string;
  updated_at: number;
};

type GeneratePayload = {
  content_pillar_id: string;
  count?: number;
};

type RawGenerateResponse = {
  content_ideas?: RawContentIdea[];
  errors?: string[];
};

export default class ContentIdeaModel extends Model {
  @belongsTo('contentPillar') declare contentPillar: ContentPillarModel;
  @belongsTo('group') declare group: GroupModel;

  @attr('number') declare createdAt: number;
  @attr('string') declare generatedTitle: string;
  @attr('string') declare platformError: string;
  @attr('string') declare platformWarning: string;
  @attr('string') declare title: string;
  @attr('boolean') declare used: boolean;
  @attr('number') declare usedAt: number;

  // POST /api/v2/content_ideas/generate.json
  generate = collectionAction<GeneratePayload, RawGenerateResponse>({
    path: 'generate',
    type: 'POST',
    after(response: RawGenerateResponse): RawGenerateResponse {
      this.store.pushPayload(response);
      return response;
    }
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-idea': ContentIdeaModel;
  }
}
