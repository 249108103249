import Service, { inject as service } from '@ember/service';
import { dropTask, task } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

import FacebookProduct from 'shared/utils/facebook-product';
import hashCode from 'shared/utils/hash-code';

import type IntlService from 'ember-intl/services/intl';
import type AuthService from 'later/services/auth';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type FacebookGraphService from 'later/services/social/facebook-graph';
import type SubscriptionsService from 'later/services/subscriptions';
import type { UntypedService } from 'shared/types';
import type { TrackedArray } from 'tracked-built-ins';

const cacheableTypes = {
  CATALOG: 'catalog',
  PRODUCT: 'product'
};

interface Catalog {
  catalog_id: string;
  catalog_name: string;
  shop_name: string;
  product_count: number;
}

interface Product {
  product_id: number;
  merchant_id: number;
  product_name: string;
  image_url: string;
  retailer_id: string;
  review_status: string;
  is_checkout_flow: boolean;
}

export type FacebookError = {
  message: string;
  type: string;
  code: number | 'OAuthException';
  error_subcode?: number;
  error_user_title: string;
  error_user_msg: string;
  fbtrace_id: string;
};

interface FacebookPaging {
  cursors?: { after?: string };
  next?: string;
}

interface FacebookProductResult {
  id: string;
  image_url: string;
  name: string;
  error?: FacebookError;
}

interface FacebookProductsResults {
  data: Product[];
  paging?: FacebookPaging;
  error?: FacebookError;
}

interface FacebookCatalogsResults {
  data: Catalog[];
  error?: FacebookError;
}
interface ProductPagination {
  next?: string;
}

interface ProductTagEligibilityResults {
  shopping_product_tag_eligibility: boolean;
  id: string;
}

type CacheableItems = Catalog[] | FacebookProduct[] | FacebookProductsResults | Product | FacebookProductResult;

export default class InstagramProductCatalogService extends Service {
  // TODO: Declare services after they've been converted to typescript
  @service declare auth: AuthService;
  @service declare cache: UntypedService;
  @service declare errors: UntypedService;
  @service declare intl: IntlService;
  @service declare locale: UntypedService;
  @service declare segment: UntypedService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service('social/facebook-graph') declare facebookGraph: FacebookGraphService;
  @service declare subscriptions: SubscriptionsService;

  @tracked catalogs: TrackedArray<Catalog> = tracked([]);
  @tracked error = '';
  @tracked products: TrackedArray<FacebookProduct> = tracked([]);
  @tracked productPagination: ProductPagination = { next: undefined };
  @tracked selectedCatalog?: Catalog;
  @tracked eligibleForProductTagging = false;

  get facebookToken(): string | undefined {
    return this.auth.currentUserModel.facebookToken;
  }

  selectCatalog(catalogId: string): void {
    this.selectedCatalog = this.catalogs.find((catalog) => catalog.catalog_id === catalogId);
    this.#createSegmentEvent();
  }

  #createSegmentEvent(): void {
    const socialProfile = this.selectedSocialProfiles.firstProfile;
    this.segment.track('connected-instagram-catalogue', {
      plan_name: this.subscriptions.planName,
      active_trial: !!this.subscriptions.subscription?.hasActiveTrial,
      profile: socialProfile?.id,
      social_profile: socialProfile,
      business_model: this.auth.currentAccount?.mainProfile?.businessModel,
      industry: this.auth.currentAccount?.mainProfile?.industry,
      num_catalogues_available: this.catalogs.length
    });
  }

  #generateCacheKey(dataType: string, params = {}): string {
    const key = hashCode(JSON.stringify({ dataType, ...params }));
    return String(key);
  }

  #getCachedItems(cacheKey: string): CacheableItems | undefined {
    return this.cache.retrieve(cacheKey);
  }

  #handleErrors(error?: FacebookError): string {
    const errorMessage = error?.message || this.intl.t('instagram_catalog.errors.fetching_catalogs');
    this.errors.log(errorMessage, error);
    this.error = errorMessage;
    return errorMessage;
  }

  #handleFetchCatalogsSuccess(results: Catalog[], cacheKey: string): Catalog[] {
    const catalogs = results;
    this.#setCachedItems(cacheKey, catalogs);
    this.error = '';
    this.catalogs = tracked(catalogs);
    return catalogs;
  }

  #handleFetchProductSuccess(product: FacebookProductResult, cacheKey: string): FacebookProduct {
    const facebookProduct = new FacebookProduct(product, this.locale);
    this.#setCachedItems(cacheKey, product);
    this.error = '';
    return facebookProduct;
  }

  #handleFetchProductsSuccess(
    results: FacebookProductsResults,
    hasPaginationUrl: boolean,
    cacheKey?: string
  ): FacebookProduct[] {
    const products = results.data.map((product: Product) => new FacebookProduct(product, this.locale));
    const next = results.paging?.next;

    this.productPagination = { next };
    this.error = '';
    this.#updateProducts(hasPaginationUrl, products);
    if (cacheKey) {
      this.#setCachedItems(cacheKey, results);
    }
    return this.products;
  }

  #handleFetchProductTagEligibilitySuccess(results: ProductTagEligibilityResults): void {
    this.eligibleForProductTagging = results.shopping_product_tag_eligibility;
    this.error = '';
  }

  #setCachedItems(cacheKey: string, items: CacheableItems): void {
    this.cache.add(cacheKey, items, { expiry: this.cache.maxExpiryDate() });
  }

  #updateProducts(hasPaginationUrl: boolean, products: FacebookProduct[]): void {
    if (hasPaginationUrl) {
      this.products.push(...products);
    } else {
      this.products = tracked(products);
    }
  }

  fetchAndCacheProduct = task(async (productId: string, businessAccountToken: string) => {
    const { PRODUCT } = cacheableTypes;
    const cacheKey = this.#generateCacheKey(PRODUCT, { productId });
    const cachedProduct = this.#getCachedItems(cacheKey) as Product;

    if (cachedProduct) {
      return new FacebookProduct(cachedProduct, this.locale);
    }

    try {
      const result: FacebookProductResult = await this.facebookGraph.fetchProduct.perform(
        this.facebookToken,
        productId,
        businessAccountToken
      );

      return result.error ? this.#handleErrors(result.error) : this.#handleFetchProductSuccess(result, cacheKey);
    } catch (error) {
      this.#handleErrors(error);
      return;
    }
  });

  fetchAndCacheCatalogs = task(async (instagramUserId: string, businessAccountToken: string) => {
    const { CATALOG } = cacheableTypes;

    const cacheKey = this.#generateCacheKey(CATALOG, { instagramUserId });
    const cachedCatalogs = this.#getCachedItems(cacheKey) as Catalog[];

    if (cachedCatalogs) {
      this.catalogs = tracked(cachedCatalogs);
      return cachedCatalogs;
    }

    try {
      const results: FacebookCatalogsResults = await this.facebookGraph.fetchAvailableCatalogs.perform(
        instagramUserId,
        this.facebookToken,
        businessAccountToken
      );
      return results.error
        ? this.#handleErrors(results.error)
        : this.#handleFetchCatalogsSuccess(results.data, cacheKey);
    } catch (error) {
      this.#handleErrors(error);
      return;
    }
  });

  fetchAndCacheProducts = dropTask(
    async ({
      instagramUserId,
      searchString = '',
      paginationUrl,
      businessAccountToken
    }: {
      instagramUserId: string;
      searchString: string;
      paginationUrl?: string;
      businessAccountToken: string;
    }) => {
      const { PRODUCT } = cacheableTypes;
      const catalogId = this.selectedCatalog?.catalog_id;
      const cacheKey = this.#generateCacheKey(PRODUCT, { instagramUserId, catalogId, searchString });
      const cachedProducts = this.#getCachedItems(cacheKey) as FacebookProductsResults;
      const hasPaginationUrl = Boolean(paginationUrl);

      if (cachedProducts) {
        const products = cachedProducts.data.map((product: Product) => new FacebookProduct(product, this.locale));
        this.#updateProducts(hasPaginationUrl, products);
        return this.products;
      }

      try {
        const params = { q: searchString, catalog_id: catalogId };
        const results: FacebookProductsResults = await this.facebookGraph.fetchCatalogProducts.perform({
          instagramUserId,
          token: this.facebookToken,
          params,
          paginationUrl,
          businessAccountToken
        });
        return results.error
          ? this.#handleErrors(results.error)
          : this.#handleFetchProductsSuccess(results, hasPaginationUrl, cacheKey);
      } catch (error) {
        this.#handleErrors(error);
        return;
      }
    }
  );

  fetchProductTagEligibility = task(async (instagramUserId: string, businessAccountToken: string) => {
    try {
      const results = await this.facebookGraph.fetchProductTagEligibility.perform(
        instagramUserId,
        this.facebookToken,
        businessAccountToken
      );
      results.error ? this.#handleErrors(results.error) : this.#handleFetchProductTagEligibilitySuccess(results);
    } catch (error) {
      this.#handleErrors(error);
    }
  });

  loadMoreProducts = task(
    async ({
      instagramUserId,
      searchString = '',
      businessAccountToken
    }: {
      instagramUserId: string;
      searchString: string;
      businessAccountToken: string;
    }) => {
      await this.fetchAndCacheProducts.perform({
        instagramUserId,
        searchString,
        paginationUrl: this.productPagination.next,
        businessAccountToken
      });
    }
  );
}

declare module '@ember/service' {
  interface Registry {
    'social/instagram-product-catalog': InstagramProductCatalogService;
  }
}
