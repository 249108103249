export default [
  'af',
  'sq',
  'am',
  'ar-dz',
  'ar-bh',
  'ar-eg',
  'ar-iq',
  'ar-jo',
  'ar-kw',
  'ar-lb',
  'ar-ly',
  'ar-ma',
  'ar-om',
  'ar-qa',
  'ar-sa',
  'ar-sy',
  'ar-tn',
  'ar-ae',
  'ar-ye',
  'hy',
  'as',
  'az-az',
  'az-az',
  'eu',
  'be',
  'bn',
  'bn',
  'bs',
  'bg',
  'my',
  'ca',
  'zh-cn',
  'zh-hk',
  'zh-mo',
  'zh-sg',
  'zh-tw',
  'hr',
  'cs',
  'da',
  'dv',
  'nl-be',
  'nl-nl',
  'en-au',
  'en-bz',
  'en-ca',
  'en-cb',
  'en-gb',
  'en-in',
  'en-ie',
  'en-jm',
  'en-nz',
  'en-ph',
  'en-za',
  'en-tt',
  'en-us',
  'et',
  'fo',
  'fa',
  'fi',
  'fr-be',
  'fr-ca',
  'fr-fr',
  'fr-lu',
  'fr-ch',
  'mk',
  'gd-ie',
  'gd',
  'de-at',
  'de-de',
  'de-li',
  'de-lu',
  'de-ch',
  'el',
  'gn',
  'gu',
  'he',
  'hi',
  'hu',
  'is',
  'id',
  'it-it',
  'it-ch',
  'ja',
  'kn',
  'ks',
  'kk',
  'km',
  'ko',
  'lo',
  'la',
  'lv',
  'lt',
  'ms-bn',
  'ms-my',
  'ml',
  'mt',
  'mi',
  'mr',
  'mn',
  'mn',
  'ne',
  'no-no',
  'no-no',
  'or',
  'pl',
  'pt-br',
  'pt-pt',
  'pa',
  'rm',
  'ro-mo',
  'ro',
  'ru',
  'ru-mo',
  'sa',
  'sr-sp',
  'sr-sp',
  'tn',
  'sd',
  'si',
  'sk',
  'sl',
  'so',
  'sb',
  'es-ar',
  'es-bo',
  'es-cl',
  'es-co',
  'es-cr',
  'es-do',
  'es-ec',
  'es-sv',
  'es-gt',
  'es-hn',
  'es-mx',
  'es-ni',
  'es-pa',
  'es-py',
  'es-pe',
  'es-pr',
  'es-es',
  'es-uy',
  'es-ve',
  'sw',
  'sv-fi',
  'sv-se',
  'tg',
  'ta',
  'tt',
  'te',
  'th',
  'bo',
  'ts',
  'tr',
  'tk',
  'uk',
  'UTF-8',
  'ur',
  'uz-uz',
  'uz-uz',
  'vi',
  'cy',
  'xh',
  'yi',
  'zu'
];
