import { ADDRESS_COUNTRIES_TAXABLE_EU, ADDRESS_FIELD_KEYS } from 'later/utils/constants';

import type { Maybe } from 'shared/types';

/**
 * Flattens address from saved address and converts to camel case
 *
 * @param response the most recently saved address for a user
 *
 * @returns containing AddressFields. Field will be set to empty string if it doesn't exist.
 */
export const assembleAddress = (response: any): AddressFields => ({
  //
  [ADDRESS_FIELD_KEYS.CITY]: response?.city || '',
  [ADDRESS_FIELD_KEYS.COUNTRY]: response?.country || '',
  [ADDRESS_FIELD_KEYS.LINE_ONE]: response?.line1 || '',
  [ADDRESS_FIELD_KEYS.LINE_TWO]: response?.line2 || '',
  [ADDRESS_FIELD_KEYS.STATE]: response?.state || '',
  [ADDRESS_FIELD_KEYS.POSTAL_CODE]: response?.postal_code || '',
  [ADDRESS_FIELD_KEYS.VERIFIED]: response?.verified || false
});

export interface AddressFields {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  state?: string;
  postalCode?: string;
  verified?: boolean;
}

export type SuggestedAddressFields = Omit<AddressFields, 'verified'>;

export interface SuggestedChangeItem {
  key: string;
  suggested: string;
}

export interface AddressConfig {
  fields: Partial<Record<keyof AddressFields, boolean>>;
  shouldDisplayTaxAmount: boolean;
}

export const ADDRESS_FORM_CONFIG = (countryCode: Maybe<string>, rolloutEuVat: boolean): AddressConfig => {
  if (countryCode === 'GB' || (ADDRESS_COUNTRIES_TAXABLE_EU.some((code) => code === countryCode) && rolloutEuVat)) {
    return {
      fields: {
        country: true,
        city: true,
        line1: true,
        line2: true,
        postalCode: true
      },
      shouldDisplayTaxAmount: true
    } as const;
  }

  return {
    fields: {
      country: true,
      city: true,
      line1: true,
      line2: true,
      state: true,
      postalCode: true
    },
    shouldDisplayTaxAmount: false
  } as const;
};

export type Field = {
  FEKey: keyof AddressFields;
  APIKey: 'locality' | 'country' | 'address1' | 'administrative_area' | 'postal_code';
  APISuggestionKey?: 'postal_code_short';
};

// Interface structure defined by
// https://www.smarty.com/docs/cloud/international-street-api
export interface SmartyResponse {
  address1: string;
  address2: string;
  address3: string;
  components: SmartyComponent;
  metadata: Record<string, string>;
  analysis: {
    verification_status: string;
    address_precision: string;
    max_address_precision: string;
    changes: { components: SmartyComponent };
  };
}

interface SmartyComponent {
  administrative_area: string;
  country_iso_3: string;
  locality: string;
  postal_code: string;
  postal_code_short: string;
  premise: string;
  premise_number: string;
  thoroughfare: string;
  thoroughfare_name: string;
  thoroughfare_trailing_type: string;
  address1?: string;
  country?: string;
  post_box?: string;
  post_box_type?: string;
}
