import Model, { attr, belongsTo } from '@ember-data/model';

import type LinkinbioBlockModel from 'later/models/linkinbio-block';

export default class LinkinbioTagModel extends Model {
  @belongsTo('linkinbio-block') declare linkinbioBlock: LinkinbioBlockModel;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-tag': LinkinbioTagModel;
  }
}
