import { isPresent } from '@ember/utils';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import type EmberObject from '@ember/object';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type BrandModel from 'later/models/brand';
import type GroupModel from 'later/models/group';
import type InfluencerModel from 'later/models/influencer';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type MediaKitModel from 'later/models/media-kit';
import type SocialProfileModel from 'later/models/social-profile';
import type { SOCIAL_PROFILE_CODES } from 'later/utils/constants';

export type SocialProfileCodeKeys = keyof typeof SOCIAL_PROFILE_CODES;
export type SocialProfileCodes = (typeof SOCIAL_PROFILE_CODES)[SocialProfileCodeKeys];

export interface TemporaryProfile extends EmberObject {
  hasProfile: boolean;
  profile: SocialProfileModel | undefined;
  profileType: SocialProfileCodes;
}

export default class SocialIdentityModel extends Model {
  @attr('string') declare name?: string;
  @attr('boolean', { defaultValue: false }) declare advancedLinkinbio: boolean;
  @attr('string') declare influencerId?: string;
  @attr('string') declare brandId?: string;
  @hasMany('socialProfile', { async: true }) declare socialProfiles: AsyncHasMany<SocialProfileModel>;
  @belongsTo('linkinbio-page', { async: true }) declare linkinbioPage: LinkinbioPageModel;
  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;
  @belongsTo('influencer', { async: true }) declare influencer: AsyncBelongsTo<InfluencerModel>;
  @belongsTo('brand') declare brand: BrandModel;
  @belongsTo('media-kit') declare mediaKit: MediaKitModel;

  @tracked temporaryProfiles?: TemporaryProfile[]; // Needed for trading profiles between identities

  get hasInstagram(): boolean {
    return isPresent(this.instagramProfile);
  }

  get hasTiktok(): boolean {
    return isPresent(this.tiktokProfile);
  }

  get instagramProfile(): SocialProfileModel | undefined {
    return this.socialProfiles.findBy('profileType', 'instagram');
  }

  get tiktokProfile(): SocialProfileModel | undefined {
    return this.socialProfiles.findBy('profileType', 'tiktok');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'social-identity': SocialIdentityModel;
  }
}
