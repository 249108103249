/**
 * @module Utils
 */

/**
 * @class isPlainObject
 * @extends Utils
 *
 * Taken from https://github.com/nefe/You-Dont-Need-jQuery
 */
function isPlainObject(obj) {
  if (typeof obj !== 'object' || obj.nodeType || (obj !== null && obj !== undefined && obj === obj.window)) {
    return false;
  }

  if (obj.constructor && !Object.prototype.hasOwnProperty.call(obj.constructor.prototype, 'isPrototypeOf')) {
    return false;
  }

  return true;
}

export { isPlainObject };
