import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { filter, mapBy, min, gt, reads, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import classic from 'ember-classic-decorator';

@classic
export default class MentionsController extends Controller {
  @service
  auth;

  @service
  intl;

  @reads('auth.currentSocialProfile')
  socialProfile;

  @notEmpty('media')
  hasMentionMedia;

  @gt('media.length', 20)
  hasMore;

  @mapBy('media', 'timestamp')
  timeStamps;

  @min('timeStamps')
  earliestMentionTimestamp;

  @filter('allMedia', function (mention) {
    return mention.uid === Number(this.get('socialProfile.businessAccountId'));
  })
  media;

  @computed('socialProfile.collectMentionsTimestamp')
  get collectDate() {
    return moment.unix(this.socialProfile.collectMentionsTimestamp).format('MMMM D, YYYY');
  }

  @computed
  get allPostsLoadedMessage() {
    return htmlSafe(
      this.intl.t('mentions.empty.no_more_posts', {
        collect_date: this.collectDate
      })
    );
  }

  @computed('media.[]')
  get yearMonthArray() {
    const createdMonth = this.media.map((mention) => moment.unix(mention.timestamp).format('YYYY-MM'));
    return new Set(createdMonth.sort().reverse());
  }
}
