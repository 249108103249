import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

import type { EmptyObject } from 'shared/types';

/**
 * This component is used to remove the page's loading indicator once
 * the ember app has booted. It is added to the application.hbs so a soon
 * as this component is rendered, the loading indicator is removed.
 *
 * It also removes any flash messages that the backend rendered on the page.
 */
export default class EmberLoadRemoverComponent extends Component {
  #loadingIndicatorClass = 'ember-load-indicator';

  constructor(owner: unknown, args: EmptyObject) {
    super(owner, args);
    this.removeLoadingIndicator();
  }

  removeLoadingIndicator(): void {
    const elements = document.querySelectorAll(`.${this.#loadingIndicatorClass}`);

    elements.forEach((element) => {
      element.parentNode?.removeChild(element);
    });

    // this clears out messages from Rails-env.
    const success = document.querySelector('.o--alert--success');
    const danger = document.querySelector('.o--alert--danger');
    const allAlertElements = document.querySelectorAll<HTMLElement>('.o--alert');

    const fadeOut = (element: HTMLElement): void => {
      element.style.transition = '.5s all';
      element.style.opacity = '0';
      setTimeout(() => {
        element.remove();
      }, 500);
    };

    setTimeout(() => {
      [success, danger].filter((i): i is HTMLElement => isPresent(i)).forEach((elem: HTMLElement) => fadeOut(elem));
    }, 2000);

    Array.from(allAlertElements).forEach((alert) => {
      alert.addEventListener('click', () => fadeOut(alert));
    });
  }
}
