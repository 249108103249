/* eslint-disable ember/no-classic-classes */
/* global deviseAuthToken:readable */
/**
 * @module Routes
 */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import BundleLoadError from 'ember-asset-loader/errors/bundle-load';
import { task } from 'ember-concurrency';
import { polyfill as mobileDragDropPolyfill } from 'mobile-drag-drop';

import config from 'later/config/environment';
import AccountModel from 'later/models/account';
import { MARKETING_QUERY_PARAMS } from 'later/utils/constants';
import { getFeatureFlags } from 'later/utils/feature-flags';
import redirect from 'shared/utils/redirect';

/**
 * Application Route
 *
 * @class ApplicationRoute
 * @extends Ember.Route
 */

export default Route.extend({
  alerts: service(),
  auth: service(),
  browserSupport: service(),
  errors: service(),
  events: service(),
  intl: service(),
  laterConfig: service(),
  locale: service(),
  offline: service(),
  performanceTracking: service(),
  segment: service(),
  session: service(),
  userAgent: service(),
  store: service(),
  integrationsQueue: service(),

  queryParams: {
    new_social_profile_id: {},
    is_uat_test: {}
  },
  isUatTest: false,

  init(...args) {
    this._super(...args);

    this.events.on('auth:setup-complete', (event) => {
      const { account } = event.data;
      const featureFlags = getFeatureFlags(account, AccountModel);
      this.errors.setFeatureFlags(featureFlags);
    });
  },

  async beforeModel(transition) {
    // get app requirements; config & translations
    // return user if already authenticated
    // fall back to `ApplicationRouteMixin`
    try {
      this.session.setup();
      this.errors.setup();
      await this.locale.setupIntl();
      this.integrationsQueue.setup();
      this.offline.setup();
      await this.laterConfig.setup();

      this.browserSupport.displayBrowserWarnings();

      if (this.session.isAuthenticated) {
        return this.auth.setup();
      }
      if (typeof deviseAuthToken !== 'undefined') {
        await this.authenticateFinishProfile.perform();
      }

      return this._super(transition);
    } catch (error) {
      this.errors.log(error, transition, 'critical');
    }
  },
  async model({ new_social_profile_id, is_uat_test }) {
    if (is_uat_test) {
      this.isUatTest = is_uat_test;
      const socialProfiles = this.store.findAll('social-profile');
      const instagramProfiles = await socialProfiles.then((socialProfiles) =>
        socialProfiles.filter((item) => item.isInstagram)
      );

      return this.store.findRecord('social-profile', instagramProfiles[0]?.id);
    }

    return new_social_profile_id ? this.store.findRecord('social-profile', new_social_profile_id) : null;
  },
  activate() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams) {
      this.trackUTMParams(searchParams);
    }

    if (this.userAgent.device.isMobile) {
      this._setMobileViewportHeight();
      this._activateMobileDragDropPolyfill();
    }
  },

  afterModel() {
    this.performanceTracking.measureSlowComponentRenderTimes();
  },
  setupController(controller, model) {
    controller.setProperties({
      newSocialProfile: model,
      isUatTest: this.isUatTest
    });
  },

  actions: {
    error(error, transition) {
      const from = transition.from ? transition.from.name : '';
      const to = transition.to ? transition.to.name : '';

      if (error instanceof BundleLoadError) {
        transition.abort();
        this.alerts.info(this.intl.t('alerts.app.engine_error_message'), {
          sticky: true,
          preventDuplicates: true
        });
      } else {
        this.errors.log(error || 'Uncaught error', { from, to }, 'critical');
        if (config.APP.environment === 'production') {
          redirect('/');
        }
        return true;
      }
    },

    refreshModel() {
      // Note: Ember 4.0 introduces the `this.router.refresh()` method, which will refresh the current route.
      // Once we upgrade, that new method can be called in the `ReconnectSocialProfile` component directly,
      // removing the need for this action.
      this.refresh();
    }
  },

  authenticateFinishProfile: task(function* () {
    const user = yield this.auth.setup();
    const id = user?.email ?? 'preauth';
    yield this.session.authenticate('authenticator:custom', id);
    return user;
  }),

  trackUTMParams(searchParams) {
    const utmAttributes = MARKETING_QUERY_PARAMS.reduce((acc, param) => {
      acc[param] = searchParams.get(param) ?? null;
      return acc;
    }, {});

    const hasUTMParams = Object.values(utmAttributes).some(Boolean);

    if (hasUTMParams) {
      this.segment.track('opened-application', { ...utmAttributes });
    }
  },

  /**
   * This method is used to make mobile web responsive when
   * scrolling. With this method we read from the browser view port
   * and replace the fixed vh value in CSS with the current view port.
   *
   *
   * This solution was inspired by this codepen: https://codepen.io/team/css-tricks/pen/WKdJaB
   * Blog post describing the approach: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   *
   * @method setMobileViewportHeight
   * @private
   */
  _setMobileViewportHeight() {
    const viewPortHeight = window.innerHeight;

    document.documentElement.style.setProperty('--viewPortHeight', `${viewPortHeight}px`);

    window.addEventListener('resize', () => {
      const viewPortHeight = window.innerHeight;

      document.documentElement.style.setProperty('--viewPortHeight', `${viewPortHeight}px`);
    });
  },

  /**
   * Drag and drop interaction in mobile web is not fully
   * supported across all browser versions and the ones that do support it
   * have shown unexpected behavior in some devices. To fix this, mobile-drag-drop
   * package was added and this method triggers the configuration for the package
   * to work.
   *
   *
   * GitHub: https://github.com/timruffles/mobile-drag-drop
   *
   * @method activateDragDropPolyfill
   * @private
   */
  _activateMobileDragDropPolyfill() {
    mobileDragDropPolyfill({
      holdToDrag: 50
    });
  }
});
