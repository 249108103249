import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-bare-strings }}\n<ul>\n  {{#each (entries @query.tokens) as |entry|}}\n    <li>\n      {{get entry 0}}:\n      {{#each (get entry 1) as |token|}}\n        {{token}},\n      {{/each}}\n    </li>\n  {{/each}}\n</ul>\n\nparts:\n<ul>\n  {{#each @query.parts as |part|}}\n    <li>\n      {{part}}\n    </li>\n  {{/each}}\n</ul>", {"contents":"{{! template-lint-disable no-bare-strings }}\n<ul>\n  {{#each (entries @query.tokens) as |entry|}}\n    <li>\n      {{get entry 0}}:\n      {{#each (get entry 1) as |token|}}\n        {{token}},\n      {{/each}}\n    </li>\n  {{/each}}\n</ul>\n\nparts:\n<ul>\n  {{#each @query.parts as |part|}}\n    <li>\n      {{part}}\n    </li>\n  {{/each}}\n</ul>","moduleName":"later/components/global-search/debug-query.hbs","parseOptions":{"srcName":"later/components/global-search/debug-query.hbs"}});
import templateOnly from '@ember/component/template-only';

import type { SearchCommandQuery } from 'command-palette/types';

interface DebugQueryComponentSignature {
  Element: HTMLDivElement;
  Args: {
    query: SearchCommandQuery;
  };
  Blocks: {
    default: [];
  };
}

export const DebugQueryComponent = templateOnly<DebugQueryComponentSignature>();
export default DebugQueryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GlobalSearch::DebugQuery': typeof DebugQueryComponent;
    'global-search/debug-query': typeof DebugQueryComponent;
  }
}
