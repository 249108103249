import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showConfirmationModal}}\n  <ConfirmationModal @model={{this.confirmationModalModel}} />\n{{/if}}\n\n{{#if this.showUpsellModal}}\n  <UpsellModal />\n{{/if}}", {"contents":"{{#if this.showConfirmationModal}}\n  <ConfirmationModal @model={{this.confirmationModalModel}} />\n{{/if}}\n\n{{#if this.showUpsellModal}}\n  <UpsellModal />\n{{/if}}","moduleName":"later/components/dialog-manager.hbs","parseOptions":{"srcName":"later/components/dialog-manager.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DialogManagerComponent extends Component {
  @service dialogManager;

  get showConfirmationModal() {
    return this.dialogManager.showConfirmationModal;
  }

  get confirmationModalModel() {
    return this.dialogManager.confirmationModalModel;
  }

  get showUpsellModal() {
    return this.dialogManager.showUpsellModal;
  }
}
