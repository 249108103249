import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';

import TiktokPost from 'later/models/tiktok-post';
import { DEFAULT_TIKTOK_VIDEO_LIMIT_PER_PAGE, OauthSocialProfileType } from 'later/utils/constants';
import { getCookie } from 'later/utils/cookie';
import { fetch } from 'later/utils/fetch';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import redirect from 'shared/utils/redirect';

import type { SafeString } from '@ember/template/-private/handlebars';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { Maybe, UntypedLaterModel } from 'shared/types';

export interface TiktokPagination {
  cursor?: number;
  hasMore?: boolean;
}

/**
 * @class TiktokService
 * @extends Service
 */

export default class TiktokService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;
  @service declare store: StoreService;

  @readOnly('auth.currentAccount') declare currentAccount: AccountModel;
  @readOnly('auth.currentGroup') declare currentGroup: GroupModel;

  async addProfile(name: string, socialIdentity: SocialIdentityModel, group: GroupModel): Promise<void> {
    const socialProfileAttributes = {
      group,
      nickname: name,
      account: this.currentAccount,
      socialIdentity,
      profileType: 'tiktok'
    };

    if (socialIdentity?.id) {
      socialProfileAttributes.socialIdentity = socialIdentity;
    }

    const socialProfile = this.store.createRecord('social-profile', socialProfileAttributes);
    try {
      const savedProfile = await socialProfile.save();
      //reqd to get new social identity for the saved profile
      savedProfile.get('socialIdentity');
      this.alerts.success(this.intl.t('alerts.tiktok.add_profile.success', { name: savedProfile.name }));
    } catch (adapterError) {
      socialProfile.rollbackAttributes();
      this.errors.handleAdapter(adapterError, socialProfile);
    }
  }

  /**
   *
   * @method refresh
   * @param {SocialProfile} socialProfile The Tiktok Social Profile to be refreshed
   */
  refresh(socialProfile: SocialProfileModel, redirectPath: string): void {
    if (!socialProfile.canConnectTiktokApi) {
      return; //safeguard
    }

    this.loginWithBusinessApiAccount(socialProfile, redirectPath);
  }

  /**
   * Redirects the user to the Tiktok Business API login page
   *
   * @method loginWithBusinessApiAccount
   * @param {SocialProfile} socialProfile The Tiktok Social Profile to be refreshed
   */
  loginWithBusinessApiAccount(socialProfile: SocialProfileModel, redirectPath: string): void {
    const groupId = socialProfile ? socialProfile.get('group').get('id') : this.currentGroup.id;
    const refreshSocialProfile = socialProfile ? `refresh_social_profile_id=${socialProfile.id}` : '';
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.TiktokBusiness,
      redirectPath
    });

    if (!this.auth.currentAccount.canConnectTiktokApi) {
      this.errors.log(new Error('Not authorized to connect to Tiktok Business'), {
        socialProfileId: socialProfile.get('id')
      });

      return; // safeguard
    }

    if (isNone(this.currentGroup)) {
      redirect(`${path}&${refreshSocialProfile}`);
    } else {
      redirect(`${path}&group_id=${groupId}&${refreshSocialProfile}`);
    }

    redirect(`${path}&${refreshSocialProfile}`);
  }

  /**
   * Creates an OAuth window to log in to Tiktok
   *
   * @method createTiktokWithSet
   */
  createTiktokWithSet(setId: string, group: GroupModel, redirectPath: string): void {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.TiktokBusiness,
      redirectGroupSlug: group.slug,
      redirectPath
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }

  /**
   * Fetches Recent Media (Posts) for specified socialProfile,
   * can handle paging through this endpoint.
   *
   * @method fetchRecentMedia
   * @param {SocialProfile} socialProfileProxy The socialProfile making the request (can be an ObjectProxy)
   * @param {Object} [pagination] Pagination object return from a
   * previous call to this method. Used to get the next page of data
   *
   * @return {Promise|Array<TiktokPost>} Formatted Recent TikTok Posts
   */
  async fetchRecentMedia(
    socialProfileProxy: Maybe<SocialProfileModel>,
    pagination: TiktokPagination,
    videoLimitPerPage: number = DEFAULT_TIKTOK_VIDEO_LIMIT_PER_PAGE
  ): Promise<{ posts: UntypedLaterModel<'TiktokPost'>[] | undefined; pagination: TiktokPagination | undefined }> {
    const socialProfile = await objectPromiseProxy(socialProfileProxy);
    const cursorSuffix = pagination && this.hasMorePages(pagination) ? `?cursor=${pagination.cursor}` : '';
    const limitSuffix = isEmpty(cursorSuffix) ? `?limit=${videoLimitPerPage}` : `&limit=${videoLimitPerPage}`;
    const urlSuffix = cursorSuffix + limitSuffix;
    const url = `/api/v2/social_profiles/${socialProfile.id}/tiktok_video_list${urlSuffix}`;

    const response = await fetch(url);
    const { video_list, cursor, has_more, error_code, error_msg } = response.data;
    const { extra } = response;

    if (error_code || error_msg || extra.error_detail) {
      throw new Error(this.intl.t('tiktok.errors.api.video_list', { error: error_msg }));
    }

    if (!video_list) {
      return { posts: undefined, pagination: undefined };
    }

    const posts = video_list.map(
      (rawPost: UntypedLaterModel<'TiktokPost'>) => new TiktokPost({ ...rawPost, socialProfile })
    );

    return { posts, pagination: { cursor, hasMore: has_more } };
  }

  /**
   * Checks whether the provided pagination object indicates
   * that there is another page of data that can be requested
   *
   * @method hasMorePages
   * @param {Object | undefined} pagination The pagination object returned
   * from a previous call to an Instagram API
   *
   * @return {Boolean}
   */
  hasMorePages(pagination: TiktokPagination | undefined): boolean {
    return Boolean(pagination?.cursor && pagination?.hasMore);
  }

  /**
   * Display alert with error message
   *
   * @method raiseError
   * @param {Array} args All arguments passed to this method
   */
  raiseError(error: SafeString | string): void {
    if (error) {
      this.alerts.warning(error);
    }
  }

  /**
   * When a Tiktok user clicks on an ad and lands on our app, Tiktok adds
   * a tiktok click id to the users cookie, which can be accessed with the key `ttclid`.
   * This function compares the tiktok click id from the URL with the tiktok click id from the server.
   * If they differ, it sets the tiktokClickId property on the user to the new click id.
   */
  updateTiktokClickId(): void {
    try {
      const currentUser = this.auth.currentUserModel;
      const cookieTiktokClickId = getCookie('ttclid');
      const serverTiktokClickId = currentUser.tiktokClickId;
      if (cookieTiktokClickId && cookieTiktokClickId !== serverTiktokClickId) {
        currentUser.set('tiktokClickId', cookieTiktokClickId);
        currentUser.save();
      }
    } catch (error) {
      this.errors.log(error);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    tiktok: TiktokService;
  }
}
