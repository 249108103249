import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import { ErrorSeverity } from 'later/services/errors';

import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type { UntypedService } from 'shared/types';

export default class FacebookAuthService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service('social/instagram-business') declare instagramBusiness: UntypedService;
  @service declare intl: IntlService;
  @service declare store: StoreService;

  get canFacebookGroups(): boolean {
    return this.auth.currentAccount.canFacebookGroups || false;
  }

  loadFacebookSDK(): Promise<fb.StatusResponse> {
    return this.instagramBusiness.loadFacebookSDK();
  }

  /**
   * Gets the Facebook login response for a user.
   * @remarks This *MUST* be called immediately upon user action such as a click event or it will be blocked by ad-blockers
   */
  authenticateFacebook(
    scopes = [
      'pages_manage_metadata',
      'pages_read_engagement',
      'pages_read_user_content',
      'pages_show_list',
      'business_management',
      'instagram_basic',
      'read_insights'
    ],
    auth_type: 'rerequest' | 'reauthenticate' | 'reauthorize' | undefined = 'rerequest'
  ): Promise<fb.AuthResponse> {
    if (this.canFacebookGroups) {
      scopes.push('publish_to_groups');
    }
    const params: fb.LoginOptions = {
      auth_type,
      return_scopes: true,
      scope: scopes.join(',')
    };
    return new RSVP.Promise((resolve, reject) => {
      if (typeof FB === 'undefined') {
        return reject();
      }
      FB.login((response) => {
        const errorData = {
          status: response.status,
          authResponse: {
            accessToken: response.authResponse?.accessToken || '',
            expiresIn: response.authResponse?.expiresIn,
            signedRequest: response.authResponse?.signedRequest || '',
            userID: response.authResponse?.userID
          },
          debug: response?.authResponse?.accessToken || ''
        };
        this.errors.log('authenticateFacebook response', errorData, ErrorSeverity.Debug);
        if (response.status === 'connected') {
          resolve(response.authResponse);
        } else {
          reject();
        }
      }, params);
    });
  }

  async addFacebookProfile(
    uid: string,
    token: string,
    scopes: string[],
    group: GroupModel,
    identity?: SocialIdentityModel
  ): Promise<void> {
    const allProfiles = this.store.peekAll('social-profile');
    const socialProfile =
      allProfiles.find((profile) => profile.uid === uid) || this.store.createRecord('social-profile');
    socialProfile.set('socialIdentity', null);
    const socialProfileAttributes = {
      token,
      uid,
      group,
      account: this.auth.currentAccount,
      profileType: 'facebook',
      permissionScope: scopes,
      facebookProfileType: 'page',
      isFbPage: true
    };

    socialProfile.setProperties(socialProfileAttributes);

    if (identity?.id) {
      socialProfile.set('socialIdentity', identity);
    }

    try {
      const savedProfile = await socialProfile.save();
      savedProfile.get('socialIdentity');
      this.alerts.success(
        this.intl.t('alerts.account.controllers.groups.facebook.added', { name: savedProfile.nickname })
      );
    } catch (adapterError) {
      if (adapterError?.errors?.[0]?.detail) {
        this.alerts.alert(adapterError.errors[0].detail);
      } else {
        this.errors.log(adapterError);
      }
      socialProfile.rollbackAttributes();
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'social/facebook-auth': FacebookAuthService;
  }
}
