import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

import type MediaItemModel from 'later/models/media-item';

export default class MediaLibraryService extends Service {
  @tracked selectedMedia: MediaItemModel[] = new TrackedArray([]);
  @tracked showStarredMedia = false;
}

declare module '@ember/service' {
  interface Registry {
    'media-library': MediaLibraryService;
  }
}
