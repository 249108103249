import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class HashtagCounter extends BaseValidator {
  validate(_, options) {
    const { field } = options;
    if (
      (field === 'caption' && this.model.isCaptionHashtagsEmpty) ||
      (field === 'comment' && this.model.isFirstCommentHashtagsEmpty)
    ) {
      return true;
    }
    if (this.get('model.socialProfile.isInstagram') && this.get('model.hashtagsLeft') < 0) {
      const hashtagsAboveLimit = Math.abs(this.model.hashtagsLeft);
      if (
        (this.model.isFirstCommentExceedingHashtagLimit && this.model.isCaptionHashtagsEmpty) ||
        (this.model.isCaptionExceedingHashtagLimit && this.model.isFirstCommentHashtagsEmpty)
      ) {
        return this.createErrorMessage('exceeding_hashtag_limit', null, { value: hashtagsAboveLimit });
      }
      return this.createErrorMessage('remove_hashtags', null, { value: hashtagsAboveLimit });
    }
    return true;
  }
}

export default HashtagCounter;
