// NOTE: MARKUP_ICONS are typically reusable icon strings being passed into `appendDiv` in
// the calendar `markup` service.
export const POST_MARKUP_ICONS = Object.freeze({
  AUTO_PUBLISH: 'fc-icon-instant',
  AUTO_PUBLISH_ERROR: 'fc-icon-instant--error',
  DANGER: 'i-danger-1',
  FC_VERIFIED: 'fc-icon-verified',
  GALLERY: 'fc-icon-gallery',
  HELP: 'fc-icon-help',
  HIDDEN: 'fc-icon-hidden',
  NOTICE: 'i-notice',
  NOTIFICATION: 'fc-icon-notify',
  PENDING: 'i-pending',
  PUBLISHING: 'fc-icon-publishing',
  REELS: 'fc-icon-reels',
  VERIFIED: 'i-verified',
  WARNING: 'fc-icon-warning',
  YOUTUBE_SHORT: 'fc-icon-youtube-short--white'
});

// NOTE: MARKUP_CLASSES are typically reusable class strings being passed into `appendDiv` and
// `addClass` the calendar `markup` service.
export const POST_MARKUP_CLASSES = Object.freeze({
  FC_BUTTON_ACTIVE: 'fc-button-active',
  FC_METHOD: 'fc-method',
  FC_METHOD_DANGER: 'fc-method u--text--danger',
  FC_POST: 'fc-post',
  FC_STATUS: 'fc-status',
  FC_TYPE: 'fc-type',
  HAS_ERROR: 'has--error',
  IS_NARROW: 'is--narrow',
  IS_PUBLISHING: 'is--publishing',
  IS_UNVERIFIED: 'is--unverified',
  IS_VERIFIED: 'is--verified',
  IS_DRAFT: 'is--draft',
  TEXT_POST: 'fc-post-text'
});

// NOTE: Calendar Notes
export const NOTE_CHARACTER_LIMIT_THRESHOLD = 2400;
export const NOTE_MAXIMUM_CHARACTERS = 2500;
export const NOTE_ERRORS = Object.freeze({ SCHEDULE_OVERLAP: "Scheduled at can't overlap" });

// Calendar Alt Text
export const ALT_TEXT_MAXIMUM_CHARACTERS = 1000;
