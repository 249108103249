import ApplicationSerializer from 'later/serializers/application';

import type Store from '@ember-data/store';
import type Snapshot from '@ember-data/store/snapshot';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports -- we need ModelSchema here for the normalizeResponse and it is not available in @ember-data
import type { ModelSchema } from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';
import type ListenerModel from 'later/models/listener';

export default class ListenerSerializer extends ApplicationSerializer {
  serializeIntoHash<K extends keyof ModelRegistry>(
    hash: ListenerModel,
    _typeClass: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): void {
    // This flattens the serialized request payload to prevent ember-data from nesting the payload under the model name
    const serializedRecord = this.serialize(snapshot, { includeId: true });
    Object.assign(hash, serializedRecord);
  }

  normalizeResponse(
    store: Store,
    primaryModelClass: ModelSchema<keyof ModelRegistry>,
    payload: { social_listening_searches?: object; social_listening_search?: object },
    id: string | number,
    requestType: string
  ): object {
    // Handles creating a new listener/search
    if (requestType === 'createRecord') {
      const transformedPayload = payload.social_listening_search
        ? { listener: payload.social_listening_search }
        : payload;

      return super.normalizeResponse(store, primaryModelClass, transformedPayload, id, requestType);
    }

    // Handles fetching all listeners/searches
    const transformedPayload = payload.social_listening_searches
      ? { listeners: payload.social_listening_searches }
      : payload;

    return super.normalizeResponse(store, primaryModelClass, transformedPayload, id, requestType);
  }
}
