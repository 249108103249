import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

import type Store from '@ember-data/store';
import type Snapshot from '@ember-data/store/snapshot';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import type { Promise as RSVPPromise } from 'rsvp';

@classic
export default class ArchivedCampaignAdapter extends ApplicationAdapter {
  namespace = `/api/v2/campaigns`;

  /**
   * Function to use existing update campaign endpoint so we do not have to duplicate that endpoint
   * for archived campaign models.
   */
  updateRecord<K extends keyof ModelRegistry>(
    _store: Store,
    _type: ModelRegistry[K],
    snapshot: Snapshot<K>
  ): RSVPPromise<unknown> {
    const serialize = this.serialize(snapshot, {});
    const data = { data: { campaign: serialize } };

    // eslint-disable-next-line prefer-destructuring
    const id = snapshot.id;

    const url = `/api/v2/campaigns/${id}.json`;
    const result = this.ajax(url, 'PATCH', data);

    return result;
  }
}
