import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { MARKETING_QUERY_PARAMS } from 'later/utils/constants';
import _features from 'later/utils/feature-drawer';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type AuthService from 'later/services/auth';
import type SegmentService from 'later/services/segment';
import type SubscriptionsService from 'later/services/subscriptions';
import type { Maybe } from 'shared/types';
import type { FeatureObject } from 'shared/types/feature-drawer';

export default class MobileController extends Controller {
  @service declare auth: AuthService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare subscriptions: SubscriptionsService;

  @tracked _feature: Maybe<FeatureObject>;

  queryParams = MARKETING_QUERY_PARAMS;

  get features(): FeatureObject[] {
    return _features(this.intl, this.auth.currentAccount);
  }

  get feature(): Maybe<FeatureObject> {
    return this.features.find((feat) => feat.name === this._feature?.name);
  }

  get featureLinkName(): string | undefined {
    const route = this.router.currentRoute;
    if (!route) {
      return undefined;
    }

    return route.queryParams.featurelink;
  }

  get isPaidPlan(): boolean {
    return this.subscriptions.isPaid;
  }

  get hashtagSuggestionNeedsUpgrade(): boolean {
    return !this.isPaidPlan || this.subscriptions.planName === 'Creator';
  }

  @action
  openFeatureCardDrawer(featureObject: FeatureObject): void {
    this._feature = featureObject;
    this.segment.track('opened-feature-modal', { area: featureObject.name });
  }

  @action
  closeFeatureDrawer(): void {
    this._feature = undefined;
    this.#removeFeaturelink();
  }

  @action
  transitionToLinkinbio(): void {
    if (this.auth.lastGroup) {
      this.router.transitionTo('cluster.linkinbio', this.auth.lastGroup.slug);
    }
  }

  @action
  transitionToSettings(): void {
    this.router.transitionTo('account.overview');
  }

  @action
  logout(): void {
    this.auth.logout.perform();
  }

  #removeFeaturelink(): void {
    this.router.transitionTo('mobile', {
      queryParams: {
        // eslint-disable-next-line unicorn/no-null
        featurelink: null // Note: null required to clear query params
      }
    });
  }
}
