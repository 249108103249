import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import { getProfile } from 'later/utils/global-search/get-profile';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type { SearchCommandQuery } from 'command-palette/types';
import type AuthService from 'later/services/auth';

@command('navigate-to-conversations')
export class NavigateToConversationsCommand extends Command {
  @service declare auth: AuthService;
  @service declare store: StoreService;
  @service declare router: RouterService;

  public searchTerms = ['navigate', 'go to', 'conversations', 'comments', 'reply', 'replies', 'messages', 'chats'];

  protected action(query: SearchCommandQuery): void | Promise<void> {
    const profile = getProfile({ auth: this.auth, store: this.store, query });

    this.router.transitionTo('cluster.conversations.profile', profile.urlName || '', {
      queryParams: { social_profile_slug: profile?.get('group')?.get('slug') }
    });

    // this.pendingIntent.create(query as any);
  }

  public isSuggested(): boolean {
    return true;
  }

  public getTitle(): string {
    return `Check Your Conversations`;
  }

  public getDescription(query: SearchCommandQuery): string {
    const profile = getProfile({ auth: this.auth, store: this.store, query });
    const displayName = profile?.displayName;

    const searchQuery = query.tokens.text[0];
    if (searchQuery) {
      return `Search for "${bold(searchQuery)}" in ${bold(displayName)}'s conversations`;
    }
    return `View recent conversations for ${bold(displayName)}`;
  }

  public getIcon(): string {
    return 'conversations';
  }
}

export default NavigateToConversationsCommand;
