/**
 * @module Services
 */

import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';

import type TableService from '../table';
import type { DynamoIgPost } from 'later/models/ig-post';
import type IgPost from 'later/models/ig-post';
import type { Moment } from 'moment';
import type { AnalyticsData, AnalyticsError } from 'shared/types/analytics-data';
import type { FormattedDynamoHashtag } from 'shared/types/analytics-data/hashtags';

interface DefaultRow {
  name: string;
  imageList: any[];
  imageUrlOne: string | undefined;
  imageUrlTwo: string | undefined;
  imageUrlThree: string | undefined;
  imageUrlFour: string | undefined;
  numPosts: number | undefined;
  reach: AnalyticsData;
  impressions: AnalyticsData;
  likeCount: AnalyticsData;
  commentCount: AnalyticsData;
  savedCount: AnalyticsData;
}

interface ProcessedHashtags {
  name: string;
  latestUsed: {
    moment: string;
    unix: Moment;
  };
  media: IgPost;
  numPosts: number | undefined;
  imageList: any[];
  imageUrlOne: string | undefined;
  imageUrlTwo: string | undefined;
  imageUrlThree: string | undefined;
  imageUrlFour: string | undefined;
  reach: AnalyticsData;
  impressions: AnalyticsData;
  likeCount: AnalyticsData;
  commentCount: AnalyticsData;
  savedCount: AnalyticsData;
}

/**
 * @class FormattersTableHashtagsService
 * @extends Service
 */
export default class HashtagsService extends Service {
  @service('analytics/formatters/table') declare tableFormatters: TableService;

  /**
   * Key names to be used to form table columns
   *
   * @property names
   * @type {Array}
   */
  names = ['likeCount', 'commentCount', 'savedCount', 'reach', 'impressions'];

  /**
   * @property defaultRow
   * @type {Object}
   * @default
   */
  get defaultRow(): DefaultRow {
    return {
      name: 'hashtag',
      imageList: [],
      imageUrlOne: undefined,
      imageUrlTwo: undefined,
      imageUrlThree: undefined,
      imageUrlFour: undefined,
      numPosts: undefined,
      reach: this.tableFormatters.buildDefaultTableObject(),
      impressions: this.tableFormatters.buildDefaultTableObject(),
      likeCount: this.tableFormatters.buildDefaultTableObject(),
      commentCount: this.tableFormatters.buildDefaultTableObject(),
      savedCount: this.tableFormatters.buildDefaultTableObject()
    };
  }

  /**
   * Takes an array of hashtags, formats each hashtag for the
   * hashtags table according to the given hashtag key names
   *
   * @method processHashtags
   * @param {Array} [hashtags=[]] Array of formatted hashtags
   *
   * @returns {Array} Array of hashtags formatted for the hashtags table
   */
  processHashtags(hashtags: FormattedDynamoHashtag[] | AnalyticsError): FormattedDynamoHashtag[] | ProcessedHashtags[] {
    if (!Array.isArray(hashtags) || isEmpty(hashtags)) {
      return hashtags as FormattedDynamoHashtag[];
    }

    return (
      hashtags
        .map((hashtag) => {
          const { name, latestUsed, numPosts, media } = hashtag;
          const [mediaOne, mediaTwo, mediaThree, mediaFour] = media as IgPost[];
          const imageList = media.map((singleMedia: DynamoIgPost) =>
            EmberObject.create({ url: singleMedia.thumbUrl, ...singleMedia })
          );

          const values = this.tableFormatters.getValues(this.names, hashtag, hashtags);
          const tableRow = Object.assign({}, ...this.tableFormatters.buildTableRow(values));

          return {
            name,
            latestUsed,
            numPosts,
            imageList,
            imageUrlOne: mediaOne?.thumbUrl,
            imageUrlTwo: mediaTwo?.thumbUrl,
            imageUrlThree: mediaThree?.thumbUrl,
            imageUrlFour: mediaFour?.thumbUrl,
            ...tableRow
          };
        })
        // Note: BE hashtag rponse is returning empty strings
        // for hashtags so we must filter them out herees
        // BE ticket to fix: https://latergramme.atlassian.net/browse/LF-2631
        .filter(({ name }) => isPresent(name))
        .reverse()
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/formatters/table/hashtags': HashtagsService;
  }
}
