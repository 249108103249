import Model, { attr } from '@ember-data/model';

import type {
  InfluencerCampaignApplicationQuestions,
  InfluencerCampaignContent,
  InfluencerCampaignDeliverable,
  InfluencerCampaignRewardWin,
  InfluencerCampaignStatus,
  InfluencerCampaignTrackingLink,
  InfluencerDraftContent,
  ProductReviewMetadata
} from 'influencers/types/influencer-campaigns';

export default class InfluencerCampaignModel extends Model {
  @attr('number', { defaultValue: undefined }) declare actionGroupId: number;
  @attr('string', { defaultValue: '' }) declare actionGroupStatus: string;
  @attr('string', { defaultValue: '' }) declare actionGroupTitle: string;
  @attr('string', { defaultValue: '' }) declare applicationDescription: string;
  @attr('string', { defaultValue: '' }) declare applicationImage: string;
  @attr('string', { defaultValue: '' }) declare applicationTitle: string;
  @attr('string', { defaultValue: '' }) declare brief: string;
  @attr('string', { defaultValue: '' }) declare campaignContentDueDate: string;
  @attr('string', { defaultValue: '' }) declare campaignDraftDueDate: string;
  @attr('number', { defaultValue: 0 }) declare clicks: number;
  @attr('string', { defaultValue: '' }) declare communityId: string;
  @attr('number', { defaultValue: 0 }) declare conversions: number;
  @attr('number', { defaultValue: 0 }) declare conversionValue: number;
  @attr('number', { defaultValue: 0 }) declare cost: number;
  @attr('number', { defaultValue: 0 }) declare cpm: number;
  @attr('number', { defaultValue: 0 }) declare cpe: number;
  @attr('number', { defaultValue: 0 }) declare cpp: number;
  @attr('number', { defaultValue: 0 }) declare engagements: number;
  @attr('string', { defaultValue: '' }) declare ftcHashtag: string;
  @attr('number', { defaultValue: undefined }) declare globalUserId: number;
  @attr('string', { defaultValue: '' }) declare image: string;
  @attr('number', { defaultValue: 0 }) declare impressions: number;
  @attr('string', { defaultValue: '' }) declare influencerContentDueDate: string;
  @attr('string', { defaultValue: '' }) declare influencerDraftDueDate: string;
  @attr('string', { defaultValue: '' }) declare listMemberStatus: string;
  @attr('number', { defaultValue: undefined }) declare listMemberId: number;
  @attr('number', { defaultValue: undefined }) declare membershipId: number;
  @attr('string', { defaultValue: '' }) declare programDescription: string;
  @attr('string', { defaultValue: '' }) declare programLogo: string;
  @attr('string', { defaultValue: '' }) declare programName: string;
  @attr('number', { defaultValue: 0 }) declare posts: number;
  @attr('boolean', { defaultValue: false }) declare requireEthnicityQuestion: boolean;
  @attr('boolean', { defaultValue: false }) declare requireFacebook: boolean;
  @attr('string', { defaultValue: '' }) declare requiredHashtag: string;
  @attr('boolean', { defaultValue: false }) declare requireInstagram: boolean;
  @attr('boolean', { defaultValue: false }) declare requirePinterest: boolean;
  @attr('boolean', { defaultValue: false }) declare requireTiktok: boolean;
  @attr('boolean', { defaultValue: false }) declare requireTwitter: boolean;
  @attr('boolean', { defaultValue: false }) declare requireMavely: boolean;
  @attr('string', { defaultValue: '' }) declare startDate: string;
  @attr('string', { defaultValue: '' }) declare statusSlug: string;
  @attr('string', { defaultValue: '' }) declare statusLabel: string;
  @attr('number', { defaultValue: 0 }) declare value: number;
  @attr('string', { defaultValue: '' }) declare video: string;

  @attr() declare applicationQuestions: InfluencerCampaignApplicationQuestions[];
  @attr() declare content: InfluencerCampaignContent[];
  @attr() declare deliverables: InfluencerCampaignDeliverable[];
  @attr() declare draftContent: InfluencerDraftContent[];
  @attr() declare productReviewMetadata: ProductReviewMetadata;
  @attr() declare rewardWins: InfluencerCampaignRewardWin[];
  @attr() declare statuses: InfluencerCampaignStatus[];
  @attr() declare trackingLinks: InfluencerCampaignTrackingLink[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'influencer-campaign': InfluencerCampaignModel;
  }
}
