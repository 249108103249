import type LinkinbioAttachmentModel from 'later/models/linkinbio-attachment';

export default function getPreferredAttachmentUrl(
  attachment: LinkinbioAttachmentModel | undefined,
  preferredKey: string
): string {
  if (!attachment?.variants) {
    return '';
  }
  const preferred = attachment.variants[preferredKey];
  const { original } = attachment.variants;
  return preferred?.processed ? preferred.url : original.url;
}
