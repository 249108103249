import Service, { service } from '@ember/service';

import type GroupModel from 'later/models/group';
import type UserModel from 'later/models/user';
import type AuthService from 'later/services/auth';

type CurrentUserRoles = {
  isAccountOwner: boolean;
  isApprover: boolean;
  isEditor: boolean;
  needsApproval: boolean;
};

export default class UserRoleService extends Service {
  @service declare auth: AuthService;

  get hasUserAndGroup(): boolean {
    return Boolean(this.auth?.currentUserModel && this.auth?.currentGroup);
  }

  get currentUser(): CurrentUserRoles {
    return {
      isAccountOwner: this.auth?.currentUserModel ? this.isAccountOwner(this.auth.currentUserModel) : false,
      isApprover: this.hasUserAndGroup ? this.isApprover(this.auth.currentUserModel, this.auth.currentGroup) : false,
      isEditor: this.hasUserAndGroup ? this.isEditor(this.auth.currentUserModel, this.auth.currentGroup) : false,
      needsApproval: this.hasUserAndGroup
        ? this.needsApproval(this.auth.currentUserModel, this.auth.currentGroup)
        : false
    };
  }

  isAccountOwner(user: UserModel): boolean {
    return user?.isAccountOwner || false;
  }

  isApprover(user: UserModel, group: GroupModel): boolean {
    if (group?.nonContributorMemberships && user?.id) {
      const member = group.nonContributorMemberships.find((member) => member.userId === user.id);
      return member?.isApprover || user.isAccountOwner;
    }

    return false;
  }

  isEditor(user: UserModel, group: GroupModel): boolean {
    if (group?.nonContributorMemberships && user?.id) {
      const member = group.nonContributorMemberships.find((member) => member.userId === user.id);
      return member?.isEditor || user.isAccountOwner;
    }

    return false;
  }

  needsApproval(user: UserModel, group: GroupModel): boolean {
    if (group?.nonContributorMemberships && user?.id) {
      const member = group.nonContributorMemberships.find((member) => member.userId === user.id);
      return Boolean(member?.needsApproval);
    }

    return false;
  }
}

declare module '@ember/service' {
  interface Registry {
    'user-role': UserRoleService;
  }
}
