import { readOnly } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import RSVP from 'rsvp';

import type { TaskInstance } from 'ember-concurrency';

const CONFIG_CONFIRMATION = {
  model: 'confirmationModalModel',
  resolve: 'resolveConfirmationPromise',
  reject: 'rejectConfirmationPromise'
} as const;

const CONFIG_UPSELL = {
  model: 'upsellModalModel',
  resolve: 'resolveUpsellPromise',
  reject: 'rejectUpsellPromise'
} as const;

export enum DialogType {
  Confirmation = 'confirmation',
  Upsell = 'upsell'
}

type DialogConfig = {
  [DialogType.Confirmation]: typeof CONFIG_CONFIRMATION;
  [DialogType.Upsell]: typeof CONFIG_UPSELL;
};

type DialogModel = {
  /** Message used in the confirmation modal */
  message: string;
  /** Secondary text to display under the title */
  description?: string;
  /** Text to display on the confirm button */
  confirmButton?: string;
  /** Text to display on the cancel button */
  cancelButton?: string;
  /** Custom css class to use on the confirm button */
  confirmButtonClass?: string;
  /** Custom css class to use on the cancel button */
  cancelButtonClass?: string;
  /** Key to access (in storage) setting for whether to show confirmation check box again */
  doNotShowAgainKey?: string;
  /** Link for navigating user to a 'learn more' page which is shown at the end of the dialog's `description` text */
  learnMoreLink?: string;
  /** QA class tag on the description */
  descQaClass?: string;
  /** QA class tag on the title */
  titleQaClass?: string;
};

/**
 * This is Later's custom dialog manager, meant to be used where one would need a confirmation dialog
 * having two actions. For example, anywhere you would use the standard
 * Javascript:
 * ```
 * if (confirm("Are you sure?")) {
 *    // user clicked OK
 * } else {
 *    // user clicked Cancel
 * }
 * ```
 *
 * You should _instead_ use:
 * ```
 * const didConfirm = await this.dialogManager.confirmation("Are you sure?");
 * ```
 *
 * The main message should be about five words or fewer, with further information going in the config's `description` property.
 *
 * ### Configuration
 * The `confirmation` method accepts an additional, optional configuration object with the following properties:
 * - `description`: Additional messaging (the smaller font text)
 * - `confirmButton`: If you want to replace the text on the "OK" button with some custom text
 * - `cancelButton`: If you want to replace the text on the "Cancel" button with some custom text
 * - `confirmButtonClass`: If you want to add a custom class for the "OK" button.
 * - `cancelButtonClass`: If you want to add a custom class for the "Cancel" button.
 *
 */
export class DialogManagerService extends Service.extend(Evented) {
  @readOnly('_confirmTask.isRunning') declare showConfirmationModal: boolean;
  @readOnly('_upsellTask.isRunning') declare showUpsellModal: boolean;

  @tracked confirmationModalModel: Partial<DialogModel> = {};
  @tracked upsellModalModel: Partial<DialogModel> = {};

  /**
   * A config object that describes the types of modals supported by the dialog manager.
   * Allows for better resuse of methods below, as they can call on this config object,
   * rather than writting explicit case for each type of modal.
   */
  dialogConfig: DialogConfig = {
    confirmation: CONFIG_CONFIRMATION,
    upsell: CONFIG_UPSELL
  };

  /**
   * Method to generate a confirmation modal
   *
   * @deprecated Use {@link DialogManagerService.confirmation} method instead where promise rejects are handled
   *  and there are no errors thrown. Instead, boolean values are returned so that they may
   *  be handled manually.
   *
   * @param title Message used in the confirmation modal
   * @param model Configuration Object to specify details of the confirmation modal
   *
   * @returns Promise for confirmation
   */
  confirm(title: string, model?: Partial<DialogModel>): TaskInstance<unknown> {
    return this._confirmTask.perform(DialogType.Confirmation, this.#buildModel(title, model));
  }

  /**
   * Show a dialog modal that can be confirmed or cancelled.
   *
   * @param title Message used in the confirmation modal
   * @param model Configuration Object to specify details of the confirmation modal
   *
   * @throws Unknown exceptions that occur while displaying the confirmation dialog.
   *
   * @example
   * const didConfirm = await this.dialogManager.confirmation("Are you sure?", {
   *    description: "This action cannot be undone.",
   *    confirmButton: "Yep!",
   *    cancelButton: "Nope"
   * });
   *
   * @returns Dialog was confirmed by user.
   *
   * @todo Once 100% replacement of {@link DialogManagerService.confirm} has taken place, we can rename this
   *  function from `confirmation` back to `confirm` as it's shorter and cleaner.
   */
  async confirmation(title: string, model?: Partial<DialogModel>): Promise<boolean> {
    try {
      await this._confirmTask.perform(DialogType.Confirmation, this.#buildModel(title, model));
      return true;
    } catch (error) {
      if (!error) {
        return false;
      }

      throw error;
    }
  }

  /**
   * Method to generate an upsell modal. This modal is yet-to-be-used.
   * It will be a part of the updated pricing.
   *
   * @param model Model to pass to the upsell modal.
   */
  upsell(model: DialogModel): TaskInstance<unknown> {
    return this._upsellTask.perform(DialogType.Upsell, model);
  }

  /**
   * Triggers an event to resolve & close one of the dialogManager modals
   *
   * @param type The type of dialogManager modal that should be resolved.
   */
  resolvePromise(type: DialogType): void {
    this.trigger(this.dialogConfig[type].resolve);
  }

  /**
   * Triggers an event to reject & close one of the dialogManager modals
   *
   * @param type The type of dialogManager modal that should be rejected.
   */
  rejectPromise(type: DialogType): void {
    this.trigger(this.dialogConfig[type].reject);
  }

  #buildModel(title: string, partialModel?: Partial<DialogModel>): DialogModel {
    const model = { message: title };
    return partialModel ? Object.assign(partialModel, model) : model;
  }

  _confirmTask = task(async (type: DialogType, model: DialogModel) => {
    return await this._runTask.perform(type, model);
  });

  _upsellTask = task(async (type: DialogType, model: DialogModel) => {
    return await this._runTask.perform(type, model);
  });

  _runTask = task(async (type: DialogType, model: DialogModel) => {
    const dialogConfig = this.dialogConfig[type];

    this.set(dialogConfig.model, model);

    return await new RSVP.Promise((resolve, reject) => {
      this.on(dialogConfig.resolve, () => resolve());
      this.on(dialogConfig.reject, () => reject());
    }).finally(() => {
      this.set(dialogConfig.model, {});
    });
  });
}

export default DialogManagerService;

declare module '@ember/service' {
  interface Registry {
    'dialog-manager': DialogManagerService;
  }
}
