import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isPresent } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const INSTAGRAM_IMAGE_ASPECT_RATIO = {
  min: {
    decimal: 0.8,
    ratio: '4:5'
  },
  max: {
    decimal: 1.92,
    ratio: '1.91:1'
  }
};

const INSTAGRAM_REEL_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 1.78,
    ratio: '16:9'
  }
};

const INSTAGRAM_REEL_THUMBNAIL_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 0.5625,
    ratio: '9:16'
  }
};

const INSTAGRAM_STORY_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 1.78,
    ratio: '16:9'
  }
};

const INSTAGRAM_VIDEO_ASPECT_RATIO = {
  min: {
    decimal: 0.8,
    ratio: '4:5'
  },
  max: {
    decimal: 1.78,
    ratio: '16:9'
  }
};

const TWITTER_VIDEO_ASPECT_RATIO = {
  min: {
    decimal: 0.33,
    ratio: '1:3'
  },
  max: {
    decimal: 3,
    ratio: '3:1'
  }
};

const FACEBOOK_VIDEO_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 1.78,
    ratio: '16:9'
  }
};

const FACEBOOK_REEL_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 0.5625,
    ratio: '9:16'
  }
};

const LINKEDIN_ASPECT_RATIO = {
  min: {
    decimal: 0.666,
    ratio: '2:3'
  },
  max: {
    decimal: 3,
    ratio: '3:1'
  }
};

const LINKEDIN_VIDEO_ASPECT_RATIO = {
  min: {
    decimal: 0.4166,
    ratio: '1:2.4'
  },
  max: {
    decimal: 2.4,
    ratio: '2.4:1'
  }
};

const PINTEREST_VIDEO_ASPECT_RATIO = {
  min: {
    decimal: 0.5,
    ratio: '1:2'
  },
  max: {
    decimal: 1.91,
    ratio: '1.91:1'
  }
};

const YOUTUBE_VIDEO_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 1,
    ratio: '1:1'
  }
};

const TIKTOK_IMAGE_ASPECT_RATIO = {
  min: {
    decimal: 0.5625,
    ratio: '9:16'
  },
  max: {
    decimal: 1.78,
    ratio: '16:9'
  }
};

/**
 *
 *  Validates the aspect ratio of a post media item
 *  Instagram limits: https://developers.facebook.com/docs/instagram-api/reference/user/media
 *  Facebook limits: https://developers.facebook.com/docs/graph-api/video-uploads
 *  Twitter limits: https://developer.twitter.com/en/docs/media/upload-media/uploading-media/media-best-practices.html
 *  Pinterest limits: https://business.pinterest.com/en/Pinterest-product-specs
 *
 *  @class AspectRatio
 *  @module Validators
 *  @extends Base
 */
export default BaseValidator.extend({
  intl: service(),
  schedulePost: service('schedule/post'),

  isCustomCover: readOnly('model.isCustomCover'),
  isFacebook: readOnly('model.isFacebook'),
  isInstagram: readOnly('model.isInstagram'),
  isLinkedin: readOnly('model.isLinkedin'),
  isNotificationPost: readOnly('model.isNotificationPost'),
  isInstagramStory: readOnly('model.gram.isInstagramStory'),
  isInstagramReel: readOnly('model.isInstagramReel'),
  isFacebookReel: readOnly('model.isFacebookReel'),
  isPinterest: readOnly('model.isPinterest'),
  isTiktok: readOnly('model.isTiktok'),
  isTwitter: readOnly('model.isTwitter'),
  isYoutube: readOnly('model.isYoutubeShort'),
  isVideo: readOnly('model.isVideo'),
  mediaItemRatio: readOnly('model.mediaItem.aspectRatio'),
  postMediaItemRatio: readOnly('model.aspectRatio'),
  profileType: readOnly('model.gram.socialProfile.profileType'),
  hasPublishingMethod: readOnly('model.gram.socialProfile.hasPublishingMethod'),

  minAspectRatio: computed(
    'isInstagram',
    'isTiktok',
    'isTwitter',
    'isLinkedin',
    'isPinterest',
    'isInstagramReel',
    'isCustomCover',
    'isFacebookReel',
    'isVideo',
    'isYoutube',
    function () {
      if (this.isInstagram) {
        if (this.isInstagramReel) {
          if (this.isCustomCover) {
            return INSTAGRAM_REEL_THUMBNAIL_ASPECT_RATIO.min;
          }
          return INSTAGRAM_REEL_ASPECT_RATIO.min;
        }
        if (this.isInstagramStory) {
          return INSTAGRAM_STORY_ASPECT_RATIO.min;
        }
        if (this.isVideo) {
          return INSTAGRAM_VIDEO_ASPECT_RATIO.min;
        }
        return INSTAGRAM_IMAGE_ASPECT_RATIO.min;
      } else if (this.isTwitter && this.isVideo) {
        return TWITTER_VIDEO_ASPECT_RATIO.min;
      } else if (this.isFacebook && this.isVideo) {
        return this.isFacebookReel ? FACEBOOK_REEL_ASPECT_RATIO.min : FACEBOOK_VIDEO_ASPECT_RATIO.min;
      } else if (this.isLinkedin) {
        return this.isVideo ? LINKEDIN_VIDEO_ASPECT_RATIO.min : LINKEDIN_ASPECT_RATIO.min;
      } else if (this.isPinterest && this.isVideo) {
        return PINTEREST_VIDEO_ASPECT_RATIO.min;
      } else if (this.isYoutube) {
        return YOUTUBE_VIDEO_ASPECT_RATIO.min;
      } else if (this.isTiktok && !this.isVideo) {
        return TIKTOK_IMAGE_ASPECT_RATIO.min;
      }
      return { decimal: 1, ratio: '1:1' };
    }
  ),

  // 16:9 = ~1.78
  maxAspectRatio: computed(
    'model',
    'isInstagram',
    'isFacebookReel',
    'isVideo',
    'isInstagramReel',
    'isCustomCover',
    function () {
      if (this.isInstagram) {
        if (this.isInstagramReel) {
          if (this.isCustomCover) {
            return INSTAGRAM_REEL_THUMBNAIL_ASPECT_RATIO.max;
          }
          return INSTAGRAM_REEL_ASPECT_RATIO.max;
        }
        if (this.isInstagramStory) {
          return INSTAGRAM_STORY_ASPECT_RATIO.max;
        }
        if (this.isVideo) {
          return INSTAGRAM_VIDEO_ASPECT_RATIO.max;
        }
        return INSTAGRAM_IMAGE_ASPECT_RATIO.max;
      } else if (this.isTwitter && this.isVideo) {
        return TWITTER_VIDEO_ASPECT_RATIO.max;
      } else if (this.isFacebook && this.isVideo) {
        return this.isFacebookReel ? FACEBOOK_REEL_ASPECT_RATIO.max : FACEBOOK_VIDEO_ASPECT_RATIO.max;
      } else if (this.isLinkedin) {
        return this.isVideo ? LINKEDIN_VIDEO_ASPECT_RATIO.max : LINKEDIN_ASPECT_RATIO.max;
      } else if (this.isPinterest && this.isVideo) {
        return PINTEREST_VIDEO_ASPECT_RATIO.max;
      } else if (this.isYoutube) {
        return YOUTUBE_VIDEO_ASPECT_RATIO.max;
      } else if (this.isTiktok && !this.isVideo) {
        return TIKTOK_IMAGE_ASPECT_RATIO.max;
      }
      return undefined;
    }
  ),

  validate() {
    const platform = this.profileType ? capitalize(this.profileType) : null;
    let minDecimal = this.minAspectRatio?.decimal;
    let maxDecimal = this.maxAspectRatio?.decimal;
    const minRatio = this.minAspectRatio?.ratio;
    const maxRatio = this.maxAspectRatio?.ratio;
    let ratio = 1.0;

    if (this.isNotificationPost || !this.hasPublishingMethod || !this.maxAspectRatio) {
      return true;
    }

    if (this.postMediaItemRatio > 0) {
      ratio = this.postMediaItemRatio;
    } else if (this.mediaItemRatio > 0) {
      ratio = this.mediaItemRatio;
    } else {
      return true;
    }

    ratio = ratio.toFixed(2);
    minDecimal = minDecimal.toFixed(2);
    maxDecimal = maxDecimal.toFixed(2);

    if (
      isPresent(minDecimal) &&
      isPresent(maxDecimal) &&
      minDecimal === maxDecimal &&
      (ratio < minDecimal || ratio > maxDecimal)
    ) {
      const errorName = this.isVideo ? 'videoAspectRatioExact' : 'aspectRatioExact';
      return this.createErrorMessage(errorName, undefined, {
        aspectRatio: minRatio,
        platform: this.isCustomCover ? this.intl.t('posts.edit.thumbnail_cover.instagram_reel_cover') : platform
      });
    }

    if (isPresent(minDecimal) && ratio < minDecimal) {
      const errorName = this.isVideo ? 'videoAspectRatioTall' : 'aspectRatioTall';
      return this.createErrorMessage(errorName, null, {
        min: minRatio,
        max: maxRatio,
        platform
      });
    }

    if (isPresent(maxDecimal) && ratio > maxDecimal) {
      const errorName = this.isVideo ? `videoAspectRatioWide` : `aspectRatioWide`;
      return this.createErrorMessage(errorName, null, {
        min: minRatio,
        max: maxRatio,
        platform
      });
    }

    return true;
  }
});
