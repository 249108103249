import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class InfluencerSeralizer extends ApplicationSerializer {
  normalizeQueryResponse(store, modelClass, payload, ...args) {
    const result = super.normalizeQueryResponse(store, modelClass, payload, ...args);
    result.meta = result.meta || {};

    if (payload.paging) {
      result.meta.paging = payload.paging;
    }
    return result;
  }
}
