import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"u--flex u--align--center\">\n  <h4 class=\"u--text--light u--p__b__0 u--m__b u--m__t__0\">{{t \"global_search.search.no_result\"}}</h4>\n</div>", {"contents":"<div class=\"u--flex u--align--center\">\n  <h4 class=\"u--text--light u--p__b__0 u--m__b u--m__t__0\">{{t \"global_search.search.no_result\"}}</h4>\n</div>","moduleName":"later/components/global-search/no-results.hbs","parseOptions":{"srcName":"later/components/global-search/no-results.hbs"}});
import templateOnly from '@ember/component/template-only';

interface NoResultsComponentSignature {
  Element: HTMLDivElement;
  Args: NonNullable<unknown>;
  Blocks: {
    default: [];
  };
}

export const NoResultsComponent = templateOnly<NoResultsComponentSignature>();
export default NoResultsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GlobalSearch::NoResults': typeof NoResultsComponent;
    'global-search/no-results': typeof NoResultsComponent;
  }
}
