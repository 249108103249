import { getOwner } from '@ember/application';
import { validateFormat } from 'ember-changeset-validations/validators';

import type IntlService from 'ember-intl/services/intl';

export function validatePassword() {
  return (
    _key: string,
    newValue: string | undefined,
    _oldValue: string | undefined,
    _changes: object,
    content: object
  ) => {
    const intl = getOwner(content)?.lookup('service:intl') as IntlService;

    // Check for minimum length
    if (!newValue || newValue.length < 10) {
      return intl.t('errors.password.length');
    }

    // Check for format
    if ((!/[A-Z]/.test(newValue) || !/[a-z]/.test(newValue)) && !/\d/.test(newValue)) {
      return intl.t('errors.password.format');
    }

    // Check for case sensitivity
    if (!/[a-z]/.test(newValue) || !/[A-Z]/.test(newValue)) {
      return intl.t('errors.password.case');
    }

    // Check for a digit
    if (!/\d/.test(newValue)) {
      return intl.t('errors.password.digit');
    }

    return true;
  };
}

export default {
  email: [validateFormat({ type: 'email' })],
  password: [validatePassword()]
};
