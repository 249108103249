import { isNone } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class AlbumExists extends BaseValidator {
  validate(value, _, model) {
    if (this.get('model.socialProfile.isPinterest')) {
      return isNone(model.album) ? this.createErrorMessage('albumExists') : true;
    }
    return true;
  }
}

export default AlbumExists;
