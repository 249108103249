/**
 * TYPES OF POSTS ---------------------------------------------------------------------------------------------------------------
 */

/**
 * Linkin.bio post object
 * @typedef {{
 *  id: number,
 *  media_id: string,
 *  posted_at: number
 * }} LIBPost
 */

/**
 * IG OBJECTS -------------------------------------------------------------------------------------------------------------------
 */

/**
 * Data point for Instagram page views over time graph
 * @typedef {{
 *  time: number,
 *  profile_views: number
 * }} IGPageView
 */

/**
 * Data point for Instagram page clicks over time graph
 * @typedef {{
 *  time: number,
 *  website_clicks: number
 * }} IGPageClick
 */

/**
 * Data point for Instagram impressions over time graph
 * @typedef {{
 *  time: number,
 *  impressions: number
 * }} IGImpression
 */

/**
 * Data point for Instagram reach over time graph
 * @typedef {{
 *  time: number,
 *  reach: number
 * }} IGReach
 */

/**
 * Data point for Instagram follower count over time graph
 * @typedef {{
 *  time: number,
 *  followers: number
 * }} IGFollowerCount
 */

/**
 * Data point for Instagram media count over time
 * @typedef {{
 *  time: number,
 *  media_count: number
 * }} IGMediaCount
 */

/**
 * Data point for Instagram following count over time
 * @typedef {{
 *  time: number,
 *  follows_count: number
 * }} IGFollowingCount
 */

/**
 * Data point for Instagram overview summary section
 * @typedef {{
 *  mediaCount: number | string,
 *  followedByCount: number | string,
 *  followsCount: number | string
 * }} IGProfileCount
 */

/**
 * Facebook Objects -------------------------------------------------------------------------------------------------------------------
 */

/**
 * Data point for Facebook pageLikes over time graph
 * @typedef {{
 *  time: number,
 *  pageLikes: number
 * }} FBPageLikes
 */

/**
 * Data point for Facebook pageViews over time graph
 * @typedef {{
 *  time: number,
 *  pageViews: number
 * }} FBPageViews
 */

/**
 * Data point for Facebook reactions over time graph
 * @typedef {{
 *  time: number,
 *  reactions: number
 * }} FBReactions
 */

/**
 * Data point for Facebook engagement over time graph
 * @typedef {{
 *  time: number,
 *  engagement: number
 * }} FBEngagement
 */

/**
 * Data point for Facebook overview summary section
 * @typedef {{
 *  reachCount: number | string,
 *  pageLikeCount: number | string,
 *  pageViewCount: number | string,
 *  reactionCount: number | string,
 *  engagementCount: number | string,
 *  impressionCount: number | string
 * }} FBProfileCount
 */

/**
 * TikTok Objects -------------------------------------------------------------------------------------------------------------------
 */

/**
 * Data point for TikTok profile views count over time graph
 * @typedef {{
 *  time: number,
 *  profile_views: number
 * }} TiktokProfileViewsCount
 */

/**
 * Data point for TikTok video views count over time graph
 * @typedef {{
 *  time: number,
 *  video_views: number
 * }} TiktokVideoViewsCount
 */

/**
 * MEDIA OBJECTS ----------------------------------------------------------------------------------------------------------------
 */

/**
 * AUDIENCE ANALYTICS OBJECTS ---------------------------------------------------------------------------------------------------
 */

/**
 * Data point for media gender and age
 * @typedef {{
 *  time: number,
 *  audienceGenderAge: any
 * }} GenderAgeDataPoint
 */

/**
 * Data point for media gender
 * @typedef {{
 *  time: number,
 *  audienceGender: any
 * }} GenderDataPoint
 */

/**
 * Data point for media followers online at given time
 * @typedef {{
 *  time: number,
 *  day: number,
 *  onlineFollowersMap: Array<number>
 * }} FollowerOnlinePoint
 */

/**
 * Data point for country/city of Linkin.bio page view, total Linkin.bio page view count, and percentage of total page views
 * @typedef {{
 *  title: string,
 *  value: string,
 *  percent: string
 * }} GeographicPoint
 */

/**
 * Array of countries and counts, the "country_code" key is dynamic (ex. 'CA', 'AU', etc...)
 * @typedef {{ [
 *  country_code: string
 *  ]: string
 * }} CountryResponse
 */

/**
 * Array of cities and counts, the "city_code" key is dynamic (ex. 'Toronto, Ontario', 'Hong Kong, Hong Kong', etc...)
 * @typedef {{ [
 *  city_code: string
 *  ]: string
 * }} CityResponse
 */

/**
 * Array of languages and counts, the "locale_code" key is dynamic (ex. 'fr_CA', 'en_AU', etc...)
 * @typedef {{ [
 *  locale_code: string
 *  ]: string
 * }} LocaleResponse
 */

/**
 * MISC OBJECTS -----------------------------------------------------------------------------------------------------------------
 */

/**
 * Url object
 * @typedef {{
 *  expanded_url: string,
 *  display_url: string,
 *  indicies: Array<string>,
 *  url: string
 * }} Url
 */

/**
 * Meta object
 * @typedef {{
 *  first_data_point: number
 * }} Meta
 */

/**
 * Array of hashtag trends graph data points, the "name" key is dynamic (ex. 'apple', 'coding', etc...)
 * @typedef {{ [
 *  Name: string
 *  ]: Array<FormattedHashtagTrendsDataPoint>
 * }} FormattedHashtagTrends
 */

/**
 * Hashtag trends graph data point
 * @typedef {{
 *  startTime: number,
 *  endTime: number,
 *  numberOfPosts: number,
 *  totalPosts: number,
 *  percentagePosts: number
 * }} FormattedHashtagTrendsDataPoint
 */

/**
 * Errors ------------------------------------------------------------------------------------------------------------------------
 */

/**
 * Format of an analytics error object
 * @typedef {{
 *  error: {
 *    errorText: string,
 *    errorType: string
 *  }
 * }} AnalyticsError
 */
