import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task, dropTask } from 'ember-concurrency';
import { isString } from 'lodash';
import RSVP from 'rsvp';

import { ONBOARDING_PREFIX } from 'later/utils/constants';
import Step from 'later/utils/onboarding/steps';
import { timestamp } from 'later/utils/time-format';

import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type DiscoveryListTaskModel from 'later/models/discovery-list-task';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type DialogManagerService from 'later/services/dialog-manager';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type SegmentEventsService from 'later/services/segment-events';
import type { UntypedService } from 'shared/types';

export const POWER_USER_ONBOARDING_STATES = Object.freeze({
  SKIPPED_SOCIAL_CONNECT: 'power_user_onboarding.skipped_social_connect',
  CONNECTING_SOCIAL: 'power_user_onboarding.connecting_social_profile'
});

type PowerUserOnboardingState = (typeof POWER_USER_ONBOARDING_STATES)[keyof typeof POWER_USER_ONBOARDING_STATES];

/**
 * This service handles global states of Onboarding
 */
export default class OnboardingService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare dialogManager: DialogManagerService;
  @service declare errors: ErrorsService;
  @service('social/instagram-business') declare instagramBusiness: UntypedService;
  @service declare intl: IntlService;
  @service declare segment: SegmentService;
  @service declare segmentEvents: SegmentEventsService;
  @service declare store: Store;
  @service declare userAgent: UserAgentService;

  @tracked isSignupFromLinkinbio = false;

  firstScheduledPost = null;
  signupSource = 'later.com'; // Note: used to direct certain on boarding flows
  onboardingTestFlow = 'deviceless_a';

  get hasSkippedOnboardingSocialConnect(): boolean {
    return Boolean(
      this.auth.discoveryListTasks?.find(
        ({ identifier }) => identifier === POWER_USER_ONBOARDING_STATES.SKIPPED_SOCIAL_CONNECT
      )
    );
  }

  get isPowerUserConnectingSocial(): boolean {
    return Boolean(
      this.auth.discoveryListTasks?.find(
        ({ identifier }) => identifier === POWER_USER_ONBOARDING_STATES.CONNECTING_SOCIAL
      )
    );
  }

  get isTrialStartOnAccountSignupABTest(): boolean {
    return this.auth.currentAccount?.experiments?.['web_trial-start_tsoas'] === 'auto-start';
  }

  get shouldShowSocialConnectOnboarding(): boolean {
    return (
      this.isPowerUserConnectingSocial && !this.hasSkippedOnboardingSocialConnect && !this.userAgent.device.isMobile
    );
  }

  get currentSocialProfile(): SocialProfileModel {
    return this.auth.currentSocialProfile;
  }

  get currentUser(): UserModel {
    return this.auth.currentUserModel;
  }

  get accountCreatedAfterNewOnboarding(): boolean {
    if (!this.currentUser.createdTime) {
      return false;
    }

    return this.currentUser.createdTime > 1634281200; // Note: 15th October 2021 the date of the new onboarding rollout
  }

  get hasNotCompletedOnboarding(): boolean {
    return (
      this.currentOnboardingStep !== Step.FinishedOnboarding &&
      this.currentOnboardingStep !== Step.NotInitialized &&
      this.accountCreatedAfterNewOnboarding
    );
  }

  get shouldStartOnboarding(): boolean {
    return this.hasNotSeenOnboarding && this.currentUser.isAccountOwner && !this.auth.currentGroup.contributor;
  }

  /**
   * Checks if account was created after new onboarding rollout
   * (has not started onboarding wizard)
   */
  get hasNotSeenOnboarding(): boolean {
    return isEmpty(this.onboardingSteps) && this.accountCreatedAfterNewOnboarding;
  }

  get onFirstOnboardingStep(): boolean {
    return this.hasNotCompletedOnboarding && this.currentOnboardingStep === Step.TeamsSetup;
  }

  get onEmptyStep(): boolean {
    return this.hasNotCompletedOnboarding && this.currentOnboardingStep === Step.Empty;
  }

  get onboardingSteps(): DiscoveryListTaskModel[] {
    return this.auth.discoveryListTasks?.filter(({ identifier }: DiscoveryListTaskModel) =>
      identifier.startsWith(ONBOARDING_PREFIX)
    );
  }

  get currentOnboardingStep(): Step {
    return this.onboardingSteps
      ?.reduce(
        (stepWithMostRecentTime, step) =>
          stepWithMostRecentTime.completedTime > step.completedTime ? stepWithMostRecentTime : step,
        { identifier: `${ONBOARDING_PREFIX}.${Step.NotInitialized}`, completedTime: 0 }
      )
      .identifier.replace(`${ONBOARDING_PREFIX}.`, '') as Step;
  }

  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    const signupSource = this.cache.retrieve('signupSource');
    if (isString(signupSource)) {
      this.signupSource = signupSource;
    }
  }

  /**
   * Creates a onboarding step using the discovery-task-list Ember Data model.
   */
  createOnboardingStep = task(async (step: Step) => {
    const onboardingStep = this.#createDiscoveryListTask(`${ONBOARDING_PREFIX}.${step}`);

    try {
      await onboardingStep.save();
    } catch (error) {
      this.errors.handleAdapter(error);
    }
  });

  /**
   * Creates a power user onboarding state using the discovery-task-list Ember Data model.
   */
  setPowerUserOnboardingState = task(async (state: PowerUserOnboardingState) => {
    const powerUserOnboardingState = this.#createDiscoveryListTask(state);

    try {
      await powerUserOnboardingState.save();
    } catch (error) {
      this.errors.handleAdapter(error);
    }
  });

  /**
   * Update the onboarding step completed by the user
   * This will only update the completed time
   */
  updateOnboardingStep = task(async (step: Step) => {
    try {
      const onboardingStep = this.findOnboardingStep(step);

      if (this.#isDiscoveryListTask(onboardingStep)) {
        onboardingStep.completedTime = timestamp();
        onboardingStep.set('user', this.currentUser);
        await onboardingStep.save();
      }
    } catch (error) {
      this.errors.handleAdapter(error);
    }
  });

  hasStoredStep(step: Step): boolean {
    return isPresent(this.findOnboardingStep(step));
  }

  findOnboardingStep(step: Step): DiscoveryListTaskModel | undefined {
    return this.onboardingSteps?.find(({ identifier }) => identifier.endsWith(step));
  }

  confirmClose(): Promise<boolean> {
    return new RSVP.Promise(async (resolve, reject) => {
      const didConfirm = await this.dialogManager.confirmation(this.intl.t('wizards.shared.close_tutorial.header'), {
        description: this.intl.t('wizards.shared.close_tutorial.description'),
        confirmButton: this.intl.t('shared_words.exit'),
        cancelButton: this.intl.t('wizards.shared.close_tutorial.cancel')
      });

      if (!didConfirm) {
        reject();
      }

      resolve();
    });
  }

  connectBusinessAccount = dropTask(async (socialProfile: SocialProfileModel, page: string) => {
    this.segmentEvents.trackAttemptedBusinessConnect(socialProfile, page);
    return await this.instagramBusiness.refreshBusinessAccountEndpoint(socialProfile, page);
  });

  addTestImage = task(async () => {
    this.segment.track('clicked-try-test-image');
    try {
      await fetch(`/api/v2/groups/${this.auth.currentGroup.id}/add_default_image`, {
        method: 'POST'
      });
    } catch (error) {
      if (error.code === 422) {
        this.alerts.alert(this.intl.t('alerts.onboarding.test_image.already_added'), {
          title: this.intl.t('alerts.onboarding.test_image.title')
        });
      } else if (error.code === 429) {
        this.alerts.alert(this.intl.t('shared_phrases.try_again'), {
          title: this.intl.t('alerts.onboarding.test_image.title')
        });
      } else {
        this.errors.log(error);
      }
    }
  });

  /**
   * Sets new onboarding to the
   * new value, update existing or create new one
   */
  async setOnboardingStep(currentStep: Step): Promise<void> {
    this.hasStoredStep(currentStep)
      ? await this.updateOnboardingStep.perform(currentStep)
      : await this.createOnboardingStep.perform(currentStep);
  }

  trackOnboardingStep(eventName: string): void {
    const { id, industry, businessModel } = this.currentSocialProfile;
    this.segment.track(eventName, {
      profile: id,
      industry: industry || '',
      bus_model: businessModel || ''
    });
  }

  initOnboardingSteps(): void {
    if (isEmpty(this.onboardingSteps)) {
      this.setOnboardingStep(Step.TeamsSetup);
    }
  }

  /**
   * Custom defined type guard for discovery-list-task
   * More information:
   * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
   */
  #isDiscoveryListTask(task: DiscoveryListTaskModel | undefined): task is DiscoveryListTaskModel {
    return (task as DiscoveryListTaskModel).identifier !== undefined;
  }

  #createDiscoveryListTask(identifier: string): DiscoveryListTaskModel {
    return this.store.createRecord('discovery-list-task', {
      identifier,
      completedTime: timestamp(),
      user: this.currentUser
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    onboarding: OnboardingService;
  }
}
