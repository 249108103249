import config from 'later/config/environment';

function getSourceVersion(): string {
  const { version } = config.APP;

  const shaRegExp = /[a-z\d]{8}$/;
  const sourceVersion = version.match(shaRegExp)?.[0] || '';

  return sourceVersion;
}

export default getSourceVersion;
