import { getOwner } from '@ember/application';
import { isEmpty } from '@ember/utils';

import { ErrorSeverity } from 'later/services/errors';
import MissingTranslationError from 'shared/errors/missing-translation';

import type IntlService from 'ember-intl/services/intl';
import type ErrorsService from 'later/services/errors';

export default function missingMessage(this: any, key: string, locales: string[] /*, options*/): void {
  const intl = getOwner(this)?.lookup('service:intl') as IntlService;
  const isEmptyLocales = intl === undefined || isEmpty(intl.locales);

  //Note: Avoid throwing dozens of MissingTranslationError if lazy load files didn't load
  if (isEmptyLocales) {
    return;
  }

  const errors = getOwner(this)?.lookup('service:errors') as ErrorsService;
  if (errors) {
    errors.log(
      new MissingTranslationError(key),
      {
        key,
        locales
      },
      ErrorSeverity.Critical
    );
  }
}
