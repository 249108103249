import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import Service, { service } from '@ember/service';
import { isTesting } from '@embroider/macros';

import config from 'later/config/environment';
import { ErrorSeverity } from 'later/services/errors';
import getSourceVersion from 'later/utils/source-version';

import type { User as DatadogUser } from '@datadog/browser-core';
import type AuthService from 'later/services/auth';
import type { FeatureFlags } from 'later/utils/feature-flags';
import type { JsonObject } from 'type-fest';

export default class DatadogService extends Service {
  @service declare auth: AuthService;

  isInitialized = false;

  get _datadogRum(): typeof datadogRum | null {
    return isTesting() ? null : datadogRum;
  }

  get _datadogLogs(): typeof datadogLogs | null {
    return isTesting() ? null : datadogLogs;
  }

  // Note: this is a temporary workaround to allow alpha enterprise users to be tracked 100% of the time in social listening. We ignore admin users to prevent our internal testling from being recorded
  get canSocialListening(): boolean {
    return this.auth.currentAccount?.canSocialListening ?? false;
  }

  get isAdmin(): boolean {
    return this.auth.currentUserModel?.admin ?? false;
  }

  get validSocialListeningEnterpriseUser(): boolean {
    return this.canSocialListening && !this.isAdmin;
  }

  setup(): void {
    const {
      deployTarget,
      datadogAppId,
      datadogClientToken,
      datadogRumSampleRate,
      datadogSessionReplaySampleRate,
      datadogLogSampleRate
    } = config.APP;
    const sourceVersion = getSourceVersion();

    this._datadogRum?.init({
      applicationId: datadogAppId,
      clientToken: datadogClientToken,
      defaultPrivacyLevel: 'allow',
      site: 'datadoghq.com',
      service: 'ember-later',
      env: deployTarget,
      version: sourceVersion,
      sessionSampleRate: datadogRumSampleRate,
      sessionReplaySampleRate: this.validSocialListeningEnterpriseUser ? 100 : datadogSessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        'https://staging.later.com',
        'https://app.later.com',
        'https://localhost:4200',
        'https://localhost:3000'
      ],
      enableExperimentalFeatures: ['feature_flags']
    });

    this._datadogLogs?.init({
      clientToken: datadogClientToken,
      site: 'datadoghq.com',
      service: 'ember-later',
      env: deployTarget,
      version: sourceVersion,
      forwardConsoleLogs: ['warn'],
      forwardErrorsToLogs: true,
      forwardReports: ['intervention', 'deprecation'],
      sessionSampleRate: datadogLogSampleRate,
      beforeSend: (event) => {
        if (event.status === 'warn') {
          if (!event.message.startsWith('DEPRECATION:')) {
            // Only forward deprecation warnings
            return false;
          }

          event.status = 'info';
        }
        return true;
      }
    });

    this.isInitialized = true;
  }

  setUser(user: DatadogUser): void {
    if (!this.isInitialized) {
      this.setup();
    }
    this._datadogRum?.setUser(user);
  }

  setFeatureFlags(flags: FeatureFlags): void {
    if (!this.isInitialized) {
      this.setup();
    }
    Object.entries(flags).forEach(([key, value]) => {
      this._datadogRum?.addFeatureFlagEvaluation(key, value);
    });
  }

  addAction(name: string, context: JsonObject): void {
    if (!this.isInitialized) {
      this.setup();
    }
    this._datadogRum?.addAction(name, context);
  }

  log(
    error: Error | string,
    context: Record<string, unknown>,
    severity: Exclude<ErrorSeverity, ErrorSeverity.Debug>
  ): void {
    if (!this.isInitialized) {
      this.setup();
    }
    try {
      let sanitizedContext: Record<string, unknown>;

      if (Array.isArray(context)) {
        sanitizedContext = { items: context };
      } else {
        sanitizedContext = context;
      }

      if (severity === ErrorSeverity.Info) {
        this.#info(error.toString(), sanitizedContext);
      } else {
        this.#addError(this.#toError(error), sanitizedContext);
      }
    } catch (error) {
      console.info('Failed to send to Datadog', error);
    }
  }

  #addError(error: Error, context: Record<string, unknown>): void {
    this._datadogRum?.addError(error, context);
  }

  #info(message: string, context: Record<string, unknown>): void {
    this._datadogLogs?.logger?.info(message, context);
  }

  #toError(error: Error | string): Error {
    if (!(error instanceof Error)) {
      return new Error(error);
    }
    return error;
  }
}

declare module '@ember/service' {
  interface Registry {
    datadog: DatadogService;
  }
}
