import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { GramState } from 'later/models/gram';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type AlertsService from 'later/services/alerts';

export default class DraftsRoute extends Route {
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  redirect(): void {
    this.alerts.info(this.intl.t('alerts.drafts.redirect.message'), {
      title: this.intl.t('alerts.drafts.redirect.title')
    });
    this.router.transitionTo('cluster.schedule.list', {
      queryParams: {
        initial_states: GramState.Draft
      }
    });
  }
}
