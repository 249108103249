import { isBlank } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class ScheduledOrPosted extends BaseValidator {
  validate() {
    if (isBlank(this.get('model.scheduledTime')) && isBlank(this.get('model.postedTime'))) {
      return this.createErrorMessage('scheduledAtBlank');
    }
    return true;
  }
}

export default ScheduledOrPosted;
