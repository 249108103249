import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<li class=\"cNA--list__item cNA--list__item--footer\" {{did-insert (perform this.setupNotificationCenter)}}>\n  <Shared::Ui::Button @class=\"cNA--nav__link\" @variant=\"unstyled\" data-test-id=\"notification-center\">\n    {{svg-jar \"notification\" class=\"o--icon--md cNA--list__icon\"}}\n    <span class=\"cNA--list__label\">\n      {{t \"shared_words.notifications\"}}\n      {{#if this.unreadCount}}\n        <span class={{concat-classes \"cNO--badgeNotification\" this.badgeClass}}>\n          {{this.unreadCount}}\n        </span>\n      {{/if}}\n    </span>\n\n  </Shared::Ui::Button>\n</li>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<li class=\"cNA--list__item cNA--list__item--footer\" {{did-insert (perform this.setupNotificationCenter)}}>\n  <Shared::Ui::Button @class=\"cNA--nav__link\" @variant=\"unstyled\" data-test-id=\"notification-center\">\n    {{svg-jar \"notification\" class=\"o--icon--md cNA--list__icon\"}}\n    <span class=\"cNA--list__label\">\n      {{t \"shared_words.notifications\"}}\n      {{#if this.unreadCount}}\n        <span class={{concat-classes \"cNO--badgeNotification\" this.badgeClass}}>\n          {{this.unreadCount}}\n        </span>\n      {{/if}}\n    </span>\n\n  </Shared::Ui::Button>\n</li>","moduleName":"later/components/nav/notification-center.hbs","parseOptions":{"srcName":"later/components/nav/notification-center.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

import type NotificationsService from 'later/services/notifications';
import type { EmptyObject } from 'shared/types';

interface AppFooterListComponentSignature {
  Args: EmptyObject;
}

export default class AppFooterListComponent extends Component<AppFooterListComponentSignature> {
  @service declare notifications: NotificationsService;

  MAX_NOTIFICATION_SM_CLASS = 99;

  get unreadCount(): number {
    return this.notifications.unreadFeedItems.length;
  }

  get badgeClass(): string {
    if (this.unreadCount <= this.MAX_NOTIFICATION_SM_CLASS) {
      return 'u--text--sm';
    }

    return 'u--text--xs';
  }

  setupNotificationCenter = task(async () => {
    await this.notifications.setup.perform();
  });
}
