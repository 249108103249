import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class SocialProfileSerializer extends ApplicationSerializer {
  attrs = {
    instagramAccountType: 'account_type'
  };
}
