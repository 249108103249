import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import RSVP from 'rsvp';

import IgPost from 'later/models/ig-post';
import getHashtags from 'shared/utils/get-hashtags';
import createMomentInTz from 'shared/utils/time-helpers/create-moment-in-tz';

import type IgComment from 'later/models/ig-comment';
import type SocialProfileModel from 'later/models/social-profile';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type IndustryDataService from 'later/services/industry-data';
import type InstagramService from 'later/services/instagram';
import type UserConfigService from 'later/services/user-config';
import type {
  IgPostWithFirstCommentHashtags,
  ProfileIndustryComparison
} from 'shared/types/industry-data/profile-industry-comparison';
import type { InstagramMedia } from 'shared/types/instagram';

export default class CategorizationRewardsService extends Service {
  @service declare cache: CacheService;
  @service declare instagram: InstagramService;
  @service declare errors: ErrorsService;
  @service declare userConfig: UserConfigService;
  @service declare industryData: IndustryDataService;

  get timeZoneIdentifier(): string {
    const DEFAULT_TIMEZONE_IDENTIFIER = 'America/Vancouver';
    return this.userConfig.currentTimeZone?.identifier ?? DEFAULT_TIMEZONE_IDENTIFIER;
  }

  /**
   * Gets all profile comparison data for the given industry and follower count.
   */
  getProfileIndustryComparison = task(
    async (
      socialProfile: SocialProfileModel,
      industry?: string,
      followerCount?: number,
      averageProfileEngagement?: number
    ): Promise<ProfileIndustryComparison | undefined> => {
      try {
        const profileIndustryComparisonData = await this.industryData.getProfileIndustryComparison.perform(
          socialProfile,
          industry,
          followerCount,
          averageProfileEngagement
        );
        return profileIndustryComparisonData;
      } catch (error) {
        // Note: New industries have no data and return 500
        if (error.status !== 500) {
          this.errors.log(error);
        }
        return;
      }
    }
  );

  /**
   * Fetch the IG posts for the given social profile
   * If there is an error, it is logged and null is returned
   */
  getProfilePosts = task(
    async (socialProfile: SocialProfileModel): Promise<IgPost[] | InstagramMedia[] | undefined> => {
      try {
        const posts = await this.getUnpaidPosts.perform(socialProfile);
        return posts;
      } catch (error) {
        this.errors.log(error);
        return;
      }
    }
  );

  /**
   * Fetch all unique hashtags from IG posts and their first comments
   * If there is an error, it is logged and null is returned
   */
  getProfileHashtags = task(async (socialProfile: SocialProfileModel): Promise<string[] | undefined> => {
    try {
      const posts: IgPost[] | InstagramMedia[] = await this.getUnpaidPosts.perform(socialProfile);
      const firstComments = await RSVP.all(
        posts.map((post) => this.getFirstCommentOnPost.perform(socialProfile, post))
      );

      const hashtagsOnEachFirstComment: (string[] | undefined)[] = firstComments.map((firstComment) =>
        firstComment ? getHashtags(firstComment) : undefined
      );
      const postsWithHashtags = posts.map((post, index) => {
        const firstCommentHashtags = hashtagsOnEachFirstComment[index];
        return {
          ...post,
          ...{ firstCommentHashtags }
        } as IgPostWithFirstCommentHashtags;
      });
      return this.#findUniqueHashtags(postsWithHashtags);
    } catch (error) {
      this.errors.log(error);
      return;
    }
  });

  /**
   * Returns first comment for a given post
   */
  getFirstCommentOnPost = task(
    { enqueue: true },
    async (socialProfile: SocialProfileModel, post: IgPost | InstagramMedia): Promise<string | undefined> => {
      try {
        if (!(post instanceof IgPost)) {
          return;
        }

        const postComments = await this.instagram.fetchMediaComments(socialProfile, post);
        const firstComment = postComments?.[0];

        if (firstComment && this.#getCommentUsername(firstComment) === socialProfile.nickname) {
          return firstComment.text;
        }
        return;
      } catch {
        return;
      }
    }
  );

  /**
   * Returns Instagram posts for unpaid users
   */
  getUnpaidPosts = task(
    { enqueue: true },
    async (socialProfile: SocialProfileModel): Promise<InstagramMedia[] | IgPost[]> => {
      const cacheKey = `unpaidPosts_${socialProfile.id}`;
      const defaultValue = [] as InstagramMedia[] | IgPost[];

      const cachedValue = this.cache.retrieve<string>(cacheKey);
      if (cachedValue) {
        return JSON.parse(cachedValue) as InstagramMedia[] | IgPost[];
      }

      try {
        const response = await this.instagram.fetchRecentMedia(socialProfile, {
          count: 30
        });

        if (!response) {
          return defaultValue;
        }

        const thirtyRecentPosts = this.#getThreeMonthsOfPosts(response.posts).slice(0, 30);
        this.cache.add(cacheKey, JSON.stringify(thirtyRecentPosts), { expiry: this.cache.expiry(1, 'day') });
        return thirtyRecentPosts;
      } catch {
        return defaultValue;
      }
    }
  );

  /**
   * Time filter the last three months of
   * IG posts in the user's timezone
   */
  #getThreeMonthsOfPosts(posts: InstagramMedia[] | IgPost[]): InstagramMedia[] | IgPost[] {
    const threeMonthsAgo = createMomentInTz(null, this.timeZoneIdentifier).subtract(3, 'months').unix();

    if (!isPresent(posts)) {
      return [];
    }

    if (this.instagram.isIgPostArray(posts)) {
      return posts.filter((post) => post.createdTime && Number(post.createdTime) > threeMonthsAgo);
    }

    return posts;
  }

  /**
   * Extract the username from an IG comment
   */
  #getCommentUsername(comment: IgComment | undefined): string | undefined {
    if (comment?.username) {
      return comment.username;
    }

    return;
  }

  /**
   * Compute all unique hashtags from IG posts and their first comments
   */
  #findUniqueHashtags(posts: IgPostWithFirstCommentHashtags[]): string[] {
    let allHashtags = [] as string[];
    posts.forEach((post) => {
      const firstCommentHashtags = post.firstCommentHashtags || [];

      let uniqueHashtags;
      if ('tags' in post) {
        uniqueHashtags = new Set([...post.tags, ...firstCommentHashtags]);
      }
      uniqueHashtags = new Set([...firstCommentHashtags]);

      allHashtags = [...allHashtags, ...uniqueHashtags];
    });

    return allHashtags;
  }
}
