import { debug } from '@ember/debug';
import Evented from '@ember/object/evented';
import { run } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { isNone, isEmpty, isPresent } from '@ember/utils';
import { enqueueTask } from 'ember-concurrency';
import Faye from 'faye';
import RSVP from 'rsvp';

import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import NexusToken from 'later/utils/nexus-token';

import type Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import type { FayeClient, FayeExtension, FayeEvent } from 'faye';
import type { RawContentIdea } from 'later/models/content-idea';
import type { RawContentPillar } from 'later/models/content-pillar';
import type { RawGeneratedCaption } from 'later/models/generated-caption';
import type GroupModel from 'later/models/group';
import type UserModel from 'later/models/user';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type EventsService from 'later/services/events';
import type LaterConfigService from 'later/services/later-config';
import type OfflineService from 'later/services/offline';
import type PaymentService from 'later/services/payment';
import type PostsPendingApprovalService from 'later/services/schedule/posts-pending-approval';
import type SegmentService from 'later/services/segment';
import type SubscriptionsService from 'later/services/subscriptions';
import type { EncodedJWT, NexusChannelModel } from 'later/utils/nexus-token';
import type { Maybe, UntypedService } from 'shared/types';
import type { ContactCollectionBlockData } from 'shared/types/linkinbio-block-data';
import type BlockTypes from 'shared/utils/constants/linkinbio-block-types';

class FayeEventError extends Error {}
interface OutgoingMessage {
  channel: string;
  clientId: string;
  id: string;
  subscription: string;
  ext: {
    userId: string;
    token: string | undefined;
    nexusToken: EncodedJWT;
  };
}

interface SimpleFayeEvent {
  id: string;
}

interface SocialListeningSearchFayeEvent extends SimpleFayeEvent {
  SocialListeningSearch?: {
    id: string;
  };
}

interface InstagramProfileFayeEvent extends SimpleFayeEvent {
  InstagramProfile?: {
    id: string;
    keen_read_key?: string;
  };
}

interface InstagramBttpReportFayeEvent extends SimpleFayeEvent {
  InstagramBttpReport: {
    name: string;
    social_profile_id: string;
  };
}

interface InstagramPerformanceReportFayeEvent extends SimpleFayeEvent {
  InstagramPerformanceReport?: {
    name: string;
    social_profile_id: string;
  };
}

interface LinkinbioBlockFayeEvent extends SimpleFayeEvent {
  LinkinbioBlock?: {
    linkinbio_attachments: unknown[];
  };
  LinkinbioContactCollectionBlock?: {
    block_data: ContactCollectionBlockData;
    block_type: BlockTypes;
    connected_object_id: null;
    connected_object_type: null;
    created_at: string;
    id: string;
    index: number;
    linkinbio_attachment_ids: [];
    linkinbio_attachments: [];
    linkinbio_page_id: string;
    linkinbio_tag_ids: [];
    linkinbio_tags: [];
  };
}
interface PostFayeEvent extends SimpleFayeEvent {
  Post?: {
    id: string;
  };

  Gram?: {
    id: string;
  };

  gram?: {
    id: string;
  };
}

interface FayeEventRegistry {
  logout: SimpleFayeEvent;
  update_share_plan: SimpleFayeEvent;
  update_social_listening_search: SocialListeningSearchFayeEvent;
  destroy_gram: SimpleFayeEvent;
  destroy_post: SimpleFayeEvent;
  destroy_social_profile: SimpleFayeEvent;
  destroy_media_item: SimpleFayeEvent;
  update_media_item: SimpleFayeEvent;
  update_submission: SimpleFayeEvent;
  update_social_profile: InstagramProfileFayeEvent;
  reload_cluster: SimpleFayeEvent;
  update_content_pillar: { ContentPillar: RawContentPillar };
  update_content_idea: { ContentIdea: RawContentIdea };
  update_generated_caption: { GeneratedCaption: RawGeneratedCaption };
  update_device: SimpleFayeEvent;
  update_account: SimpleFayeEvent;
  update_post_media_item: SimpleFayeEvent;
  update_gram: PostFayeEvent;
  update_post: PostFayeEvent;
  destroy_linkinbio_post: SimpleFayeEvent;
  update_linkinbio_post: SimpleFayeEvent;
  update_linkinbio_block: LinkinbioBlockFayeEvent;
  update_instagram_bttp_report: InstagramBttpReportFayeEvent;
  update_instagram_performance_report: InstagramPerformanceReportFayeEvent;
}

type DataPayload = Record<string, Maybe<string | number | { id: string | number }>>;

// Note: Narrowing function to get the specific event data type for the given event name
function getEventData<T extends keyof FayeEventRegistry>(eventName: T, event: FayeEvent): FayeEventRegistry[T] {
  return event.data as FayeEventRegistry[typeof eventName];
}

/**
 * This service listens to websocket events from Later's `nexus` service. `nexus` implements the Faye service
 * and will send down payloads when different models have been updated or logout events happen. A user should
 * ideally be listening to the faye channels for their own user and whichever group they are currently viewing.
 */
export default class FayeService extends Service.extend(Evented) {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare laterConfig: LaterConfigService;
  @service declare offline: OfflineService;
  @service declare payment: PaymentService;
  @service('schedule/posts-pending-approval') declare postsPendingApproval: PostsPendingApprovalService;
  @service declare segment: SegmentService;
  @service declare store: StoreService;
  @service declare subscriptions: SubscriptionsService;
  @service declare cache: CacheService;
  @service declare genericMediaManager: UntypedService;
  @service declare events: EventsService;

  listeningUserId = null;
  declare fayeClient: FayeClient;

  get fayeHost(): string {
    return this.laterConfig.fayeHost;
  }

  get currentGroup(): GroupModel {
    return this.auth.currentGroup;
  }

  /**
   * Initializes the faye client based on the current Later config and subscribes to the user channel
   * @param user Currently authenticated user
   */
  setupFaye(user: UserModel): void {
    if (!this.fayeHost) {
      return this.errors.log(new Error('No Faye Host'), {
        userId: user.id,
        groupId: this.currentGroup.id
      });
    }

    this.fayeClient = new Faye.Client(this.fayeHost + '/websocket', {
      timeout: 90,
      retry: 5
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const extension: FayeExtension<OutgoingMessage> = {
      async outgoing(message: OutgoingMessage, callback: (message: OutgoingMessage) => void) {
        if (message.channel !== '/meta/subscribe') {
          return callback(message);
        }

        const nexusToken = await self._getNexusToken.perform(user);
        const updatedMessage = { ...message };

        updatedMessage.ext = updatedMessage.ext || {};
        updatedMessage.ext.userId = user.get('id');
        updatedMessage.ext.token = user.get('websocketToken');

        if (nexusToken) {
          updatedMessage.ext.nexusToken = nexusToken.encodedToken;
        }

        callback(updatedMessage);
      }
    };

    this.fayeClient.addExtension(extension);

    this.fayeClient.on('transport:down', () => {
      debug('Faye/Nexus connection is down');
    });

    this.fayeClient.on('transport:up', () => {
      debug('Faye/Nexus connection is up');
    });

    if (this.fayeClient) {
      this.fayeClient.subscribe('/user/' + user.get('id'), this._handleEvent.bind(this));
    }
    this.set('listeningUserId', user.get('id'));
  }

  /**
   * Starts listening for faye updates from given group
   * @param group Currently active group
   */
  subscribeGroup(group: GroupModel): void {
    if (this.fayeClient) {
      this.fayeClient.subscribe('/cluster/' + group.get('id'), this._handleEvent.bind(this));
    }
  }

  /**
   * Stops listening for faye updates from given group
   * @param group Formerly active group
   */
  unsubscribeGroup(group: GroupModel): void {
    if (this.fayeClient) {
      this.fayeClient.unsubscribe('/cluster/' + group.get('id'));
    }
  }

  /**
   * Stops listening for faye updates from given user. Called during Logout
   */
  unsubscribeUser(): void {
    if (this.fayeClient && this.listeningUserId) {
      this.fayeClient.unsubscribe('/user/' + this.listeningUserId);
      this.set('listeningUserId', null);
    }
  }

  /**
   * Fetches the Nexus JWT token from the backend
   * or uses a cached token for the current user
   *
   * @param user Current user for whom the token is fetched for.
   */
  _getNexusToken = enqueueTask(async (user: UserModel) => {
    const cacheKey = NexusToken.cacheKey(user.id);
    const cachedToken: unknown = this.cache.retrieve(cacheKey);

    if (NexusToken.isValidEncodedToken(cachedToken)) {
      const nexusToken = await NexusToken.build(cachedToken);
      const requiredChannels = this.auth.groups.map<[NexusChannelModel, string]>((group: GroupModel) => [
        'cluster',
        group.id
      ]);
      requiredChannels.push(['user', this.auth.currentUserModel.id]);
      if (nexusToken.hasRequiredChannels(requiredChannels)) {
        return nexusToken;
      }
    }

    try {
      const token = await NexusToken.build();

      this.cache.add(cacheKey, token.encodedToken, {
        expiry: token.expiry,
        persist: false
      });

      return token;
    } catch (error) {
      this.errors.log(new Error('Could not fetch Nexus token'), {
        userId: user.id,
        groupId: this.currentGroup.id,
        error
      });
      return;
    }
  });

  async _handleEvent(message: FayeEvent): Promise<void> {
    const event_name = message.name as keyof FayeEventRegistry;

    debug('event_name: ' + event_name);

    if (event_name === 'logout') {
      this.offline.testLogin();
    } else if (event_name === 'update_social_listening_search') {
      const event_data = getEventData(event_name, message);

      if (event_data.SocialListeningSearch?.id) {
        this.events.trigger('social-listening:data-is-ready', {
          social_listening_search_id: event_data.SocialListeningSearch.id
        });
      }
    } else if (event_name === 'update_share_plan') {
      const event_data = getEventData(event_name, message);
      this.store.pushPayload('share-plan', event_data);
    } else if (event_name === 'destroy_gram' || event_name === 'destroy_post') {
      const event_data = getEventData(event_name, message);
      run(() => this._pushDeletion('gram', event_data.id));
    } else if (event_name === 'destroy_social_profile') {
      const event_data = getEventData(event_name, message);
      //safer to just update the group, it will update socialProfiles -iMack
      run(() => this._pushDeletion('social_profile', event_data.id));
      if (!isNone(this.currentGroup)) {
        this.currentGroup.reload();
      }
    } else if (event_name === 'destroy_media_item') {
      const event_data = getEventData(event_name, message);
      run(() => this._pushDeletion('media_item', event_data.id));
    } else if (event_name === 'update_media_item') {
      const event_data = getEventData(event_name, message);

      try {
        this.store.pushPayload('media-item', event_data);
        // eslint-disable-next-line no-empty
      } catch (error) {} // Error handling needs to be improved; this is a temp fix until we can log the error using the errors service
    } else if (event_name === 'update_submission') {
      const event_data = getEventData(event_name, message);
      this.store.pushPayload('submission', event_data);
    } else if (event_name === 'update_social_profile') {
      const event_data = getEventData(event_name, message);
      if (!isNone(this.currentGroup)) {
        try {
          await this.currentGroup.reload();
          // eslint-disable-next-line no-empty
        } catch (error) {} // Error handling needs to be improved; this is a temp fix until we can log the error using the errors service
      }
      if (event_data.InstagramProfile) {
        const socialProfile = this.store.peekRecord('social-profile', event_data.InstagramProfile.id);
        if (socialProfile) {
          if (isPresent(event_data.InstagramProfile.keen_read_key)) {
            socialProfile.set('keenReadKey', event_data.InstagramProfile.keen_read_key);
            this.trigger('socialProfileHasKeenKey', socialProfile);
          }

          this.trigger('socialProfileUpdated', socialProfile);
        }
      }
    } else if (event_name === 'reload_cluster') {
      const event_data = getEventData(event_name, message);
      const model = this.store.peekRecord('group', event_data.id);
      if (model) {
        model.reload();
        model.get('socialProfiles').forEach((socialProfile) => socialProfile.reload());
      }
    } else if (event_name === 'update_device') {
      const event_data = getEventData(event_name, message);
      this.store.pushPayload('device', event_data);
    } else if (event_name === 'update_account') {
      await Promise.all([
        this.auth.refresh(),
        this.subscriptions.reload(),
        this.payment.retrieve.perform(),
        this.payment.retrieveCard.perform()
      ]);
      this.subscriptions.receivedAccountUpdate();
      this.events.trigger('account:updated', this.auth.currentAccount);
    } else if (event_name === 'update_post_media_item') {
      const event_data = getEventData(event_name, message);
      this.store.pushPayload('post-media-item', event_data);
    } else if (event_name === 'update_gram' || event_name === 'update_post') {
      const event_data = getEventData(event_name, message);
      event_data.Gram = event_data.Gram || event_data.Post || event_data.gram;
      delete event_data.Post;
      delete event_data.gram;
      this._waitForReady(event_data, 'Gram').then(() => this.store.pushPayload('gram', event_data));
    } else if (event_name === 'destroy_linkinbio_post') {
      const event_data = getEventData(event_name, message);
      run(() => this._pushDeletion('linkinbioPost', event_data.id));
    } else if (event_name === 'update_linkinbio_post') {
      const event_data = getEventData(event_name, message);
      this.store.pushPayload('linkinbio-post', event_data);
    } else if (event_name === 'update_linkinbio_block') {
      const event_data = getEventData(event_name, message);
      const attachments = event_data.LinkinbioBlock?.linkinbio_attachments;
      const contactCollectionBlock = event_data.LinkinbioContactCollectionBlock;

      if (attachments) {
        this.genericMediaManager.loadAttachments(attachments).then(() => {
          this.store.pushPayload('linkinbio-block', event_data);
        });
      }

      if (contactCollectionBlock) {
        this.store.pushPayload('linkinbio-block', { LinkinbioBlock: contactCollectionBlock });
        const contactCollectionBlockStoreRecord = this.store.peekRecord('linkinbio-block', contactCollectionBlock.id);

        if (contactCollectionBlock.block_data.download_area_content.export_status === 'accepting') {
          this.trigger('contactCollectionBlockCsvExportCompleted', contactCollectionBlockStoreRecord);
        }
      }
    } else if (event_name === 'update_instagram_bttp_report') {
      const event_data = getEventData(event_name, message);
      const { name, social_profile_id } = event_data.InstagramBttpReport;

      // clear out place holder
      const analyticsReportRecord = this.store
        .peekAll('analytics-report')
        .find(
          (report) =>
            report.belongsTo('socialProfile').id() === social_profile_id && name === report.name && report.isPlaceholder
        );

      if (!isNone(analyticsReportRecord)) {
        analyticsReportRecord.deleteRecord();
      }

      this.store.pushPayload('analytics-report', event_data);
      this.trigger('analyticsReportUpdated', event_data);
    } else if (event_name === 'update_instagram_performance_report') {
      const event_data = getEventData(event_name, message);
      this.store.pushPayload('analytics-report', event_data);
      this.trigger('instagramPerformanceReportUpdated', event_data.InstagramPerformanceReport);
    } else if (event_name === 'update_generated_caption') {
      const event_data = getEventData(event_name, message);
      if (event_data.GeneratedCaption.platform_error) {
        this.segment.track(SegmentEventTypes.AiGenerationTimeout, {
          model: 'generated caption',
          id: event_data.GeneratedCaption.id
        });
      }
      this.store.pushPayload('generated-caption', event_data);
      this.trigger('generatedCaptionUpdate', event_data);
    } else if (event_name === 'update_content_pillar') {
      const event_data = getEventData(event_name, message);
      if (event_data.ContentPillar.platform_error) {
        this.segment.track(SegmentEventTypes.AiGenerationTimeout, {
          model: 'content pillar',
          id: event_data.ContentPillar.id
        });
      }
      this.store.pushPayload('content-pillar', event_data);
      this.trigger('contentPillarUpdate', event_data);
    } else if (event_name === 'update_content_idea') {
      const event_data = getEventData(event_name, message);
      if (event_data.ContentIdea.platform_error) {
        this.segment.track(SegmentEventTypes.AiGenerationTimeout, {
          model: 'content idea',
          id: event_data.ContentIdea.id
        });
      }
      this.store.pushPayload('content-idea', event_data);
      this.trigger('contentIdeaUpdate', event_data);
    } else if (event_name === 'update_pending_approval_count') {
      this.postsPendingApproval.fetchCount.perform();
    }
  }

  // adapted from https://gist.github.com/runspired/96618af26fb1c687a74eb30bf15e58b6/#file-push-deletion-js
  /**
   * Handles remote deletion of records
   * @param type type of the record to be deleted
   * @param id id of the record to be deleted
   */
  _pushDeletion(type: string, id: string): void {
    if (isNone(type) || isNone(id)) {
      this.errors.log(new Error('Faye deletion received empty parameter(s)'), {
        type,
        id
      });
    }

    const record: Maybe<Model> = this.store.peekRecord(type, id);
    const isRecordDefined = typeof record !== 'undefined' && record !== null;

    if (isRecordDefined && record.get('dirtyType') !== 'deleted') {
      const relationships: Record<string, { data: [] | null }> = {};
      let hasRelationships = false;

      record.eachRelationship((name: string, { kind }: { kind: string }) => {
        hasRelationships = true;
        relationships[name] = {
          data: kind === 'hasMany' ? [] : null
        };
      });

      if (hasRelationships) {
        this.store.push({
          data: {
            type,
            id,
            relationships
          }
        });
      }
      record.unloadRecord();
    }
  }

  // Adapted from https://github.com/emberjs/data/issues/4262#issuecomment-240139154.
  // Returns a promise that resolves when the data payload can be processed.
  _waitForReady(data: unknown, type: string): Promise<void> {
    const isDataPayload = (value: unknown): value is DataPayload => typeof value === 'object' && value !== null;
    const isStringOrNumber = (value: unknown): value is string | number => ['string', 'number'].includes(typeof value);
    const isDataPayloadWithId = (value: unknown): value is { id: string | number } =>
      typeof value === 'object' && Boolean((value as { id: string | number }).id);

    if (!isDataPayload(data) || !Object.keys(data).length) {
      this.errors.log(new FayeEventError(`No data provided. Event type: ${type}`));
      return RSVP.reject();
    }

    const recordData = data[type];
    const recordId = data.id;

    if (!data[type]) {
      this.errors.log(new FayeEventError(`Invalid data. Event type: ${type}`), { data: data.toString() });
    }

    let id: Maybe<string | number> = undefined;
    if (isStringOrNumber(recordId)) {
      id = recordId;
    } else if (isDataPayloadWithId(recordData)) {
      ({ id } = recordData);
    }

    if (!id) {
      this.errors.log(new FayeEventError(`No ID provided. Event type: ${type}`), { data: data.toString() });
      return RSVP.resolve();
    }

    const record: Maybe<Model> = this.store.peekRecord(type, id);

    // If the model is already in the store, return immediately.
    if (record) {
      return RSVP.resolve();
    }

    return new RSVP.Promise((resolve) => {
      // Watch for models currently being created.
      const modelsToBeSaved = this.store
        .peekAll(type)
        .filter((model) => model.get('isSaving') && model.get('dirtyType') === 'created');

      // If no models of this type are being saved, return immediately.
      if (isEmpty(modelsToBeSaved)) {
        resolve();
        return;
      }

      let resolvedCount = 0;

      // Resolve once all save requests have resolved.
      const didResolve: () => void = () => {
        resolvedCount += 1;
        if (modelsToBeSaved.length === resolvedCount) {
          finish();
        }
      };

      // Resolve if the model now exists in the store.
      const didCreate: () => void = () => {
        const record = this.store.peekRecord(type, String(id));
        if (record) {
          finish();
        } else {
          didResolve();
        }
      };

      // Explicitly resolve if the model exists in the store and is not actively updating.
      const didUpdate: () => void = () => {
        const record = this.store.peekRecord(type, String(id));
        if (record && !(record as Model).isSaving) {
          finish();
        } else {
          didResolve();
        }
      };

      const start: () => void = () => {
        modelsToBeSaved.forEach((model) => {
          model.on('didCreate', didCreate);
          model.on('didUpdate', didUpdate);
          model.on('becameInvalid', didResolve);
          model.on('becameError', didResolve);
        });
      };

      const finish: () => void = () => {
        modelsToBeSaved.forEach((model) => {
          model.off('didCreate', didCreate);
          model.off('didUpdate', didUpdate);
          model.off('becameInvalid', didResolve);
          model.off('becameError', didResolve);
        });
        resolve();
      };

      start();
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    faye: FayeService;
  }
}
