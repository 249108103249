import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class ShortUrlChecker extends BaseValidator {
  validate() {
    if (!this.model.get('socialProfile.isPinterest')) {
      return true;
    }

    const sites = [
      '1url\\.com',
      'adcrun\\.ch',
      'adf\\.ly',
      'bc\\.vc',
      'bit\\.do',
      'bit\\.ly',
      'bitly\\.com',
      'buzurl\\.com',
      'crisco\\.com',
      'cur\\.lv',
      'cutt\\.us',
      'db\\.tt',
      'filoops\\.info',
      'goo\\.gl',
      'is\\.gd',
      'ity\\.im',
      'j\\.mp',
      'link\\.zip\\.net',
      'lnkd\\.in',
      'ow\\.ly',
      'po\\.st',
      'prettylinkpro\\.com',
      'q\\.gs',
      'qr\\.ae',
      'qr\\.net',
      'scrnch\\.me',
      'sir\\.bz',
      't\\.co',
      'tinyarrows\\.com',
      'tinyurl\\.com',
      'tr\\.im',
      'tweez\\.me',
      'twitthis\\.com',
      'u\\.bb',
      'u\\.to',
      'v\\.gd',
      'viralul\\.com',
      'virl\\.ws',
      'vzturl\\.com',
      'x\\.co',
      'yourls\\.org',
      '➡\\.ws',
      '✩\\.ws'
    ];

    const buildRegEx = (site) => RegExp(`(\\s|^)(http[s]?:\\/\\/)?${site}(\/|\\s|\$)`, 'gi');
    const hasShortUrl = sites.some((site) => buildRegEx(site).test(this.model.get('linkUrl')));

    return hasShortUrl ? this.createErrorMessage('shortUrlRejector') : true;
  }
}

export default ShortUrlChecker;
