import Service from '@ember/service';

import type PerfectScrollbar from 'perfect-scrollbar';

type ScrollbarId = string | number;
export interface ScrollContainer {
  scrollbar: PerfectScrollbar;
}

export default class PerfectScrollService extends Service {
  cachedScrollbars: Record<ScrollbarId, ScrollContainer> = {};

  /**
   * Gets the scrollbar with the given ID from the cache
   */
  getScrollbarById(id: ScrollbarId): ScrollContainer | undefined {
    const scrollbar = this.cachedScrollbars[id];
    return scrollbar || undefined;
  }

  saveScrollbarToCache(id: ScrollbarId, scrollbar: PerfectScrollbar): void {
    this.cachedScrollbars[id] = { scrollbar };
  }

  removeScrollbarFromCache(id: ScrollbarId): void {
    delete this.cachedScrollbars[id];
  }

  /**
   * @returns The scrollbars current position, offset from the bottom
   */
  getBottomOffset(id: ScrollbarId): number {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      return element.scrollHeight - element.scrollTop;
    }

    return 0;
  }

  /**
   * Scrolls the provided scrollbar to the top
   */
  scrollToTop(id: ScrollbarId): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollTop = 0;
      scrollContainer.scrollbar.update();
    }
  }

  /**
   * Scrolls the provided scrollbar to the left
   */

  scrollToLeft(id: ScrollbarId): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollLeft = 0;
      scrollContainer.scrollbar.update();
    }
  }

  /**
   * Scrolls the provided scrollbar to the left by given offset
   *
   * @param offset The number of pixels to scroll
   */
  scrollToLeftBy(id: ScrollbarId, offset: number): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollLeft -= offset;
      scrollContainer.scrollbar.update();
    }
  }

  /**
   * Scrolls the provided scrollbar to the right
   */
  scrollToRight(id: ScrollbarId): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollLeft = element.scrollWidth;
      scrollContainer.scrollbar.update();
    }
  }

  /**
   * Scrolls the provided scrollbar to the right by given offset
   *
   * @param offset The number of pixels to scroll
   */
  scrollToRightBy(id: ScrollbarId, offset: number): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollLeft += offset;
      scrollContainer.scrollbar.update();
    }
  }

  /**
   * Scrolls the provided scrollbar to the bottom
   */
  scrollToBottom(id: ScrollbarId): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollTop = element.scrollHeight;
      scrollContainer.scrollbar.update();
    }
  }

  /**
   * Scrolls the provided scrollbar to the bottom by given offset
   *
   * @param offset The number of pixels from the bottom to scroll to.
   */
  scrollToBottomOffset(id: ScrollbarId, offset: number): void {
    const scrollContainer = this.getScrollbarById(id);
    const element = this.#getElement(scrollContainer);

    if (scrollContainer && element) {
      element.scrollTop = element.scrollHeight - offset;
      scrollContainer.scrollbar.update();
    }
  }

  #getElement(scrollContainer: ScrollContainer | undefined): HTMLElement | undefined {
    if (!scrollContainer) {
      return;
    }

    return scrollContainer.scrollbar.element;
  }
}

declare module '@ember/service' {
  interface Registry {
    'perfect-scroll': PerfectScrollService;
  }
}
