import Service, { inject as service } from '@ember/service';

import type AdaService from 'later/services/ada';
import type AppcuesService from 'later/services/appcues';
import type AuthService from 'later/services/auth';
import type IntegrationsSuppressorService from 'later/services/integrations-suppressor';
import type { UntypedService } from 'shared/types';

export interface Integration {
  readonly name: string;
  show: () => void;
  hide: () => void;
  setup: () => void;
}

type integrationKey = 'ada' | 'appcues' | 'integrationsSuppressor';

/**
 * Service used to manage the bottom right corner real estate of our web app.
 * Interacting with Ada and Appcues Checklist API to make sure only one is active by
 * toggling display = none.  Appcues libraries are imported through
 * Segment integration via its analytics.js bundle.
 */
export default class IntegrationsQueueService extends Service {
  @service declare ada: AdaService;
  @service declare appcues: AppcuesService;
  @service declare auth: AuthService;
  @service declare integrationsSuppressor: IntegrationsSuppressorService;
  @service declare segment: UntypedService;

  readonly defaultIntegration = 'ada';
  queue: integrationKey[] = [];

  authSetupFinished = new Promise((res) => {
    this.auth.on('authSetupFinished', res);
  });

  analyticsReady = new Promise((res) => {
    this.segment.on('analyticsReady', res);
  });

  get integrations(): { [key in integrationKey]: Integration } {
    return {
      ada: this.ada,
      appcues: this.appcues,
      integrationsSuppressor: this.integrationsSuppressor
    };
  }

  get currentIntegration(): Integration {
    return this.integrations[this.queue[0]] || this.integrations[this.defaultIntegration];
  }

  authAndAnalyticsReady(): Promise<[unknown, unknown]> {
    return Promise.all([this.authSetupFinished, this.analyticsReady]);
  }

  setup(): void {
    this.authSetupFinished.then(() => this.ada.setup());
    this.authAndAnalyticsReady().then(() => {
      Object.values(this.integrations).forEach((integration) => {
        if (integration.name !== 'ada') {
          integration.setup();
        }
      });
    });
  }

  addToQueue(integrationName: integrationKey, jumpTheQueue = false): void {
    if (jumpTheQueue) {
      this.queue.unshift(integrationName);
    } else {
      this.queue.push(integrationName);
    }
    this.updateCurrentIntegration();
  }

  removeFromQueue(integrationName: integrationKey): void {
    this.queue = this.queue.filter((integration) => integration !== integrationName);
    this.updateCurrentIntegration();
  }

  updateCurrentIntegration(): void {
    this.currentIntegration.show();
    Object.values(this.integrations).forEach((integration) => {
      if (this.currentIntegration.name !== integration.name) {
        integration.hide();
      }
    });
  }

  refreshIntegrations(): void {
    const activeIntegration = this.queue[0];
    if (activeIntegration) {
      this.addToQueue(activeIntegration, true);
    }
  }

  isInQueue(integrationName: integrationKey): boolean {
    return this.queue.includes(integrationName);
  }
}

declare module '@ember/service' {
  interface Registry {
    'integrations-queue': IntegrationsQueueService;
  }
}
