/**
 * Reads the cookie with the given name, and returns its value
 */
const getCookie = (name: string): string | undefined => {
  const cookies = Object.assign(
    {},
    ...document.cookie.split('; ').map((cookie) => {
      const [name, value] = cookie.split('=');
      return { [name]: value };
    })
  );

  return cookies[name];
};

/**
 * Erases a cookie by updating its expiration time to zero
 */
const eraseCookie = (name: string): void => {
  document.cookie = name + '=; Path=/; Max-Age=0';
};

export { getCookie, eraseCookie };
