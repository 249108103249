import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import type SeamlessCheckoutManagerService from 'later/services/seamless-checkout-manager';

@command('upgrade-plan')
export class UpgradePlanCommand extends Command {
  @service declare seamlessCheckoutManager: SeamlessCheckoutManagerService;

  public searchTerms = ['upgrade', 'plans', 'change', 'limit', 'access', 'restrict', 'settings'];
  public mandatoryTerms: string[] = [];
  protected action(): Promise<void> | void {
    this.seamlessCheckoutManager.upgrade({ feature: 'getMorePosts' });
  }

  public isSuggested(): boolean {
    return true;
  }

  public getTitle(): string {
    return `Upgrade Your Plan`;
  }

  public getDescription(): string {
    return `Upgrade your plan to access more features`;
  }

  public getIcon(): string {
    return 'monetary';
  }
}

export default UpgradePlanCommand;
