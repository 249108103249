import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

import { shiftTimeToEndOfLocalDay, shiftTimeToStartOfLocalDay } from 'shared/utils/analytics/timezone-helpers';
import { MAX_LISTENER_TITLE_CHARACTER_LENGTH } from 'social-listening/types/validations';

import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';

export const ListenerType = {
  Hashtag: 'InstagramHashtagListener',
  Mention: 'InstagramMentionListener',
  User: 'InstagramUserListener'
} as const;

export type ListenerTypeKeys = keyof typeof ListenerType;
export type ListenerTypes = (typeof ListenerType)[ListenerTypeKeys];

const Validations = buildValidations({
  name: [
    validator('presence', {
      presence: true,
      messageKey: 'social-listening.create.form.errors.title.empty'
    }),
    validator('length', {
      max: MAX_LISTENER_TITLE_CHARACTER_LENGTH,
      message: 'social-listening.create.form.errors.title.length'
    })
  ],
  includedHashtags: validator('inline', {
    validate(_value: unknown, _options: unknown, model: ListenerModel) {
      return model.hasTopicOrProfile || 'social-listening.create.form.errors.hashtag.empty';
    }
  }),
  includedProfiles: validator('inline', {
    validate(_value: unknown, _options: unknown, model: ListenerModel) {
      return model.hasTopicOrProfile || 'social-listening.create.form.errors.profile.empty';
    }
  }),
  campaignId: validator('inline', {
    validate(value: string, _options: unknown, model: ListenerModel) {
      const regex =
        /^(\d+|https:\/\/[a-zA-Z0-9-]+\.later\.com\/campaigns\/\d+.*|https:\/\/[a-zA-Z0-9-]+\.mavrck\.co\/community\/join\?activity=\d+.*)$/;
      return !model.isCampaignToggledOn || regex.test(value);
    }
  })
});

const MINUTES_BEFORE_DATA_LOAD = 3;

export default class ListenerModel extends Model.extend(Validations) {
  @service('user-config') declare userConfig: UserConfigService;

  @belongsTo('socialProfile') declare socialProfile: SocialProfileModel;
  @belongsTo('group') declare group: GroupModel;
  @attr('string') declare createdAt: string;
  @attr('string') declare name: string;
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare includedHashtags: string[];
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare includedProfiles: string[];
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare excludedHashtags: string[];
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare excludedProfiles: string[];
  @attr('string') declare campaignId?: string;
  @attr('boolean') declare isDefaultSearch?: boolean;

  @tracked startDate: moment.Moment = moment.tz(this.timezone).subtract(3, 'months');
  @tracked endDate: moment.Moment = moment.tz(this.timezone);
  @tracked isCampaignToggledOn: boolean = isPresent(this.campaignId);
  @tracked tempCampaignId: string = '';

  get timezone(): string {
    return this.userConfig.currentTimeZone?.identifier ?? 'UTC';
  }

  get hasIncludedHashtags(): boolean {
    return this.includedHashtags.length > 0;
  }

  get hasIncludedProfiles(): boolean {
    return this.includedProfiles.length > 0;
  }

  get hasTopicOrProfile(): boolean {
    return this.hasIncludedHashtags || this.hasIncludedProfiles;
  }

  get isBackfilling(): boolean {
    const backfillEndTime = moment.unix(Number(this.createdAt)).add(MINUTES_BEFORE_DATA_LOAD, 'minutes');
    return moment().unix() < backfillEndTime.unix();
  }

  get createdAtMoment(): Moment {
    return moment.unix(Number(this.createdAt));
  }

  get closestStartDate(): Moment {
    const startDate = moment.tz(this.timezone).subtract(3, 'months');
    const createdAt = Number(this.createdAt) ?? 0;

    return moment(shiftTimeToStartOfLocalDay(Math.max(createdAt, startDate.unix()), this.timezone));
  }

  get closestEndDate(): Moment {
    return moment(shiftTimeToEndOfLocalDay(this.endDate.unix(), this.timezone));
  }

  get startDayMoment(): Moment {
    return moment(shiftTimeToStartOfLocalDay(this.createdAtMoment.clone().unix(), this.timezone));
  }

  get predictedEndDate(): Moment {
    return moment(shiftTimeToEndOfLocalDay(this.createdAtMoment.clone().add(3, 'month').unix(), this.timezone));
  }

  get hasContent(): boolean {
    return (
      isPresent(this.name) ||
      this.hasTopicOrProfile ||
      this.excludedHashtags.length > 0 ||
      this.excludedProfiles.length > 0 ||
      isPresent(this.campaignId)
    );
  }

  @action
  setIsCampaignToggledOn(value: boolean): void {
    if (!value) {
      this.tempCampaignId = this.campaignId ?? '';
      this.campaignId = undefined;
    } else {
      this.campaignId = this.tempCampaignId;
    }
    this.isCampaignToggledOn = value;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    listener: ListenerModel;
  }
}
