import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n{{! Flyout Navigation }}\n<section class=\"o--page__sidebar cNA--sidebar is--open\" data-test-id=\"app-header\">\n  <section class=\"o--page__sidebarHeader\" {{on \"click\" this.hideFlyout}}>\n    <LinkTo @route=\"cluster.schedule.calendar\" class={{if this.isLinkinbioRoute \"img--logo--linkinbio\" \"img--logo\"}} />\n  </section>\n  <Nav::AppHeaderList @isCurrentGroupContributor={{this.isCurrentGroupContributor}} />\n  <Nav::AppFooterList\n    @dropTarget=\"e--footer__toggle\"\n    @logout={{@logout}}\n    @openChatbot={{@openChatbot}}\n    @showLabs={{this.isCurrentUserAdmin}}\n  />\n</section>\n<Shared::Ui::ClusterSwitcher />\n<Nav::AppHeaderSubnav />", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n{{! Flyout Navigation }}\n<section class=\"o--page__sidebar cNA--sidebar is--open\" data-test-id=\"app-header\">\n  <section class=\"o--page__sidebarHeader\" {{on \"click\" this.hideFlyout}}>\n    <LinkTo @route=\"cluster.schedule.calendar\" class={{if this.isLinkinbioRoute \"img--logo--linkinbio\" \"img--logo\"}} />\n  </section>\n  <Nav::AppHeaderList @isCurrentGroupContributor={{this.isCurrentGroupContributor}} />\n  <Nav::AppFooterList\n    @dropTarget=\"e--footer__toggle\"\n    @logout={{@logout}}\n    @openChatbot={{@openChatbot}}\n    @showLabs={{this.isCurrentUserAdmin}}\n  />\n</section>\n<Shared::Ui::ClusterSwitcher />\n<Nav::AppHeaderSubnav />","moduleName":"later/components/nav/app-header.hbs","parseOptions":{"srcName":"later/components/nav/app-header.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type RouterService from '@ember/routing/router-service';
import type AppHeaderService from 'later/services/app-header';
import type AuthService from 'later/services/auth';

interface AppHeaderComponentArgs {
  logout: () => void;
  openChatbot: (location: string) => void;
}

export default class AppHeaderComponent extends Component<AppHeaderComponentArgs> {
  @service declare auth: AuthService;
  @service declare appHeader: AppHeaderService;
  @service declare router: RouterService;

  get isCurrentGroupContributor(): boolean {
    return this.auth.currentGroup.contributor;
  }

  get isCurrentUserAdmin(): boolean {
    return this.auth.currentUserModel.admin;
  }

  get isLinkinbioRoute(): boolean {
    return this.router.currentRouteName.includes('linkinbio');
  }

  @action
  hideFlyout(): void {
    this.appHeader.hideFlyout();
  }
}
