import classic from 'ember-classic-decorator';
import { resolve } from 'rsvp';

import _Devise from './devise';

@classic
export default class Custom extends _Devise {
  /**
   * Custom authentication for sign-ups.
   * Avoid saving token on ember-simple-auth store.
   *
   * @method authenticate
   * @param {String} identification The user's identification
   * @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming authenticated
   * @public
   */
  authenticate(identification) {
    const { identificationAttributeName } = this;
    const cookieData = {};
    cookieData[identificationAttributeName] = identification;
    return resolve(cookieData);
  }
}
