/**
 * @module Helpers
 */

/**
 * @class LaterHelpers
 * @extends Helpers
 */

/**
 * Check if a collection contains a certain value.
 *
 * @method if-contains
 * @param {Array|Object} collection A collection of values
 * @param {Any} targetValue The target value
 *
 * @return {Boolean} Whether or not the collection contains a certain value
 */
import { helper as buildHelper } from '@ember/component/helper';

export default buildHelper(([collection, targetValue] /*, hash*/) => {
  if (Array.isArray(collection)) {
    return collection.includes(targetValue);
  }
  return targetValue in collection;
});
