import { action } from '@ember/object';

import CalendarMediaRoute from 'calendar/routes/media';

export default class ListMediaRoute extends CalendarMediaRoute {
  @action
  closeModal(): void {
    this.router.transitionTo('cluster.schedule.list', { queryParams: this.router.currentRoute.queryParams });
  }
}
