import type Model from '@ember-data/model';
import type { CopyableModel } from 'later/models/interfaces/copyable-model';

/**
 * Copy a Model record with optional filtering on which fields are copied over.
 * @param model
 * @returns Copy of record
 */
export function copyRecord<M extends Model>(model: CopyableModel<M>): M {
  return model.copy();
}

export default copyRecord;
