import { readOnly } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type { IndustryKey } from 'influencers/types';
import type MediaKitAttachment from 'later/models/partnerships-attachment';
import type SocialIdentityModel from 'later/models/social-identity';
import type { MediaKitReport, Rate, SocialPlatform, MediaKitTheme, CustomizationBlocks } from 'media-kit/types';

export default class MediaKitModel extends Model {
  @belongsTo('socialIdentity') declare socialIdentity: SocialIdentityModel;
  @hasMany('mediaKitAttachment') declare mediaKitImages: MediaKitAttachment[];

  @attr('string') declare name?: string;
  @attr('string') declare about?: string;
  @attr('string') declare website?: string;
  @attr('string') declare contactInfo?: string;
  @attr('string') declare industry?: IndustryKey;
  @attr('string') declare location?: string;
  @attr('boolean') declare otherTypesOfCollaboration?: boolean;
  @attr('string') declare analyticsLastUpdatedAt?: string;
  @readOnly('boolean') declare enabled: boolean;

  @attr() declare socialPlatforms: SocialPlatform[];
  @attr() declare pastCollabs: string[];
  @attr() declare rates: Rate[];

  @attr() declare theme: MediaKitTheme;
  @attr() declare mediaKitReport: MediaKitReport;
  @attr() declare customizationBlocks: CustomizationBlocks;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'media-kit': MediaKitModel;
  }
}
