import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class TitleLength extends BaseValidator {
  get pastTitleCharacterLimit() {
    return this.model.titleCharactersLeft < 0;
  }

  get socialProfile() {
    return this.model.socialProfile;
  }

  get hasTitle() {
    return this.socialProfile.get('isPinterest') || this.socialProfile.get('isYoutube');
  }

  validate /*value, options, model, attribute*/() {
    if (this.socialProfile.get('isYoutube') && this.model.titleCharacterCount == 0) {
      return this.createErrorMessage('postTitleRequired');
    }

    if (this.hasTitle && this.pastTitleCharacterLimit) {
      return this.createErrorMessage('postTitleLength');
    }

    return true;
  }
}
