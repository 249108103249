import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<tr>\n  <td>\n    <Shared::ProfileAvatar @showDetails={{true}} @showPlatform={{true}} @socialProfile={{@post.socialProfile}} />\n  </td>\n  <td>\n    {{this.postDisplayTime}}\n  </td>\n  <td class=\"u--text--right\">\n    {{#if this.postIsStory}}\n      <span class=\"u--text--light\">\n        {{t \"shared_phrases.instagram_story\"}}\n      </span>\n    {{else if this.postLocked}}\n      <button class=\"o--btn o--btn--mute\" type=\"button\" {{on \"click\" this.editPost}}>\n        {{svg-jar \"verified\" class=\"o--icon o--icon--light u--m__r__xs\"}}\n        {{t \"shared_words.posted\"}}\n      </button>\n    {{else}}\n      <button class=\"o--btn o--btn--outline u--p__lr__md\" type=\"button\" {{on \"click\" this.editPost}}>\n        {{t \"shared_words.edit\"}}\n      </button>\n    {{/if}}\n  </td>\n</tr>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<tr>\n  <td>\n    <Shared::ProfileAvatar @showDetails={{true}} @showPlatform={{true}} @socialProfile={{@post.socialProfile}} />\n  </td>\n  <td>\n    {{this.postDisplayTime}}\n  </td>\n  <td class=\"u--text--right\">\n    {{#if this.postIsStory}}\n      <span class=\"u--text--light\">\n        {{t \"shared_phrases.instagram_story\"}}\n      </span>\n    {{else if this.postLocked}}\n      <button class=\"o--btn o--btn--mute\" type=\"button\" {{on \"click\" this.editPost}}>\n        {{svg-jar \"verified\" class=\"o--icon o--icon--light u--m__r__xs\"}}\n        {{t \"shared_words.posted\"}}\n      </button>\n    {{else}}\n      <button class=\"o--btn o--btn--outline u--p__lr__md\" type=\"button\" {{on \"click\" this.editPost}}>\n        {{t \"shared_words.edit\"}}\n      </button>\n    {{/if}}\n  </td>\n</tr>","moduleName":"later/components/media-items/post-card.hbs","parseOptions":{"srcName":"later/components/media-items/post-card.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment-timezone';

import type GramModel from 'later/models/gram';
import type ErrorsService from 'later/services/errors';
import type UserConfigService from 'later/services/user-config';

const DATE_FORMAT_STRING = 'MMM DD, h:mm a';

interface PostCardArgs {
  post: GramModel;
  editPost: (gramId: string) => void;
}

export default class PostCardComponent extends Component<PostCardArgs> {
  @service declare userConfig: UserConfigService;
  @service declare errors: ErrorsService;

  get timeZoneIdentifier(): string {
    return this.userConfig.currentTimeZone?.identifier || '';
  }

  get postLocked(): boolean {
    return this.args.post.locked;
  }

  get postIsStory(): boolean {
    return this.args.post.isInstagramStory;
  }

  get postDisplayTime(): string {
    if (!this.args.post.scheduledTime) {
      this.errors.log(new Error('PostCardComponent: postDisplayTime: post.scheduledTime is null'), {
        postId: this.args.post?.id
      });
      return 'Invalid date';
    }

    return moment.unix(this.args.post.scheduledTime).tz(this.timeZoneIdentifier).format(DATE_FORMAT_STRING);
  }

  @action
  editPost(): void {
    if (!this.postIsStory) {
      this.args.editPost(this.args.post.id);
    }
  }
}
