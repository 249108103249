import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class SocialIdentitySerializer extends ApplicationSerializer {
  attrs = {
    socialProfiles: {
      embedded: 'always',
      serialize: 'ids'
    }
  };
}
