import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task, dropTask, restartableTask } from 'ember-concurrency';
import moment from 'moment';
import 'moment-timezone';

import { profileColorOutlineClass } from 'later/helpers/profile-color-outline-class';
import CalendarEvent from 'later/models/calendar-event';
import CalendarNoteModel from 'later/models/calendar-note';
import GramModel from 'later/models/gram';
import { FetchableRecordTypes } from 'later/services/schedule/fetch-within-dates';
import { CALENDAR_EVENT_TYPES, CALENDAR_TYPES, CALENDAR_VIEW_TYPES } from 'later/utils/constants';
import findOpenTimeSlot from 'later/utils/find-best-fit-open-timeslot';
import { days } from 'shared/utils/time';

import type { CachedData } from 'calendar/types/full-calendar';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type SocialProfileModel from 'later/models/social-profile';
import type TimeSlotModel from 'later/models/time-slot';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type BttpService from 'later/services/calendar/bttp';
import type ConfigService from 'later/services/calendar/config';
import type ErrorsService from 'later/services/errors';
import type PostNavigationService from 'later/services/post-navigation';
import type FetchWithinDatesService from 'later/services/schedule/fetch-within-dates';
import type { FetchRecordsParams, FetchableRecords } from 'later/services/schedule/fetch-within-dates';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';
import type { Maybe } from 'shared/types';

const { NOTE, BTTP, BTTP_PROMO, TIME_SLOT, POST, NOTES_PROMO } = CALENDAR_EVENT_TYPES;
const { STORIES } = CALENDAR_TYPES;
const { WEEK, MONTH } = CALENDAR_VIEW_TYPES;

function isGramList(records: FetchableRecords[]): records is GramModel[] {
  return records[0] instanceof GramModel;
}

function isNotesList(records: FetchableRecords[]): records is CalendarNoteModel[] {
  return records[0] instanceof CalendarNoteModel;
}

export default class CalendarEventsService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service('calendar/bttp') declare bttp: BttpService;
  @service('calendar/config') declare calendarConfig: ConfigService;
  @service declare errors: ErrorsService;
  @service('schedule/fetch-within-dates') declare fetchWithinDates: FetchWithinDatesService;
  @service declare intl: IntlService;
  @service declare postNavigation: PostNavigationService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare userConfig: UserConfigService;

  @tracked selectedNoteDateWithNotesPromo: Moment | undefined = undefined;
  @tracked hasSeenNotesPromo = false;

  defaultTimedEventDuration = '01:00:00';

  @readOnly('auth.currentAccount') declare account: AccountModel;
  @readOnly('auth.currentGroup.id') declare currentGroupId: string;
  @readOnly('selectedSocialProfiles.hasMultipleSelected') declare multipleProfilesSelected: boolean;
  @readOnly('selectedSocialProfiles.firstProfile') declare singleSocialProfile: SocialProfileModel;

  get defaultFetchInfo(): FetchRecordsParams {
    return {
      start: this.calendarConfig.fullCalendar?.view.activeStart ?? new Date(),
      end: this.calendarConfig.fullCalendar?.view.activeEnd ?? days(7, new Date())
    };
  }

  get selectedSocialProfileIds(): string[] {
    return isEmpty(this.selectedSocialProfiles.profiles)
      ? [this.auth.currentSocialProfile?.id]
      : this.selectedSocialProfiles.profileIds;
  }

  get shouldShowBttpPromo(): boolean {
    return (
      !this.account.isOnLegacyPlan &&
      this.bttp.isValidSocialProfile &&
      !this.multipleProfilesSelected &&
      this.bttp?.shouldShowBttpDiscovery
    );
  }

  get userTimeSlots(): TimeSlotModel[] {
    return this.singleSocialProfile ? this.singleSocialProfile.userTimeSlots : [];
  }

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this.hasSeenNotesPromo = this.cache.retrieve('hasSeenNotesPromo') ?? false;
  }

  seenNotesPromo(): void {
    this.cache.add('hasSeenNotesPromo', true, { expiry: this.cache.expiry(30, 'days'), persist: true });
    this.hasSeenNotesPromo = true;
  }

  #addBttpPromoEvent(events: CalendarEvent[]): void {
    const calendarStartTime = moment(this.defaultFetchInfo.start).tz(this.calendarConfig.timeZoneIdentifier);

    const bttpPromoTimeSlot = findOpenTimeSlot({
      events,
      calendarStartTime,
      defaultTimedEventDuration: this.defaultTimedEventDuration
    });

    if (bttpPromoTimeSlot && bttpPromoTimeSlot.start) {
      events.push(this.#createBttpPromoTimeSlotEvent(bttpPromoTimeSlot));
    }
  }

  #addNotesPromoEvent(events: CalendarEvent[]): void {
    const calendarStartTime = moment(this.defaultFetchInfo.start).tz(this.calendarConfig.timeZoneIdentifier);
    const notesPromoTimeSlot = findOpenTimeSlot({
      events,
      calendarStartTime,
      defaultTimedEventDuration: this.defaultTimedEventDuration
    });

    this.selectedNoteDateWithNotesPromo = moment.tz(notesPromoTimeSlot?.start, this.calendarConfig.timeZoneIdentifier);

    if (notesPromoTimeSlot) {
      events.push(this.#createNotesPromoTimeSlotEvent(notesPromoTimeSlot));
    }
  }

  #createBttpPromoTimeSlotEvent({ start, end }: { start: string; end: string }): CalendarEvent {
    return CalendarEvent.create({
      start,
      end,
      allDay: false,
      eventType: BTTP_PROMO,
      editable: false
    });
  }

  #createNotesPromoTimeSlotEvent({ start, end }: { start: string; end: string }): CalendarEvent {
    return CalendarEvent.create({
      start,
      end,
      allDay: false,
      eventType: NOTES_PROMO,
      editable: false
    });
  }

  // Note: Only display notes for the current group
  #filterNotes(notes: CalendarNoteModel[]): CalendarNoteModel[] {
    return notes.filter((note) => note.group.get('id') === this.currentGroupId);
  }

  // Note: Only return posts that are within the date range requested, and for the social profiles requested
  #filterPosts(posts: GramModel[], { start, end }: FetchRecordsParams): GramModel[] {
    return posts.filter((post) => {
      const socialProfile = post.get('socialProfile');
      const socialProfileId = socialProfile?.get('id');
      const isWithinDates = post.start ? moment(post.start).isBetween(start, end) : false;
      return (
        this.selectedSocialProfileIds.includes(socialProfileId || '') &&
        isWithinDates &&
        !post.isDeleted &&
        !post.isInstagramStory &&
        !post.isNew &&
        post.active &&
        post.startTime
      );
    });
  }

  #formatPostsAsEvents(posts: GramModel[]): CalendarEvent[] {
    return posts.reduce((result: CalendarEvent[], post: GramModel): CalendarEvent[] => {
      const {
        id,
        start,
        calendarOrProcessingUrl,
        isCarouselPost,
        isFacebookReel,
        isInstagramReel,
        isReel,
        hasErrorIcon,
        unverified,
        isEditable,
        autoPublish,
        locked
      } = post.getProperties(
        'id',
        'start',
        'calendarOrProcessingUrl',
        'isCarouselPost',
        'isFacebookReel',
        'isInstagramReel',
        'isReel',
        'hasErrorIcon',
        'unverified',
        'isEditable',
        'autoPublish',
        'locked'
      );

      const socialProfile = post.get('socialProfile');

      const eventObject = {
        id,
        autoPublish,
        calendarOrProcessingUrl,
        displayEventTime: true,
        durationEditable: false,
        editable: isEditable,
        eventType: POST,
        hasError: hasErrorIcon,
        isCarouselPost,
        locked,
        post,
        start,
        unverified,
        socialProfileId: socialProfile?.get('id'),
        profileIconClass: socialProfile?.get('profileIconClass'),
        avatarChildClass: socialProfile?.get('avatarChildClass'),
        profileColorClass: profileColorOutlineClass([socialProfile?.get('profileColorClass')])
      };

      const shouldIncludeReels = isInstagramReel || isFacebookReel;

      const finalizedEventObject = shouldIncludeReels ? Object.assign(eventObject, { isReel }) : eventObject;

      result.push(CalendarEvent.create(finalizedEventObject));
      return result;
    }, []);
  }

  #formatNotesAsEvents(notes: CalendarNoteModel[]): CalendarEvent[] | [] {
    const tzIdentifier = this.userConfig.currentTimeZone?.identifier;

    if (!tzIdentifier) {
      this.errors.log(new Error('Timezone Not Found. Cannot load notes.'), {
        userConfig: JSON.stringify(this.userConfig)
      });
      this.alerts.alert(this.intl.t('calendar.errors.cant_load_notes'), {
        title: this.intl.t('calendar.errors.timezone_not_found'),
        preventDuplicates: true
      });
      return [];
    }

    return notes.map((note) => {
      if (!note || !note.moment) {
        return {} as CalendarEvent;
      }

      const time = note.moment.tz(tzIdentifier);
      const endTime = moment(time).add(30, 'minutes');
      const obj = {
        allDay: false,
        backgroundColor: 'white',
        displayNote: note.displayNote,
        end: endTime.format(),
        eventType: NOTE,
        id: note.id,
        note,
        noteContent: note.noteContent,
        noteSummary: note.summary,
        start: time.format(),
        startDay: time.weekday()
      };
      return CalendarEvent.create(obj);
    });
  }

  #formatTimeSlotsAsEvents(timeSlots = [] as TimeSlotModel[]): CalendarEvent[] {
    const formattedTimeSlots = [] as CalendarEvent[];
    timeSlots.forEach((timeSlot) => {
      const start = moment().clone().utc();
      const { wday, hour, minute, isBttp, isUser } = timeSlot;
      Number.isInteger(wday) && start.day(wday);
      Number.isInteger(hour) && start.hour(hour);
      Number.isInteger(minute) && start.minute(minute);

      start.tz(this.calendarConfig.timeZoneIdentifier);

      const baseEvent = {
        allDay: false,
        daysOfWeek: [start.day()],
        durationEditable: false,
        id: timeSlot.id,
        isBttp,
        isUser,
        profileColorClass: timeSlot.socialProfile.get('profileColorClass'),
        startTime: start.format('HH:mm'),
        stick: true
      };

      const bttpEvent = {
        display: 'background',
        eventType: BTTP
      };

      const userEvent = {
        start: start.format(),
        backgroundColor: '#ddd',
        editable: Boolean(timeSlot.id),
        eventType: TIME_SLOT
      };

      if (!timeSlot.isDeleted) {
        const eventAttributes = isBttp ? bttpEvent : userEvent;
        const event = Object.assign(baseEvent, eventAttributes);
        formattedTimeSlots.push(CalendarEvent.create(event));
      }
    });

    return formattedTimeSlots;
  }

  #getInitialDate(start: Date): Date {
    return new Date(start.getTime());
  }

  prefetch = dropTask(async (start: Date) => {
    // Note:  Note: #getInitialDate needed for each variable declaration as functions in shared/utils/time mutate the Date object
    const prefetchStart = days(-7, this.#getInitialDate(start));
    const prefetchEnd = days(14, this.#getInitialDate(start));

    // Note: Prefetch events for the previous and next week views
    await this.getEvents.perform({} as CachedData, {
      start: prefetchStart,
      end: prefetchEnd,
      isPrefetch: true
    });
  });

  // Note: Story calendar doesn't need to fetch or display calendar-notes
  fetchOrIgnoreNotes = task(async (cacheNotes: Maybe<CalendarNoteModel[]>, fetchInfo: FetchRecordsParams) => {
    if (this.calendarConfig.calendarType === STORIES) {
      return [];
    }
    return await this.fetchWithinDates.fetchRecords.perform(cacheNotes, FetchableRecordTypes.Notes, fetchInfo);
  });

  getEvents = restartableTask(async (cachedData = {} as CachedData, fetchInfo = this.defaultFetchInfo) => {
    const { isPrefetch } = fetchInfo;
    const { cacheNotes, cachePosts, cacheBttpTimeSlots, cacheTimeSlots } = cachedData;

    // Note: If events have already been fetched, use data from store instead of making a request
    const posts = await this.fetchWithinDates.fetchRecords.perform(cachePosts, FetchableRecordTypes.Posts, fetchInfo);
    const notes = await this.fetchOrIgnoreNotes.perform(cacheNotes, fetchInfo);
    const userTimeSlots = cacheTimeSlots || this.userTimeSlots;

    // Note: Do not return events if this is a prefetch
    if (isPrefetch) {
      return [];
    }

    const filteredPosts = isGramList(posts) ? this.#filterPosts(posts, fetchInfo) : [];
    const filteredNotes = isNotesList(notes) ? this.#filterNotes(notes) : [];

    const sortedFilteredPosts = filteredPosts.sort(
      (firstPost: GramModel, secondPost: GramModel) => (firstPost.scheduledTime || -1) - (secondPost.scheduledTime || 1)
    );
    this.postNavigation.setPosts(sortedFilteredPosts);

    const bttpSlotEvents = this.#formatTimeSlotsAsEvents(cacheBttpTimeSlots);
    const postEvents = this.#formatPostsAsEvents(filteredPosts);
    const noteEvents = this.#formatNotesAsEvents(filteredNotes);
    const userSlotEvents = this.#formatTimeSlotsAsEvents(userTimeSlots);

    // Note: Only return post and note events for month view
    if (this.calendarConfig.fullCalendar?.view.type === MONTH) {
      return [postEvents, noteEvents].flat();
    }

    const events = [postEvents, noteEvents, userSlotEvents].flat();

    if (
      isPresent(this.auth.currentGroup?.socialProfiles) &&
      this.calendarConfig.calendarView === WEEK &&
      notes.length === 0 &&
      !this.hasSeenNotesPromo
    ) {
      this.#addNotesPromoEvent(events);
    }

    // Note: Only return bttp and bttp promo events in the event that the user's social profile is valid and multiple profiles are not selected
    if (this.multipleProfilesSelected || !this.bttp.isValidSocialProfile) {
      return events.flat();
    }

    const eventsWithBttp = [...events, bttpSlotEvents].flat();
    if (this.calendarConfig.calendarView === WEEK && this.shouldShowBttpPromo && isEmpty(bttpSlotEvents)) {
      this.#addBttpPromoEvent(eventsWithBttp);
    }
    return eventsWithBttp;
  });
}

declare module '@ember/service' {
  interface Registry {
    'calendar/events': CalendarEventsService;
  }
}
