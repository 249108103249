import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { task } from 'ember-concurrency';

import { TEXT_POST_TYPE } from 'later/utils/constants';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import { timestamp } from 'later/utils/time-format';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type SocialIdentityModel from 'later/models/social-identity';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type { Transition } from 'shared/types';

export default class FeatureRoute extends Route {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare intl: IntlService;
  @service declare errors: ErrorsService;
  @service declare router: RouterService;
  @service declare store: Store;

  get group(): undefined | GroupModel {
    return this.auth.lastGroup;
  }

  get groupSlug(): undefined | string {
    return this.group?.slug;
  }

  get instagramProfile(): undefined | SocialProfileModel {
    return this.group?.instagramProfiles.firstObject;
  }

  get facebookProfile(): undefined | SocialProfileModel {
    return this.group?.facebookProfiles.firstObject;
  }

  get linkinbioPage(): undefined | LinkinbioPageModel {
    return this.group?.linkinbioPages.firstObject;
  }

  get tiktokProfile(): undefined | SocialProfileModel {
    return this.group?.tiktokProfiles.firstObject;
  }

  get pinterestProfile(): undefined | SocialProfileModel {
    return this.group?.pinterestProfiles.firstObject;
  }

  get socialIdentity(): undefined | SocialIdentityModel {
    const identity = this.group?.socialIdentities.firstObject;
    return identity;
  }

  redirect(_model: unknown, transition: Transition): void {
    const { feature } = transition.to.params;
    switch (feature) {
      case 'contributors':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.collect.submissions', this.groupSlug, 'inbox');
        }
        break;

      case 'add_profile_modal':
        if (this.group?.id) {
          this.router.transitionTo('account.groups.group.social_profiles.new', this.group.id);
        }
        break;

      case 'calendar':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.schedule.calendar', this.groupSlug);
        }
        break;

      case 'conversation':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.conversations', this.groupSlug);
        }
        break;

      case 'media_library':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.media', this.groupSlug);
        }
        break;

      case 'analytics':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.analytics', this.groupSlug);
        }
        break;

      case 'search_hashtags':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.collect', this.groupSlug);
        }
        break;

      case 'collect_mentions':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.collect.mentions', this.groupSlug);
        }
        break;

      case 'collect_tags':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.collect.tags', this.groupSlug);
        }
        break;

      case 'content_pillars':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.planner.content-pillars', this.groupSlug);
        }
        break;

      case 'draft_posts':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.schedule.list', this.groupSlug);
        }
        break;

      case 'pinterest_analytics':
        this._redirectToPinterestAnalytics();
        break;

      case 'preview':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.schedule.preview-grid', this.groupSlug);
        }
        break;

      case 'linkinbio':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.linkinbio', this.groupSlug);
        }
        break;

      case 'stories':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.schedule.stories', this.groupSlug);
        }
        break;

      case 'groups':
        this.router.transitionTo('account.groups');
        break;

      case 'hashtag_analytics':
        this.#redirectToInstagramAnalyticsFeature('hashtags');
        break;

      case 'linkinbio_analytics':
        this._redirectToLinkinbioAnalytics.perform();
        break;

      case 'stories_analytics':
        this.#redirectToInstagramAnalyticsFeature('stories');
        break;

      case 'posts_analytics':
        this.#redirectToInstagramAnalyticsFeature('posts');
        break;

      case 'audience_analytics':
        this.#redirectToInstagramAnalyticsFeature('audience');
        break;

      case 'reels_analytics':
        this.#redirectToInstagramAnalyticsFeature('reels');
        break;

      case 'tiktok_analytics':
        this._redirectToTiktokAnalytics();
        break;

      case 'tiktok_posts_analytics':
        this.#redirectToTiktokAnalyticsFeature('posts');
        break;

      case 'tiktok_audience_analytics':
        this.#redirectToTiktokAnalyticsFeature('audience');
        break;

      case 'facebook_posts_analytics':
        this.#redirectToFacebookAnalyticsFeature('posts');
        break;

      case 'search_profile':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.collect.ig-profile', this.groupSlug);
        }
        break;

      case 'linkinbio_gallery':
        this._redirectToLinkinbioEmbed();
        break;

      case 'linkinbio_settings':
        this._redirectToLinkinbioSettings.perform();
        break;

      case 'linkinbio_style':
        this._redirectToLinkinbioStyleBlock.perform();
        break;

      case 'linkinbio_tiktok':
        this._redirectToLinkinbioTiktok();
        break;

      case 'media_kit':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.media-kit', this.groupSlug);
        }
        break;

      case 'partnerships':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.partnerships', this.groupSlug);
        }
        break;

      case 'partnership':
        if (this.groupSlug && this.socialIdentity) {
          this.router.transitionTo('cluster.partnerships.profile', this.groupSlug, this.socialIdentity.id);
        }
        break;

      case 'post_modal':
        this.#redirectToPostModal();
        break;

      case 'list_posts':
        if (this.groupSlug) {
          this.router.transitionTo('cluster.schedule.list', this.groupSlug);
        }
        break;

      case 'devices':
        this.router.transitionTo('account.devices');
        break;

      case 'high_performing_post': {
        this.#redirectToHighPerformingPost(transition);
        break;
      }

      case 'post_review': {
        this.#redirectToPostReview(transition);
        break;
      }

      case 'social_listening': {
        if (this.auth.currentAccount.canSocialListening && this.groupSlug) {
          this.router.transitionTo('cluster.social-listening', this.groupSlug);
        }
        break;
      }

      case 'users': {
        this.router.transitionTo('account.users');
        break;
      }

      default:
        this.router.transitionTo('/');
    }
  }

  _loadGroupLinkinbioPages = task(async () => {
    if (this.group) {
      await objectPromiseProxy(this.group.get('linkinbioPages'));
    }
  });

  _redirectToLinkinbioAnalytics = task(async () => {
    await this._loadGroupLinkinbioPages.perform();

    if (this.linkinbioPage && this.groupSlug) {
      this.router.transitionTo(
        'cluster.analytics.profile.linkinbio.overview',
        this.groupSlug,
        this.linkinbioPage.get('slug')
      );
    } else if (this.groupSlug) {
      this.alerts.warning(this.intl.t('alerts.analytics.errors.no_lib_page_analytics'));
      this.router.transitionTo('cluster.linkinbio.setup', this.groupSlug);
    }
  });

  _redirectToLinkinbioSettings = task(async () => {
    await this._loadGroupLinkinbioPages.perform();

    if (this.linkinbioPage && this.groupSlug) {
      this.router.transitionTo('cluster.linkinbio.page.settings', this.groupSlug, this.linkinbioPage.get('slug'), {
        queryParams: { tab: 'settings' }
      });
    } else if (this.groupSlug) {
      this.router.transitionTo('cluster.linkinbio.setup', this.groupSlug);
    }
  });

  _redirectToLinkinbioStyleBlock = task(async () => {
    await this._loadGroupLinkinbioPages.perform();

    if (this.linkinbioPage && this.groupSlug) {
      this.router.transitionTo('cluster.linkinbio.page.style', this.groupSlug, this.linkinbioPage.get('slug'));
    } else if (this.groupSlug) {
      this.router.transitionTo('cluster.linkinbio.setup', this.groupSlug);
    }
  });

  #redirectToPostModal(): void {
    if (this.group?.socialProfileCount && this.group.socialProfileCount >= 1 && this.groupSlug) {
      this.router.transitionTo('cluster.schedule.calendar.post.new', this.groupSlug, TEXT_POST_TYPE, {
        queryParams: {
          scheduledTime: timestamp()
        }
      });
    } else if (this.groupSlug) {
      this.alerts.warning(this.intl.t('app.connect_social_profile'));
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  #redirectToHighPerformingPost(transition: Transition): void {
    if (transition.to.queryParams?.trigger === 'comment_count') {
      this._redirectToHighPerformingPostComments.perform(transition);
      return;
    }
    const { post_id } = transition.to.queryParams;
    if (post_id && this.group?.socialProfileCount && this.group.socialProfileCount >= 1 && this.groupSlug) {
      this.router.transitionTo('cluster.schedule.calendar.post.edit', this.groupSlug, post_id);
    } else if (this.groupSlug) {
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  _redirectToHighPerformingPostComments = task(async (transition: Transition) => {
    if (!this.groupSlug) {
      return this.router.transitionTo('user.login');
    }

    const { post_id } = transition.to.queryParams;
    if (!post_id) {
      return this.router.transitionTo('cluster.conversations', this.groupSlug);
    }

    try {
      let post = this.store.peekRecord('gram', post_id);
      if (!post) {
        post = await this.store.findRecord('gram', post_id);
      }

      const profileUrlName = post?.get('socialProfile')?.get('urlName');
      const mediaId = post?.mediaId;

      if (profileUrlName && mediaId) {
        return this.router.transitionTo(
          'cluster.conversations.profile.posts.post',
          this.groupSlug,
          profileUrlName,
          mediaId
        );
      }

      return this.router.transitionTo('cluster.conversations', this.groupSlug);
    } catch (error) {
      this.errors.log('featurelink error- High Performing Post Comments', error);
      return this.router.transitionTo('cluster.conversations', this.groupSlug);
    }
  });

  #redirectToPostReview(transition: Transition): void {
    const { post_id } = transition.to.queryParams;
    if (post_id && this.group?.socialProfileCount && this.group.socialProfileCount >= 1 && this.groupSlug) {
      this.router.transitionTo('cluster.schedule.calendar.post.edit', this.groupSlug, post_id);
    } else if (this.groupSlug) {
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  #redirectToInstagramAnalyticsFeature(feature: string): void {
    if (this.instagramProfile && this.groupSlug) {
      this.router.transitionTo(
        `cluster.analytics.profile.instagram.${feature}`,
        this.groupSlug,
        this.instagramProfile.urlName
      );
    } else if (this.groupSlug) {
      this.alerts.warning(
        this.intl.t('alerts.analytics.errors.no_ig_profiles_analytics', { feature: capitalize(feature) })
      );
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  #redirectToFacebookAnalyticsFeature(feature: string): void {
    if (this.facebookProfile && this.groupSlug) {
      this.router.transitionTo(
        `cluster.analytics.profile.facebook.${feature}`,
        this.groupSlug,
        this.facebookProfile.urlName
      );
    } else if (this.groupSlug) {
      this.alerts.warning(
        this.intl.t('alerts.analytics.errors.no_fb_profiles_analytics', { feature: capitalize(feature) })
      );
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  #redirectToTiktokAnalyticsFeature(feature: string): void {
    if (this.tiktokProfile && this.groupSlug) {
      this.router.transitionTo(
        `cluster.analytics.profile.tiktok.${feature}`,
        this.groupSlug,
        this.tiktokProfile.urlName
      );
    } else if (this.groupSlug) {
      this.alerts.warning(
        this.intl.t('alerts.analytics.errors.no_tiktok_profile_analytics', { feature: capitalize(feature) })
      );
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  _redirectToPinterestAnalytics(): void {
    if (this.pinterestProfile && this.groupSlug) {
      this.router.transitionTo(
        'cluster.analytics.profile.pinterest.overview',
        this.groupSlug,
        this.pinterestProfile.urlName
      );
    } else if (this.groupSlug) {
      this.alerts.warning(this.intl.t('alerts.analytics.errors.no_pinterest_profile_analytics'));
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  _redirectToTiktokAnalytics(): void {
    if (this.tiktokProfile && this.groupSlug) {
      this.router.transitionTo('cluster.analytics.profile.tiktok.overview', this.groupSlug, this.tiktokProfile.urlName);
    } else if (this.groupSlug) {
      this.alerts.warning(this.intl.t('alerts.analytics.errors.no_tiktok_profile_analytics'));
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  _redirectToLinkinbioEmbed(): void {
    if (this.instagramProfile && this.groupSlug) {
      this.router.transitionTo('cluster.linkinbio.page.embed', this.groupSlug, this.instagramProfile.urlName);
    } else if (this.groupSlug) {
      this.alerts.alert(
        this.intl.t('alerts.instagram_explore.auth.need_instagram.message', {
          feature: this.intl.t('shared_words.linkinbio')
        }),
        {
          title: this.intl.t('alerts.instagram_explore.auth.need_instagram.title')
        }
      );
      this.router.transitionTo('cluster.schedule', this.groupSlug);
    }
  }

  _redirectToLinkinbioTiktok(): void {
    const { tiktokProfile } = this;

    if (tiktokProfile && this.groupSlug) {
      this.router.transitionTo('cluster.linkinbio.page', this.groupSlug, tiktokProfile.urlName);
    } else if (this.groupSlug) {
      this.alerts.alert(this.intl.t('alerts.linkinbio.get_started_tiktok.no_tiktok_warning.message'), {
        title: this.intl.t('alerts.linkinbio.get_started_tiktok.no_tiktok_warning.title')
      });
      this.router.transitionTo('cluster.linkinbio', this.groupSlug);
    }
  }
}
