import EmberObject from '@ember/object';
import classic from 'ember-classic-decorator';

import type { Maybe } from 'shared/types';

@classic
export default class CalendarEvent extends EmberObject {
  title = '';
  icon: Maybe<string> = null;
  start: Maybe<string> = null;
  stick = true;
  allDay = false;
  eventType = 'post';
}
