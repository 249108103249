import Service from '@ember/service';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import uuid from 'shared/utils/uuid';

export default class AdBlockerService extends Service {
  /**
   * Whether a user's ad blocker is enabled in the current session
   */
  #isEnabled = false;

  /**
   * A unique identifier for the script used
   * to test whether an ad blocker is present
   */
  #testScriptId = `ad-blocker-test-script-id-${uuid()}`;

  /**
   * When the ad blocker service is destroyed, cleans
   * up the test elements if they are present
   */
  willDestroy(): void {
    document.getElementById('ad-blocker-test-div')?.remove();
    document.getElementById(this.#testScriptId)?.remove();
  }

  /**
   * Calls the #setIsEnabled method and awaits
   * before returning the final this.#isEnabled
   * boolean.
   */
  isAdblockEnabled = async (): Promise<boolean> => {
    await this.#setIsEnabled();
    return this.#isEnabled;
  };

  /**
   * Creates a test script with `#testScriptId` that
   * loads ads.js, and if the ad blocker blocks the
   * script resolves to true.
   *
   * Uses a method similar to https://www.detectadblock.com/
   */
  createTestScript = (id: string): Promise<boolean> => {
    // Note: Need to add base URL for loading script in tests
    const isTestEnvironment = config.environment === 'test';
    const baseURL = window.location?.origin;

    return new RSVP.Promise((resolve) => {
      const newScriptElement = document.createElement('script');
      newScriptElement.id = this.#testScriptId;
      newScriptElement.classList.add('ad-blocker-test-script');
      newScriptElement.type = 'text/javascript';
      newScriptElement.src = baseURL && isTestEnvironment ? baseURL + '/assets/ads.js' : '/assets/ads.js';
      newScriptElement.onload = () => resolve(!document.getElementById(id));
      newScriptElement.onerror = () => resolve(true);

      const firstScriptElement = document.getElementsByTagName('script')[0];
      firstScriptElement.parentNode?.insertBefore(newScriptElement, firstScriptElement);
    });
  };

  /**
   * Sets the `#isEnabled` property by creating a test
   * script that activates the ad blocker.
   */
  #setIsEnabled = async (): Promise<void> => {
    try {
      this.#isEnabled = await this.createTestScript('ad-blocker-test-div');
    } catch {
      this.#isEnabled = true;
    }
  };
}

declare module '@ember/service' {
  interface Registry {
    'ad-blocker': AdBlockerService;
  }
}
