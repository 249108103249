/* eslint-disable no-undef */
import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import NProgress from 'nprogress';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import { MediaUploadError } from 'later/errors/index';
import { isEnv } from 'later/utils/is-env';
import { MimeType } from 'shared/types/image';
import { base64toBlob } from 'shared/utils/file';

import type IntlService from 'ember-intl/services/intl';
import type AlertsService from 'later/services/alerts';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';
import type MediaItemUploadService from 'later/services/media-item-upload';

export type GoogleDriveFile = google.picker.DocumentObject & { sizeBytes: number };

/**
 * This service handles fetching media from Google Drive for upload into Later's Amazon S3 buckets.
 */
export default class GooglePickerService extends Service {
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare laterConfig: LaterConfigService;
  @service declare mediaItemUpload: MediaItemUploadService;

  #appId = config.APP.googleAppId as string;
  #discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

  _scopes = ['https://www.googleapis.com/auth/drive.file'];

  @tracked pickerApiLoaded = false;
  @tracked oauthToken?: string = undefined;

  get scopes(): string {
    return this._scopes.join(' ');
  }

  get isLocalDevelopment(): boolean {
    // Note: Disable in dev to prevent errors due to non-whitelisted domain
    return isEnv('development');
  }

  /**
   * Setup and load Google picker API
   */
  async loadPicker(): Promise<void> {
    if (this.isLocalDevelopment) {
      return;
    }

    try {
      await this.#loadGoogle();
      gapi.load('client:auth2', this.#initClient.bind(this));
      gapi.load('picker', () => {
        this.pickerApiLoaded = true;
      });
    } catch (error) {
      const url = 'http://status.later.com/';
      const link = htmlSafe(
        `<a href="${url}">${this.intl.t('alerts.media_items.new.google_drive_not_available_link')}</a>`
      );
      const message = this.intl.t('alerts.media_items.new.google_drive_not_available', { link });
      this.alerts.warning(htmlSafe(message));
    }
  }

  /**
   * Open a Google picker widget
   */
  async createPicker(): Promise<void> {
    if (this.isLocalDevelopment) {
      this.alerts.info(this.intl.t('alerts.media_items.new.google_drive_disabled_local'));
      return;
    }

    if (!this.pickerApiLoaded) {
      return;
    }

    if (!this.oauthToken) {
      await this.#googleAuth();
    }

    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId(this.#appId)
      // Note: oauthToken will be defined if #googleAuth is successful
      .setOAuthToken(this.oauthToken as string)
      .addView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
      .setDeveloperKey(this.laterConfig.googleApiKey)
      .setCallback(this.#pickerCallback.bind(this))
      .build();
    picker.setVisible(true);
  }

  /**
   * Callback when a file is selected from picker
   */
  async #pickerCallback(data: google.picker.ResponseObject): Promise<void> {
    try {
      if (data.action == google.picker.Action.PICKED && data.docs) {
        NProgress.start();
        const promises = data.docs.map((file: GoogleDriveFile) => this.#uploadFile(file));
        const results = await RSVP.allSettled(promises);
        results.forEach((result) => {
          if (result?.state === 'rejected') {
            if (result.reason instanceof MediaUploadError) {
              result.reason.resolve.call(this, result.reason);
            } else {
              this.alerts.warning(
                result.reason?.length
                  ? result.reason
                  : this.intl.t('alerts.media_items.new.google_drive_download_failed.message'),
                {
                  title: this.intl.t('alerts.media_items.new.google_drive_download_failed.title')
                }
              );
            }
          }
        });
      }
    } finally {
      NProgress.done();
    }
  }

  /**
   * Process and upload drive file to media library.
   */
  async #uploadFile(file: GoogleDriveFile): Promise<void> {
    const mediaError = this.mediaItemUpload.getMediaError(file);
    if (mediaError) {
      throw mediaError;
    }

    if (file.serviceId === 'picasa') {
      const thumbnailUrl = file.thumbnails[0].url;
      const url = thumbnailUrl.replace(/\/s\S+?\//g, '/s0/');
      const blob = await this.#downloadMedia(url);
      return this.mediaItemUpload.uploadMediaItem(blob);
    }

    if (this.#isMimeType(file.mimeType)) {
      const binary = await this.#getFileBinary(file.id);
      const blob = base64toBlob(binary, file.mimeType);
      return this.mediaItemUpload.uploadMediaItem(blob);
    }

    this.errors.log(new Error(`Google drive file type ${file.mimeType} is not supported`));
    this.alerts.warning(this.intl.t('alerts.media_items.new.google_drive_file_type_support.message'), {
      title: this.intl.t('alerts.media_items.new.google_drive_file_type_support.title'),
      actionText: this.intl.t('alerts.media_items.new.google_drive_file_type_support.action_text'),
      action: () =>
        window.open(
          'https://help.later.com/hc/en-us/articles/360043361213-Uploading-Media-Format-Requirements',
          '_blank'
        )
    });
    return;
  }

  /**
   * Type guard for MimeType enum
   */
  #isMimeType(value: string): value is MimeType {
    return Object.values<string>(MimeType).includes(value);
  }

  /**
   * Get a drive file's binary data.
   */
  #getFileBinary(fileId: string): Promise<string> {
    return new RSVP.Promise((resolve) =>
      gapi.client.drive.files
        .get({
          fileId,
          alt: 'media'
        })
        .then((data) => resolve(data.body))
    );
  }

  #downloadMedia(downloadUrl: string): Promise<Blob> {
    return new RSVP.Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', downloadUrl, true);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject();
          }
        }
      };
      xhr.send(null);
    });
  }

  /**
   * Callback when Google client api loaded
   */
  #initClient(): void {
    gapi.client.init({
      apiKey: this.laterConfig.googleApiKey,
      clientId: this.laterConfig.googleClientID,
      discoveryDocs: this.#discoveryDocs,
      scope: this.scopes
    });
  }

  /**
   * Load Required Google Drive libraries
   */
  async #loadGoogle(): Promise<void> {
    return new RSVP.Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';

      script.onload = () => resolve();
      script.onerror = () => reject();

      document.querySelector('head')?.appendChild(script);
    });
  }

  /**
   * Initiate Oauth2 with Google
   */
  async #googleAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      gapi.auth.authorize(
        {
          client_id: this.laterConfig.googleClientID,
          scope: this.scopes,
          immediate: false
        },
        (authResult) => {
          if (authResult && !authResult.error) {
            this.oauthToken = authResult.access_token;
            resolve();
          } else {
            this.errors.log(authResult.error || new Error('Google Drive auth failed'));
            this.alerts.warning(this.intl.t('alerts.media_items.new.google_drive_connection_failed.message'), {
              title: this.intl.t('alerts.media_items.new.google_drive_connection_failed.title')
            });
            reject();
          }
        }
      );
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'google-picker': GooglePickerService;
  }
}
