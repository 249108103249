import { createHash } from 'crypto-browserify';

/**
 * Generates finds a "counter" which combined with username, pass, and auth token generates a SHA256 token with a specific prefix.
 * This will take around 200ms to compute, but can be sent up to the server for immediate validation. This won't stop credential stuffing
 * attacks, but it will make it more troublesome for script kiddies due to the complexity and will chew up some CPU on client machine.
 * The example from the blog post contains its own SHA256 implementation, but we can use @aws-crypto/sha256-browser instead
 *
 * @method computeProofOfWork
 * @param {String} identification The user's identification
 * @param {String} password The user's password token
 * @param {String} authenticityToken The user's authenticity token from the server
 * @return {Number} counter value that generates a sha256 hash with the secret prefix
 * @see https://www.fastly.com/blog/defend-against-credential-stuffing-attacks-proof-of-work
 */
export default function computeProofOfWork(identification, password, authenticityToken) {
  const prefixpattern = '640';
  let counter = 0;
  let resulthash = '';

  while (!resulthash.startsWith(prefixpattern) && counter < 500000) {
    counter++;
    resulthash = createHash('sha256')
      .update(identification + password + authenticityToken + counter)
      .digest('hex');
  }
  return counter;
}
