import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { DialogType } from 'later/services/dialog-manager';

import type MutableArray from '@ember/array/mutable';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type DialogManagerService from 'later/services/dialog-manager';
import type SegmentService from 'later/services/segment';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type { Maybe, Transition, UntypedController } from 'shared/types';

export default class StoriesRoute extends Route {
  @service declare auth: AuthService;
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: StoreService;
  @service declare dialogManager: DialogManagerService;

  fallbackProfile: Maybe<SocialProfileModel>;

  get canInstagramStories(): boolean {
    return Boolean(this.auth.currentAccount.canInstagramStories);
  }

  beforeModel(): void {
    const socialProfile = this.selectedSocialProfiles.firstProfile;

    if (!socialProfile || !socialProfile.isInstagram || this.selectedSocialProfiles.hasMultipleSelected) {
      this.alerts.warning(this.intl.t('alerts.stories.only_with_instagram.message'), {
        title: this.intl.t('alerts.stories.only_with_instagram.title')
      });
      this.router.transitionTo('account.groups.group.social_profiles', this.auth.currentGroup.id);
      return;
    }

    this.fallbackProfile = socialProfile;

    this.segment.track('viewed-story-tab', {
      profile: socialProfile.nickname || ''
    });
  }

  model(): MutableArray<GramModel> {
    return this.store.peekAll('gram');
  }

  activate(): void {
    this.send('setSideLibraryBehavior', 'stories');
  }

  setupController(controller: UntypedController, model: GramModel[]): void {
    controller.set('model', model);
    controller.set('hasDevices', this.auth.hasDevices);
  }

  resetController(controller: UntypedController): void {
    controller.set('selectedStory', null);
    controller.set('startDay', null);
    controller.set('endDay', null);
  }

  deactivate(): void {
    this.send('setSideLibraryBehavior', null);
  }

  // Note: Async is a workaround here to make `this.controller.resetOpenStory` work until
  // https://latergramme.atlassian.net/browse/WHI-1104 is completed.
  @action
  async willTransition(transition: Transition): Promise<void> {
    const controller = this.controller as UntypedController;
    if (controller.get('hasUnsavedChanges') && !transition.to.name.startsWith('cluster.schedule.stories')) {
      // Note: Although this aborts the transition - this will not safely handle situations where
      // other functionality accompanies the transition (for example, selectedSocialProfiles.addProfileAndTransition)
      transition.abort();
      this.dialogManager
        .confirm(this.intl.t('shared_phrases.close_warning'), {
          description: this.intl.t('shared_phrases.changes_not_saved'),
          confirmButton: this.intl.t('shared_words.leave')
        })
        .then(async () => {
          await controller.resetOpenStory();
          // Note: If this transition is based on a user clicking a calendar/social-profiles/avatar
          // it may trigger #checkValidProfiles in the stories calendar
          transition.retry();
        })
        .catch(() => {
          // Note: If the dialog was prompted by selecting a new social profile, and the dialog was
          // then cancelled - we need to reset the selectedSocialProfiles to the original state and
          // prevent the transition from happening. We use selectExactly here as we do not want to trigger a
          // transition by using selectSingleProfile
          if (this.fallbackProfile) {
            this.selectedSocialProfiles.selectExactly([this.fallbackProfile]);
            this.dialogManager.rejectPromise(DialogType.Confirmation);
          }
        });
    }
  }
}
