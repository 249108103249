/**
 * @module Helpers
 */

/**
 * @class LaterHelpers
 * @extends Helpers
 */

/**
 * Converts a caption into a clickable hashtag or clickable user
 *
 * @method clickable-hashtags
 * @param {Undefined} [params]  Unused empty array required for helper declaration
 * @param {Any} caption All arguments passed to this method
 * @param {Boolean} [clickable] Whether or not the caption is clickable
 *
 * @return {String} Formatted caption for display
 */

import { helper as buildHelper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

export default buildHelper((params, { caption, clickable = true }) => {
  const tag = clickable ? 'a' : 'span';
  let sanitizedCaption = caption.replace(/#[\w\d_]+/g, (hashtag) => {
    const attr = hashtag.match(/[\w\d_]+/g);
    return `<${tag} class='clickable_hashtag' data-hashtag='${attr}'>${hashtag}</${tag}>`;
  });
  sanitizedCaption = sanitizedCaption.replace(/@[a-zA-Z0-9_\.]{1,30}/g, (mention) => {
    let matching_mention = mention.toLowerCase(); // can't search usernames that have capital letters
    if (matching_mention[matching_mention.length - 1] === '.') {
      // if there is a period immediately after the mention (IG usernames can include . just not as the last character)
      matching_mention = matching_mention.slice(0, -1);
    }
    const attr = matching_mention.match(/[a-zA-Z0-9_\.]{1,30}/g);
    return `<${tag} class='clickable_user' data-user='${attr}'>${mention}</${tag}>`;
  });
  return new htmlSafe(sanitizedCaption);
});
