import { attr, belongsTo } from '@ember-data/model';

import GenericAttachment from 'later/models/generic-attachment';

import type Campaign from 'later/models/campaign';

export default class PartnershipsAttachmentModel extends GenericAttachment {
  /**
   * Fe only, set when user first upload the image
   */
  @attr() declare previewUrl?: string;

  @belongsTo('campaign') declare campaign: Campaign;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partnerships-attachment': PartnershipsAttachmentModel;
  }
}
