import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import { fetch } from 'later/utils/fetch';
import loadScript from 'later/utils/load-script';
import getCountryList from 'shared/utils/country-list';

import type {
  Stripe as StripeClient,
  StripeCardElement,
  Token,
  StripePaymentElement,
  StripeElementsOptionsMode,
  StripePaymentElementOptions,
  StripeElements,
  SetupIntent,
  ConfirmationToken,
  PaymentMethod
} from '@stripe/stripe-js';
import type IntlService from 'ember-intl/services/intl';
import type DatadogService from 'later/services/datadog';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';
import type SegmentService from 'later/services/segment';
import type { JsonObject } from 'type-fest';

/**
 *
 * This service exists as an Interface for the Stripe JS SDK.
 * Details of the SDK can be found here https://stripe.com/docs/stripe-js/reference.
 *
 * @class StripeService
 * @extends Service
 */

export default class StripeService extends Service {
  @service declare laterConfig: LaterConfigService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare segment: SegmentService;
  @service declare datadog: DatadogService;

  card?: StripeCardElement = undefined;
  client?: StripeClient = undefined;

  get stripePublishableKey(): string {
    return this.laterConfig.stripePublishableKey;
  }

  /**
   * Creates a "Stripe Elements" credit card input box
   * which is injected into the supplied HTML element
   *
   * @method createCard
   * @param elementString A string to identify the HTML element
   * which will be used to create a Stripe Card Input. Ex: `#stripe-card-div`
   *
   * @returns The generated Stripe SDK Card
   */
  async createCard(elementString: string): Promise<StripeCardElement | undefined> {
    await this._setup();

    if (!this.client) {
      return;
    }

    const style = {
      base: {
        fontSize: '1rem',
        lineHeight: '16px'
      }
    };

    const card = this.client.elements().create('card', { style });
    this.card = card;

    card.mount(elementString);

    return card;
  }

  /**
   * Creates a "Stripe Payment Element"  input box
   * which is injected into the supplied HTML element
   *
   * @method createStripePaymentElement
   * @param elementString A string to identify the HTML element
   * which will be used to create a Stripe Card Input. Ex: `#stripe-payment-element-div`
   *
   * @returns The Stripe Elements object and the Stripe Payment Element
   */
  async createStripePaymentElement(args: {
    elementString: string;
    //Note: amount should be the amount charged immediately. For trials, it is 0. for non-trials, this may need to be updated.
    amount: number;
  }): Promise<{ elements?: StripeElements; paymentElement?: StripePaymentElement | undefined }> {
    const { elementString, amount } = args;

    await this._setup();

    if (!this.client) {
      return {};
    }

    const options: StripeElementsOptionsMode = {
      //mode: 'subscription',
      mode: 'setup',
      amount,
      currency: 'usd',
      paymentMethodCreation: 'manual',
      //Note: in the future, this will include apple_pay and google_pay
      paymentMethodTypes: ['card'],
      appearance: {
        variables: {
          // Note: temporarily setting to blue. should be set to each plan colour.
          // (Not required until we implement wallets)
          colorPrimary: '#1bc3fe',
          // Note: fontFamily is same as $FONT_FAMILY_SANS_SERIF
          borderRadius: '3px',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontSizeBase: '13px',
          fontSmooth: 'always',
          spacingGridRow: '6.5px'
        },
        labels: 'floating',
        rules: {
          '.Input': {
            padding: '4px 13px',
            boxShadow: 'unset'
          },
          '.Input:focus': {
            boxShadow: 'inset 0 0 0 1px #dce4ec, 0 0 0 1px #fff, 0 0 0 3px rgba(50, 59, 67, 0.3)',
            borderColor: 'transparent',
            outline: '0'
          },
          '.Input--empty::placeholder': {
            color: '#BBCBDA'
          },
          '.Input--invalid': {
            boxShadow: 'none',
            marginBottom: '9.75px'
          },
          '.Label--resting': {
            color: '#BBCBDA',
            fontWeight: '500',
            opacity: '1'
          },
          '.Error': {
            marginBottom: '3.25px'
          }
        }
      }
    };

    const paymentElementOptions: StripePaymentElementOptions = {
      wallets: {
        applePay: 'never',
        googlePay: 'never'
      },
      fields: {
        billingDetails: {
          address: {
            country: 'auto',
            postalCode: 'auto'
          }
        }
      },
      // Note: needed in order to make sure google pay doesn't just say 'Pay Later'
      business: { name: 'Later Social' },
      //Note: removes the text By providing your card information, you allow Later Social to charge your card for future payments in accordance with their terms.
      terms: { card: 'never', googlePay: 'never', applePay: 'never' }
    };

    const elements = this.client.elements(options);

    // Create and mount the Payment Element
    const paymentElement = elements.create('payment', paymentElementOptions);
    paymentElement.mount(elementString);

    return { elements, paymentElement };
  }

  /**
   * Create Confirmation Token
   *
   * Use to get information about the payment method before final confirmation.
   */
  async createConfirmationToken(elements: StripeElements): Promise<ConfirmationToken> {
    if (!this.client) {
      assert('client must be set');
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw new Error(submitError?.message);
    }

    const { error, confirmationToken } = await this.client.createConfirmationToken({ elements });
    if (error) {
      this.errors.log('error creating payment method' + JSON.stringify(error));
      throw new Error(error?.message);
    }

    return confirmationToken;
  }

  /**
   * Create Setup Intent
   */
  async #createSetupIntent(confirmationToken: string): Promise<SetupIntent> {
    const response: { setup_intent: SetupIntent } = await fetch('/api/v2/create_setup_intent', {
      method: 'POST',
      body: {
        confirmation_token: confirmationToken
      }
    });
    return response.setup_intent;
  }

  /**
   *
   * Creates a payment method from stripe and runs 3ds when required
   *
   * Returns a payment method id that can be used to create a new subscription or update a customer
   */
  async confirmPaymentMethod(elements: StripeElements, location: 'checkout' | 'settings'): Promise<PaymentMethod> {
    if (!this.client) {
      assert('client must be set');
    }

    this.datadog.addAction('confirmPaymentMethod: started', { location });

    //Note: will run elements.submit()
    const confirmationToken = await this.createConfirmationToken(elements);

    const { card, billing_details } = confirmationToken.payment_method_preview;

    if (card?.country && card.country !== billing_details.address?.country) {
      const countries = getCountryList(this.intl);
      const suggestedCountry = countries.findBy('code', card.country)?.name ?? card.country;

      this.datadog.addAction('confirmPaymentMethod: country-mismatch', { location });
      throw new Error(this.intl.t('checkout.payment.country_mismatch_error', { suggestedCountry }));
    }

    const setupIntent = await this.#createSetupIntent(confirmationToken.id);
    this.datadog.addAction('confirmPaymentMethod: createSetupIntent', { location, status: setupIntent.status });
    if (!setupIntent.client_secret || !setupIntent.payment_method) {
      this.errors.log('setupIntent failed to setup correctly', { setupIntent: setupIntent as unknown as JsonObject });
      throw new Error(this.intl.t('alerts.account.subscription.billing.update_card.error'));
    }

    //Note: No 3ds required
    if (setupIntent.status != 'requires_action') {
      this.datadog.addAction('confirmPaymentMethod: no-3ds-required', { location });
      return {
        id: setupIntent.payment_method as string,
        ...confirmationToken.payment_method_preview
      } as PaymentMethod;
    }

    //Note: 3ds required:
    this.segment.track('Stripe-3ds-opened', { location });

    //Note: BE creates setup intent with confirm: true, so we need to use handleNextAction instead of confirmSetup
    const { error } = await this.client.handleNextAction({
      clientSecret: setupIntent.client_secret
    });

    if (error) {
      this.errors.log('error confirming payment method' + JSON.stringify(error));
      this.segment.track('Stripe-3ds-actioned', { location, '3DS-status': 'failed attempt' });
      throw new Error(error?.message);
    }

    this.segment.track('Stripe-3ds-actioned', { location, '3DS-status': 'success' });

    return {
      id: setupIntent.payment_method as string,
      ...confirmationToken.payment_method_preview
    } as PaymentMethod;
  }

  /**
   * Generates a Stripe Credit Card Token for the supplied Card
   *
   * @method createToken
   * @param card A "Stripe Elements" card
   * which will be used to generate a token
   * @deprecated after rolloutPaymentMethods
   *
   * @returns The generated Stripe card token
   */
  async createToken(card: StripeCardElement): Promise<Token> {
    await this._setup();

    if (!this.client) {
      return RSVP.reject();
    }

    return new RSVP.Promise((resolve, reject) => {
      this.client?.createToken(card).then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          resolve(result.token);
        }
      });
    });
  }

  /**
   * Updates a User's stored Stripe Card using a supplied
   * Stripe Credit Card token.
   * @deprecated after rolloutPaymentMethods
   *
   * @method updateCard
   * @param token A Stripe Credit Card Token
   *
   */
  async updateCard(token: Token, eventProperties: { location: string; route?: string }): Promise<void> {
    const defaultEventProperties = {
      route: 'no_previous_prompt'
    };
    await this._setup();

    if (!this.client) {
      return;
    }

    const response = await fetch('/api/v2/update_stripe_card', {
      method: 'POST',
      body: {
        stripeToken: token.id
      }
    });
    this.segment.track('updated-credit-card', { ...defaultEventProperties, ...eventProperties });
    return response;
  }

  /**
   * Opens an on-page "checkout" that can be used to update Billing information.
   * Refer to Stripe Legacy Checkout Reference:
   * https://stripe.com/docs/legacy-checkout#integration-custom
   * @deprecated after rolloutPaymentMethods
   *
   * @method openCheckout
   * @param email The current billing email, used to automatically fill in the email field.
   *
   * @returns The newly generated Stripe Credit Card Token
   */
  async openCheckout(email: string): Promise<Token | undefined> {
    await this._setup();

    if (!this.client) {
      return RSVP.reject();
    }

    return new RSVP.Promise((resolve) => {
      const handler = StripeCheckout.configure({
        key: this.stripePublishableKey,
        locale: 'auto',
        token: (token: Token) => resolve(token)
      });

      handler.open({
        description: 'Update Credit Card',
        email,
        panelLabel: 'Update Credit Card',
        name: 'Later',
        label: 'Update Credit Card',
        zipCode: 'true'
      });

      window.addEventListener('popstate', () => {
        handler.close();
      });
    });
  }

  async _setup(): Promise<void> {
    await Promise.all([loadScript('https://js.stripe.com/v3/'), loadScript('https://checkout.stripe.com/checkout.js')]);
    if (!this.client && this.stripePublishableKey) {
      this.client = Stripe(this.stripePublishableKey);
    }
    return;
  }
}

declare module '@ember/service' {
  interface Registry {
    stripe: StripeService;
  }
}
