import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div>{{yield}}</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div>{{yield}}</div>","moduleName":"later/components/connection-status.hbs","parseOptions":{"srcName":"later/components/connection-status.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type IntlService from 'ember-intl/services/intl';
import type AlertsService from 'later/services/alerts';
import type { EmptyObject } from 'shared/types';

export default class ConnectionStatusComponent extends Component {
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;

  constructor(owner: unknown, args: EmptyObject) {
    super(owner, args);
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  willDestroy(): void {
    super.willDestroy();
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  @action
  handleConnectionChange(event: Event): void {
    if (event.type === 'offline') {
      this.alerts.warning(this.intl.t('alerts.app.offline_message'));
    } else if (event.type === 'online') {
      this.alerts.clear();
      this.alerts.success(this.intl.t('alerts.app.back_online_message'));
    }
  }
}
