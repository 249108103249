import Model, { attr } from '@ember-data/model';

import type { Survey } from 'influencers/types/influencer-campaigns';

export default class InfluencerCampaignApplicationModel extends Model {
  @attr('string', { defaultValue: '' }) declare description: string;
  @attr('string', { defaultValue: '' }) declare fulfillmentStage: string;
  @attr('string', { defaultValue: '' }) declare image: string;
  @attr('string', { defaultValue: '' }) declare locale: string;
  @attr('boolean', { defaultValue: false }) declare requestBrandedContentPermission: boolean;
  @attr('boolean', { defaultValue: false }) declare requireConcept: boolean;
  @attr('boolean', { defaultValue: false }) declare requireEthnicityQuestion: boolean;
  @attr('boolean', { defaultValue: false }) declare requireFacebook: boolean;
  @attr('boolean', { defaultValue: false }) declare requireInstagram: boolean;
  @attr('boolean', { defaultValue: false }) declare requirePhoneNumber: boolean;
  @attr('boolean', { defaultValue: false }) declare requirePinterest: boolean;
  @attr('boolean', { defaultValue: false }) declare requirePrivacyPolicy: boolean;
  @attr('boolean', { defaultValue: false }) declare requireQuote: boolean;
  @attr('boolean', { defaultValue: false }) declare requireStreetAddress: boolean;
  @attr('boolean', { defaultValue: false }) declare requireTiktok: boolean;
  @attr('boolean', { defaultValue: false }) declare requireMavely: boolean;
  @attr('boolean', { defaultValue: false }) declare requireTwitter: boolean;
  @attr('boolean', { defaultValue: false }) declare shareWithLater: boolean;
  @attr('string', { defaultValue: '' }) declare status: string;
  @attr({ defaultValue: undefined }) declare survey: Survey;
  @attr('string', { defaultValue: '' }) declare title: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'influencer-campaign-application': InfluencerCampaignApplicationModel;
  }
}
