import type { FileType } from 'later/services/media-item-upload';

/**
 * Checks file type and returns a boolean if file is a GIF
 */
export const isGif = (file: File): boolean => Boolean(file.type ? file.type.match(/gif/) : file.name.match(/\.(gif)/i));

/**
 * Checks file type and returns a boolean if file is an image
 */
export const isImage = (file: FileType): boolean => {
  if ('type' in file) {
    return Boolean(file.type.match(/image|photo/));
  }
  return Boolean(file.name.match(/\.(jpe?g|png|gif)/i));
};

/**
 * Checks file type and returns a boolean if file is a video
 */
export const isVideo = (file: FileType): boolean => {
  if ('type' in file) {
    return Boolean(file.type.match(/video/));
  }
  return Boolean(file.name.match(/\.(mp4|mov|wmv|avi|webm|mkv)/i));
};

export default { isGif, isImage, isVideo };
