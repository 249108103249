import { reads } from '@ember/object/computed';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

const MB = 1024 * 1024;
const TWITTER_IMAGE_MAX_SIZE_WITHOUT_REDUCED_QUALITY = 5 * MB;
const INSTAGRAM_IMAGE_MAX_SIZE_WITHOUT_REDUCED_QUALITY = 8 * MB;
const FACEBOOK_IMAGE_MAX_SIZE_WITHOUT_REDUCED_QUALITY = 10 * MB;

@classic
export default class ImageQualityReduced extends BaseValidator {
  @reads('model.autoPublish') isAutoPublish;
  @reads('model.mediaItem.isImage') isImage;
  @reads('model.isInstagram') isInstagram;
  @reads('model.isFacebook') isFacebook;
  @reads('model.isTwitter') isTwitter;
  @reads('model.mediaItem.publishingFileSize') publishingFileSize;

  validate() {
    if (this.isImage) {
      if (this.isTwitter && this.publishingFileSize > TWITTER_IMAGE_MAX_SIZE_WITHOUT_REDUCED_QUALITY) {
        return this.createErrorMessage('twitterImageReducedQualityWarning');
      } else if (
        this.isAutoPublish &&
        this.isInstagram &&
        this.publishingFileSize > INSTAGRAM_IMAGE_MAX_SIZE_WITHOUT_REDUCED_QUALITY
      ) {
        return this.createErrorMessage('instagramImageReducedQualityWarning');
      } else if (this.isFacebook && this.publishingFileSize > FACEBOOK_IMAGE_MAX_SIZE_WITHOUT_REDUCED_QUALITY) {
        return this.createErrorMessage('facebookImageReducedQualityWarning');
      }
    }
    return true;
  }
}
