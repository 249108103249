import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { difference } from 'lodash';

import { convert } from 'later/utils/time-format';

import type { UntypedService } from 'shared/types';
import type { JsonValue } from 'type-fest';

type LocalStorageContents = { [key: string]: JsonValue };

interface LocalStorageItem {
  value: JsonValue;
  expireBy: number;
}

const DEFAULT_EXPIRY_IN_HOURS = 24;
/**
 * This service can be used to save data with an associated expiration time to localStorage
 * When retrieving an item from localStorage, it returns null if the key does not exist
 * or is expired, otherwise, it returns the saved value.
 */
export default class LocalStorageManagerService extends Service {
  @service declare errors: UntypedService;

  /**
   * Creates object with Window.localStorage contents
   */
  @tracked contents = { ...localStorage };

  getContentsByPrefix(prefix: string): LocalStorageContents {
    const contents: LocalStorageContents = {};
    Object.entries(this.contents).forEach(([key, value]) => {
      if (key.includes(prefix)) {
        const keyMinusPrefix = key.replace(prefix, '');
        const object = JSON.parse(value);
        contents[keyMinusPrefix] = object.value;
      }
    });
    return contents;
  }

  /**
   * Returns all keys that exist in localStorage
   */
  getContentsKeys(): string[] {
    return Object.keys(this.contents);
  }

  /**
   * Replaces contents object with Window.localStorage object
   */
  updateContents(): void {
    this.contents = { ...localStorage };
  }

  /**
   * Sets a key to a hash object under a hash key.
   * Creates the object with value passed in,
   * and a timestamp in which it expires.
   * Calls updateContents to get latest localStorage contents.
   */
  setItem(key: string, value: JsonValue, expirationInHours: number = DEFAULT_EXPIRY_IN_HOURS): null | void {
    if (!this.#isSupported()) {
      return null;
    }

    try {
      const expirationInMilliseconds = convert.hours(expirationInHours).toMilliseconds();
      const localStorageObject: LocalStorageItem = { value, expireBy: Date.now() + expirationInMilliseconds };
      localStorage.setItem(key, JSON.stringify(localStorageObject));
      this.updateContents();
    } catch (error) {
      this.errors.log(`Could not add "${key}" to localStorage`, error);
    }
  }

  /**
   * Checks if item is expired then remove from localStorage,
   * Returns the saved value for a given key if it exists, otherwise return null.
   */
  getItem<T>(key: string): T | null {
    if (!this.#isSupported()) {
      return null;
    }
    try {
      const item = this.contents[key] === undefined ? null : JSON.parse(this.contents[key]);
      if (!this.#isLocalStorageManagerItem(item)) {
        return item && item.data ? item.data : item;
      }
      if (item.expireBy < Date.now()) {
        this.removeItem(key);
        return null;
      }
      return item.value;
    } catch (error) {
      this.errors.log(`Could not retrieve "${key}" from localStorage`, error);
      return null;
    }
  }

  /**
   * Removes key from localStorage.
   * Calls updateContents to get latest localStorage contents.
   */
  removeItem(key: string): null | void {
    if (!this.#isSupported()) {
      return null;
    }

    try {
      localStorage.removeItem(key);
      this.updateContents();
    } catch (error) {
      this.errors.log(`Could not remove "${key}" from localStorage`, error);
    }
  }

  /**
   * Checks if localStorage item has a value and expiredBy keys,
   * Returns true if localStorageItem is created with localStorageManager, otherwise returns false.
   */
  #isLocalStorageManagerItem(item?: LocalStorageItem | null): boolean {
    if (!item) {
      return false;
    }
    return isEmpty(difference(['value', 'expireBy'], Object.keys(item)));
  }

  /**
   * Returns true if item can be set in localStorage, otherwise returns false.
   */
  #isSupported(): boolean {
    const testKey = 'test_local_storage_item';
    try {
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'local-storage-manager': LocalStorageManagerService;
  }
}
