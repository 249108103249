import { inject as service } from '@ember/service';
import Command from 'command-palette/command/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import { getGroup } from 'later/utils/global-search/get-group';
import { getProfile } from 'later/utils/global-search/get-profile';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type { SearchCommandQuery } from 'command-palette/types';
import type AuthService from 'later/services/auth';

@command('navigate-to-analytics')
export class NavigateToAnalyticsCommand extends Command {
  @service declare auth: AuthService;
  @service declare store: StoreService;
  @service declare router: RouterService;

  public searchTerms = ['navigate', 'go to', 'analytics', 'stats', 'charts', 'graphs'];

  protected action(query: SearchCommandQuery): void | Promise<void> {
    const group = getGroup({ auth: this.auth });
    const profile = getProfile({ auth: this.auth, store: this.store, query });

    this.router.transitionTo('cluster.analytics.profile', profile.urlName || '', {
      queryParams: { slug: group.slug }
    });
  }

  public getTitle(): string {
    return `See Your Analytics`;
  }

  public getDescription(query: SearchCommandQuery): string {
    const profile = getProfile({ auth: this.auth, store: this.store, query });
    const displayName = profile?.displayName;

    return `Navigate to analytics for ${bold(displayName)}?`;
  }

  public getIcon(): string {
    return 'insights';
  }
}

export default NavigateToAnalyticsCommand;
