/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import { extractValues, filterTimeSeries } from 'later/utils/array-filters';

/**
 * @class FormattersTableTweetsService
 * @extends Service
 */
export default class TweetsService extends Service {
  @service('analytics/helpers-analytics') helpersAnalytics;
  @service('analytics/formatters/table') tableFormatters;

  /**
   * @property defaultRow
   * @type {Object}
   * @default
   */
  get defaultRow() {
    return {
      id: null,
      uid: null,
      url: null,
      isVideo: null,
      isTwitterCarousel: null,
      isDeleted: false,
      createdAt: '',
      createdAtTime: '',
      createdAtUnix: null,
      caption: '-',
      engagement: this.tableFormatters.buildDefaultTableObject('width:0%'),
      followersCount: '-',
      retweetCount: this.tableFormatters.buildDefaultTableObject(),
      favoriteCount: this.tableFormatters.buildDefaultTableObject(),
      clicks: this.tableFormatters.buildDefaultTableObject(),
      type: 'tweet'
    };
  }

  /**
   * Takes an array of tweets, formats each tweet for the
   * tweets table according to the given tweet key names
   *
   * @method processTweets
   * @param {Array} tweets Array of unformatted tweets
   * @param {Moment} startDate
   * @param {Moment} endDate
   *
   * @returns {Array} Array of tweets formatted for the tweets table
   */
  processTweets(tweets, startDate, endDate) {
    if (!tweets) {
      return null;
    }

    if (isEmpty(tweets) || tweets.error) {
      return tweets;
    }

    const tweetsWithEngagement = this._addEngagement(tweets);
    const timeFilteredTweets = filterTimeSeries(tweetsWithEngagement, startDate.unix(), endDate.unix(), 'createdTime');
    const retweets = extractValues(timeFilteredTweets, 'retweetCount');
    const favoriteCounts = extractValues(timeFilteredTweets, 'favoriteCount');

    const validClicks = this.tableFormatters.getValidValues(timeFilteredTweets, 'clicks');

    return timeFilteredTweets.map((tweet) => ({
      id: tweet.id,
      uid: tweet.uid,
      url: tweet.thumbUrl,
      imageUrl: tweet.standardResUrl,
      isVideo: tweet.isVideo,
      isTwitterCarousel: tweet.isTwitterCarousel,
      isDeleted: false,
      createdAt: this.helpersAnalytics.createMomentInTz(tweet.createdTime).format('MMM D, YYYY') || '-',
      createdAtTime: this.helpersAnalytics.createMomentInTz(tweet.createdTime).format('LT') || '-',
      createdAtUnix: tweet.createdTime,
      caption: this.tableFormatters.formatCaption(tweet.caption),
      engagement: {
        value: this.tableFormatters.isValid(tweet.engagement) ? `${tweet.engagement.toFixed(0)}%` : '-',
        raw: this.tableFormatters.isValid(tweet.engagement) ? Number(tweet.engagement) : null,
        scaled: this.tableFormatters.createElementWidthAttr(tweet.engagement)
      },
      followersCount: this.tableFormatters.formatFollowersCount(tweet.followersCount),
      clicks: {
        value: tweet.clicks,
        raw: tweet.clicks,
        scaled: this.tableFormatters.scaleValue(validClicks, tweet.clicks)
      },
      favoriteCount: this.tableFormatters.buildTableRowItem(
        tweet.favoriteCount,
        this.tableFormatters.scaleValue(favoriteCounts, tweet.favoriteCount)
      ),
      retweetCount: this.tableFormatters.buildTableRowItem(
        tweet.retweetCount,
        this.tableFormatters.scaleValue(retweets, tweet.retweetCount)
      ),
      type: 'tweet'
    }));
  }

  /**
   * Takes an array of tweets, computes the engagement
   * on each tweet and adds it as a value
   *
   * Engagement = (Likes + Comments) / Followers
   *
   * @protected
   * @method _addEngagement
   * @param {Array} tweets
   *
   * @return {Array} Array of tweets including engagement on each tweet
   */
  _addEngagement(tweets) {
    return tweets.map((tweet) => {
      const { favoriteCount, retweetCount, followersCount } = tweet;
      const clicks = typeof tweet.clicks === 'number' ? tweet.clicks : 0;
      let engagement = null;

      if (followersCount) {
        engagement = Number((((favoriteCount + retweetCount + clicks) / followersCount) * 100).toFixed(0));
      }

      tweet.engagement = engagement;

      return tweet;
    });
  }
}
