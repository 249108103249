import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import moment from 'moment';
import { tracked } from 'tracked-built-ins';

import type StoreService from '@ember-data/store';
import type ErrorsService from 'later/services/errors';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';

export default class PostsPendingApprovalService extends Service {
  @service declare errors: ErrorsService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: StoreService;
  @service declare userConfig: UserConfigService;

  @tracked count: number = 0;
  @tracked dateRange: { start: Moment; end: Moment } | undefined;

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this.fetchCount.perform();
  }

  get timeZone(): string {
    return this.userConfig.currentTimeZone?.identifier || '';
  }

  fetchCount = dropTask(async () => {
    if (this.selectedSocialProfiles?.profileIds?.length) {
      try {
        // Note: A model instance is currently required for calling collectionActions,
        // so we instantiate and unload a temporary one.
        const temporarySocialProfile = await this.store.createRecord('social-profile');
        const rawResponse = await temporarySocialProfile.getPendingApprovalCount({
          ids: this.selectedSocialProfiles.profileIds
        });
        temporarySocialProfile.unloadRecord();

        this.#setCountFromResponse(rawResponse.count);
        this.#setDateRangeFromResponse(rawResponse.start, rawResponse.end);
      } catch (error) {
        this.errors.log('fetchCount Error', error);
      }
    }
  });

  #setCountFromResponse = (count: Record<string, number>): void => {
    if (!count) {
      return;
    }

    const calculatedCount = Object.values(count)?.reduce(
      (sum: number, posts_pending_approval: number): number => sum + posts_pending_approval,
      0
    );
    if (typeof calculatedCount === 'number') {
      this.count = calculatedCount;
      this.#updateListViewButton();
    }
  };

  #setDateRangeFromResponse = (start: number, end: number): void => {
    if (!start || !end) {
      return;
    }

    this.dateRange = {
      start: moment.unix(start).tz(this.timeZone),
      end: moment.unix(end).tz(this.timeZone)
    };
  };

  // Note: Once we have a consolidated navbar for both the full-calendar and non-full-calendar schedule
  // views, we should refactor this logic to live in that navbar
  #updateListViewButton(): void {
    const listViewButton = document.querySelector('.fc-listButton-button');
    if (listViewButton && this.count) {
      listViewButton?.classList.add('fc-listButton-button__notification');
    } else if (listViewButton) {
      listViewButton.classList.remove('fc-listButton-button__notification');
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'schedule/posts-pending-approval': PostsPendingApprovalService;
  }
}
