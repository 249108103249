/**
 * @module Utils
 */

import { assert } from '@ember/debug';
import { isAbortError } from 'ember-fetch/errors';
import _fetch from 'fetch';
import RSVP from 'rsvp';

import { NetworkError, RateLimitError, RequestError, ServerError } from 'later/errors/fetch';
import getSourceVersion from 'later/utils/source-version';
import buildErrorMessage from 'shared/utils/build-error-message';
import { isStringifiedJSON } from 'shared/utils/is-stringified-json';
import retry, { STRATEGY } from 'shared/utils/retry';

/**
 * @class fetch
 * @extends Utils
 */

const HTTPMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];

/**
 * Safely parses an object into JSON if it can be converted
 * e.g. 404 will throw 'unexpected end of input' if you call response.json()
 *
 * @method _parseJSON
 * @param {Object} response raw JSON response object from request
 * @protected
 *
 * @returns {Object} Parsed JSON response object
 */
const _parseJSON = async (response) => {
  try {
    return await response.json();
  } catch (error) {
    return {};
  }
};

/**
 * Wraps native fetch API in a retry and rejects with Request or Server error depending on status code.
 * Throws Network error if fetch fails.
 */
const fetch = (
  url,
  config = {},
  options = { intl: null, numRetries: 3, retryStrategy: STRATEGY.DEFAULT, raw: false }
) => {
  const fetchRequest = () =>
    _fetch(url, normalizeConfig(config, url))
      .then(async (response) => {
        const type = response.headers.get('content-type');
        const errorDescriptor = { code: response.status, message: response.statusText };

        if (response.ok) {
          if (options.raw) {
            return response;
          } else if (type.includes('application/json')) {
            return response.json();
          }
          return type.includes('text') ? response.text() : response.blob();
        } else if (response.status === 429) {
          return RSVP.reject(new RateLimitError(errorDescriptor));
        } else if (response.status >= 400 && response.status < 500) {
          const data = await _parseJSON(response);

          if (options.intl && type.includes('application/json') && response.url.match(/api\/v2/)) {
            errorDescriptor.message = buildErrorMessage(options.intl, data);
            return RSVP.reject(new RequestError(errorDescriptor));
          }

          const requestError = new RequestError({
            code: data?.error?.code ?? response.status,
            message: data?.errors?.firstObject ?? data?.error?.message ?? response.statusText
          }).withDescription(data);

          return RSVP.reject(requestError);
        }
        return RSVP.reject(new ServerError(errorDescriptor));
      })
      .catch((error) => {
        if (isAbortError(error)) {
          // handle aborted network error
        } else if (error instanceof RequestError || error instanceof ServerError || error instanceof RateLimitError) {
          return RSVP.reject(error);
        }
        return RSVP.reject(new NetworkError());
      });
  return retry(fetchRequest, options.numRetries, options.retryStrategy);
};

const normalizeConfig = (config, url) => {
  const method = config.method ? config.method.toUpperCase() : 'GET';
  const { headers } = config;
  const defaultHeaders = {};

  let { body } = config;
  let contentType;

  if (!HTTPMethods.includes(method)) {
    assert(`fetch: method ${method} is not valid`);
  }

  if (_isRelativePath(url)) {
    defaultHeaders['X-Later-Version'] = getSourceVersion();
  }

  if (body) {
    contentType = _getValueCaseInsensitively(headers, 'Content-type') || 'application/json';
    defaultHeaders['Content-type'] = contentType;

    if (contentType === 'application/json' && !isStringifiedJSON(body)) {
      body = JSON.stringify(body);
    }
  }

  const normalizedHeaders = Object.assign({}, defaultHeaders, headers);

  return {
    ...config,
    method,
    headers: normalizedHeaders,
    body
  };
};

const objectToQueryString = (obj = {}) => {
  const queryParams = Object.entries(obj)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((item) => `${key}[]=${item}`).join('&');
      }
      return `${key}=${value}`;
    })
    .join('&');

  return `?${queryParams}`;
};

/**
 * @example
 * ?key=value&key=[value1,value2,value3]
 */
const objectToQueryStringSingleArray = (obj = {}) => {
  const queryParams = Object.entries(obj)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        // Create a single query parameter for the array
        return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return queryParams ? `?${queryParams}` : '';
};

/**
 * @example
 * const params = {
 *  details: {
 *      thing: {
 *        item: "cool"
 *      }
 *      wow: "yeah"
 *    }
 * }
 * nestedObjectToQueryString(params)
 * // returns `details[thing][item]=cool&details[wow]=yeah`
 */
const nestedObjectToQueryString = (obj = {}, prefix = '') => {
  const queryString = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const fullKey = prefix ? `${prefix}[${key}]` : key;
      if (value === null || value === undefined) {
        continue;
      }
      if (typeof value === 'object') {
        queryString.push(nestedObjectToQueryString(value, fullKey));
      } else {
        queryString.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryString.join('&');
};

const _getValueCaseInsensitively = (object, propertyKey) =>
  object ? object[Object.keys(object).find((key) => key.toLowerCase() === propertyKey.toLowerCase())] : undefined;

const _isRelativePath = (url) => {
  // Regex taken from here: https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
  const absolutePathRegExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  const isAbsolutePath = absolutePathRegExp.test(url);

  return !isAbsolutePath;
};

export { fetch, objectToQueryString, nestedObjectToQueryString, objectToQueryStringSingleArray };
