import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class CaptionLength extends BaseValidator {
  validate() {
    if (this.model.charactersLeft < 0) {
      return this.createErrorMessage('captionLength', undefined, { maxCharacters: this.model.maxCharacters });
    }
    return true;
  }
}

export default CaptionLength;
