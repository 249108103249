import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import moment from 'moment';

import type {
  CampaignSettings,
  ExternalCampaignFields,
  ManageCampaignsTabOptions,
  RequiredInfluencerData
} from 'influencers/types';
import type Brand from 'later/models/brand';
import type InfluencerModel from 'later/models/influencer';
import type PartnershipsAttachment from 'later/models/partnerships-attachment';
import type SocialIdentityModel from 'later/models/social-identity';

interface ManageInfluencersPayload {
  status: ManageCampaignsTabOptions;
}
interface ManageInfluencersResponse {
  influencers: InfluencerModel[];
}

interface InfluencersResponse {
  influencers: InfluencerModel[];
  inactive_influencers: number[];
}

export default class CampaignModel extends Model {
  @belongsTo('brand') declare brand: Brand;
  @belongsTo('socialIdentity') declare socialIdentity: SocialIdentityModel;
  @hasMany('partnershipsAttachment') declare campaignImages: PartnershipsAttachment[];

  @attr('string') declare name: string;
  @attr('string') declare about: string;
  @attr('string') declare minMonetaryCompensation: string;
  @attr('string') declare maxMonetaryCompensation: string;
  @attr('string') declare otherCompensation: string;
  @attr('string') declare deadline: string;
  @attr('string') declare deliverables: string;
  @attr('string', { defaultValue: moment().format('YYYY-MM-DD') }) declare startDate: string;
  @attr('string', { defaultValue: '' }) declare endDate: string;
  @attr('string') declare goal: string;
  @attr('string') declare keywords: string;
  @attr('boolean') declare hasApplied: boolean;
  @attr('boolean', { defaultValue: true }) declare visible: boolean;
  @attr('boolean', { defaultValue: false }) declare archived: boolean;
  @attr('string') declare origin: 'Mavrck' | 'Later';
  @attr('string') declare campaignId?: number;
  @attr('string') declare image?: string;
  @attr('string') declare communityId?: string;
  @attr declare externalCampaignFields?: ExternalCampaignFields;
  @attr declare settings?: CampaignSettings;
  @attr declare requiredInfluencerData?: RequiredInfluencerData;

  inactiveInfluencers: number[] = [];

  influencers = memberAction<never, InfluencersResponse>({
    path: 'influencers',
    type: 'GET'
  });

  manageInfluencers = memberAction<ManageInfluencersPayload, ManageInfluencersResponse>({
    type: 'GET',
    path: 'influencers/manage'
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    campaign: CampaignModel;
  }
}
