import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import type AuthService from 'later/services/auth';

@command('logout')
export class LogoutCommand extends Command {
  @service declare auth: AuthService;

  public searchTerms = ['logout', 'sign out', 'log out', 'signout'];

  protected action(): void | Promise<void> {
    this.auth.logout.perform();
  }

  public getTitle(): string {
    return `Log Out`;
  }

  public getDescription(): string {
    return `Log out of ${bold(this.auth.currentUserModel?.email)}?`;
  }

  public getIcon(): string {
    return 'warning';
  }
}

export default LogoutCommand;
