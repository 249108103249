import { timestamp } from 'later/utils/time-format';

import type SignupFormComponent from 'auth/components/signup-form';
import type SubscriptionPlanModel from 'later/models/subscription-plan';
import type SignupComponent from 'plans/components/account-details/signup';

interface NewUser {
  name: string;
  email: string;
  password: string;
  timeZone: string;
}

async function createUser(
  this: SignupFormComponent | SignupComponent,
  event?: SubmitEvent,
  plan?: SubscriptionPlanModel
): Promise<boolean> {
  event?.preventDefault();

  const newUser: NewUser = (await this.newUserChangeset) as unknown as NewUser;
  const { name, email, password, timeZone } = newUser;

  this.segmentEvents.trackWithGAIntegration('clicked-create-account', {
    later_app: 'Later_Social',
    location: plan ? '' : 'checkout'
  });

  if (!this.isValid) {
    this.showErrors = true;
    return false;
  }
  this.showErrors = false;

  if ('trackCampaignUserSignup' in this) {
    this.trackCampaignUserSignup();
  }

  const userParams = { name, email, password, timeZone };
  const accountParams = {
    gdprCompliant: this.expressConsent,
    gdprComplianceTimestamp: this.expressConsent ? timestamp() : undefined
  };
  await this.auth.createUser.perform(userParams, accountParams, plan);

  if (this.auth.isKeycloak && !this.auth.delayKeycloakLogin) {
    return true;
  }

  if (!this.session.isAuthenticated) {
    this.showErrors = true;
    return false;
  }
  return true;
}

export { createUser };
