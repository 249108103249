/**
 * For a given URL, download the image as a Blob and return it.
 */
export default async function downloadMedia(downloadUrl: string): Promise<Blob> {
  try {
    const response = await fetch(downloadUrl);

    if (!response.ok) {
      throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
    }

    return response.blob();
  } catch (error) {
    throw new Error(`Fetch error: ${error.message}`);
  }
}
