import Model, { type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { collectionAction, memberAction } from 'ember-api-actions';

import type ContentIdeaModel from 'later/models/content-idea';
import type GroupModel from 'later/models/group';

export type RawContentPillar = {
  created_at: number;
  generated_title: string;
  platform_error: string;
  platform_warning: string;
  id: number;
  title: string;
  updated_at: number;
};

type GeneratePayload = {
  group_id: string;
};

type RawGenerateResponse = {
  content_pillars?: RawContentPillar[];
  errors?: string[];
};

type RawRegenerateResponse = {
  content_pillars?: RawContentPillar;
  errors?: string[];
};

export default class ContentPillarModel extends Model {
  @belongsTo('group') declare group: GroupModel;
  @hasMany('contentIdeas', { async: true }) declare contentIdeas: AsyncHasMany<ContentIdeaModel>;

  @attr('boolean') declare hasNeverGeneratedIdeas: boolean;
  @attr('number') declare createdAt: number;
  @attr('string') declare description: string;
  @attr('string') declare generatedDescription: string;
  @attr('string') declare generatedTitle: string;
  @attr('string') declare platformError: string;
  @attr('string') declare platformWarning: string;
  @attr('string') declare title: string;

  // POST /api/v2/content_pillars/generate.json
  generate = collectionAction<GeneratePayload, RawGenerateResponse>({
    path: 'generate',
    type: 'POST',
    after(response: RawGenerateResponse): RawGenerateResponse {
      this.store.pushPayload(response);
      return response;
    }
  });

  // POST /api/v2/content_pillars/:id/regenerate.json
  regenerate = memberAction<never, RawRegenerateResponse>({
    path: 'regenerate',
    type: 'POST',
    after(response: RawRegenerateResponse): RawRegenerateResponse {
      this.store.pushPayload(response);
      return response;
    }
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-pillar': ContentPillarModel;
  }
}
