import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Knock from '@knocklabs/client';
import { task } from 'ember-concurrency';

import config from 'later/config/environment';

import type ErrorsService from './errors';
import type { Feed, FeedItem, FeedStoreState } from '@knocklabs/client';
import type AuthService from 'later/services/auth';

export default class NotificationsService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;

  @tracked isKnockLoaded = false;
  @tracked knockClient?: Knock = undefined;
  @tracked knockFeed?: Feed = undefined;
  @tracked feed?: FeedStoreState = undefined;

  get knockUserKey(): string {
    return `LS:User:${this.auth.currentUserModel.id}`;
  }

  get knockTenantKey(): string {
    return `LS:Group:${this.auth.currentGroup.id}`;
  }

  get feedItems(): FeedItem[] {
    return this.feed?.items || [];
  }

  get unreadFeedItems(): FeedItem[] {
    return this.feedItems.filter((feedItem: FeedItem) => !feedItem.read_at);
  }

  @action disconnect(): void {
    if (this.knockClient && this.isKnockLoaded) {
      this.knockClient.teardown();
    }
    this.isKnockLoaded = false;
    this.knockClient = undefined;
    this.knockFeed = undefined;
    this.feed = undefined;
  }

  setup = task(async () => {
    try {
      this.knockClient = new Knock(config.APP.knockAppPublicKey);
      this.knockClient.authenticate(this.knockUserKey);

      this.knockFeed = this.knockClient.feeds.initialize(config.APP.knockAppChannelId, {
        tenant: this.knockTenantKey
      });

      await this.knockFeed.fetch();
      this.#setupKnockListeners();
      this.feed = this.knockFeed.getState();
      console.log(this.feed);

      this.isKnockLoaded = true;
    } catch (error) {
      this.errors.log('Error setting up notification service', error);
    }
  });

  #setupKnockListeners(): void {
    if (this.knockFeed) {
      this.knockFeed.listenForUpdates();

      this.knockFeed.on('items.received.*', () => {
        this.feed = this.knockFeed!.getState();
        console.log(this.feed);
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    notifications: NotificationsService;
  }
}
