import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

import type { HTTPVerb } from 'ember-api-actions/utils/types';
import type { Promise as RSVPPromise } from 'rsvp';

@classic
export default class RepositoryAdapter extends ApplicationAdapter {
  ajax(
    url: string,
    type: HTTPVerb,
    hash: { data: { campaign: Record<string, any>; social_identity_id: string | undefined } }
  ): RSVPPromise<any> {
    if (type === 'POST') {
      hash.data.social_identity_id = hash.data.campaign.social_identity_id;
    }

    return super.ajax(url, type, hash);
  }
}
