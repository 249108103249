import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

import type UserModel from 'later/models/user';
import type AuthService from 'later/services/auth';

export interface TimeZone {
  identifier: string;
  name: string;
}

/**
 * This service handles state for user configurations used throughout the app
 */
export default class UserConfigService extends Service {
  @service declare auth: AuthService;

  @tracked timezones: TimeZone[] | null = null;

  /**
   * The currently set time zone
   */
  @tracked currentTimeZone: TimeZone = { name: 'UTC', identifier: 'UTC' };

  get currentUser(): UserModel {
    return this.auth.currentUserModel;
  }

  get timeZone(): string {
    if (!this.currentUser?.timeZone) {
      return moment.tz.guess();
    }
    return this.currentUser.timeZone;
  }

  get timeZoneIdentifier(): string {
    if (!this.currentUser?.timeZoneIdentifier) {
      return moment.tz.guess();
    }
    return this.currentUser.timeZoneIdentifier;
  }

  /**
   * Sets up user config for session.
   * Maps all non-deprecated moment.js timezones and creates and sets the timezones property.
   * Sets the currentTimeZone based on either: the timezone the user has set as their default, or, lacking
   * a set timezone from the user, a guess of the user's timezone using moment.js's tz.guess() function.
   */
  setupUserConfig(): void {
    const momentTimezones = this.#getTimezoneNames();

    this.timezones = momentTimezones.map((zone) => ({ name: zone, identifier: zone }));

    this.currentTimeZone = {
      name: this.timeZone,
      identifier: this.timeZoneIdentifier
    };
  }

  /**
   * Returns the names of all non-deprecated timezones from moment.js
   */
  #getTimezoneNames(): string[] {
    const momentTimezones = moment.tz.names();
    const deprecatedTimezones = this.#deprecatedTimezones();

    return momentTimezones.filter((timezone) => !deprecatedTimezones.includes(timezone));
  }

  /**
   * Returns the names of all timezones that have been deprecated.
   * List from: https://docs.google.com/spreadsheets/d/1ypN1CgNM7az0s7Ym-Trc1QAqhf2XljEPx7PBXO6VIzE/edit?usp=sharing
   * which is based on the deprecated timezones listed in https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   */
  #deprecatedTimezones(): string[] {
    return [
      'Australia/ACT',
      'Australia/LHI',
      'Australia/NSW',
      'Australia/North',
      'Australia/Queensland',
      'Australia/South',
      'Australia/Tasmania',
      'Australia/Victoria',
      'Australia/West',
      'Brazil/Acre',
      'Brazil/DeNoronha',
      'Brazil/East',
      'Brazil/West',
      'CET',
      'CST6CDT',
      'Canada/Atlantic',
      'Canada/Central',
      'Canada/Eastern',
      'Canada/Mountain',
      'Canada/Newfoundland',
      'Canada/Pacific',
      'Canada/Saskatchewan',
      'Canada/Yukon',
      'Chile/Continental',
      'Chile/EasterIsland',
      'Cuba',
      'EET',
      'EST',
      'EST5EDT',
      'Egypt',
      'Eire',
      'Etc/Greenwich',
      'Etc/UCT',
      'Etc/Universal',
      'Etc/Zulu',
      'Europe/Nicosia',
      'GB',
      'GB-Eire',
      'GMT+0',
      'GMT0',
      'GMT−0',
      'Greenwich',
      'HST',
      'Hongkong',
      'Iceland',
      'Iran',
      'Israel',
      'Jamaica',
      'Japan',
      'Kwajalein',
      'Libya',
      'MET',
      'MST',
      'MST7MDT',
      'Mexico/BajaNorte',
      'Mexico/BajaSur',
      'Mexico/General',
      'NZ',
      'NZ-CHAT',
      'Navajo',
      'PRC',
      'PST8PDT',
      'Poland',
      'Portugal',
      'ROC',
      'ROK',
      'Singapore',
      'Turkey',
      'UCT',
      'US/Alaska',
      'US/Aleutian',
      'US/Arizona',
      'US/Central',
      'US/East-Indiana',
      'US/Eastern',
      'US/Hawaii',
      'US/Indiana-Starke',
      'US/Michigan',
      'US/Mountain',
      'US/Pacific',
      'US/Pacific-New',
      'US/Samoa',
      'Universal',
      'W-SU',
      'WET',
      'Zulu'
    ];
  }
}

declare module '@ember/service' {
  interface Registry {
    'user-config': UserConfigService;
  }
}
