import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/* eslint-disable import/no-duplicates */
import type IntegrationsQueueService from 'later/services/integrations-queue';
import type { Integration } from 'later/services/integrations-queue';

export default class IntegrationsSuppressorService extends Service implements Integration {
  @service declare integrationsQueue: IntegrationsQueueService;

  readonly name = 'integrationsSuppressor';

  @tracked timeoutId: ReturnType<typeof setTimeout> | null = null;

  setup(): void {
    return;
  }

  show(): void {
    return;
  }

  hide(): void {
    return;
  }

  suppress(ms = 0): void {
    this.integrationsQueue.addToQueue(this.name, true);
    this.timeoutId = setTimeout(() => this.integrationsQueue.removeFromQueue(this.name), ms);
  }

  unsuppress(): void {
    this.integrationsQueue.removeFromQueue(this.name);
    clearTimeout(Number(this.timeoutId));
    this.timeoutId = null;
  }
}

declare module '@ember/service' {
  interface Registry {
    'integrations-suppressor': IntegrationsSuppressorService;
  }
}
