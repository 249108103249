import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<span class=\"u--inline\">\n  <DragSortList\n    @dragEndAction={{perform this.updateOrder}}\n    @isHorizontal={{true}}\n    @items={{this.orderedPostMediaItems}}\n    as |postMediaItem|\n  >\n    <Schedule::Stories::Editor::Thumbnails::SortableItem\n      @canCropOnPost={{@canCropOnPost}}\n      @canDeleteStoryItems={{@canDeleteStoryItems}}\n      @editPMICrop={{fn @editPMICrop postMediaItem}}\n      @groupId={{@groupId}}\n      @item={{postMediaItem}}\n      @removeStoryItem={{fn this.removeStoryItem postMediaItem}}\n      @story={{@story}}\n      @storyVideoMaxLength={{@storyVideoMaxLength}}\n    />\n  </DragSortList>\n</span>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<span class=\"u--inline\">\n  <DragSortList\n    @dragEndAction={{perform this.updateOrder}}\n    @isHorizontal={{true}}\n    @items={{this.orderedPostMediaItems}}\n    as |postMediaItem|\n  >\n    <Schedule::Stories::Editor::Thumbnails::SortableItem\n      @canCropOnPost={{@canCropOnPost}}\n      @canDeleteStoryItems={{@canDeleteStoryItems}}\n      @editPMICrop={{fn @editPMICrop postMediaItem}}\n      @groupId={{@groupId}}\n      @item={{postMediaItem}}\n      @removeStoryItem={{fn this.removeStoryItem postMediaItem}}\n      @story={{@story}}\n      @storyVideoMaxLength={{@storyVideoMaxLength}}\n    />\n  </DragSortList>\n</span>","moduleName":"later/components/schedule/stories/editor/thumbnails.hbs","parseOptions":{"srcName":"later/components/schedule/stories/editor/thumbnails.hbs"}});
import { action } from '@ember/object';
import { sort } from '@ember/object/computed';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

import type GramModel from 'later/models/gram';
import type PostMediaItemModel from 'later/models/post-media-item';

interface ThumbnailsComponentArgs {
  canCropOnPost: boolean;
  canDeleteStoryItems: boolean;
  deleteStoryItem: (item: PostMediaItemModel) => void;
  editPMICrop: (postMediaItem: PostMediaItemModel) => void;
  groupID: string;
  postMediaItems: PostMediaItemModel[];
  story: GramModel;
  storyVideoMaxLength: number;
}
export default class ThumbnailsComponent extends Component<ThumbnailsComponentArgs> {
  postMediaItemsSorting = ['ordering'];

  @sort('args.postMediaItems', 'postMediaItemsSorting') declare orderedPostMediaItems: PostMediaItemModel[];

  @action
  removeStoryItem(item: PostMediaItemModel): void {
    if (this.args.canDeleteStoryItems) {
      item.set('isRemoved', true);

      const ordering = item.get('ordering');
      const removedOrdering = typeof ordering === 'string' ? parseInt(ordering) : ordering;
      const orderedItems = this.orderedPostMediaItems;

      for (let i = removedOrdering; i < orderedItems.length; i++) {
        const postMediaItem = orderedItems.objectAt(i);
        const currentOrdering = postMediaItem?.get('ordering');

        if (currentOrdering) {
          postMediaItem?.set('ordering', currentOrdering - 1);
        }
      }

      this.args.deleteStoryItem(item);
    }
  }

  updateOrder = task(
    async ({
      sourceList,
      sourceIndex,
      targetIndex,
      targetList
    }: {
      sourceList: PostMediaItemModel[];
      sourceIndex: number;
      targetIndex: number;
      targetList: PostMediaItemModel[];
    }) => {
      if (sourceList === targetList && sourceIndex === targetIndex) {
        return;
      }

      const item = sourceList.objectAt(sourceIndex);

      if (!item) {
        return;
      }

      await sourceList.removeAt(sourceIndex);
      await targetList.insertAt(targetIndex, item);
      this.updatePMISorting(targetList);
    }
  );

  updatePMISorting(newlySortedList: PostMediaItemModel[]): void {
    newlySortedList.forEach((item, sortIndex) => {
      item.set('ordering', sortIndex);
    });
  }
}
