import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div data-test-id=\"post-list-time-picker\">\n  <Shared::PeriodTimePicker\n    @altFormat=\"M d, Y\"\n    @area=\"schedule-list\"\n    @inputClass=\"tAN--field__dropdownInput\"\n    @onDateChange={{@onDateChange}}\n    @pickerClass=\"o--btn--r u--m__r__sm at--listView__timePicker\"\n    @timePickerConfig={{this.timePickerConfig}}\n  />\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div data-test-id=\"post-list-time-picker\">\n  <Shared::PeriodTimePicker\n    @altFormat=\"M d, Y\"\n    @area=\"schedule-list\"\n    @inputClass=\"tAN--field__dropdownInput\"\n    @onDateChange={{@onDateChange}}\n    @pickerClass=\"o--btn--r u--m__r__sm at--listView__timePicker\"\n    @timePickerConfig={{this.timePickerConfig}}\n  />\n</div>","moduleName":"later/components/schedule/post-list/time-picker.hbs","parseOptions":{"srcName":"later/components/schedule/post-list/time-picker.hbs"}});
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment, { type Moment } from 'moment';

import { TimePeriodDirection } from 'shared/types/time-picker';

import type IntlService from 'ember-intl/services/intl';
import type UserConfigService from 'later/services/user-config';
import type { TimePeriodDuration, TimePickerConfig } from 'shared/types/time-picker';

interface TimePickerComponentSignature {
  Element: HTMLElement;
  Args: {
    startDate?: Moment;
    endDate?: Moment;
    onDateChange: (...args: unknown[]) => unknown;
  };
}

export default class TimePickerComponent extends Component<TimePickerComponentSignature> {
  @service declare intl: IntlService;
  @service declare userConfig: UserConfigService;

  get baseDate(): Moment {
    return moment().tz(this.timeZone);
  }

  get durations(): TimePeriodDuration[] {
    return [
      {
        direction: TimePeriodDirection.Forward,
        baseDate: this.baseDate,
        value: '7',
        type: 'days' as moment.unitOfTime.DurationConstructor,
        text: this.intl.t('datetime.distance_in_words.x_days', { count: 7 }),
        isActive: true,
        isLonger: false
      },
      {
        direction: TimePeriodDirection.Forward,
        baseDate: this.baseDate,
        value: '14',
        type: 'days' as moment.unitOfTime.DurationConstructor,
        text: this.intl.t('datetime.distance_in_words.x_days', { count: 14 }),
        isActive: false,
        isLonger: false
      },
      {
        direction: TimePeriodDirection.Forward,
        baseDate: this.baseDate,
        value: '30',
        type: 'days' as moment.unitOfTime.DurationConstructor,
        text: this.intl.t('datetime.distance_in_words.x_days', { count: 30 }),
        isActive: false,
        isLonger: false
      }
    ].map((value) => EmberObject.create(value));
  }

  get secondaryDurations(): TimePeriodDuration[] {
    return [
      {
        direction: TimePeriodDirection.Forward,
        baseDate: this.baseDate.add(1, 'week').startOf('week'),
        value: '7',
        type: 'days' as moment.unitOfTime.DurationConstructor,
        text: this.intl.t('datetime.distance_in_words.next_week'),
        isActive: true,
        isLonger: false
      },
      {
        direction: TimePeriodDirection.Forward,
        baseDate: this.baseDate.add(1, 'week').startOf('week'),
        value: '2',
        type: 'weeks' as moment.unitOfTime.DurationConstructor,
        text: this.intl.t('datetime.distance_in_words.next_x_weeks', { count: 2 }),
        isActive: false,
        isLonger: false
      },
      {
        direction: TimePeriodDirection.Forward,
        baseDate: this.baseDate.add(1, 'month').startOf('month'),
        value: '1',
        type: 'months' as moment.unitOfTime.DurationConstructor,
        text: this.intl.t('datetime.distance_in_words.next_month'),
        isActive: false,
        isLonger: false
      }
    ].map((value) => EmberObject.create(value));
  }

  get endDate(): Moment {
    if (this.args.endDate) {
      return moment(this.args.endDate.unix() * 1000)
        .tz(this.timeZone)
        .endOf('day');
    }
    return this.baseDate.subtract(7, 'days');
  }

  get startDate(): Moment {
    if (this.args.startDate) {
      return moment(this.args.startDate.unix() * 1000)
        .tz(this.timeZone)
        .startOf('day');
    }
    const startDate = this.endDate.clone().subtract(7, 'days');
    return moment(startDate.unix() * 1000)
      .tz(this.timeZone)
      .startOf('day');
  }

  get timePickerConfig(): TimePickerConfig {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      durations: this.durations,
      secondaryDurations: this.secondaryDurations,
      updateTimePickerConfig: this.args.onDateChange
    };
  }

  get timeZone(): string {
    return this.userConfig.currentTimeZone?.identifier || '';
  }
}
