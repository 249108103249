import Inflector from 'ember-inflector';

export function initialize(/* container, application */) {
  const { inflector } = Inflector;
  inflector.uncountable('media');
}

export default {
  name: 'inflector',
  initialize
};
