/**
 * Builds mention_type value for regrammed mention segment event
 */
export default function buildMentionSegmentType(
  nickname: string | undefined,
  source: string | null,
  type: string | undefined
): string {
  if (!type || !nickname || !source) {
    return 'null_value';
  }
  const location = nickname.match(source) ? 'own_' : 'third_party_';
  return location + type;
}
