import { isNone } from '@ember/utils';

import type { Maybe } from 'shared/types';
import type { CarouselMediaItem } from 'shared/types/mentions';

/**
 * Creates a new caption that includes the Media Item's type, source url, username and the original Instagram caption
 */
export default function captionHelper(
  carouselItem: CarouselMediaItem,
  username: Maybe<string>,
  index: number,
  array: CarouselMediaItem[]
): string {
  let creditStr = '';
  const { link, mediaType = 'media', captionText } = carouselItem;
  const mediaSource = `Media Source: ${link}`;
  const formattedTypeString = mediaType.charAt(0).toUpperCase() + mediaType.slice(1);
  const postType = `Post Type: Carousel (${formattedTypeString} ${index + 1} of ${array.length})`;

  if (!isNone(username)) {
    creditStr = `( #📷 @${username} )`;
  }

  return captionText
    ? `${mediaSource}\n\n${postType}\n\n${captionText} ${creditStr}`
    : `${mediaSource}\n\n ${postType}\n\n${creditStr}`;
}
