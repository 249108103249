interface Config {
  async?: boolean;
  id?: string;
  targetElement?: string;
  useCache?: boolean;
}

type ScriptElement = Element | null;

/**
 * Loads a script by injecting a script tag with the given url into the DOM
 *
 * @param url The url to inject
 * @param config Optional config options for creating and loading the script
 * @param beforeLoad Optional callback that gives access to the created script tag and runs before the script loads
 *
 * @returns Resolves when script is loaded and rejects if script cannot be loaded
 */
const loadScript = (
  url: string,
  config: Config = {},
  beforeLoad: (script?: HTMLScriptElement) => void = () => {
    /* noop */
  }
): Promise<void> => {
  const { async = true, id, targetElement = 'body', useCache = true } = config;

  return new Promise((resolve, reject) => {
    const script = document.querySelector(`script[src="${url}"]`);

    if (useCache && _isLoaded(script)) {
      resolve();
      return;
    }

    if (_isLoading(script)) {
      script?.addEventListener('load', () => {
        _setLoaded(script);
        resolve();
      });
      script?.addEventListener('error', () => {
        _setReload(script);
        reject(new Error('Could not load script'));
      });
    } else {
      _loadScript(async, url, id, beforeLoad, targetElement)
        .then(() => resolve())
        .catch((error) => reject(error));
    }
  });
};

const _loadScript = (
  async: boolean,
  url: string,
  id: string | undefined,
  beforeLoad: (script?: HTMLScriptElement) => void,
  targetElement: string
): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    _setLoading(script);
    if (id) {
      script.id = id;
    }
    script.type = 'text/javascript';
    script.async = async;
    script.addEventListener('load', () => {
      _setLoaded(script);
      resolve();
    });
    script.addEventListener('error', () => {
      _setReload(script);
      reject(new Error('Could not load script'));
    });

    beforeLoad(script);

    script.src = url;

    document.querySelector(targetElement)?.appendChild(script);
  });

const _isLoading = (script: ScriptElement): boolean | undefined => script?.classList.contains('script-loading');

const _isLoaded = (script: ScriptElement): boolean | undefined => script?.classList.contains('script-loaded');

const _setLoading = (script: ScriptElement): void => script?.classList.add('script-loading');

const _setLoaded = (script: ScriptElement): void => {
  script?.classList.remove('script-loading');
  script?.classList.add('script-loaded');
};

const _setReload = (script: ScriptElement): void => script?.classList.remove('script-loading');

export default loadScript;
