import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import type RouterService from '@ember/routing/router-service';
import type AuthService from 'later/services/auth';
import type CredentialStatusService from 'later/services/credential-status';

@command('manage-credentials')
export class ManageCredentialsCommand extends Command {
  @service declare auth: AuthService;
  @service declare router: RouterService;
  @service declare credentialStatus: CredentialStatusService;

  public searchTerms = ['navigate', 'go to', 'credentials', 'refresh', 'manage', 'connect'];

  protected action(): void {
    const brokenProfiles = this.auth.currentGroup.socialProfiles.filter(
      ({ hasError, needsRefresh, isProfessional, hasProfessionalAccount }) =>
        hasError || needsRefresh || (isProfessional && !hasProfessionalAccount)
    );
    if (brokenProfiles.length > 0) {
      this.credentialStatus.showReconnectSocialProfileModal(brokenProfiles[0]);
    } else {
      this.router.transitionTo('account.groups.group.social_profiles', this.auth.currentGroup.id);
    }
  }

  public getTitle(): string {
    return `Manage your credentials`;
  }

  public getDescription(): string {
    return `Connect or refresh your social profiles`;
  }

  public getIcon(): string {
    return 'refresh';
  }
}

export default ManageCredentialsCommand;
