import { isEmpty } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { collectionAction, memberAction } from 'ember-api-actions';

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type AccountModel from 'later/models/account';
import type CalendarNoteModel from 'later/models/calendar-note';
import type ContentIdeaModel from 'later/models/content-idea';
import type ContributorModel from 'later/models/contributor';
import type DeviceModel from 'later/models/device';
import type LabelModel from 'later/models/label';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type MembershipModel from 'later/models/membership';
import type SavedCaptionModel from 'later/models/saved-caption';
import type SocialIdentityModel from 'later/models/social-identity';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type { Maybe } from 'shared/types';

interface DisconnectShopAPIActionParams {
  instagram_profile_id: number;
}
interface DisconnectShopAPIActionResponse {
  group: GroupModel;
  contributors: ContributorModel[];
  users: UserModel[];
  labels: LabelModel[];
}

interface RearrangeAPIActionParams {
  groups: GroupModel[];
}
interface RearrangeAPIActionResponse {
  social_profiles: SocialProfileModel[];
  clusters: GroupModel[];
  devices: DeviceModel[];
  accounts: AccountModel[];
  social_identities: SocialIdentityModel[];
}

interface UserTable {
  isAccountOwner: boolean;
  isApprover: boolean;
  isEditor: boolean;
  user?: AsyncBelongsTo<UserModel>;
}

export default class GroupModel extends Model {
  @tracked temporaryIdentities: Maybe<SocialIdentityModel[]>; // Needed for trading identities between groups

  @attr('boolean', { defaultValue: false }) declare readonly contributor: boolean;
  @attr('number') declare readonly createdTime: Maybe<number>;
  @attr('boolean', { defaultValue: false }) declare defaultCluster: boolean;
  @attr('string') declare emailHandle: Maybe<string>;
  @attr('string') declare hexColour: Maybe<string>;
  @attr('string') declare imageUrl: Maybe<string>;
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare readonly linkinbioPageIds: number[];
  @attr('number', { defaultValue: 0 }) declare mediaItemCount: number;
  @attr('string', { defaultValue: '' }) declare name: string;
  @attr('string') declare shortBio: string;
  @attr('string') declare shortBioSetAt: Maybe<number>;
  @attr('string', { defaultValue: '' }) declare slug: string;
  @attr('string') declare uploadingDataUrl: Maybe<string>;
  @attr('boolean', { defaultValue: false }) declare useShortBioAuth: boolean;

  @belongsTo('account', { async: true }) declare account: AsyncBelongsTo<AccountModel>;
  @hasMany('calendarNotes', { async: true }) declare calendarNotes: AsyncHasMany<CalendarNoteModel>;
  @hasMany('contentIdeas', { async: true }) declare contentIdeas: AsyncHasMany<ContentIdeaModel>;
  @hasMany('contributors', { async: true }) declare contributors: AsyncHasMany<ContributorModel>;
  @hasMany('label', { async: true }) declare labels: AsyncHasMany<LabelModel>;
  @hasMany('linkinbioPage', { async: true }) declare linkinbioPages: AsyncHasMany<LinkinbioPageModel>;
  @hasMany('user', { async: true }) declare members: AsyncHasMany<UserModel>;
  @hasMany('membership', { async: false }) declare memberships: MembershipModel[]; //will be embedded -iMack
  @hasMany('savedCaption', { async: true }) declare savedCaptions: AsyncHasMany<SavedCaptionModel>;
  @hasMany('socialIdentity', { async: true }) declare socialIdentities: AsyncHasMany<SocialIdentityModel>;
  @hasMany('socialProfile', { async: true }) declare socialProfiles: AsyncHasMany<SocialProfileModel>;

  get contributorCount(): number {
    return this.contributors.get('length');
  }

  get memberCount(): number {
    return this.members.get('length');
  }

  get socialProfileCount(): number {
    return this.socialProfiles.get('length');
  }

  get socialIdentitiesCount(): number {
    return this.socialIdentities.get('length');
  }

  get hasContributors(): boolean {
    return this.contributors.get('length') > 0;
  }

  get hasLabels(): boolean {
    return this.labels.get('length') > 0;
  }

  get labelList(): Maybe<string>[] {
    return this.labels.mapBy('tagName');
  }

  get hasFacebook(): boolean {
    return !isEmpty(this.facebookProfiles);
  }

  get hasInfluencer(): boolean {
    return !isEmpty(this.influencerProfiles);
  }

  get hasInstagram(): boolean {
    return !isEmpty(this.instagramProfiles);
  }

  get hasLinkedin(): boolean {
    return !isEmpty(this.linkedinProfiles);
  }

  get hasPinterest(): boolean {
    return !isEmpty(this.pinterestProfiles);
  }

  get hasTiktok(): boolean {
    return !isEmpty(this.tiktokProfiles);
  }

  get hasTwitter(): boolean {
    return !isEmpty(this.twitterProfiles);
  }

  get hasYoutube(): boolean {
    return !isEmpty(this.youtubeProfiles);
  }

  get facebookProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isFacebook', true);
  }

  get influencerProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('businessModel', 'blog_influencer');
  }

  get instagramProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isInstagram', true);
  }

  get linkedinProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isLinkedin', true);
  }

  get linkinbioPagesCount(): number {
    return (this.linkinbioPageIds ?? []).length;
  }

  get nonContributorMemberships(): MembershipModel[] {
    return this.memberships.filterBy('isContributor', false);
  }

  get pinterestProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isPinterest', true);
  }

  get tiktokProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isTiktok', true);
  }

  get twitterProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isTwitter', true);
  }

  get youtubeProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isYoutube', true);
  }

  get nonContributorMemberCount(): number {
    return this.nonContributorMemberships.length + 1; //add one for account owner
  }

  get sortedContributors(): ContributorModel[] {
    return this.contributors.sortBy('name');
  }

  get sortedNonContributorMembers(): MembershipModel['user'][] {
    const teamMembers = this.nonContributorMemberships.map((membership) => membership.get('user'));
    teamMembers.addObject(this.account.get('owner') as MembershipModel['user']);
    return teamMembers.sortBy('name');
  }

  get sortedNonContributorsMembersUserRoles(): UserTable[] {
    const userRoles = [];
    this.nonContributorMemberships.forEach((member) => {
      userRoles.push({
        isAccountOwner: member.isAccountOwner,
        isApprover: member.isApprover,
        isEditor: member.isEditor,
        needsApproval: member.needsApproval,
        user: member.get('user')
      });
    });

    // Add admin account
    userRoles.push({
      isAccountOwner: true,
      isApprover: true,
      isEditor: true,
      user: this.account.get('owner')
    });

    return userRoles.sortBy('user.name');
  }

  disconnectShop = memberAction<DisconnectShopAPIActionParams, DisconnectShopAPIActionResponse>({
    path: 'disconnect_shop',
    type: 'patch'
  });

  rearrange = collectionAction<RearrangeAPIActionParams, RearrangeAPIActionResponse>({
    path: 'arrange',
    type: 'patch'
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    group: GroupModel;
  }
}
