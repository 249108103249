import { ErrorSeverity } from '../errors';

import type { JsonObject } from 'type-fest';

export interface Logger {
  critical: (message: Error | string, context?: JsonObject) => void;
  error: (message: Error | string, context?: JsonObject) => void;
  warning: (message: Error | string, context?: JsonObject) => void;
  info: (message: Error | string, context?: JsonObject) => void;
  debug: (message: Error | string, context?: JsonObject) => void;
}

function getPrefix(context: string, severity: ErrorSeverity): string {
  const level = severity.toLocaleUpperCase();
  return `[${context}] ${level}`;
}

export function createLogger(
  context: string,
  logFn: (message: string | Error, data?: Record<string, unknown>, severity?: ErrorSeverity) => void
): Logger {
  return {
    critical: (message: Error | string, data: JsonObject = {}): void => {
      logFn(`${getPrefix(context, ErrorSeverity.Critical)}: ${message}`, data, ErrorSeverity.Critical);
    },
    error: (message: Error | string, data?: JsonObject): void => {
      logFn(`${getPrefix(context, ErrorSeverity.Error)}: ${message}`, data, ErrorSeverity.Error);
    },
    warning: (message: Error | string, data?: JsonObject): void => {
      logFn(`${getPrefix(context, ErrorSeverity.Warning)}: ${message}`, data, ErrorSeverity.Warning);
    },
    info: (message: Error | string, data?: JsonObject): void => {
      logFn(`${getPrefix(context, ErrorSeverity.Info)}: ${message}`, data, ErrorSeverity.Info);
    },
    debug: (message: Error | string, data?: JsonObject): void => {
      logFn(`${getPrefix(context, ErrorSeverity.Debug)}: ${message}`, data, ErrorSeverity.Debug);
    }
  };
}
