import Service, { inject as service } from '@ember/service';
import { task, enqueueTask } from 'ember-concurrency';

import { fetch, objectToQueryString } from 'later/utils/fetch';
import generateCacheKey from 'shared/utils/analytics/generate-cache-key';

import type HelpersAnalyticsService from './helpers-analytics';
import type SocialProfileModel from 'later/models/social-profile';
import type AnalyticsService from 'later/services/analytics';
import type CacheService from 'later/services/cache';
import type { Moment } from 'moment';

export default class LaterApiService extends Service {
  @service declare analytics: AnalyticsService;
  @service declare cache: CacheService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;

  /**
   * Gets the current social profile as defined in the Analytics Service
   */
  get socialProfile(): SocialProfileModel | undefined {
    return this.analytics.socialProfile;
  }

  /**
   * Default start date for data calls in this service
   */
  get startDate(): Moment {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  clearCache(): void {
    const cacheKeyPrefix = 'laterApi';
    this.cache.clearCacheByKeyword(cacheKeyPrefix);
  }

  /**
   * Returns linkinbio posts with data for analytics
   */
  getLinkinbioAnalyticsPosts = enqueueTask(async (forceRefresh) => {
    const socialProfileId = this.socialProfile?.id;
    const endpoint = 'linkinbio';
    const cacheKey = generateCacheKey('laterApi', { endpoint, socialProfileId });
    const cachedPosts = this.cache.retrieve(cacheKey);

    if (!cachedPosts || forceRefresh) {
      return await this._fetch.linked().perform({
        endpoint,
        cacheKey,
        queryParams: {
          after: this.startDate.unix()
        }
      });
    }

    return cachedPosts;
  });

  /**
   * Fetches data based on the given config object
   * and sets the resulting data in the
   * cache under the given cache key name.
   */
  _fetch = task(async (config) => {
    const params = { social_profile_id: this.socialProfile?.id, ...config.queryParams };
    const result = await fetch(`/api/v2/analytics/${config.endpoint}.json${objectToQueryString(params)}`);

    this.cache.add(config.cacheKey, result, { expiry: this.cache.expiry(1, 'day'), persist: false });
    return result;
  });
}
