/**
 * @module Helpers
 */

/**
 * @class LaterHelpers
 * @extends Helpers
 */

/**
 * Compare the equality of two blocks for tests.
 *
 * @method are-equal-block
 * @param {Any} arg1  First block for comparison
 * @param {Any} arg2  Second block for comparison
 * @param {Any} isEqualResult   Result returned when blocks are equal
 * @param {Any} notEqualResult  Result returned when blocks are not equal
 *
 * @return {Any} isEqualResult or notEqualResult from parameters
 */
import { helper as buildHelper } from '@ember/component/helper';

export default buildHelper(([arg1, arg2, isEqualResult, notEqualResult] /*, hash*/) => {
  if (arg1 === arg2) {
    return isEqualResult;
  }
  return notEqualResult;
});
