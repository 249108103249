import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

import type Snapshot from '@ember-data/store/snapshot';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import type SocialIdentityModel from 'later/models/social-identity';

/**
 * Adapter for media-kit
 * All the endpoints are implemented under api/v2/social_identities namespace.
 * This requires us to use an adapter in order to make use of Ember Data Store
 */
@classic
export default class MediaKitAdapter extends ApplicationAdapter {
  urlForCreateRecord<K extends keyof ModelRegistry>(_modelName: K, snapshot: Snapshot<ModelRegistry[K]>): string {
    const socialIdentity: SocialIdentityModel = snapshot.record.get('socialIdentity');
    const id = socialIdentity.get('id');
    return `/api/v2/social_identities/${id}/media_kit.json`;
  }

  urlForUpdateRecord<K extends keyof ModelRegistry>(
    _id: string,
    _modelName: K,
    snapshot: Snapshot<ModelRegistry[K]>
  ): string {
    const socialIdentity: SocialIdentityModel = snapshot.record.get('socialIdentity');
    const id = socialIdentity.get('id');
    return `/api/v2/social_identities/${id}/media_kit.json`;
  }

  /**
   * A social Identity ID is required to retrieve a media kit, and is the only
   * query param that is accepted
   */
  urlForQueryRecord(query: { socialIdentityId: string | number }): string {
    const { socialIdentityId } = query;
    if (!socialIdentityId) return '';
    return `/api/v2/social_identities/${socialIdentityId}/media_kit.json`;
  }
}
