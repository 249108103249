function rgbaToHex(rgba: string): string | undefined {
  // Extract the individual R, G, B values
  const values = rgba.match(/\d+/g);

  if (!values || !rgba.includes('rgba')) {
    return rgba;
  }

  if (values.length < 3) {
    return undefined;
  }

  const r = parseInt(values[0], 10);
  const g = parseInt(values[1], 10);
  const b = parseInt(values[2], 10);

  // Convert to hex
  const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return `#${hex.toUpperCase()}`;
}

export default rgbaToHex;
