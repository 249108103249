import type Model from '@ember-data/model';

export type FeatureFlags = Record<string, boolean>;

type AttributeMeta = {
  type: string;
  options: Record<string, unknown>;
  isAttribute: true;
  kind: 'attribute';
};

type ModelWithAttributes = Model & { eachAttribute: (callback: (name: string, meta: AttributeMeta) => void) => void };

/**
 * Returns a map of the feature flags for a given model instance.
 * @remarks A feature flag is defined as an attribute that starts with `rollout` or `can`.
 * @remarks An "attribute" is defined using the `@attr` decorator. Computed properties
 * or getters are not considered attributes.
 */
export function getFeatureFlags<T extends Model>(modelInstance: T): FeatureFlags {
  // The typing in this function is a bit messy due to the types provided by ember-data.
  // When we move to Ember 4 and update our type packages, we will likely be able to clean this up.
  const modelWithAttributes = modelInstance as unknown as ModelWithAttributes;

  const featureFlags: FeatureFlags = {};
  modelWithAttributes.eachAttribute((name, meta) => {
    if (meta.type === 'boolean' && (name.startsWith('rollout') || name.startsWith('can'))) {
      const value = modelInstance[name as keyof T];

      if (typeof value !== 'boolean') {
        featureFlags[name] = false;
        return;
      }

      featureFlags[name] = value;
    }
  });

  return featureFlags;
}
