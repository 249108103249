import { inject as service } from '@ember/service';
import ActiveModelAdapter from 'active-model-adapter';
import classic from 'ember-classic-decorator';

import getSourceVersion from 'later/utils/source-version';

@classic
export default class Application extends ActiveModelAdapter {
  @service session;
  namespace = '/api/v2';

  get headers() {
    const csrf = document.querySelector('meta[name="csrf-token"]')?.content;
    const headers = {};

    headers['X-Later-Version'] = getSourceVersion();

    if (this.session.isAuthenticated) {
      headers['X-CSRF-Token'] = csrf;
    }
    return headers;
  }

  shouldBackgroundReloadRecord() /*store, ticketSnapshot*/ {
    return false;
  }

  // Note: This will replace a lot of calls to #show with a single call to index
  // will provide an array of ids on the param :id
  coalesceFindRequests = true;
}
