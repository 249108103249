/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import { filterTimeSeries, extractValues } from 'later/utils/array-filters';

/**
 * @class FormattersTablePinsService
 * @extends Service
 */
export default class PinsService extends Service {
  @service('analytics/helpers-analytics') helpersAnalytics;
  @service('analytics/formatters/table') tableFormatters;

  /**
   * @property defaultRow
   * @type {Object}
   * @default
   */
  get defaultRow() {
    return {
      id: null,
      link: null,
      url: null,
      isDeleted: false,
      createdAt: '',
      createdAtTime: '',
      createdAtUnix: null,
      caption: '-',
      followersCount: '-',
      repins: this.tableFormatters.buildDefaultTableObject(),
      commentCount: this.tableFormatters.buildDefaultTableObject(),
      clicks: this.tableFormatters.buildDefaultTableObject(),
      type: 'pin'
    };
  }

  /**
   * Takes an array of media, formats each mediaItem for the
   * media table according to the given mediaItem key names
   *
   * @method processPins
   * @param {Array} [media=[]] Array of unformatted media
   * @param {Moment} startDate
   * @param {Moment} endDate
   *
   * @returns {Array} Array of media formatted for the media table
   */
  processPins(media = [], startDate, endDate) {
    if (isEmpty(media) || media.error) {
      return media;
    }

    const pins = media.media ? media.media : [];
    const filteredPins = filterTimeSeries(pins, startDate.unix(), endDate.unix(), 'createdTime');

    const repins = extractValues(filteredPins, 'repinCount');
    const commentCounts = extractValues(filteredPins, 'commentCount');
    const clicks = this.tableFormatters.getValidValues(filteredPins, 'clicks');

    return filteredPins.map((pin) => ({
      id: pin.id,
      pinnerId: pin.pinnerId,
      boardId: pin.boardId,
      link: pin.link,
      url: pin.thumbUrl,
      isDeleted: false,
      createdAt: this.helpersAnalytics.createMomentInTz(pin.createdTime).format('MMM D, YYYY') || '-',
      createdAtTime: this.helpersAnalytics.createMomentInTz(pin.createdTime).format('LT') || '-',
      createdAtUnix: pin.createdTime,
      caption: this.tableFormatters.formatCaption(pin.caption),
      followersCount: this.tableFormatters.formatFollowersCount(pin.followersCount),
      repinCount: this.tableFormatters.buildTableRowItem(
        pin.repinCount,
        this.tableFormatters.scaleValue(repins, pin.repinCount)
      ),
      commentCount: this.tableFormatters.buildTableRowItem(
        pin.commentCount,
        this.tableFormatters.scaleValue(commentCounts, pin.commentCount)
      ),
      clicks: {
        value: pin.clicks,
        raw: pin.clicks,
        scaled: this.tableFormatters.scaleValue(clicks, pin.clicks)
      },
      type: 'pin'
    }));
  }
}
