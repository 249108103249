import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class MediaNotProcessing extends BaseValidator {
  validate(value, _, model) {
    const isProcessing = model && model.get('mediaItem.processing');
    if (isProcessing) {
      return this.createErrorMessage('media_processing');
    }
    return true;
  }
}

export default MediaNotProcessing;
