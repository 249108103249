import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import { getGroup } from 'later/utils/global-search/get-group';

import type RouterService from '@ember/routing/router-service';
import type AuthService from 'later/services/auth';

@command('navigate-to-schedule')
export class NavigateToScheduleCommand extends Command {
  @service declare auth: AuthService;
  @service declare router: RouterService;

  public searchTerms = ['navigate', 'go to', 'calendar', 'schedule', 'post', 'gram', 'plan'];

  protected action(): void | Promise<void> {
    const groupSlug = getGroup({ auth: this.auth }).slug;
    this.router.transitionTo('cluster.schedule', groupSlug);
  }

  public getTitle(): string {
    return `View Your Schedule`;
  }

  public getDescription(): string {
    const group = getGroup({ auth: this.auth });
    return `Navigate to your content calendar for the ${bold(group.name)} group`;
  }

  public getIcon(): string {
    return 'calendar';
  }
}

export default NavigateToScheduleCommand;
