import { assert } from '@ember/debug';
import { TrackedArray, TrackedMap, tracked } from 'tracked-built-ins';

import GroupModel from 'later/models/group';
import SocialIdentityModel from 'later/models/social-identity';

export type MavelyProfile = Readonly<{
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: PropertyKey]: any;
  /** Mavely Profile ID - used to make requests to Mavely API */
  id: string;
  /** IDs of the Groups that the Mavely Profile belongs to through its associated Social Identities */
  groupIds: string[];
  /** IDs of the SocialIdentities that the Mavely Profile belongs to */
  socialIdentityIds: string[];
  /** Whether the Mavely Profile is still authenticated within our system (i.e. has valid access token) */
  authenticated: boolean;
  /** Email used when the user registered their Mavely Profile */
  email: string;
  /** Name used when the user registered their Mavely Profile */
  name: string;
  /** Total number of Mavely Links on the Mavely Profile (represented by string) */
  totalLinks?: string;
}>;

function isSocialIdentityModel(model: any): model is SocialIdentityModel {
  if (model instanceof SocialIdentityModel) {
    return true;
  }
  if (!model) {
    return false;
  }
  return (
    (typeof model === 'object' && typeof model.get === 'function' && model.get('id') && model.get('group')) ||
    (model.id && model.group)
  );
}

function isGroupModel(model: any): model is GroupModel {
  if (model instanceof GroupModel) {
    return true;
  }
  if (!model) {
    return false;
  }
  return (
    (typeof model === 'object' &&
      typeof model.get === 'function' &&
      model.get('id') &&
      model.get('socialIdentities')) ||
    (model.id && model.socialIdentities)
  );
}

/**
 * The `MavelyProfileIndex` class manages a collection of Mavely profiles
 * associated with `SocialIdentity` records. It provides methods to retrieve,
 * and filter profiles based on their authentication status.
 *
 * Use cases:
 * 1. Managing Mavely profiles for multiple social identities
 * 2. Filtering authenticated and unauthenticated profiles
 * 3. Retrieving profiles by social identity or group
 * 4. Iterating over all profiles in the collection
 *
 * Usage examples:
 *
 * Creating and populating the index:
 * ```typescript
 * const index = new MavelyProfileIndex();
 * ```
 *
 * Retrieving profiles:
 * ```typescript
 * // Get profiles for a social identity
 * const socialIdentityProfiles = index.get(socialIdentity);
 *
 * // Get profiles for a group
 * const groupProfiles = index.get(group);
 * ```
 *
 * Filtering profiles:
 * ```typescript
 * // Get all authenticated profiles
 * const authenticatedProfiles = index.authenticated;
 *
 * // Get all unauthenticated profiles
 * const unauthenticatedProfiles = index.unauthenticated;
 * ```
 *
 * Iterating over profiles:
 * ```typescript
 * // Using for...of loop
 * for (const profile of index) {
 *   console.log(profile.name);
 * }
 *
 * // Using spread operator
 * const allProfiles = [...index];
 *
 * // Using Array.from()
 * const profileArray = Array.from(index);
 * ```
 */
export class MavelyProfileIndex {
  @tracked private map = new TrackedMap<MavelyProfile['id'], MavelyProfile>();

  get size(): number {
    return this.map.size;
  }

  /**
   * Returns an array of all Mavely profiles.
   *
   * @readonly
   */
  get all(): TrackedArray<MavelyProfile> {
    return new TrackedArray(Array.from(this.map.values()));
  }

  /**
   * Returns an array of all authenticated Mavely profiles.
   *
   * @readonly
   */
  get authenticated(): TrackedArray<MavelyProfile> {
    return new TrackedArray(Array.from(this.map.values()).filter((profile) => profile.authenticated));
  }

  /**
   * Returns an array of all unauthenticated Mavely profiles.
   *
   * @readonly
   */
  get unauthenticated(): TrackedArray<MavelyProfile> {
    return new TrackedArray(Array.from(this.map.values()).filter((profile) => !profile.authenticated));
  }

  /**
   * Implements the iterable protocol, allowing iteration over all Mavely profiles.
   *
   * This generator function yields each Mavely profile stored in the internal map.
   *
   * @yields {MavelyProfile} Each Mavely profile in the collection.
   */
  *[Symbol.iterator](): IterableIterator<MavelyProfile> {
    yield* this.map.values();
  }

  /**
   * Converts the internal map to a flat array of all Mavely profiles.
   *
   * @returns A flat array of all Mavely profiles.
   */
  toArray(): MavelyProfile[] {
    return Array.from(this.map.values()).flat();
  }

  /**
   * Adds an array of Mavely profiles associated with a social identity or group.
   * The profiles are frozen to ensure immutability.
   *
   * @param model - The social identity model.
   * @param profiles - An array of Mavely profiles to add.
   */
  add<T extends SocialIdentityModel | GroupModel>(model: T, profiles: Record<string, unknown>[]): void {
    if (isSocialIdentityModel(model)) {
      this.addForSocialIdentity(model, profiles);
    } else if (isGroupModel(model)) {
      this.addForGroup(model, profiles);
    } else {
      assert('MavelyProfileIndex.add: Provided model must be a SocialIdentityModel or GroupModel');
    }
  }

  /**
   * Retrieves the array of Mavely profiles associated with a given social identity or group,
   * or retrieves a single Mavely profile by its ID.
   *
   * @param model - The social identity model, group model, or Mavely profile ID.
   * @returns An array of Mavely profiles for the given social identity or group, a single Mavely profile for the given ID, or undefined.
   */
  get<T extends SocialIdentityModel | GroupModel | string>(
    model: T
  ): T extends string ? MavelyProfile | undefined : MavelyProfile[] {
    if (typeof model === 'string') {
      return this.map.get(model) as T extends string ? MavelyProfile : never;
    } else if (isSocialIdentityModel(model)) {
      return this.getForSocialIdentity(model) as T extends string ? never : TrackedArray<MavelyProfile>;
    } else if (isGroupModel(model)) {
      return this.getForGroup(model) as T extends string ? never : TrackedArray<MavelyProfile>;
    }
    assert('MavelyProfileIndex.get: Provided model must be a SocialIdentityModel, GroupModel, or string');
  }

  /**
   * Checks if there are any Mavely profiles associated with the given social identity or group.
   *
   * @param model - The social identity or group model to check for associated Mavely profiles.
   * @returns A boolean indicating whether any Mavely profiles exist for the given model.
   */
  exists<T extends SocialIdentityModel | GroupModel | string>(model: T): boolean {
    if (typeof model === 'string') {
      return this.map.has(model);
    } else if (isSocialIdentityModel(model)) {
      return Array.from(this.map.values()).some((profile) => profile.socialIdentityId === model.get('id'));
    } else if (isGroupModel(model)) {
      return Array.from(this.map.values()).some((profile) => profile.groupId === model.get('id'));
    }
    assert('MavelyProfileIndex.exists: Provided model must be a SocialIdentityModel, GroupModel, or string');
  }

  /**
   * Removes Mavely profiles associated with the given social identity, group, or profile ID.
   *
   * @param model - The social identity model, group model, or Mavely profile ID.
   */
  remove<T extends SocialIdentityModel | GroupModel | string>(model: T): void {
    if (typeof model === 'string') {
      this.map.delete(model);
    } else if (isSocialIdentityModel(model)) {
      this.removeForSocialIdentity(model);
    } else if (isGroupModel(model)) {
      this.removeForGroup(model);
    } else {
      assert('MavelyProfileIndex.remove: Provided model must be a SocialIdentityModel, GroupModel, or string');
    }
  }

  private removeForSocialIdentity(model: SocialIdentityModel): void {
    const socialIdentityId = model.get('id');
    const groupId = model.get('group').get('id') as string; // Note: hope this doesn't break anything

    this.map.forEach((profile, id) => {
      const updatedProfile = this.updateProfileForSocialIdentity(profile, socialIdentityId, groupId);
      if (updatedProfile.socialIdentityIds.length === 0) {
        this.map.delete(id);
      } else {
        this.map.set(id, Object.freeze(updatedProfile));
      }
    });
  }

  private removeForGroup(model: GroupModel): void {
    const groupId = model.get('id');
    this.map.forEach((profile, id) => {
      const updatedProfile = this.updateProfileForGroup(profile, groupId, model);
      if (updatedProfile.groupIds.length === 0) {
        this.map.delete(id);
      } else {
        this.map.set(id, Object.freeze(updatedProfile));
      }
    });
  }

  private updateProfileForSocialIdentity(
    profile: MavelyProfile,
    socialIdentityId: string,
    groupId: string
  ): MavelyProfile {
    return {
      ...profile,
      socialIdentityIds: profile.socialIdentityIds.filter((sid) => sid !== socialIdentityId),
      groupIds: profile.groupIds.filter((gid) => gid !== groupId)
    };
  }

  private updateProfileForGroup(profile: MavelyProfile, groupId: string, model: GroupModel): MavelyProfile {
    return {
      ...profile,
      groupIds: profile.groupIds.filter((gid) => gid !== groupId),
      socialIdentityIds: profile.socialIdentityIds.filter(
        (sid) =>
          !model
            .get('socialIdentities')
            .map((si) => si.get('id'))
            .includes(sid)
      )
    };
  }

  private validateProfile(profile: Record<string, any>): void {
    assert('MavelyProfileIndex.validateProfile: Profile must have an `id`', 'id' in profile);
    assert('MavelyProfileIndex.validateProfile: Profile must have an `email`', 'email' in profile);
    assert('MavelyProfileIndex.validateProfile: Profile must have a `name`', 'name' in profile);
    assert(
      'MavelyProfileIndex.validateProfile: Profile must have an `authenticated` status',
      'authenticated' in profile
    );
  }

  private addForSocialIdentity(socialIdentity: SocialIdentityModel, profiles: Record<string, any>[]): void {
    const groupId = socialIdentity.get('group').get('id') as string;
    const socialIdentityId = socialIdentity.get('id');

    profiles.forEach((profile) => {
      this.validateProfile(profile);

      const existingProfile = this.map.get(profile.id);

      const combinedGroupIds = existingProfile ? [...new Set([...existingProfile.groupIds, groupId])] : [groupId];
      const combinedSocialIdentityIds = existingProfile
        ? [...new Set([...existingProfile.socialIdentityIds, socialIdentityId])]
        : [socialIdentityId];

      const mavelyProfile: MavelyProfile = {
        ...existingProfile,
        id: profile.id,
        groupIds: combinedGroupIds,
        socialIdentityIds: combinedSocialIdentityIds,
        authenticated: profile.authenticated,
        email: profile.email,
        name: profile.name,
        totalLinks: profile.total_links
      };

      this.map.set(mavelyProfile.id, Object.freeze(mavelyProfile));
    });
  }

  private addForGroup(group: GroupModel, profiles: Record<string, any>[]): void {
    const socialIdentities = group.get('socialIdentities');
    socialIdentities.forEach((socialIdentity) => {
      this.addForSocialIdentity(
        socialIdentity,
        profiles.map((profile) => ({
          ...profile,
          groupId: group.get('id'),
          socialIdentityId: socialIdentity.get('id')
        }))
      );
    });
  }

  private getForSocialIdentity(socialIdentity: SocialIdentityModel): MavelyProfile[] {
    return Array.from(this.map.values()).filter((profile) =>
      profile.socialIdentityIds.includes(socialIdentity.get('id'))
    );
  }

  private getForGroup(group: GroupModel): MavelyProfile[] {
    return Array.from(this.map.values()).filter((profile) => profile.groupIds.includes(group.get('id')));
  }
}

export default MavelyProfileIndex;
