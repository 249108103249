import Service, { inject as service } from '@ember/service';

import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type TimeSlotModel from 'later/models/time-slot';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';
import type { Maybe } from 'shared/types';

export default class TimeSlotService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare intl: IntlService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: Store;
  @service declare userConfig: UserConfigService;

  get currentGroup(): GroupModel {
    return this.auth.currentGroup;
  }

  get timeZoneIdentifier(): Maybe<string> {
    return this.userConfig?.currentTimeZone?.identifier;
  }

  async create(time: Moment): Promise<boolean> {
    const timeSlot = this.store.createRecord('time-slot', {
      socialProfile: this.selectedSocialProfiles.firstProfile,
      slotType: 'user'
    });

    const { validations } = timeSlot.validateSync();

    if (validations.get('isValid')) {
      timeSlot.setFromMoment(time);
      await timeSlot.save();

      return true;
    }
    const message = validations.get('messages')[0];
    this.alerts.warning(message, { preventDuplicates: true });
    return false;
  }

  async delete(id: string): Promise<void> {
    this.store.findRecord('time-slot', id).then(async (timeSlot: TimeSlotModel) => {
      if (!timeSlot.isSaving && !timeSlot.isDeleted) {
        await timeSlot.destroyRecord();
      }
    });
  }

  async update(id: string, time: Moment): Promise<void> {
    await this.store.findRecord('time-slot', id).then(async (timeSlot: TimeSlotModel) => {
      timeSlot.setFromMoment(time);
      if (!this.#isTimeSlotDuplicated(timeSlot)) {
        await timeSlot.save();
      } else {
        await timeSlot.rollbackAttributes();
        this.alerts.alert(this.intl.t('alerts.calendar.time_slots.duplicated'));
      }
    });
  }

  #isTimeSlotDuplicated(newTimeSlot: TimeSlotModel): boolean {
    return this.store
      .peekAll('time-slot')
      .any(
        (timeSlot: TimeSlotModel) =>
          timeSlot.id !== newTimeSlot.id &&
          timeSlot.hour === newTimeSlot.hour &&
          timeSlot.minute === newTimeSlot.minute &&
          timeSlot.wday === newTimeSlot.wday
      );
  }
}

declare module '@ember/service' {
  interface Registry {
    'schedule/calendar/time-slot': TimeSlotService;
  }
}
