import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type { ConnectedPlatform } from 'influencers/types';
import type Campaign from 'later/models/campaign';
import type SocialIdentityModel from 'later/models/social-identity';

export default class BrandModel extends Model {
  @attr('string') declare about: string;
  @attr('number') declare readonly acceptedAt: number;
  @attr('string') declare industry: string;
  @attr('string') declare name: string;
  @attr('string') declare website: string;
  @attr('string') declare avatarUrl: string;
  @attr() declare connectedSocialProfiles: ConnectedPlatform[];

  @belongsTo('socialIdentity') declare socialIdentity: SocialIdentityModel;
  @hasMany('campaign') declare campaigns: Campaign[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    brand: BrandModel;
  }
}
