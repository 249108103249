import moment from 'moment';

export enum ScheduleNavbarDateInterval {
  Week = 'week',
  Month = 'month'
}

/**
 * Get a Moment object that starts at the beginning of the provided date interval.
 * For example, if `week` is provided, then the returned moment object will be the
 * previous Sunday of the current week.
 * @param dateInterval Interval used (ex. week, month)
 * @param timeZoneIdentifier Timezone identifier string (ex. "America/Vancouver")
 * @returns Moment tuple with the start and end dates as first and second elements - respectively.
 */
export function getDateRange(
  fromDate: number | moment.Moment,
  dateInterval = ScheduleNavbarDateInterval.Week,
  timeZoneIdentifier: string
): [moment.Moment, moment.Moment] {
  const rightNow = moment(fromDate);
  if (dateInterval === ScheduleNavbarDateInterval.Month) {
    return [
      moment(rightNow).tz(timeZoneIdentifier).startOf('month'),
      moment(rightNow).tz(timeZoneIdentifier).endOf('month')
    ];
  }
  if (dateInterval === ScheduleNavbarDateInterval.Week) {
    return [
      moment(rightNow).tz(timeZoneIdentifier).startOf('week'),
      moment(rightNow).tz(timeZoneIdentifier).endOf('week')
    ];
  }
  throw new Error(`Unsupported date interval provided: "${dateInterval}"`);
}

/**
 * Get the previous target date based on the interval provided.
 * @param date Target date
 * @param dateInterval Date interval (ex. "week", "month")
 * @returns New target date
 */
export function getPreviousDate(date: moment.Moment, dateInterval: ScheduleNavbarDateInterval): moment.Moment {
  if (dateInterval === ScheduleNavbarDateInterval.Month) {
    return moment(date).subtract(1, 'month');
  }

  return moment(date).subtract(1, 'week');
}

/**
 * Get the next target date based on the interval provided.
 * @param date Target date
 * @param dateInterval Date interval (ex. "week", "month")
 * @returns New target date
 */
export function getNextDate(date: moment.Moment, dateInterval: ScheduleNavbarDateInterval): moment.Moment {
  if (dateInterval === ScheduleNavbarDateInterval.Month) {
    return moment(date).add(1, 'month');
  }

  return moment(date).add(1, 'week');
}

/**
 * Get the present date as a moment object. If `timezone` isn't provided, moment will try
 * to guess the timezone via `moment.tz.guess`.
 * @param timezone Timezone identifier string
 * @returns Present Moment based on provided timezone.
 */
export function getPresentDate(timezone?: string): moment.Moment {
  return moment.tz(timezone ?? moment.tz.guess());
}

/**
 * Convert a Moment object to a unix timestamp in seconds.
 * @param date Moment object to convert
 * @returns Unix timestamp in seconds.
 */
export function convertToUnixSeconds(date: moment.Moment): number {
  return date.unix();
}

/**
 * Convert a Moment object to a unix timestamp in milliseconds.
 * @param date Moment object to convert
 * @returns Unix timestamp in milliseconds.
 */
export function convertToUnixMilliseconds(date: moment.Moment): number {
  return date.unix() * 1000;
}

export default {
  getDateRange,
  getNextDate,
  getPreviousDate,
  getPresentDate
};
