import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type SessionService from 'later/services/-private/session';
import type { Transition } from 'shared/types';

export default class ChangePlanRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'user.login');
  }

  redirect(model: { plan: string }): void {
    this.router.replaceWith('plans.plan', model.plan);
  }
}
