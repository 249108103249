import type GroupModel from 'later/models/group';
import type AuthService from 'later/services/auth';

/**
 * Helper function to get the current group from the auth service.
 * This is a workaround for the fact that there may not be a current group.
 * This typically is only an issue if the page is loaded when the user is in the
 *  settings page.
 */
export function getGroup(params: { auth: AuthService }): GroupModel {
  if (params.auth.currentGroup) {
    return params.auth.currentGroup;
  }

  if (params.auth.lastGroup) {
    return params.auth.lastGroup;
  }

  const groups = params.auth.groups as GroupModel[];
  if (groups.length > 0) {
    return groups.firstObject as GroupModel;
  }

  // Note: throwing an error here can cause the app to not load
  // when opening a cached version of the app

  // throw new Error('No group found');
  console.log('No group found');
  return params.auth.currentGroup;
}
