import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import type RouterService from '@ember/routing/router-service';

@command('navigate-to-linkinbio')
export class NavigateToLinkinbioCommand extends Command {
  @service declare router: RouterService;

  public searchTerms = [
    'navigate',
    'go to',
    'linkinbio',
    'link',
    'button',
    'featured',
    'banner',
    'media',
    'youtube',
    'feed',
    'mailchimp',
    'traffic'
  ];

  protected action(): void | Promise<void> {
    this.router.transitionTo('cluster.linkinbio');
  }

  public isSuggested(): boolean {
    return true;
  }

  public getTitle(): string {
    return `Manage your Linkin.bio`;
  }

  public getDescription(): string {
    return `Use Linkin.bio to drive traffic from Instagram & TikTok, track clicks, and more.`;
  }

  public getIcon(): string {
    return 'linkin-bio';
  }
}

export default NavigateToLinkinbioCommand;
