import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment';
import RSVP from 'rsvp';

import { AttachmentName } from 'later/utils/constants';
import getPreferredAttachmentUrl from 'later/utils/get-preferred-attachment-url';
import { isProduction } from 'later/utils/is-env';
import formattedLastUpdatedTime from 'later/utils/last-updated-time';
import BlockTypes from 'shared/utils/constants/linkinbio-block-types';

import type { SafeString } from '@ember/template/-private/handlebars';
import type { AsyncBelongsTo } from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type LinkinbioBlockModel from 'later/models/linkinbio-block';
import type SocialIdentityModel from 'later/models/social-identity';
import type SocialProfileModel from 'later/models/social-profile';
import type { LinkinbioPageUserIntent } from 'later/utils/constants';
import type { Maybe } from 'shared/types';
import type { HeaderBlockData } from 'shared/types/linkinbio-block-data';

export default class LinkinbioPageModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('group', { async: false }) declare group: GroupModel;
  @belongsTo('socialIdentity', { async: true }) declare socialIdentity: AsyncBelongsTo<SocialIdentityModel>;
  @hasMany('linkinbioBlock', { async: false }) declare linkinbioBlocks: LinkinbioBlockModel[];

  @attr('number') declare createdTime: number;
  @attr('number') declare enabledTime: Maybe<number>;
  @attr('number') declare enabledGaTime?: number;
  @attr('number') declare lastUpdatedTime: number;
  @attr('string', { defaultValue: '' }) declare slug: string;
  @attr('number') declare updateSlugAfterTime: Maybe<number>;
  @attr('string') declare userIntent: Maybe<LinkinbioPageUserIntent>;

  get avatarUrl(): string {
    const headerBlock = this.linkinbioBlocks?.filterBy('blockType', BlockTypes.Header).objectAt(0);
    const placeholderUrl = '/assets/images/img--userAvatar--placeholder.svg';
    const attachment = headerBlock?.linkinbioAttachments
      .filterBy('name', AttachmentName.LinkinbioHeaderAvatar)
      .objectAt(0);
    const attachmentPreferredKey = 'thumb_md';

    return getPreferredAttachmentUrl(attachment, attachmentPreferredKey) || placeholderUrl;
  }

  get displayName(): string {
    const headerBlockData = this.linkinbioBlocks?.filterBy('blockType', BlockTypes.Header).objectAt(0)
      ?.blockData as HeaderBlockData;

    return headerBlockData?.display_name || '';
  }

  get enabled(): boolean {
    return isPresent(this.enabledTime);
  }

  set enabled(value) {
    this.enabledTime = value ? moment().unix() : null;
    if (this.instagramProfile) {
      this.instagramProfile.linkinbioEnabled = value;
    }
  }

  get feedBlocks(): LinkinbioBlockModel[] {
    return this.linkinbioBlocks.filter((block) => block.blockType === BlockTypes.Feed);
  }

  get googleAnalyticsEnabled(): boolean {
    return isPresent(this.enabledGaTime);
  }

  set googleAnalyticsEnabled(value) {
    this.enabledGaTime = value ? moment().unix() : undefined;
  }

  get headerName(): Maybe<string> {
    return this.instagramProfile?.get('name') || this.instagramProfile?.get('nickname');
  }

  get instagramProfile(): SocialProfileModel | undefined {
    return this.#getProfileFromConnectedFeedBlock(this.group.instagramProfiles);
  }

  get lastUpdatedTimeCopy(): string {
    return formattedLastUpdatedTime(this.lastUpdatedTime, this.intl);
  }

  get keenReadKey(): Maybe<string> {
    return this.socialIdentity.get('instagramProfile')?.get('keenReadKey');
  }

  get tiktokProfile(): SocialProfileModel | undefined {
    return this.#getProfileFromConnectedFeedBlock(this.group.tiktokProfiles);
  }

  get url(): string | undefined {
    return isPresent(this.urlName) ? `https://${this.urlDomain}/${this.urlName}` : undefined;
  }

  get urlDomain(): string {
    return isProduction() ? 'linkin.bio' : 'staging.linkin.bio';
  }

  get urlFormatted(): Maybe<SafeString> {
    return isPresent(this.urlName) ? htmlSafe(`https://${this.urlDomain}/<strong>${this.urlName}</strong>`) : undefined;
  }

  get urlName(): string | undefined {
    return this.slug || this.instagramProfile?.get('urlName');
  }

  get urlTruncated(): string | undefined {
    return isPresent(this.urlName) ? `${this.urlDomain}/${this.urlName}` : undefined;
  }

  disable(): void {
    this.enabled = false;

    if (this.instagramProfile) {
      this.instagramProfile.linkinbioEnabled = false;
    }

    if (this.tiktokProfile) {
      this.tiktokProfile.linkinbioEnabled = false;
    }
  }

  saveProfiles(): RSVP.Promise<SocialProfileModel[]> {
    const promises = [];

    if (this.instagramProfile) {
      promises.push(this.instagramProfile.save());
    }

    if (this.tiktokProfile) {
      promises.push(this.tiktokProfile.save());
    }

    return RSVP.all(promises);
  }

  #getProfileFromConnectedFeedBlock(profiles: SocialProfileModel[]): SocialProfileModel | undefined {
    return profiles.find((profile) => this.feedBlocks.some((block) => block.connectedObjectId === profile.get('id')));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-page': LinkinbioPageModel;
  }
}
