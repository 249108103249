import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';

import { ErrorSeverity } from 'later/services/errors';
import { arrayDiff } from 'later/utils/array-methods';
import {
  InsuffcientPermissionsError,
  UnknownLoginError,
  FetchPageError,
  ExtensionBlockedError
} from 'shared/errors/instagram-auth';

import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';

enum FacebookResponseStatus {
  Connected = 'connected',
  NotAuthorized = 'not_authorized',
  Unknown = 'unknown'
}

interface FacebookPagePicture {
  data: {
    height: number;
    is_silhouette: boolean;
    url: string;
    width: number;
  };
}

interface InstagramProfile {
  id: string;
  username: string;
  profile_picture_url: string;
  name: string;
}

interface FacebookPage {
  picture: FacebookPagePicture;
  name: string;
  name_with_location_descriptor: string;
  access_token: string;
  id: string;
  instagram_business_account?: InstagramProfile;
  tasks: string[];
}

interface AccountsResponse {
  data: FacebookPage[];
  error?: {
    message: string;
    type: string;
    code: number;
    error_subcode: number;
    error_user_title: string;
    error_user_msg: string;
    fbtrace_id: string;
  };
  paging?: {
    cursor: {
      after: string;
      before: string;
    };
  };
}

const instagramAdditionalScopes = [
  'business_management',
  'catalog_management',
  'instagram_basic',
  'instagram_content_publish',
  'instagram_manage_comments',
  'instagram_manage_insights',
  'instagram_shopping_tag_products',
  'pages_manage_metadata',
  'pages_manage_posts',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_show_list',
  'read_insights'
] as const;

const fetchAccountsParams = {
  fields:
    'picture,username,name,name_with_location_descriptor,access_token,tasks,instagram_business_account.fields(id,username,profile_picture_url,name)',
  limit: 1000
} as const;

const facebookLoginParams = {
  return_scopes: true,
  scope: instagramAdditionalScopes.join(',')
} as const;

export default class InstagramAuthService extends Service {
  @service declare auth: AuthService;
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare store: StoreService;

  @tracked authResponse?: fb.AuthResponse;
  @tracked facebookPages?: FacebookPage[];
  @tracked showInstagramSelector = false;
  @tracked socialIdentityId?: string;

  get currentUserModel(): UserModel {
    return this.auth.currentUserModel;
  }
  @action
  closeInstagramModal(): void {
    this.showInstagramSelector = false;
    this.facebookPages = [];
    this.authResponse = undefined;
  }

  authenticateWithFacebookGraphApi = dropTask(async (passedInSocialIdentityId: string | null | undefined) => {
    if (this.#facebookSdkDidNotLoad()) {
      return this.#showFacebookSdkError();
    }

    this.socialIdentityId = passedInSocialIdentityId || undefined;
    this.authResponse = undefined;

    this.#loginWithFacebook()
      .then(async (response) => await this._handleFacebookResponse.perform(response))
      .catch((error) => this.#handleErrors(error));
  });

  #facebookSdkDidNotLoad(): boolean {
    return typeof FB === 'undefined';
  }

  #showFacebookSdkError(): void {
    this.errors.log('@service:instagram-auth | Facebook Javascript SDK is unavailable.');
    this.alerts.warning(
      this.intl.t('alerts.account.controllers.groups.facebook.cant_load_sdk', {
        title: this.intl.t('shared_phrases.something_went_wrong')
      })
    );
  }

  #handleErrors(error: InsuffcientPermissionsError | UnknownLoginError | string): void {
    if (error instanceof InsuffcientPermissionsError) {
      this.alerts.warning(
        this.intl.t('errors.instagram_auth.insufficient_permissions.message', { permissions: error.message }),
        {
          title: this.intl.t('errors.instagram_auth.insufficient_permissions.title')
        }
      );
    } else if (error instanceof UnknownLoginError) {
      this.alerts.warning(this.intl.t('errors.instagram_auth.unknown.message'), {
        title: this.intl.t('errors.instagram_auth.unknown.title')
      });
    } else {
      this.alerts.warning(error, { title: this.intl.t('shared_phrases.something_went_wrong') });
    }
  }

  #loginWithFacebook(): Promise<fb.StatusResponse> {
    return new Promise((resolve, reject) => {
      FB.login((response: fb.StatusResponse) => {
        if (response.status === FacebookResponseStatus.Connected) {
          resolve(response);
        } else if (response.status === FacebookResponseStatus.Unknown) {
          reject(new UnknownLoginError(''));
        } else {
          reject(new Error(`Login error: ${response.status}`));
        }
      }, facebookLoginParams);
    });
  }

  _handleFacebookResponse = task(async (response: fb.StatusResponse) => {
    this.#logResponse(response);
    const declinedScopes = this.#getDeclinedScopes(response.authResponse.grantedScopes);
    if (declinedScopes.length) {
      throw new InsuffcientPermissionsError(
        declinedScopes.map((declinedScope) => declinedScope.replace(/_/g, ' ')).join(', ')
      );
    } else {
      this.authResponse = response.authResponse;
      await this._saveUserModelToken.perform(response);
      await this._fetchFacebookPages.perform();
    }
  });

  #logResponse(response: fb.StatusResponse): void {
    this.errors.log(
      '@service:instagram-auth | authenticateWithFacebookGraphApi response',
      { data: JSON.stringify(response) },
      ErrorSeverity.Debug
    );
  }

  #getDeclinedScopes(grantedScopes: string | undefined): string[] {
    return grantedScopes ? arrayDiff(instagramAdditionalScopes, grantedScopes.split(',')) : [];
  }

  _saveUserModelToken = task(async (response: fb.StatusResponse) => {
    try {
      const { userID, accessToken } = response.authResponse;
      this.currentUserModel.setProperties({
        facebookUid: userID,
        facebookToken: accessToken
      });
      this.currentUserModel.save();
    } catch (error) {
      this.errors.log('@service:instagram-auth | Unable to #saveUserModelToken', error);
      throw new Error('Unable to save information from Instagram');
    }
  });

  _fetchFacebookPages = task(async () => {
    this.facebookPages = [];

    await this._fetchPages
      .perform()
      .then((facebookPages: FacebookPage[]) => this.#handleFetchPagesReturn(facebookPages))
      .catch((error) => this.#handleFetchPagesError(error));
  });

  _fetchPages = task(async () => {
    return new Promise((resolve, reject) => {
      FB.api('/me/accounts', fetchAccountsParams, async (accountsResponse: AccountsResponse) => {
        if (!accountsResponse) {
          reject(new FetchPageError());
        }

        if (accountsResponse.error) {
          if (accountsResponse.error.error_subcode == 1357045) {
            reject(new ExtensionBlockedError());
          } else {
            reject(new Error(JSON.stringify(accountsResponse.error)));
          }
        }

        resolve(accountsResponse.data);
      });
    });
  });

  #handleFetchPagesReturn(facebookPages: FacebookPage[]): void {
    this.facebookPages = facebookPages;
    this.showInstagramSelector = true;
  }

  #handleFetchPagesError(error: FetchPageError | ExtensionBlockedError | Error): void {
    if (error instanceof ExtensionBlockedError) {
      this.alerts.warning(this.intl.t('errors.instagram_auth.request_blocked.message'));
    } else if (error instanceof FetchPageError) {
      this.alerts.warning(this.intl.t('errors.instagram_auth.fetch_page.message'));
    } else {
      this.alerts.warning(
        this.intl.t('errors.instagram_auth.fetch_page_subcode.message', {
          subcode: error.message
        })
      );
    }
  }

  createInstagramProfile = task(async (selectedPage: FacebookPage) => {
    if (
      !this.authResponse ||
      !this.authResponse.grantedScopes ||
      !selectedPage.id ||
      !selectedPage.instagram_business_account
    ) {
      return;
    }

    const socialProfile = this.store.createRecord('social-profile');
    const socialProfileAttributes = {
      account: this.auth.currentAccount,
      additionalPermissionScope: this.authResponse.grantedScopes.split(','),
      authorizingFacebookUid: this.authResponse.userID,
      autoPublishFollowUp: true,
      avatarUrl: selectedPage.instagram_business_account.profile_picture_url,
      businessAccountId: selectedPage.instagram_business_account.id,
      businessAccountToken: selectedPage.access_token,
      facebookPageId: selectedPage.id,
      group: this.auth.currentGroup,
      nickname: selectedPage.instagram_business_account.username,
      profileColorClass: 'igpink',
      profileType: 'instagram',
      shorttermUserToken: this.authResponse.accessToken,
      uid: selectedPage.instagram_business_account.id
    };

    socialProfile.setProperties(socialProfileAttributes);

    if (this.socialIdentityId) {
      const socialIdentity = this.store.peekRecord('social-identity', this.socialIdentityId);
      socialProfile.set('socialIdentity', socialIdentity);
    }

    try {
      const savedProfile = await socialProfile.save();
      savedProfile.get('socialIdentity');
      this.alerts.success(
        this.intl.t('alerts.account.controllers.groups.facebook.added', { name: savedProfile.nickname })
      );
    } catch (error) {
      this.errors.log('@service:instagram-auth | Unable to createInstagramProfile', error);
      if (error.isAdapterError && error?.errors?.[0]) {
        this.alerts.alert(error.errors[0], {
          title: this.intl.t('shared_phrases.something_went_wrong')
        });
      } else {
        this.alerts.alert(error, {
          title: this.intl.t('shared_phrases.something_went_wrong')
        });
      }
      socialProfile.rollbackAttributes();
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'social/instagram-auth': InstagramAuthService;
  }
}
