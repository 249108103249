export default function generateTimeslot({ events, calendarStartTime, defaultTimedEventDuration }) {
  const config = {
    rankedDaysIndex: [4, 3, 5, 2, 6, 1, 7], // Note: priority of day to scan for open slot, based on timeGridWeek view
    scanBoundaries: { upper: '08:00', lower: '18:00' }, // Note: only handling hours, so if input is 08:30, the 30 is ignored
    targetTime: '12:00', // Note: will find closest slot available to targetTime, slot includes paddingAroundTimeSlot buffer
    scanInterval: 30, // Note: scan for open blocks of scanInterval (minutes) in calendar
    paddingAroundTimeSlot: 60, // Note: (minutes) before and after timeslot
    targetTimeSlotDuration: 60,
    defaultTimedEventDuration: moment.duration(defaultTimedEventDuration).asMinutes() // Note: media posts don't have an end time, so they use defaultTimedEventDuration
  };

  const firstDayOfCalendarView = calendarStartTime.clone();

  for (const day of config.rankedDaysIndex) {
    const timeSlot = findOpenTimeSlot(day, config, events, firstDayOfCalendarView);
    if (timeSlot) {
      return createTimeSlot(timeSlot, config.paddingAroundTimeSlot);
    }
  }
}

function findOpenTimeSlot(targetDayIndex, config, events, firstDayOfCalendarView) {
  const [startTimeMomentObject, endTimeMomentObject, targetTimeMomentObject] = generateMomentObjects({
    firstDayOfCalendarView,
    scanBoundaries: config.scanBoundaries,
    targetTime: config.targetTime,
    targetDayIndex
  });

  const eventsWithinTimeRange = filterEvents(
    events,
    startTimeMomentObject,
    endTimeMomentObject,
    firstDayOfCalendarView,
    targetDayIndex
  );

  const availabilities = populateAvailabilityArray(eventsWithinTimeRange, config, startTimeMomentObject);

  const { candidates } = scanForCandidates(availabilities, config);

  const timeSlotForButton = selectTimeSlotForButton(
    candidates,
    startTimeMomentObject,
    targetTimeMomentObject,
    config.scanInterval
  );

  return timeSlotForButton.timeSlot;
}

function generateMomentObjects({ firstDayOfCalendarView, scanBoundaries, targetTime, targetDayIndex }) {
  const { upper, lower } = scanBoundaries;
  return [upper, lower, targetTime].map((time) =>
    returnTargetDateAndTime(firstDayOfCalendarView, time, targetDayIndex)
  );
}

function returnTargetDateAndTime(firstDayOfCalendarView, time, dayOfWeek, format) {
  const [hour, minute] = time.split(':');
  return firstDayOfCalendarView
    .clone()
    .set({ hour: parseInt(hour), minute: parseInt(minute) })
    .add(dayOfWeek, 'days')
    .format(format);
}

function selectTimeSlotForButton(candidates, startTimeMomentObject, targetTime, scanInterval) {
  const indicesAndTimes = mapIndexesToTimeslots(candidates, startTimeMomentObject, scanInterval);

  return indicesAndTimes.reduce(
    (result, { timeSlot }) => {
      const timeDifferenceInMinutes = Math.abs(calculateTimeDiffInMinutes(timeSlot, targetTime));

      if (timeDifferenceInMinutes < result.timeDifferenceInMinutes || !result.timeSlot) {
        return { timeDifferenceInMinutes, timeSlot };
      }
      return result;
    },
    { timeDifferenceInMinutes: 0, timeSlot: null }
  );
}

function calculateSteps(event, scanInterval, defaultTimeSlotDuration) {
  const duration = event.end ? Math.ceil(calculateTimeDiffInMinutes(event.end, event.start)) : defaultTimeSlotDuration;
  const intervalsInEvent = duration / scanInterval;
  const shouldIncrementIntervals = moment(event.start).get('minutes') % scanInterval;

  return shouldIncrementIntervals ? intervalsInEvent + 1 : intervalsInEvent;
}

function calculateTimeDiffInMinutes(a, b) {
  return moment(a).startOf('minute').diff(moment(b), 'minutes');
}

function filterEvents(events, startTimeMomentObject, endTimeMomentObject, firstDayOfCalendarView, targetDayIndex) {
  const dayFilteredEvents = filterEventsByDay(events, firstDayOfCalendarView, targetDayIndex);

  const timeFilteredEvents = filterEventsBetweenValidRange(
    dayFilteredEvents,
    startTimeMomentObject,
    endTimeMomentObject
  );

  return timeFilteredEvents;
}

function filterEventsBetweenValidRange(events, startTimeMomentObject, endTimeMomentObject) {
  return events.filter((event) => moment(event.start).isBetween(startTimeMomentObject, endTimeMomentObject));
}

function filterEventsByDay(events, firstDayOfCalendarView, targetDayIndex) {
  const dayWanted = firstDayOfCalendarView.clone().add(targetDayIndex, 'days');
  return events.filter((event) => moment(event.start).isSame(dayWanted, 'day'));
}

function setTimeslotToFalse(availabilities, idx, steps) {
  if (idx < 0) return availabilities;
  for (let i = idx; i < idx + steps; i++) {
    availabilities[i] = false;
  }
  return availabilities;
}

function findTimeslotIdxInAvailabilityArray(startTime, event, scanInterval, defaultTimeSlotDuration) {
  const minutesFromStartOfAvailabilityToEvent = calculateTimeDiffInMinutes(event.start, startTime);
  const idx = Math.floor(minutesFromStartOfAvailabilityToEvent / scanInterval);
  const steps = calculateSteps(event, scanInterval, defaultTimeSlotDuration);
  return [idx, steps];
}

function mapIndexesToTimeslots(indexes, startTimeMomentObject, scanInterval) {
  return indexes.map((idx) => {
    const timeSlot = moment(startTimeMomentObject)
      .clone()
      .add(scanInterval * idx, 'minute');

    return {
      timeSlot,
      index: idx
    };
  });
}

function populateAvailabilityArray(events, config, startTimeMomentObject) {
  return events.reduce(
    (availabilities, event) => {
      const [index, steps] = findTimeslotIdxInAvailabilityArray(
        startTimeMomentObject,
        event,
        config.scanInterval,
        config.defaultTimedEventDuration
      );

      return setTimeslotToFalse(availabilities, index, steps);
    },
    generateBlankStateAvailabilityArray(config.scanBoundaries, config.scanInterval)
  );
}

function generateBlankStateAvailabilityArray(scanBoundaries, scanInterval) {
  const upperBound = parseInt(scanBoundaries.upper.split(':')[0]);
  const lowerBound = parseInt(scanBoundaries.lower.split(':')[0]);

  return Array(calculateTotalHours(upperBound, lowerBound) * (60 / scanInterval)).fill(true);
}

function calculateTotalHours(upperBound, lowerBound) {
  if (lowerBound < upperBound) {
    return 0;
  }
  return lowerBound - upperBound;
}

function scanForCandidates(availabilities, config) {
  const timeSlotsForPadding = (config.paddingAroundTimeSlot / config.scanInterval) * 2;
  const timeSlotsForButton = config.targetTimeSlotDuration / config.scanInterval;
  const consecutiveTimeSlotsNeeded = timeSlotsForPadding + timeSlotsForButton;

  return availabilities.reduce(
    (results, slot, idx) => {
      if (slot) {
        results.current.push(idx);
      } else {
        results.current = [];
      }

      if (results.current.length === consecutiveTimeSlotsNeeded) {
        results.candidates.push(results.current[0]);
        results.current.shift();
      }

      return results;
    },
    { current: [], candidates: [] }
  );
}

function createTimeSlot(timeSlot, paddingAroundTimeSlot) {
  const start = moment(timeSlot).clone().add(paddingAroundTimeSlot, 'minutes').format();
  const end = moment(timeSlot).clone().add({ hour: 1, minute: paddingAroundTimeSlot }).format();
  return { start, end };
}
