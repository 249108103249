import { A } from '@ember/array';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout, restartableTask, dropTask } from 'ember-concurrency';

import { fetch } from 'later/utils/fetch';
import { convert } from 'later/utils/time-format';

import type MutableArray from '@ember/array/mutable';
import type { UntypedService } from 'shared/types';
import type { RawSuggestedHashtag, SuggestedHashtagsResponse } from 'shared/types/hashtag-suggestions';

const DEBOUNCE_TIME_IN_S = 1;

/**
 * This service handles loading caption hashtags for hashtag suggestions.
 */
export default class CaptionHashtagsService extends Service {
  @service declare errors: UntypedService;

  @tracked data: MutableArray<RawSuggestedHashtag> = A();
  @tracked error: Error | null = null;
  @tracked lastCaptionUsed = '';

  get isLoading(): boolean {
    return this.fetchCaptionHashtags.isRunning || this.refetchCaptionHashtags.isRunning;
  }

  /**
   * External method to remove hashtags from the data store
   */
  removeHashtags(hashtags: MutableArray<RawSuggestedHashtag>): void {
    this.data.removeObjects(hashtags);
  }

  /**
   * Cleanup method to clear active processes and data
   */
  handleDeactivate(): void {
    this.data = A();
    this.lastCaptionUsed = '';
    this.error = null;
  }

  /**
   * Re-fetch hashtag suggestions (using a debounce) for a caption
   * and store them in the data or the error property
   *
   * @param socialProfileId - The social profile id
   * @param caption - The caption to search for hashtags
   *
   * @remarks
   *
   * Note: We can "debounce" a task by combining the .restartable()
   * task modifier with a yield timeout at the beginning
   */
  refetchCaptionHashtags = restartableTask(async (socialProfileId: string, caption: string) => {
    if (!this.#isCaptionValid(caption)) {
      return;
    }

    await timeout(convert.seconds(DEBOUNCE_TIME_IN_S).toMilliseconds());
    return await this._refetchCaptionHashtags.perform(socialProfileId, caption);
  });

  /**
   * Fetch hashtag suggestions for a caption and store
   * them in the data or the error property
   *
   * @param socialProfileId - The social profile id
   * @param caption - The caption to search for hashtags
   *
   * @remarks
   */
  fetchCaptionHashtags = dropTask(async (socialProfileId: string, caption: string) => {
    if (!this.#isCaptionValid(caption)) {
      return;
    }

    return await this._refetchCaptionHashtags.perform(socialProfileId, caption);
  });

  _refetchCaptionHashtags = restartableTask(async (socialProfileId: string, caption = '') => {
    try {
      const captionHashtags: SuggestedHashtagsResponse = await fetch('/api/v2/hashtag_suggestions.json', {
        method: 'POST',
        body: {
          post: {
            social_profile_id: socialProfileId,
            caption
          }
        }
      });
      const hashtagSuggestions = captionHashtags.hashtag_suggestions;
      this.lastCaptionUsed = caption;
      this.data = A(hashtagSuggestions);
    } catch (error) {
      this.errors.log(error);
      this.error = error;
    }
  });

  #isCaptionValid(caption: string): boolean {
    return caption?.length > 0 && this.lastCaptionUsed.trim() !== caption.trim();
  }
}

declare module '@ember/service' {
  interface Registry {
    'schedule/caption-hashtags': CaptionHashtagsService;
  }
}
