import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

import type AdapterError from '@ember-data/adapter/error';
import type { AnyObject } from 'active-model-adapter';

type RequestData = (AnyObject | AdapterError) & { url?: string; method?: string };
type Response = { status: number; payload: ActiveModelPayload } | ActiveModelPayload;

interface ActiveModelPayload {
  errors: AnyObject;
}

@classic
export default class InfluencerAdapter extends ApplicationAdapter {
  handleResponse(status: number, _headers: AnyObject, payload: ActiveModelPayload, requestData: RequestData): Response {
    const regex = new RegExp(/.*campaigns$/);
    if (requestData.url?.match(regex)) {
      return { status, payload };
    }
    return payload;
  }
}
