import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class CanScheduleReel extends BaseValidator {
  @service auth;

  validate() {
    if (
      (this.model.isInstagramReel && !this.auth.currentAccount.canInstagramReels) ||
      (this.model.isFacebookReel && !this.auth.currentAccount.canFacebookReels)
    ) {
      return this.createErrorMessage('reels_scheduling_not_available');
    }
    return true;
  }
}

export default CanScheduleReel;
