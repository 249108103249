import { later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { isObject } from 'lodash';

import { fetch } from 'later/utils/fetch';
import { convert, timestamp } from 'later/utils/time-format';

import type IntlService from 'ember-intl/services/intl';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';

interface Offline {
  options: {
    checkOnLoad: boolean;
    requests: boolean;
  };
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
  check: () => void;
}

/**
 * This declares the Offline global only in this service. It should not
 * be used in other files.
 */
declare const Offline: Offline;

/**
 * Wrapper service for the [Offline](https://github.hubspot.com/offline/) library from
 * Hubspot which detects when a browser is no longer connected to the internet.
 *
 * The main purpose of this is to track how long the ember client has been disconnected
 * from the backend servers and will reload the browser page to refresh the frontend
 * client if it has been disconnected longer than 8 hours.
 *
 * On re-connection, it will also ensure that the session that the client has
 * is still valid by making a call to `/api/v2/status`
 */
export default class OfflineService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare intl: IntlService;

  @tracked lastActiveTime: number | null = null;

  /**
   * Initializes the underlying Offline package and sets the 'up' and 'down' listeners.
   * Must be called in the application route so that it runs on all page loads.as
   *
   * @deprecated This service needs to be modernized. This method exists to support legacy functionality
   */
  setup(): void {
    Offline.options = { checkOnLoad: true, requests: false };

    Offline.on('up', () => {
      const lastTimestamp = this.lastActiveTime;
      if (isNone(lastTimestamp)) {
        return;
      }

      const currentTime = timestamp();

      console.log('Got a wake up: ' + (currentTime - lastTimestamp) + '; focus: ' + window.document.hasFocus());
      if (currentTime - lastTimestamp > convert.hours(8).toSeconds() && !window.document.hasFocus()) {
        //user has been offline long enough that we should refresh the page in background -iMack
        window.location.reload();
      } else if (currentTime - lastTimestamp > 30) {
        this.testLogin();
      }

      this.lastActiveTime = null;
    });

    Offline.on('down', () => {
      this.lastActiveTime = timestamp();
    });
  }

  /**
   * Wrapper for the `check()` call on Offline. Forces a check to see if client is still connected to Internet
   */
  checkConnection(): void {
    Offline.check();
  }

  /**
   * Method to test the session that the user has to make sure it is still valid. Ensures that the session is still valid
   * and that it can still access the currently active `Group`. If the login is valid, call
   * `AuthService#setup() to refresh authorization data.
   */
  testLogin(): void {
    if (this.auth.logout.isRunning) {
      return;
    }

    const groupId = this.auth.currentGroup?.id;

    interface StatusResponse {
      is_logged_in: boolean;
      authorized_for_group: boolean;
    }

    if (groupId) {
      fetch(`/status?group_id=${groupId}`).then((data?: StatusResponse) => {
        if (!isObject(data)) {
          return;
        }

        if (data.is_logged_in === false) {
          this.alerts.warning(this.intl.t('alerts.app.offline.login_expired'));
          later(() => {
            window.location.reload();
          }, 2500);
        } else if (data.authorized_for_group === false) {
          this.alerts.warning(this.intl.t('alerts.app.offline.login_different'));
          later(() => {
            window.location.reload();
          }, 2500);
        } else {
          //Refresh account state (mostly to get post count updates) -iMack
          this.auth.setup();
        }
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    offline: OfflineService;
  }
}
