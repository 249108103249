import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import MediaController from 'calendar/controllers/media';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type ErrorsService from 'later/services/errors';

export default class ListMediaController extends MediaController {
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare errors: ErrorsService;

  @action
  editPost(gram_id: string): void {
    this.store
      .findRecord('gram', gram_id)
      .then(() => {
        this.router.transitionTo('cluster.schedule.list.post.edit', gram_id);
      })
      .catch((error) => this.errors.log(error));
  }
}
