/**
 * @module Helpers
 */

/**
 * @class LaterHelpers
 * @extends Helpers
 */

/**
 * Converts the profile color to outline class string for styling.
 *
 * @method profile-color-outline-class
 * @param {String} colorClass Profile color to be converted to class string
 *
 * @return {String} Class string for profile color outline styling
 */
import { helper as buildHelper } from '@ember/component/helper';

export function profileColorOutlineClass([colorClass] /*, hash*/) {
  return 'is--p__' + colorClass;
}

export default buildHelper(profileColorOutlineClass);
