import { gt, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Model, { attr } from '@ember-data/model';

import type IntlService from 'ember-intl/services/intl';
import type { Maybe } from 'shared/types';
import type { PlanType } from 'shared/types/plans';

interface Addon {
  amount: number;
  id: string;
}

interface AddonCost {
  monthly: {
    cents: number;
    dollars: number;
  };
  yearly: {
    cents: number;
    dollars: number;
  };
}

export default class SubscriptionPlanModel extends Model {
  @service declare intl: IntlService;

  @attr() features?: Record<string, boolean>;
  @attr() addOns?: Record<string, Addon>;

  @attr('boolean') declare active: boolean;
  @attr('number') declare amount: number;
  @attr('string') description?: string;
  @attr('string') interval?: string;
  @attr('boolean') declare linkinBioEnabled: boolean;
  @attr('string') name?: string;
  @attr('string', { defaultValue: '' }) declare stripePlanId: string;
  @attr('string') stripeProductId?: string;
  @attr('string', { defaultValue: '' }) declare planType: PlanType;

  @attr('number') numberOfInstagramProfiles?: number;
  @attr('number') numberOfPinterestProfiles?: number;
  @attr('number') numberOfTwitterProfiles?: number;
  @attr('number') numberOfFacebookProfiles?: number;
  @attr('number') numberOfSocialProfiles?: number;

  @attr('number', { defaultValue: 1 }) declare numberOfSocialSets: number;
  @attr('number', { defaultValue: 1 }) declare numberOfUsers: number;
  @attr('number', { defaultValue: 0 }) declare numberOfAiCredits: number;

  @attr('number', { defaultValue: 0 }) declare numberOfPosts: number;
  @attr('number') numberOfInstagramPosts?: number;
  @attr('number') numberOfTwitterPosts?: number;
  @attr('number') numberOfPinterestPosts?: number;
  @attr('number') numberOfFacebookPosts?: number;

  @attr('number', { defaultValue: 0 }) declare numberOfBttpSuggestions: number;
  @attr('number', { defaultValue: 0 }) declare numberOfCollectTags: number;
  @attr('number', { defaultValue: 0 }) declare numberOfHashtagSearches: number;
  @attr('number') numberOfLinkinbioButtonGroups?: number;
  @attr('number') numberOfLinkinbioFeaturedBanners?: number;
  @attr('number', { defaultValue: 0 }) declare numberOfLinkinbioFeeds: number;
  @attr('number', { defaultValue: 0 }) declare numberOfLinkinbioFeaturedMediaCustomMedia: number;
  @attr('number', { defaultValue: 0 }) declare numberOfLinkinbioMultiItemBannerItems: number;

  @gt('amount', 0) declare isPaid: boolean;
  @equal('normalizedInterval', 'yearly') declare isYearly: boolean;
  @equal('normalizedInterval', 'monthly') declare isMonthly: boolean;

  get displayName(): string {
    return this.intl.t(`plans.plan_names.${this.planType}`) || this.name || '';
  }

  get pricingVersion(): number {
    const versionRegex = /\_[v][\d]{1,}$/g;
    const defaultPricingVersion = 1;
    const hasVersion = new RegExp(versionRegex).test(this.stripePlanId);

    if (hasVersion) {
      // Find the "_v1" and remove the "_v"
      const parsedVersion = this.stripePlanId.match(versionRegex)?.[0].substring(2);
      return parsedVersion ? parseInt(parsedVersion) : defaultPricingVersion;
    }

    return defaultPricingVersion;
  }

  get rolloutVersion(): Maybe<number> {
    const rolloutRegex = /\_[r][\d]{1,}/g;
    const defaultRolloutVersion = null;
    const hasRollout = new RegExp(rolloutRegex).test(this.stripePlanId);

    if (this.pricingVersion > 1 && hasRollout) {
      // Find the "_r1" and remove the "_r"
      const parsedVersion = this.stripePlanId.match(rolloutRegex)?.[0].substring(2);
      return parsedVersion ? parseInt(parsedVersion) : defaultRolloutVersion;
    }

    return defaultRolloutVersion;
  }

  get additionalAiCreditCost(): number {
    return this.addOns?.additional_ai_credits?.amount ?? 0;
  }

  get additionalSocialSetCost(): number {
    return this.addOns?.additional_social_sets?.amount || 0;
  }

  get additionalAiCreditCosts(): AddonCost {
    const MONTHS_PER_YEAR = 12;
    const cost = this.additionalAiCreditCost;
    const costInDollars = (cost || 0) / 100;

    return {
      monthly: {
        cents: this.isMonthly ? cost : cost / MONTHS_PER_YEAR,
        dollars: this.isMonthly ? costInDollars : costInDollars / MONTHS_PER_YEAR
      },
      yearly: {
        cents: this.isMonthly ? (cost / MONTHS_PER_YEAR) * 10 : cost,
        dollars: this.isMonthly ? (cost / MONTHS_PER_YEAR) * 10 : costInDollars
      }
    };
  }

  get additionalSocialSetCosts(): AddonCost {
    const MONTHS_PER_YEAR = 12;
    const cost = this.additionalSocialSetCost;
    const costInDollars = (cost || 0) / 100;

    return {
      monthly: {
        cents: this.isMonthly ? cost : cost / MONTHS_PER_YEAR,
        dollars: this.isMonthly ? costInDollars : costInDollars / MONTHS_PER_YEAR
      },
      yearly: {
        cents: this.isMonthly ? (cost / MONTHS_PER_YEAR) * 10 : cost,
        dollars: this.isMonthly ? (cost / MONTHS_PER_YEAR) * 10 : costInDollars
      }
    };
  }

  get additionalUserCost(): number {
    return this.addOns?.additional_users?.amount || 0;
  }

  get additionalUserCosts(): AddonCost {
    const MONTHS_PER_YEAR = 12;
    const cost = this.additionalUserCost;
    const costInDollars = (cost || 0) / 100;

    return {
      monthly: {
        cents: this.isMonthly ? cost : cost / MONTHS_PER_YEAR,
        dollars: this.isMonthly ? costInDollars : costInDollars / MONTHS_PER_YEAR
      },
      yearly: {
        cents: this.isMonthly ? (cost / MONTHS_PER_YEAR) * 10 : cost,
        dollars: this.isMonthly ? (cost / MONTHS_PER_YEAR) * 10 : costInDollars
      }
    };
  }

  get canAddAiCredits(): boolean {
    return isPresent(this.addOns?.additional_ai_credits?.id);
  }

  get canAddUsers(): boolean {
    return isPresent(this.addOns?.additional_users?.id);
  }

  get canAddSocialSets(): boolean {
    return isPresent(this.addOns?.additional_social_sets?.id);
  }

  get canAddons(): boolean {
    return this.canAddUsers || this.canAddSocialSets;
  }

  get canSearchHashtags(): boolean {
    return this.features?.hashtag_suggestions || false;
  }

  get canMultipleLinkinbioFeeds(): boolean {
    return this.numberOfLinkinbioFeeds > 1;
  }

  get downgradeAnalyticsCopy(): string {
    return this.features?.analytics_standard && this.features.linkinbio_extended_click_analytics
      ? this.intl.t('account.downgrade.longer_analytics')
      : this.intl.t('plans.analytics_limited');
  }

  get hasLimitedBttpSuggestions(): boolean {
    const maxBttpSuggestions = 7;
    return this.numberOfBttpSuggestions > 0 && this.numberOfBttpSuggestions < maxBttpSuggestions;
  }

  get hasLimitedCollectTags(): boolean {
    return this.numberOfCollectTags > 0;
  }

  get hasLimitedHashtagSearches(): boolean {
    return this.numberOfHashtagSearches > 0;
  }

  get hasLimitedPosts(): boolean {
    return this.numberOfPosts > -1;
  }

  get instagramAnalyticsType(): string {
    return this.features?.analytics_standard
      ? this.intl.t('account.plans.analytics_standard')
      : this.intl.t('account.plans.analytics_basic');
  }

  get normalizedInterval(): string {
    if (this.interval === 'year' || this.interval === 'yearly') {
      return 'yearly';
    } else if (this.interval === 'month' || this.interval === 'monthly') {
      return 'monthly';
    }
    return '';
  }

  get intervalName(): string {
    return this.normalizedInterval === 'monthly'
      ? this.intl.t('plans.interval.monthly')
      : this.intl.t('plans.interval.yearly');
  }

  get imageSizeLimit(): number {
    const largeMb = 20;
    const limitedMb = 5;
    return this.features?.upload_large_media ? largeMb : limitedMb;
  }

  get readablePosts(): string {
    return this.hasLimitedPosts
      ? this.numberOfPosts.toString()
      : this.intl.t('account.overview.post_usage.no_post_limit');
  }

  get videoSizeLimit(): number {
    const largeMb = 512;
    const limitedMb = 25;
    return this.features?.upload_large_media ? largeMb : limitedMb;
  }

  get numOfLinkinbioLinkedSocialPosts(): number {
    const largeSocialPosts = 5;
    const limitedPosts = 1;
    return this.features?.linkinbio_many_linked_social_posts ? largeSocialPosts : limitedPosts;
  }

  get igAnalyticsData(): string {
    return this.features?.analytics_standard
      ? this.intl.t('authorization.sign_up_trial.analytics_full')
      : this.intl.t('authorization.sign_up_trial.analytics_limited');
  }

  get isAgencyPlan(): boolean {
    return this.planType?.toLowerCase().includes('scale') || false;
  }

  get isAgency30Plan(): boolean {
    return (this.isAgencyPlan && this.planType?.includes('30')) || false;
  }

  get isAgency15Plan(): boolean {
    return (this.isAgencyPlan && this.planType?.includes('15')) || false;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-plan': SubscriptionPlanModel;
  }
}
