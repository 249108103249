import Service, { inject as service } from '@ember/service';
import { tracked, TrackedArray } from 'tracked-built-ins';

import type Store from '@ember-data/store';
import type MediaItemModel from 'later/models/media-item';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type SegmentService from 'later/services/segment';

export default class StarredMediaService extends Service {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare segment: SegmentService;
  @service declare store: Store;

  @tracked starredMediaItems = new TrackedArray();

  get currentGroupId(): string {
    return this.auth.currentGroup.id;
  }

  async initStarredMedia(): Promise<void> {
    const starredMediaIds = this.cache.retrieve<string[]>('starred_media_group_' + this.currentGroupId) ?? [];
    this.starredMediaItems.clear();
    if (starredMediaIds?.length > 0) {
      const mediaItems = await this.store.query('media-item', {
        group_id: this.currentGroupId,
        filter: { ids: starredMediaIds }
      });
      mediaItems.forEach((mediaItem) => {
        mediaItem.set('isStarred', true);
      });
      this.starredMediaItems.addObjects(mediaItems);
    }
  }

  starMediaBulk(selectedAssets: MediaItemModel[]): void {
    this.starredMediaItems.addObjects(selectedAssets);
    selectedAssets.forEach((mediaItem) => {
      mediaItem.set('isStarred', true);
    });
    const mediaItemIds = selectedAssets.map((mediaItem) => mediaItem.id);
    const mediaItemCount = selectedAssets.length;
    this.segment.track('starred-media', {
      group_id: this.currentGroupId,
      media_count: mediaItemCount,
      media_ids: mediaItemIds
    });
    this.#starredMediaChanged();
  }

  unstarAll(): void {
    this.starredMediaItems.forEach((mediaItem: MediaItemModel) => {
      mediaItem.set('isStarred', false);
    });
    this.starredMediaItems.clear();
    this.#starredMediaChanged();
  }

  addStarred(mediaItem: MediaItemModel): void {
    this.starredMediaItems.addObject(mediaItem);
    mediaItem.set('isStarred', true);
    this.segment.track('starred-media', {
      group_id: this.currentGroupId,
      media_count: 1,
      media_ids: mediaItem.id
    });
    this.#starredMediaChanged();
  }

  removeStarred(mediaItem: MediaItemModel): void {
    this.starredMediaItems.removeObject(mediaItem);
    mediaItem.set('isStarred', false);
    this.#starredMediaChanged();
  }

  #starredMediaChanged(): void {
    const starredMediaIds = this.starredMediaItems.map((media: MediaItemModel) => media.id);
    this.cache.add('starred_media_group_' + this.currentGroupId, starredMediaIds, {
      expiry: this.cache.maxExpiryDate(),
      persist: true
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'starred-media': StarredMediaService;
  }
}
