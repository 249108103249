import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import NProgress from 'nprogress';

@classic
export default class AddController extends Controller {
  @service
  alerts;

  @service
  errors;

  @service
  intl;

  @service
  segment;

  @action
  addToLibrary() {
    NProgress.start();
    const { mediaItem } = this;
    mediaItem
      .save()
      .then(() => {
        NProgress.done();
        this._track(mediaItem);
        this.alerts.success(this.intl.t('alerts.extension.save_success'));
        later(() => {
          this.closeExtensionFrame();
        }, 2000);
      })
      .catch((error) => {
        NProgress.done();
        this.errors.log(error);
        this.alerts.warning(this.intl.t('alerts.generic_error_message'), {
          title: this.intl.t('alerts.extension.save_failure.title')
        });
      });
  }

  @action
  setGroup(group, closeAction) {
    this.set('mediaItem.group', group);
    this.set('currentGroup', group);
    closeAction();
  }

  @action
  closeFrame() {
    this.closeExtensionFrame();
  }

  closeExtensionFrame() {
    parent.postMessage({ close_later_extension: true }, '*');
  }

  _track(mediaItem) {
    const { type, link, standardResUrl, processingUrl, sourceUrl } = mediaItem || {};
    const isFromSpark = /spark\.adobe/.test(sourceUrl);
    const sourceType = isFromSpark ? 'spark' : 'chrome_extension';
    const socialProfile = this.get('auth.currentSocialProfile');
    this.segment.track('imported-media', {
      source: sourceType,
      source_image_url: processingUrl,
      source_page_url: sourceUrl,
      media_url: processingUrl
    });
    this.segment.track(`added-to-library-from-${sourceType}`, {
      base_url: link,
      media_type: type,
      media_url: standardResUrl,
      social_profile: socialProfile ? socialProfile.get('nickname') : '',
      industry: socialProfile ? socialProfile.get('industry') : ''
    });
  }
}
