import Model, { attr, belongsTo } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';

import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type { Maybe } from 'shared/types';

export type RawGeneratedCaption = {
  created_at: number;
  generated_text: string;
  generation_id: string;
  id: number;
  original_generated_caption_id: number | null;
  platform_error: string;
  platform_warning: string;
  post_id: number;
  reaction: string;
  social_profile_id: number;
  topic: string;
  tone?: string | undefined;
  updated_at: number;
  used_at: number;
  variant: 'rephrase_1' | 'refine_tone_1';
};

type GeneratePayload = {
  locale: string;
  social_profile_id: string;
  topic: string;
  tone?: string;
};

type RefinePayload = {
  change: 'tone' | 'rephrase';
  social_profile_id: string;
  text?: string;
  original_generated_caption_id?: string;
  change_parameters?: {
    tone: string;
  };
};

type RawGenerateResponse = {
  generated_captions?: RawGeneratedCaption[];
  errors?: string[];
};

/**
 * Note: the variant keys are still changing as we experiment in the BE,
 * so for now, we can keep this loose.
 */
type GeneratedCaptionVariant = string;

export default class GeneratedCaptionModel extends Model {
  @belongsTo('socialProfile') declare socialProfile: SocialProfileModel;
  @belongsTo('gram') declare gram: Maybe<GramModel>;

  /**
   * A generated caption can be refined. A refinement is a `GeneratedCaptionModel` instance.
   * This relationship maintains a refinement's original source.
   */
  @belongsTo('generated-caption', { inverse: null, async: false })
  declare originalGeneratedCaption?: GeneratedCaptionModel;

  @attr('number') declare createdAt: number;
  @attr('number') declare updatedAt: number;
  @attr('string') declare generatedText: string;
  @attr('string') declare generationId: string;
  @attr('string') declare platformError: string;
  @attr('string') declare platformWarning: string;
  @attr('string') declare topic: string;
  @attr('string') declare tone: string;
  @attr('number') declare usedAt: number;
  @attr('string') declare variant: GeneratedCaptionVariant;

  /**
   * Checks if this caption is the original caption in which all predecessors
   * are generated from.
   */
  get isGenesis(): boolean {
    return this === this.genesis();
  }

  get isRephrase(): boolean {
    return /^rephrase/.test(this.get('variant'));
  }

  get isToneChange(): boolean {
    return /^refine_tone/.test(this.get('variant'));
  }

  get isRefinement(): boolean {
    return this.isRephrase || this.isToneChange;
  }

  /**
   * If this instance is a refinement but also the originating caption (gensis)
   * then we can assume it was refined from a non-generated-caption (ex. directly
   * from the post modal's caption input field).
   */
  get isFromExistingCaption(): boolean {
    return this.isGenesis && this.isRefinement;
  }

  generate = collectionAction<GeneratePayload, RawGenerateResponse>({
    path: 'generate',
    type: 'POST',
    after(this: GeneratedCaptionModel, response: RawGenerateResponse) {
      this.store.pushPayload(response);
      return response;
    }
  });

  refine = collectionAction<RefinePayload, RawGenerateResponse>({
    path: 'refine',
    type: 'POST',
    before(this: GeneratedCaptionModel, payload) {
      payload.original_generated_caption_id = this.get('id');
      payload.text = this.get('generatedText');
      return payload;
    },
    after(this: GeneratedCaptionModel, response: RawGenerateResponse) {
      this.store.pushPayload(response);
      return response;
    }
  });

  parent(): GeneratedCaptionModel | undefined {
    const parent = this.get('originalGeneratedCaption');
    if (!parent) {
      return undefined;
    }

    // Note: a rephrased caption's parent should be the last non-rephrasing!
    // this is because you can continuously rephrase captions and they should
    // be allowed to group together
    if (parent.isRephrase) {
      if (parent.isFromExistingCaption) {
        return parent;
      }

      return parent.parent();
    }

    return parent;
  }

  /**
   * Return the caption in which all refinements originated from, or itself if it's
   * not a refinement.
   * @returns Genesis caption or self
   */
  genesis(): GeneratedCaptionModel {
    const parent = this.get('originalGeneratedCaption');
    if (!parent) {
      return this;
    }
    return parent.genesis();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'generated-caption': GeneratedCaptionModel;
  }
}
