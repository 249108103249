/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import { preciseRound } from '@latermedia/ember-later-analytics/utils';

import { extractValues } from 'later/utils/array-filters';

import type HelpersAnalyticsService from '../helpers-analytics';
import type { SafeString } from '@ember/template/-private/handlebars';
import type { AnalyticsData } from 'shared/types/analytics-data';

interface TableRow {
  [key: string]: AnalyticsData;
}

interface TableRowInput {
  name: string;
  all: any[];
  value: any;
}

/**
 * @class FormattersTableService
 * @extends Service
 */
export default class TableService extends Service {
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;

  /**
   * Formats a post's caption, such that empty captions are cleared
   *
   * @method formatCaption
   * @param {String} caption
   *
   * @returns {String} formatted caption
   */
  formatCaption(caption: string): string | undefined {
    return caption === ' ' ? undefined : caption;
  }

  /**
   * Takes given width (%) and creates a html safe width
   * attribute to be applied to any element
   *
   * @method createElementWidthAttr
   * @param {Number} widthAsPercent
   *
   * @returns {String} HTML Safe version of width attribute
   */
  createElementWidthAttr(widthAsPercent: string | number): SafeString {
    if (widthAsPercent === '-' || !this.isValid(widthAsPercent)) {
      return htmlSafe('width:0%');
    }
    return htmlSafe(`width:${Number(widthAsPercent).toFixed(0)}%`);
  }

  /**
   * Scales a number value, based on the minimum
   * and maximum of the given values
   *
   * @method scaleValue
   * @param {Array} values Array of values
   * @param {Number} currentValue Current value
   *
   * @return {String} The scaled percentage for the given value.
   */
  scaleValue(values: number[], currentValue: number): string {
    const currentValueSanitized = this.isValid(currentValue) ? currentValue : 0;
    return this.helpersAnalytics.scaleNumber(Math.min(...values), Math.max(...values), currentValueSanitized);
  }

  /**
   * Builds a default table object, to be
   * used when the table is loading
   *
   * @method buildDefaultTableObject
   * @param {Number} [htmlValue=0]
   * @param {Boolean} [isScaled=true]
   *
   * @return {Object} default table object
   */
  buildDefaultTableObject(htmlValue = 0, isScaled = true): AnalyticsData {
    return {
      value: '-',
      CSVValue: undefined,
      raw: undefined,
      scaled: isScaled ? htmlSafe(htmlValue.toString()) : undefined
    };
  }

  /**
   * Builds a single table row object from the given values
   *
   * @method buildTableRowItem
   * @param {Number} value
   * @param {Boolean} scaledValue
   *
   * @return {Object} table object
   */
  buildTableRowItem(value: number, scaledValue: string): AnalyticsData {
    return {
      value: this.isValid(value) ? Number(value).toLocaleString() : '-',
      CSVValue: this.isValid(value) ? preciseRound(value, 0) : undefined,
      raw: this._formatRawValue(value),
      scaled: htmlSafe(scaledValue)
    };
  }

  /**
   * Takes any number of arrays containing table row values,
   * and creates one concatonated array of table row objects
   *
   * @method buildTableRow
   * @param {...Array} objectArrays
   *
   * @return {Array} Array of table objects
   */
  buildTableRow(...objectArrays: any): TableRow[] {
    return []
      .concat(...objectArrays)
      .map(({ name, all, value }) => ({
        name,
        scaled: this.scaleValue(all, value),
        value
      }))
      .map(({ name, scaled, value }) => ({ [name]: this.buildTableRowItem(value, scaled) }));
  }

  /**
   * Takes an array of desired table column names, and generates
   * a formatted object for each, including the name,
   * possible values, and current value
   *
   * @method getValues
   * @param {Array} columnNames
   * @param {Object} mediaItem
   * @param {Array} arrayOfMedia
   *
   * @return {Array} Array of formatted table values
   */
  getValues(columnNames: string[], mediaItem: any, arrayOfMedia: any[]): TableRowInput[] {
    return columnNames.map((name) => ({
      name,
      all: this.getValidValues(arrayOfMedia, name),
      value: mediaItem[name]
    }));
  }

  /**
   * Takes a key name, and extracts that key out of every object in the array
   *
   * @method getValidValues
   * @param {Array} arrayOfObjects
   * @param {String} key
   *
   * @return {Array} Array of extracted values according to given key name
   */
  getValidValues(arrayOfObjects: any[], key: string): any[] {
    return extractValues(arrayOfObjects, key).filter((item) => this.isValid(item));
  }

  /**
   * Determines if a given value is valid within the table
   *
   * @method isValid
   * @param {Number|String} value
   *
   * @return {Boolean} Whether the given value is a valid table entry
   */
  isValid(value: string | number): boolean {
    return value !== 'N/A' && typeof value !== 'string' && isPresent(value);
  }

  /**
   * Takes a followers value, and formats it for table display
   *
   * @method formatFollowersCount
   * @param {Number|String} followers
   *
   * @return {String} Formatted followers count
   */
  formatFollowersCount(followers: string | number): string {
    if (this.isValid(followers)) {
      return Number(followers).toLocaleString();
    }
    return '-';
  }

  /**
   * Takes a raw value, and nulls it, if it is not valid.
   *
   * @protected
   * @method _formatRawValue
   * @param {Number|String} rawValue
   *
   * @return {Number|String} Formatted raw value
   */
  _formatRawValue(rawValue: string | number): string | number | undefined {
    return this.isValid(rawValue) ? rawValue : undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/formatters/table': TableService;
  }
}
