import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div\n  class=\"eCA--stories cCA--calendar--timeline\"\n  id=\"stories-calendar\"\n  {{did-insert this.createCalendar}}\n  {{did-update this.updateEvents this.profiles this.cachedData}}\n>\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div\n  class=\"eCA--stories cCA--calendar--timeline\"\n  id=\"stories-calendar\"\n  {{did-insert this.createCalendar}}\n  {{did-update this.updateEvents this.profiles this.cachedData}}\n>\n</div>","moduleName":"later/components/schedule/stories/full-calendar.hbs","parseOptions":{"srcName":"later/components/schedule/stories/full-calendar.hbs"}});
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isNone, isEmpty } from '@ember/utils';
import { Calendar } from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import Component from '@glimmer/component';
import { task, dropTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import PerfectScrollbar from 'perfect-scrollbar';

import { profileColorOutlineClass } from 'later/helpers/profile-color-outline-class';
import { CALENDAR_EVENT_TYPES, CALENDAR_TYPES, CALENDAR_VIEW_TYPES } from 'later/utils/constants';

import type RouterService from '@ember/routing/router-service';
import type { EventSourceInput } from '@fullcalendar/core';
import type { CustomButtons } from 'calendar/types/full-calendar';
import type IntlService from 'ember-intl/services/intl';
import type CalendarEventModel from 'later/models/calendar-event';
import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ConfigService from 'later/services/calendar/config';
import type CalendarEventsService from 'later/services/calendar/events';
import type MarkupService from 'later/services/calendar/markup';
import type PostsPendingApprovalService from 'later/services/schedule/posts-pending-approval';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { UntypedService } from 'shared/types';

const { WEEK, MONTH } = CALENDAR_VIEW_TYPES;
const { STORIES } = CALENDAR_TYPES;
const { POST } = CALENDAR_EVENT_TYPES;

interface StoriesFullCalendarComponentArgs {
  posts: GramModel[];
  targetDate: string;
  updateTargetDate: (dateInCurrentRange: number) => void;
  viewPreviewGrid: () => void;
  viewWeekCalendar: () => void;
  viewMonthCalendar: () => void;
}

export default class StoriesFullCalendarComponent extends Component<StoriesFullCalendarComponentArgs> {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare dialogManager: UntypedService;
  @service declare intl: IntlService;
  @service declare locale: UntypedService;
  @service declare router: RouterService;
  @service('calendar/markup') declare calendarMarkup: MarkupService;
  @service('calendar/events') declare calendarEvents: CalendarEventsService;
  @service('calendar/config') declare calendarConfig: ConfigService;
  @service('schedule/posts-pending-approval') declare postsPendingApproval: PostsPendingApprovalService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare userConfig: UserConfigService;
  @service declare errors: UntypedService;

  @readOnly('auth.currentFirstDay') declare firstDay: number;
  @readOnly('auth.currentUserModel') declare currentUserModel: UserModel;
  @readOnly('currentUserModel.timeZone') declare timeZone: string;
  @readOnly('selectedSocialProfiles.firstProfile') declare socialProfile: SocialProfileModel;
  @readOnly('selectedSocialProfiles.profiles') declare profiles: SocialProfileModel[];
  @readOnly('userConfig.currentTimeZone.identifier') declare timeZoneIdentifier: string;
  @readOnly('userConfig.currentTimeZone.name') declare timeZoneName: string;

  constructor(owner: unknown, args: StoriesFullCalendarComponentArgs) {
    super(owner, args);
    this.postsPendingApproval.fetchCount.perform();
  }

  get cachedData(): { cachePosts: GramModel[] } {
    return {
      cachePosts: this.args.posts
    };
  }

  get calendarTimeZone(): string {
    return isNone(this.timeZoneIdentifier) ? 'local' : this.timeZoneIdentifier;
  }

  get customButtons(): CustomButtons {
    return {
      previewButton: {
        text: this.intl.t('calendar.views.preview'),
        click: () => this.args.viewPreviewGrid()
      },
      timezoneButton: {
        text: this.timeZoneName
      },
      storiesButton: {
        text: this.intl.t('calendar.views.stories'),
        click: () => undefined
      },
      weekButton: {
        text: this.intl.t('calendar.views.week'),
        click: () => this.args.viewWeekCalendar()
      },
      monthButton: {
        text: this.intl.t('calendar.views.month'),
        click: () => this.args.viewMonthCalendar()
      },
      listButton: {
        text: this.intl.t('calendar.views.list'),
        click: () =>
          this.router.transitionTo('cluster.schedule.list', { queryParams: this.router.currentRoute.queryParams })
      }
    };
  }

  @action
  createCalendar(): void {
    this.#checkValidProfiles();
    this.calendarConfig.calendarType = STORIES;

    const calendarEl = document.getElementById('stories-calendar');

    if (!calendarEl) {
      return this.errors.log('Stories/FullCalendar: No calendar element found', {}, 'critical');
    }

    const calendar = new Calendar(calendarEl, {
      ...this.calendarConfig.commonConfig,
      aspectRatio: 1.65,
      buttonIcons: {
        prev: 'left',
        next: 'right'
      },
      customButtons: this.customButtons,
      dayPopoverFormat: { weekday: 'short', month: 'short', day: 'numeric' },
      defaultTimedEventDuration: this.calendarEvents.defaultTimedEventDuration,
      droppable: false,
      editable: false,
      eventBorderColor: 'transparent',
      eventDurationEditable: false,
      firstDay: isNone(this.firstDay) ? 0 : this.firstDay,

      initialDate: this.calendarConfig.targetDateObject(this.args.targetDate),
      initialView: WEEK,
      locales: [deLocale, esLocale, frLocale],
      locale: this.locale.primaryLocale,
      now: moment().tz(this.calendarTimeZone).format(),

      views: {
        [WEEK]: {
          dayHeaderFormat: { weekday: 'short', day: 'numeric' },
          titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false
          }
        },
        [MONTH]: {
          eventLimit: 2,
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short'
          }
        }
      },

      eventContent: ({ event }): { domNodes: HTMLDivElement[] } | undefined => {
        const { extendedProps } = event;

        if (extendedProps.eventType !== POST) return;

        const eventContentWrapper = document.createElement('div');
        const postTimeContainer = document.createElement('div');
        const postContentContainer = document.createElement('div');
        const postIconContainer = document.createElement('div');
        const postElements = {
          postTimeContainer,
          postContentContainer,
          postIconContainer,
          eventContentWrapper
        };
        this.calendarMarkup.updateCommonPostMarkup(postElements, event);

        eventContentWrapper.appendChild(postTimeContainer);
        eventContentWrapper.appendChild(postContentContainer);
        eventContentWrapper.appendChild(postIconContainer);

        return { domNodes: [eventContentWrapper] };
      },

      eventDidMount: ({ el, event }): void => {
        const { extendedProps } = event;

        if (extendedProps.eventType !== POST) return;

        const { profileColorClass } = extendedProps;
        event.setProp('editable', false);
        event.setProp('allDay', false);
        event.setExtendedProp('profileColorClass', profileColorOutlineClass(profileColorClass));

        const postElement = el.parentElement;
        if (!postElement) {
          return this.errors.log('No post element found', { el, event, postElement });
        }

        postElement.classList.add('fc-post-harness');
      },

      viewDidMount: ({ view }): void => {
        this.calendarConfig.set('calendarView', view.type);
        const scrollContainer = document.querySelector('.fc-scrollgrid-section-body');
        if (!scrollContainer) {
          return this.errors.log('Stories/FullCalendar: No scroll container element found', {}, 'critical');
        }
        new PerfectScrollbar(scrollContainer);
      }
    });

    this.calendarConfig.set('fullCalendar', calendar);
    this.calendarConfig.set('calendarView', STORIES);
    calendar.render();

    this.calendarMarkup.updateStoriesButton();

    this.calendarConfig.handleWithProgressBar.perform(() => {
      this._addEventsToCalendar.perform(calendar);
    });

    // Note: Remove and add event sources based on new dates, update dates for 'vis'
    calendar.on('datesSet', ({ start, end }) =>
      this.calendarConfig.handleWithProgressBar.perform(() => {
        this._handleDateSet.perform(start, end);
      })
    );
  }

  @action
  updateEvents(): void {
    this._updateEvents.perform();
  }

  #updateCalendarDates(start: Date, end: Date): void {
    if (typeof this.args.updateTargetDate === 'function' && this.calendarConfig.fullCalendar) {
      const targetDate = this.calendarConfig.fullCalendar?.getDate();
      const formattedDate = moment(targetDate).valueOf();
      this.args.updateTargetDate(formattedDate);
    }
    this.calendarConfig.startTime = start;
    this.calendarConfig.endTime = end;
  }

  #checkValidProfiles(): void {
    if (!this.socialProfile.isInstagram || this.selectedSocialProfiles.hasMultipleSelected) {
      this.alerts.warning(this.intl.t('alerts.stories.only_with_instagram.message'), {
        title: this.intl.t('alerts.stories.only_with_instagram.title')
      });

      // Note: Required for circumstances where the social profile would update during an aborted transition in
      // app/routes/cluster/schedule/stories.js
      if (!this.dialogManager.showConfirmationModal) {
        this.router.transitionTo('account.groups.group.social_profiles', this.auth.currentGroup.id);
      }
    }
  }

  _addEventsToCalendar = task(async (calendar: Calendar) => {
    this.#updateCalendarDates(calendar.view.activeStart, calendar.view.activeEnd);

    const events = await this._fetchPostEvents.perform();
    calendar.removeAllEvents();
    await calendar.addEventSource(events as EventSourceInput);
  });

  _handleDateSet = task(async (start: Date, end: Date) => {
    await this._updateEvents.perform({ start, end });
    this.#updateCalendarDates(start, end);
  });

  _updateEvents = dropTask(async (fetchInfo = this.calendarEvents.defaultFetchInfo) => {
    this.#checkValidProfiles();

    const { fullCalendar } = this.calendarConfig;
    const events = await this._fetchPostEvents.perform(fetchInfo);
    this.calendarEvents.prefetch.perform(fetchInfo.start);
    await fullCalendar?.removeAllEvents();
    await fullCalendar?.addEventSource(events as EventSourceInput);
  });

  _fetchPostEvents = task(async (fetchInfo = this.calendarEvents.defaultFetchInfo) => {
    const events = await this.calendarEvents.getEvents.perform(this.cachedData, fetchInfo);
    return isEmpty(events) ? [] : events.filter((event: CalendarEventModel) => event.eventType === POST);
  });
}
