import freeEmailDomains from './static-data/free-email-domains';

const isFreeEmail = (email: string): boolean => {
  const domainFromEmail = email.match(/@(.+)/)?.[1];
  if (!domainFromEmail) {
    return false;
  }

  return freeEmailDomains.includes(domainFromEmail);
};

export { isFreeEmail };
