import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div class={{concat-classes \"cCA--account__item\" @profileClass}}>\n  <Shared::Ui::Button\n    @onClick={{perform this.addSocialProfile}}\n    @variant=\"unstyled\"\n    class=\"cCA--user o--user is--unselected u--p__0\"\n    data-test-id=\"schedule-add-social-profile-button\"\n  >\n    <img class=\"o--user__avatar\" src={{@profileImage}} />\n    <span class=\"cCA--user__action\">\n      {{svg-jar \"add\" class=\"o--icon--xs o--icon--white\"}}\n    </span>\n  </Shared::Ui::Button>\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div class={{concat-classes \"cCA--account__item\" @profileClass}}>\n  <Shared::Ui::Button\n    @onClick={{perform this.addSocialProfile}}\n    @variant=\"unstyled\"\n    class=\"cCA--user o--user is--unselected u--p__0\"\n    data-test-id=\"schedule-add-social-profile-button\"\n  >\n    <img class=\"o--user__avatar\" src={{@profileImage}} />\n    <span class=\"cCA--user__action\">\n      {{svg-jar \"add\" class=\"o--icon--xs o--icon--white\"}}\n    </span>\n  </Shared::Ui::Button>\n</div>","moduleName":"later/components/schedule/social-profiles/add-profile-link.hbs","parseOptions":{"srcName":"later/components/schedule/social-profiles/add-profile-link.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency';

import { SOCIAL_PROFILE_CODES } from 'later/utils/constants';

import type GroupModel from 'later/models/group';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ConnectProfilesService from 'later/services/social/connect-profiles';

interface AddProfileLinkComponentArgs {
  selectedSocialProfileType: string;
  currentGroup: GroupModel;
  profileImage: string;
  profileClass: string;
}

export default class AddProfileLinkComponent extends Component<AddProfileLinkComponentArgs> {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  get socialIdentityId(): string {
    return this.auth.currentGroup?.socialIdentities?.firstObject?.id || '0';
  }

  get passedInSocialIdentityId(): string | undefined {
    if (this.auth.currentGroup.socialIdentitiesCount) {
      const socialIdentity = this.auth.currentGroup.socialIdentities.firstObject;
      const hasSocialProfile = socialIdentity?.socialProfiles.any(
        (socialProfile) => socialProfile.profileType === this.args.selectedSocialProfileType
      );
      if (socialIdentity && !hasSocialProfile) {
        return socialIdentity.id;
      }
    }

    return undefined;
  }

  get shouldPassSocialIdentity(): boolean {
    return (
      this.auth.currentAccount.rolloutIgWithFbLoginOauth &&
      this.args.selectedSocialProfileType === SOCIAL_PROFILE_CODES.INSTAGRAM
    );
  }

  addSocialProfile = restartableTask(async () => {
    if (this.args.selectedSocialProfileType === SOCIAL_PROFILE_CODES.LINKEDIN) {
      this.cache.add('linkedin_selected_social_identity_id', this.socialIdentityId, {
        expiry: this.cache.maxExpiryDate(),
        persist: true
      });
    }

    await this.connectProfiles.addSocialProfile.perform({
      selectedSocialProfileType: this.args.selectedSocialProfileType,
      group: this.args.currentGroup,
      area: 'calendar',
      passedInSocialIdentityId: this.shouldPassSocialIdentity ? this.passedInSocialIdentityId : undefined
    });
  });
}
