import { equal } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

import { convert, MINUTES_PER_HOUR } from 'later/utils/time-format';

import type { AsyncBelongsTo } from '@ember-data/model';
import type SocialProfileModel from 'later/models/social-profile';
import type { Moment, MomentInput } from 'moment';

const Validations = buildValidations({
  socialProfile: [
    validator('presence', {
      presence: true,
      messageKey: 'errors.socialProfileEmpty'
    })
  ]
});

export default class TimeSlotModel extends Model.extend(Validations) {
  @attr('number') declare readonly createdTime: number;
  @attr('number') declare hour: number;
  @attr('number') declare minute: number;
  @attr('number') declare wday: number;
  @attr('string') declare slotType: string;
  @attr('number') declare score?: number;
  @attr('number') declare val?: number;

  @belongsTo('socialProfile', { async: true }) declare socialProfile: AsyncBelongsTo<SocialProfileModel>;

  @equal('slotType', 'bttp') declare isBttp: boolean;

  get isUser(): boolean {
    return this.slotType === 'user' || isBlank(this.slotType);
  }

  get wdayInMins(): number {
    return convert.days(this.wday).toMinutes();
  }

  get hourInMins(): number {
    return convert.hours(this.hour).toMinutes();
  }

  get mow(): number {
    return this.minute + this.hourInMins + this.wdayInMins;
  }

  get roundedHalfHourMow(): number {
    let newMinute = this.minute;

    if (this.minute < 15) {
      newMinute = 0;
    } else if (this.minute >= 15 && this.minute < 45) {
      newMinute = 30;
    } else if (this.minute >= 45) {
      newMinute = 60;
    }

    return newMinute + this.hourInMins + this.wdayInMins;
  }

  get scoreOrZero(): number {
    return this.score || 0;
  }

  setFromMinuteOfWeek(mow: number): void {
    const day = Math.floor(mow / convert.day().toMinutes());
    const dayRemainder = mow - day * convert.day().toMinutes();
    const hour = Math.floor(dayRemainder / MINUTES_PER_HOUR);
    const minute = dayRemainder - hour * MINUTES_PER_HOUR;

    this.wday = day;
    this.hour = hour;
    this.minute = minute;
  }

  setFromMoment(momentArg: MomentInput): Moment {
    const v = moment(momentArg);
    v.utcOffset(0);

    this.wday = v.day();
    this.hour = v.hour();
    this.minute = v.minute();

    return this.momentFromMoment(v);
  }

  momentFromMoment(momentArg: MomentInput): Moment {
    const m = moment(momentArg);
    m.utcOffset(0);

    m.day(this.wday);
    m.hour(this.hour);
    m.minute(this.minute);
    m.second(0);
    m.millisecond(0);

    return m;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'time-slot': TimeSlotModel;
  }
}
