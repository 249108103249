import { attr, belongsTo } from '@ember-data/model';

import GenericAttachment from 'later/models/generic-attachment';

import type MediaKit from 'later/models/media-kit';

export default class MediaKitAttachmentModel extends GenericAttachment {
  /**
   * Fe only, set when user first upload the image
   */
  @attr() declare previewUrl?: string;

  @belongsTo('mediaKit') declare mediaKit: MediaKit;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'media-kit-attachment': MediaKitAttachmentModel;
  }
}
