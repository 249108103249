/**
 * Formats a Keen Response array into a timeseries
 *
 * @method formatData
 * @param {Array} data Keen reponse
 *
 * @return {Array}
 */
export default function formatKeenData(data = []) {
  return data.map((chunk) => ({
    time: moment(chunk.timeframe.start).unix(),
    value: chunk.value
  }));
}
