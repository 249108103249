import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';

import { ErrorSeverity } from 'later/services/errors';
import isPopupWindow from 'shared/utils/is-popup-window';

import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';

const CACHE_KEY = 'popup_send_message_to_parent';

@command('popup-send-message-to-parent')
export class PopupSendMessageToParentCommand extends Command {
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;

  public searchTerms = ['send', 'message', 'parent', 'window', 'data'];

  public isAvailable(): boolean {
    return isPopupWindow();
  }

  protected action(): void {
    if (!isPopupWindow()) {
      return;
    }

    const lastValue = this.cache.retrieve<string>(CACHE_KEY);
    const defaultValue = !lastValue ? '{}' : lastValue;
    const data = prompt('Enter JSON data to send to parent window: ', defaultValue);

    if (!data) {
      return;
    }

    const expiresIn = 12 * 30 * 24 * 60 * 60 * 1000; // 1 year
    this.cache.add<string>(CACHE_KEY, data, { expiry: Date.now() + expiresIn, persist: true });

    try {
      const parsed = JSON.parse(data);
      this.#send(parsed);
    } catch (error) {
      this.errors.log(error);
    }

    this.#close();
  }

  public getTitle(): string {
    return `Send Message to Parent Window`;
  }

  public getDescription(): string {
    return `Prompt for JSON data and send it to the parent window using the 'message' event`;
  }

  public getIcon(): string {
    return 'lib-email';
  }

  #send(data: Record<string, unknown>): void {
    this.errors.log(`Sending data to opener "${JSON.stringify(data)}"`, {}, ErrorSeverity.Debug);
    window.opener.postMessage(data, window.origin);
  }

  #close(): void {
    window.close();
  }
}

export default PopupSendMessageToParentCommand;
