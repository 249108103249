import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { task, enqueueTask } from 'ember-concurrency';
import { sumBy } from 'lodash';
import RSVP from 'rsvp';

import { fetch, objectToQueryString } from 'later/utils/fetch';
import formatKeenData from 'later/utils/format-keen-data';
import snakeToCamel from 'later/utils/string-formatters/snake-to-camel';
import generateCacheKey from 'shared/utils/analytics/generate-cache-key';
import createMomentInTz from 'shared/utils/time-helpers/create-moment-in-tz';

import type SocialProfileModel from 'later/models/social-profile';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type { Moment } from 'moment';
import type { UntypedService } from 'shared/types';
import type { BinnedTimeseriesDataPoint, TimeseriesDataPoint } from 'shared/types/analytics-data';
import type {
  KeenData,
  KeenParams,
  LIBClicksOverTime,
  LIBEmbeddedClicksOverTime,
  MediaItemWithLIBData,
  GenericClicksParams,
  LIBBlockClicksOverTime
} from 'shared/types/analytics-data/keen';
import type { LIBPost } from 'shared/types/analytics-data/linkinbio';

export default class KeenApiService extends Service {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/later-analytics') declare laterAnalytics: UntypedService;

  /**
   * Default start date for data calls in this service
   */
  get startDate(): Moment {
    return this.helpersAnalytics.createMomentInTz().subtract(12, 'months').subtract(1, 'day');
  }

  /**
   * Default end date for data calls in this service
   *
   * @property endDate
   * @default
   */
  get endDate(): Moment {
    return this.helpersAnalytics.createMomentInTz();
  }

  get socialProfile(): SocialProfileModel {
    return this.auth.currentSocialProfile;
  }

  /**
   * Timezone required for query date params for daily datasets in order to match keen datasets.
   */
  dailyDatasetTimezone = 'GMT';

  clearCache(): void {
    const cacheKeyPrefix = 'keenApi';
    this.cache.clearCacheByKeyword(cacheKeyPrefix);
  }

  /**
   * Takes a unix timestamp and shifts to start of the next day in GMT as required by
   * BE keen endpoints
   */
  createKeenEndDate(unixTime: number): number {
    return createMomentInTz(unixTime, this.dailyDatasetTimezone).startOf('day').add(1, 'day').unix();
  }

  /**
   * Takes a unix timestamp and shifts to start of the next day in GMT as required by
   * BE keen endpoints. Returns the time in ISO String format.
   */
  createKeenEndDateInIsoFormat(unixTime: number): string {
    return createMomentInTz(unixTime, this.dailyDatasetTimezone).startOf('day').add(1, 'day').toISOString();
  }

  /**
   * Takes a unix timestamp and shifts to start of day GMT as required by
   * BE keen endpoints
   * @param unixTime the unix timestamp given
   * @returns Unix timestamp for start of day in GMT
   */
  createKeenStartDate(unixTime: number): number {
    return createMomentInTz(unixTime, this.dailyDatasetTimezone).startOf('day').unix();
  }

  /**
   * Takes a unix timestamp and shifts to start of day GMT as required by
   * BE keen endpoints
   * @param unixTime the unix timestamp given
   * @returns Unix timestamp for start of day in GMT
   */
  createKeenStartDateInIsoFormat(unixTime: number): string {
    return createMomentInTz(unixTime, this.dailyDatasetTimezone).startOf('day').subtract(1, 'day').toISOString();
  }

  /**
   * Returns Linkin.bio App Block clicks count over time.
   */
  getDailyAppClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const endpoint = 'cached_dataset';
      const recordType = 'linkinbio_page';
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: `${endpoint}_${datasetName}`,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        dataset_name: datasetName,
        record_id: linkinbioPageId,
        record_type: recordType,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio clicks count over time.
   */
  getDailyButtonClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const endpoint = 'cached_dataset';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const recordType = 'linkinbio_page';
      const cacheKey = generateCacheKey('keenApi', {
        endpoint,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        dataset_name: datasetName,
        record_id: linkinbioPageId,
        record_type: recordType,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio button group clicks count over time.
   */
  getDailyButtonGroupClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const endpoint = 'cached_dataset';
      const recordType = 'linkinbio_page';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: datasetName,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const params = {
        record_type: recordType,
        record_id: linkinbioPageId,
        dataset_name: datasetName
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio Contact Collection Block clicks count over time.
   */
  getDailyContactCollectionClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const endpoint = 'cached_dataset';
      const recordType = 'linkinbio_page';
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: `${endpoint}_${datasetName}`,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        dataset_name: datasetName,
        record_id: linkinbioPageId,
        record_type: recordType,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio Featured Banner Block clicks count over time.
   */
  getDailyFeaturedBannerClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const endpoint = 'cached_dataset';
      const recordType = 'linkinbio_page';
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: `${endpoint}_${datasetName}`,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        dataset_name: datasetName,
        record_id: linkinbioPageId,
        record_type: recordType,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio Featured Media clicks count over time.
   */
  getDailyCustomFeaturedMediaClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const endpoint = 'cached_dataset';
      const recordType = 'linkinbio_page';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: datasetName,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        record_type: recordType,
        record_id: linkinbioPageId,
        dataset_name: datasetName,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio Featured Media clicks count over time.
   */
  getDailyMultiItemBannerItemClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const endpoint = 'cached_dataset';
      const recordType = 'linkinbio_page';
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: `${endpoint}_${datasetName}`,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        dataset_name: datasetName,
        record_id: linkinbioPageId,
        record_type: recordType,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio clicks count over time.
   */
  getDailySocialLinkClicks = enqueueTask(
    async ({
      datasetName,
      linkinbioPageId,
      startDate = this.startDate,
      endDate = this.endDate,
      forceRefresh = false
    }: GenericClicksParams) => {
      const endpoint = 'cached_dataset';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const recordType = 'linkinbio_page';
      const cacheKey = generateCacheKey('keenApi', {
        endpoint,
        linkinbioPageId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<LIBBlockClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const formattedStartDate = this.createKeenStartDateInIsoFormat(startDate.unix());
      const formattedEndDate = this.createKeenEndDateInIsoFormat(endDate.unix());

      const params = {
        record_id: linkinbioPageId,
        record_type: recordType,
        dataset_name: datasetName,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBBlockClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio Tiktok Post clicks count over time.
   */
  getDailyTiktokPostClicks = enqueueTask(
    async ({
      datasetName,
      startDate = this.startDate,
      endDate = this.endDate,
      socialProfile,
      linkinbioPostId,
      forceRefresh = false
    }: GenericClicksParams & {
      linkinbioPostId: string;
    }) => {
      const socialProfileId = socialProfile?.id ?? this.socialProfile.id;
      const endpoint = 'cached_dataset';
      const recordType = 'social_profile';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: datasetName,
        socialProfileId,
        dateKey,
        linkinbioPostId
      });

      const cachedClicks = this.cache.retrieve<LIBClicksOverTime[]>(cacheKey);
      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const params = {
        dataset_name: datasetName,
        record_type: recordType,
        record_id: socialProfile,
        'secondary_indexes[]': `linkinbio_post: ${linkinbioPostId}`
      };

      return (await this.#fetch(endpoint, true, { cacheKey, params })) as LIBClicksOverTime[];
    }
  );

  /**
   * Returns Linkin.bio button clicks over time.
   */
  getLibButtonClicksOverTime = enqueueTask(async (linkinbioPageId: string, forceRefresh = false) => {
    const endpoint = 'lib-block-clicks-daily-last-year-by-page-id';
    const cacheKey = generateCacheKey('keenApi', {
      endpoint,
      linkinbioPageId
    });

    const cachedButtonClicks = this.cache.retrieve<TimeseriesDataPoint[]>(cacheKey);

    if (cachedButtonClicks && !forceRefresh) {
      return cachedButtonClicks;
    }

    return (await this.#fetch(endpoint, true, { cacheKey }, { linkinbioPageId })) as TimeseriesDataPoint[];
  });

  /**
   * Returns Linkin.bio clicks count over time.
   */
  getLibClicksOverTimeDailyTotals = enqueueTask(async (linkinbioPageId: string, forceRefresh = false) => {
    const endpoint = 'lib-clicks-daily-linkinbio-post-last-year-by-page-id';
    const cacheKey = generateCacheKey('keenApi', {
      endpoint,
      linkinbioPageId
    });

    const cachedClicks = this.cache.retrieve<TimeseriesDataPoint<number>[]>(cacheKey);
    if (cachedClicks && !forceRefresh) {
      return cachedClicks;
    }

    return (await this.#fetch(endpoint, true, { cacheKey }, { linkinbioPageId })) as TimeseriesDataPoint<number>[];
  });

  /**
   * Returns Linkin.bio embedded clicks count over time.
   */
  getLibEmbeddedClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    const socialProfileId = this.socialProfile?.id;
    const mediaItemCreatedTime = Number(mediaItem?.get('createdTime'));
    const libClicksOverTime = await this._libEmbeddedClicksOverTime.perform(
      socialProfileId,
      true,
      forceRefresh,
      mediaItemCreatedTime
    );

    return this.#formatMediaLibClicksOverTime(libClicksOverTime, mediaItem?.get('id'));
  });

  /**
   * Returns Linkin.bio clicks count over time for a specificmedia item.
   */
  getLibLifespanClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    const startDate = this.helpersAnalytics.createMomentInTz(Number(mediaItem.createdTime));
    const endDate = startDate.clone().add(2, 'weeks');
    const libClicksOverTime = await this._getLibClicksOverTime.perform(
      startDate,
      endDate,
      this.socialProfile,
      forceRefresh
    );

    return this.#formatMediaLibClicksOverTime(libClicksOverTime, mediaItem.get('id'));
  });

  /**
   * Returns Linkin.bio page clicks count over time.
   */
  getLibPageClicksOverTime = enqueueTask(async (mediaItem: MediaItemWithLIBData, forceRefresh = false) => {
    const socialProfileId = this.socialProfile?.id;
    const mediaItemCreatedTime = Number(mediaItem?.get('createdTime'));
    const libClicksOverTime = await this._libEmbeddedClicksOverTime.perform(
      socialProfileId,
      false,
      forceRefresh,
      mediaItemCreatedTime
    );

    return this.#formatMediaLibClicksOverTime(libClicksOverTime, mediaItem?.get('id'));
  });

  /**
   * Returns linkinbio page views by city
   */
  getLibPageViewsByCity = enqueueTask(async (linkinbioPageId: string, forceRefresh = false) => {
    const endpoint = 'lib-pageviews-daily-city-by-page-id';
    const cacheKey = generateCacheKey('keenApi', { endpoint, linkinbioPageId });
    const cachedPosts = this.cache.retrieve(cacheKey);

    if (!cachedPosts || forceRefresh) {
      try {
        const linkinbioParams = {
          compute_function: 'sum',
          compute_scope: 'per_item',
          compute_property: 'ip_geo.city'
        };
        return await this.#fetch(endpoint, false, { cacheKey }, { linkinbioPageId, linkinbioParams });
      } catch (error) {
        return error;
      }
    }

    return cachedPosts;
  });

  /**
   * Returns linkinbio page views by country
   */
  getLibPageViewsByCountry = enqueueTask(async (linkinbioPageId: string, forceRefresh = false) => {
    const endpoint = 'lib-pageviews-daily-country-by-page-id';
    const cacheKey = generateCacheKey('keenApi', { endpoint, linkinbioPageId });
    const cachedPosts = this.cache.retrieve(cacheKey);

    if (!cachedPosts || forceRefresh) {
      try {
        const linkinbioParams = {
          compute_function: 'sum',
          compute_scope: 'per_item',
          compute_property: 'ip_geo.country_code'
        };
        return await this.#fetch(endpoint, false, { cacheKey }, { linkinbioPageId, linkinbioParams });
      } catch (error) {
        return error;
      }
    }

    return cachedPosts;
  });

  /**
   * Returns Linkin.bio page views over time.
   */
  getLibPageViewsOverTime = enqueueTask(async (linkinbioPageId: string, forceRefresh = false) => {
    const endpoint = 'lib-pageviews-daily-by-page-id';
    const cacheKey = generateCacheKey('keenApi', {
      endpoint,
      linkinbioPageId
    });
    const cachedPageViews = this.cache.retrieve<TimeseriesDataPoint[]>(cacheKey);

    if (cachedPageViews && !forceRefresh) {
      return cachedPageViews;
    }

    return (await this.#fetch(endpoint, true, { cacheKey }, { linkinbioPageId })) as TimeseriesDataPoint[];
  });

  /**
   * Returns the total media clicks in the last 4 months.
   * Gets clicks from the 'lib-clicks-daily-linkinbio-post-embedded' Keen dataset, which returns the total clicks in daily
   * intervals, with the `linkinbio_post.id` and `embedded` properties. The value of the `embedded` property
   * can be one of true, false, or null, and defines whether or not the click came from embedded Linkinbio.
   */
  getMediaTotalClicks = enqueueTask(async (startDate: Moment, endDate: Moment, forceRefresh = false) => {
    if (!this.socialProfile.isInstagram) {
      this.errors.log('media_total_clicks requires an instagram profile to access', {
        socialProfile: this.socialProfile.id
      });

      return {};
    }

    const socialProfileId = this.socialProfile.id;
    const endpoint = 'media_total_clicks';
    const hasDateParams = startDate && endDate;
    const dateKey = hasDateParams ? this.helpersAnalytics.createKeyFromDates(startDate, endDate) : null;
    const cacheKey = generateCacheKey('keenApi', { endpoint: snakeToCamel(endpoint), socialProfileId, dateKey });
    const cachedClicks = this.cache.retrieve(cacheKey);

    if (cachedClicks && !forceRefresh) {
      return cachedClicks;
    }
    const formattedStartDate = this.createKeenStartDate(startDate.unix());
    const formattedEndDate = this.createKeenEndDate(endDate.unix());

    const params = hasDateParams
      ? {
          social_profile_id: socialProfileId,
          start_date: formattedStartDate,
          end_date: formattedEndDate
        }
      : {
          social_profile_id: socialProfileId
        };

    return await this.#fetch(endpoint, false, { cacheKey, params });
  });

  /**
   * Return Short Link clicks count over time.
   */
  getShortLinkClicksOverTime = enqueueTask(async (media: MediaItemWithLIBData, forceRefresh = false) => {
    if (!media || !media.shortcodes) {
      return [];
    }

    const start = this.helpersAnalytics.createMomentInTz(media.createdTime);
    const oneDayAfter = start.clone().add(1, 'day');
    const now = this.helpersAnalytics.createMomentInTz();
    const end = now.unix() < oneDayAfter.unix() ? now : oneDayAfter;

    const results = this.#generateDefaultShortLinkClicksPoints(start.unix(), end.unix());

    const { shortcodes } = media;

    for (const shortcode of shortcodes) {
      const response = await this._fetchShortLinkClicksOverTime.linked().perform(shortcode, start, end, forceRefresh);

      results.forEach((result, index) => {
        if (!isEmpty(result.count)) {
          const responseAtIndex = response[index];

          result.count = result.count || 0;
          result.count += responseAtIndex.value;
        }
      });
    }

    return results;
  });

  /**
   * Fetches short link clicks data for the given shortcode.
   */
  _fetchShortLinkClicksOverTime = task(
    async (shortcode: string, startDate: Moment, endDate: Moment, forceRefresh: boolean) => {
      const socialProfileId = this.socialProfile.id;
      const endpoint = 'short_link_clicks_over_time';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const cacheKey = generateCacheKey('keenApi', {
        endpoint: `${endpoint}-${shortcode}`,
        socialProfileId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve<Record<string, number>[]>(cacheKey);

      if (cachedClicks && !forceRefresh) {
        return cachedClicks;
      }

      const params = {
        social_profile_id: socialProfileId,
        dynamodb_record_id: shortcode,
        start_date: startDate.unix(),
        end_date: endDate.unix()
      };

      return (await this.#fetch(endpoint, false, { cacheKey, params })) as Record<string, number>[];
    }
  );

  /**
   * Returns Linkin.bio clicks count over time.
   */
  _getLibClicksOverTime = enqueueTask(
    async (
      startDate = this.startDate,
      endDate = this.endDate,
      socialProfile = this.socialProfile,
      forceRefresh = false
    ) => {
      const socialProfileId = socialProfile?.get('id') ?? this.socialProfile.id;
      const endpoint = 'lib_clicks_over_time';
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const cacheKey = generateCacheKey('keenApi', {
        endpoint,
        socialProfileId,
        dateKey
      });

      const cachedClicks = this.cache.retrieve(cacheKey);
      const formattedStartDate = this.createKeenStartDate(startDate.unix());
      const formattedEndDate = this.createKeenEndDate(endDate.unix());
      const params = {
        social_profile_id: socialProfileId,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };
      const promises = {
        libPosts: this.laterAnalytics.getLinkinbioAnalyticsPosts.linked().perform(),
        libClicksOverTime:
          cachedClicks && !forceRefresh ? cachedClicks : this.#fetch(endpoint, true, { cacheKey, params })
      };

      return await RSVP.hashSettled(promises).then(({ libPosts, libClicksOverTime }) => {
        if (libPosts.state === 'fulfilled' && libClicksOverTime.state === 'fulfilled') {
          return this.#addMediaIdToClicks(libPosts.value, libClicksOverTime.value as LIBClicksOverTime[]);
        }
        if (libClicksOverTime.state === 'rejected') {
          this.errors.log(libClicksOverTime.reason);
        }
        return [];
      });
    }
  );

  /**
   * Fetches Linkinbio Clicks and optionally filters by embedded or regular clicks.
   */
  _libEmbeddedClicksOverTime = task(
    async (socialProfileId: string, isEmbedded: boolean, forceRefresh: boolean, mediaItemCreatedTime: number) => {
      const endpoint = 'lib_embedded_clicks_over_time';
      const startDate = this.helpersAnalytics
        .createMomentInTz(mediaItemCreatedTime, this.dailyDatasetTimezone)
        .startOf('day');
      const endDate = startDate.clone().add(1, 'day').add(2, 'weeks');
      const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
      const cacheKey = generateCacheKey('keenApi', { endpoint, socialProfileId, dateKey });
      const cachedEmbeddedClicks = this.cache.retrieve<LIBEmbeddedClicksOverTime>(cacheKey);
      const currentDate = this.helpersAnalytics
        .createMomentInTz(undefined, this.dailyDatasetTimezone)
        .startOf('day')
        .add('1', 'day');
      const endDateSanitized = currentDate.unix() < endDate.unix() ? currentDate : endDate;
      const params = {
        social_profile_id: socialProfileId,
        start_date: startDate.unix(),
        end_date: endDateSanitized.unix()
      };

      const promises = {
        libPosts: this.laterAnalytics.getLinkinbioAnalyticsPosts.linked().perform(),
        libClicksOverTime:
          !cachedEmbeddedClicks || forceRefresh
            ? fetch(`/api/v2/keen/${endpoint}.json${objectToQueryString(params)}`)
            : new RSVP.Promise((resolve) => {
                resolve(cachedEmbeddedClicks);
              })
      };

      return await RSVP.hashSettled(promises).then(({ libPosts, libClicksOverTime }) => {
        if (libPosts.state === 'fulfilled' && libClicksOverTime.state === 'fulfilled') {
          this.cache.add(cacheKey, libClicksOverTime.value, { expiry: this.cache.expiry(1, 'day'), persist: false });
          const {
            keen_response: { result }
          } = libClicksOverTime.value;
          const formattedResponse = formatKeenData(result);
          const filteredClicks = this.#filterEmbeddedClicks(formattedResponse, isEmbedded);
          return this.#addMediaIdToClicks(libPosts.value, filteredClicks);
        }
        if (libClicksOverTime.state === 'rejected') {
          this.errors.log(libClicksOverTime.reason);
        }

        return [];
      });
    }
  );

  #addMediaIdToClicks<T extends LIBEmbeddedClicksOverTime | LIBClicksOverTime>(
    libPosts: LIBPost[],
    keenLibData: T[]
  ): T[] {
    const mediaIdReference: Record<string, string> = libPosts?.reduce((mediaIdObj, libPost) => {
      const newObj = {
        [`${libPost.id}`]: libPost.media_id
      };
      return Object.assign({}, mediaIdObj, newObj);
    }, {});

    return keenLibData.map((day) => {
      const formattedMedia =
        day.value?.map((media) => {
          const linkinbioPostId = `${media['linkinbio_post.id']}`;
          const updatedMedia = { ...media };
          updatedMedia['media.id'] = mediaIdReference[linkinbioPostId];
          return updatedMedia;
        }) || [];
      const updatedDay = { ...day };
      updatedDay.value = formattedMedia;
      return updatedDay;
    });
  }

  /**
   * Filters Keen LiB clicks based on given embedded property
   */
  #filterEmbeddedClicks(keenLibData: LIBEmbeddedClicksOverTime[], isEmbedded: boolean): LIBEmbeddedClicksOverTime[] {
    return keenLibData.map((day) => {
      const filteredValue = day.value?.filter((media) => (isEmbedded ? media.embedded : !media.embedded));
      return Object.assign({}, day, { value: filteredValue });
    });
  }

  /**
   * Formats LiB clicks over time for the given media id, start date and end date.
   */
  #formatMediaLibClicksOverTime(keenLibData: LIBClicksOverTime[], mediaId: string): BinnedTimeseriesDataPoint[] {
    const dataPoints: BinnedTimeseriesDataPoint[] = [];
    keenLibData.forEach((day) => {
      if (!day) {
        return;
      }
      const dayValues = day.value?.filter((media) => media['media.id'] === mediaId);
      if (isPresent(dayValues) && !!day.time) {
        dataPoints.push({ sampled_at: day.time, count: sumBy(dayValues, 'result') });
      }
    });
    return dataPoints;
  }

  /**
   * Returns default Short Link clicks count over time.
   */
  #generateDefaultShortLinkClicksPoints(startTime: number, endTime: number): BinnedTimeseriesDataPoint[] {
    const defaultPoints = [];

    let currTime = startTime;
    while (currTime < endTime) {
      defaultPoints.push({ sampled_at: currTime, count: 0 });

      currTime = this.helpersAnalytics.createMomentInTz(currTime).add(1, 'hour').startOf('hour').unix();
    }

    return defaultPoints;
  }

  async #fetch(
    endpoint: string,
    shouldFormatData = false,
    { cacheKey, params }: { cacheKey: string; params?: KeenParams },
    {
      linkinbioPageId,
      linkinbioParams
    }: {
      linkinbioPageId?: string;
      linkinbioParams?: { compute_function: string; compute_scope: string; compute_property: string };
    } = {}
  ): Promise<KeenData> {
    try {
      let url = '';

      if (isPresent(linkinbioPageId)) {
        const endpointName = isPresent(linkinbioParams?.compute_function) ? 'compute_cached_dataset' : 'cached_dataset';

        url = `/api/v2/keen/${endpointName}${objectToQueryString(
          linkinbioParams
        )}&dataset_name=${endpoint}&record_type=linkinbio_page&record_id=${linkinbioPageId}`;
      } else {
        url = `/api/v2/keen/${endpoint}${shouldFormatData ? '' : '.json'}${objectToQueryString(params)}`;
      }

      const response = await fetch(url);
      const result = response.keen_response?.result ?? response.response;
      if (!result) {
        return [];
      }
      let keenData = result;
      if (shouldFormatData) {
        keenData = formatKeenData(result);
      } else if (isPresent(linkinbioPageId) && isPresent(linkinbioParams?.compute_function)) {
        const formattedData: { [key: string]: number } = {};
        result.forEach((result: { key: string; value: number }) => {
          formattedData[result.key] = result.value;
        });
        keenData = formattedData;
      }

      this.cache.add(cacheKey, keenData, {
        expiry: this.cache.expiry(1, 'day'),
        persist: false
      });
      return keenData;
    } catch (error) {
      this.errors.log(error);
      return [];
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/keen-api': KeenApiService;
  }
}
