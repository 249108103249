import { inject as service } from '@ember/service';
import { isNone, isPresent } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import { Resource } from 'ember-modify-based-class-resource';
import moment from 'moment';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';

import { computeTimeSlotDiffInMins } from 'calendar/utils/time-slots';
import { NUM_BTTP_SLOTS, BTTP_VERSIONS } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';

import type Store from '@ember-data/store';
import type { LegacyBttpInstance, ImmediateBttpInstance, BttpInstance, BttpReport } from 'calendar/types/bttp';
import type IntlService from 'ember-intl/services/intl';
import type AnalyticsReportModel from 'later/models/analytics-report';
import type SocialProfileModel from 'later/models/social-profile';
import type TimeSlotModel from 'later/models/time-slot';
import type BttpService from 'later/services/calendar/bttp';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type { UntypedService } from 'shared/types';

// Type guard for LegacyBttpInstance
function isLegacyBttp(_bttp: BttpInstance, isLegacy: boolean): _bttp is LegacyBttpInstance {
  return isLegacy;
}

const ERROR_MESSAGES = {
  MISSING_METRICS: 'Missing metrics',
  NOT_ENOUGH_POSTS: 'Not enough posts to run',
  TIMESLOT_ERROR: 'Error fetching best timeslots'
};

const ERROR_CODES = {
  DATA_MALFORMED: 'input_data_malformed',
  MISSING_METRICS: 'missing_metrics',
  NOT_ENOUGH_POSTS: 'not_enough_posts'
};

export default class BttpResource extends Resource {
  @service('calendar/bttp') declare bttp: BttpService;
  @service declare errors: UntypedService;
  @service declare intl: IntlService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: Store;

  // Note: Track unpersisted timeSlots for purposes of later clearing the ember-data store
  timeSlots: TimeSlotModel[] | null = null;

  get bttpTimeSlots(): TimeSlotModel[] {
    return this.socialProfile?.timeSlots.filter((timeSlot: TimeSlotModel) => timeSlot.isBttp) || [];
  }

  get socialProfile(): SocialProfileModel | undefined {
    return this.selectedSocialProfiles.firstProfile;
  }

  get multipleProfilesSelected(): boolean {
    return this.selectedSocialProfiles.hasMultipleSelected;
  }

  #clearBttpTimeSlots(): void {
    this.timeSlots?.forEach((timeSlot) => timeSlot.deleteRecord());
  }

  #createLegacyTimeSlot({ score, val }: LegacyBttpInstance): void {
    const timeSlot = this.store.createRecord('time-slot', {
      socialProfile: this.socialProfile,
      slotType: 'bttp',
      score
    });

    timeSlot.setFromMinuteOfWeek(val);
  }

  #createTimeSlot({ wday, hour }: ImmediateBttpInstance): void {
    // Note: Moment objects are initialized with a timezone based on the users computer settings.
    // Immediate BTTP day/hour is PST/PDT by default, timeslots are processed in full-calendar as UTC (default for legacy BTTP and quick slots)
    // As a result, we need to convert the 'wday' and 'hour' in the BTTP report from the users initial timezone, to PST/PDT, to UTC before saving them
    // to ensure they're correctly processed.
    const initialTimeZone = 'America/Vancouver';
    const utcDateTime = moment.tz(initialTimeZone).day(wday).hour(hour).utc();

    this.store.createRecord('time-slot', {
      hour: utcDateTime.hour(),
      minute: 0,
      socialProfile: this.socialProfile,
      slotType: 'bttp',
      wday: utcDateTime.day()
    });
  }

  #filterLegacyBttpTimeSlots(): TimeSlotModel[] {
    const minDist = 60 * 4;
    const rawTimeSlots = this.bttpTimeSlots.sort((a, b) => b.scoreOrZero - a.scoreOrZero);

    const timeSlots = rawTimeSlots.reduce((accumulator: TimeSlotModel[], timeSlot: TimeSlotModel) => {
      const isInvalid = accumulator.some(
        (otherTimeSlot: TimeSlotModel) => computeTimeSlotDiffInMins(timeSlot, otherTimeSlot) < minDist
      );
      if (!isInvalid) {
        accumulator.pushObject(timeSlot);
      }
      return accumulator;
    }, []);

    return timeSlots.slice(0, NUM_BTTP_SLOTS);
  }

  #generateReportErrorText(reportData: BttpReport): string | undefined {
    const { error, errorCode } = reportData;

    if (errorCode === ERROR_CODES.NOT_ENOUGH_POSTS || error?.includes(ERROR_MESSAGES.NOT_ENOUGH_POSTS)) {
      return this.intl.t('bttp.errors.not_enough_posts_details');
    }

    if (isPresent(error)) {
      return error;
    }

    return errorCode;
  }

  #handleReportErrors(reportData: BttpReport, report: AnalyticsReportModel): string | void {
    if (!reportData) {
      const errorMessage = this.intl.t('bttp.errors.unable_to_fetch');
      return this.errors.log(errorMessage, report);
    }
  }

  _requestData = dropTask(async (report: AnalyticsReportModel) => {
    if (isNone(report.url)) {
      return { error: this.intl.t('bttp.errors.missing_s3_url') };
    }

    try {
      const { LEGACY } = BTTP_VERSIONS;
      const url = report.s3SignedUrl;
      const rawData = await fetch(url);
      const parsedData = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
      const isLegacy = parsedData.version === LEGACY;

      this.#clearBttpTimeSlots();
      parsedData.data?.bttp_data?.forEach((bttp: LegacyBttpInstance | ImmediateBttpInstance): void => {
        isLegacyBttp(bttp, isLegacy) ? this.#createLegacyTimeSlot(bttp) : this.#createTimeSlot(bttp);
      });
      return parsedData;
    } catch (error) {
      this.errors.handleAjax(error);
    }
  });

  _fetchBttpReport = dropTask(async () => {
    const { LEGACY } = BTTP_VERSIONS;

    const bttpReportUnavailable =
      !this.bttp.canBttp || this.multipleProfilesSelected || !this.bttp.isValidSocialProfile;
    if (bttpReportUnavailable) {
      return {};
    }

    const reports = await this.store.query('analytics-report', {
      social_profile_id: this.socialProfile?.id,
      names: [this.bttp.analyticsReportName]
    });

    const report = reports.firstObject;

    // Note: If the user hasn't manually triggered a report before, no report will exist
    if (!report) {
      return {};
    }

    const reportData = await this._requestData.perform(report);

    this.#handleReportErrors(reportData, report);
    const isLegacy = reportData?.version === LEGACY;
    const timeSlots = isLegacy ? this.#filterLegacyBttpTimeSlots() : this.bttpTimeSlots;
    this.timeSlots = timeSlots;

    const bttpReport = await {
      ...reportData,
      numOfFollowers: this.socialProfile?.followedBy,
      numOfPosts: reportData?.error_data?.data?.num_of_posts,
      reportErrorText: reportData ? this.#generateReportErrorText(reportData) : null,
      requiredBttpPostData: 50,
      requiredFollowerCount: 100,
      timeSlots
    };

    return bttpReport;
  });

  fetchTask = trackedTask(this, this._fetchBttpReport, () => [this.selectedSocialProfiles.firstProfile?.id]);
}
