import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import { OauthSocialProfileType } from 'later/utils/constants';
import redirect from 'shared/utils/redirect';

import type GroupModel from 'later/models/group';
import type AuthService from 'later/services/auth';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { Maybe } from 'shared/types';

export default class TwitterService extends Service {
  @service declare auth: AuthService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  get group(): GroupModel {
    return this.auth.currentGroup;
  }

  loginTwitter(): void {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Twitter,
      redirectGroupSlug: this.group.slug
    });

    if (isNone(this.group)) {
      redirect(path);
    } else {
      redirect(`${path}&group_id=${this.group.id}`);
    }
  }

  createTwitterWithSet(setId: number | undefined, group: GroupModel, redirectPath: Maybe<string>): void {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Twitter,
      redirectGroupSlug: group.slug,
      redirectPath
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    twitter: TwitterService;
  }
}
