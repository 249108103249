import classic from 'ember-classic-decorator';

import ApplicationSerializer from 'later/serializers/application';

@classic
export default class GramSerializer extends ApplicationSerializer {
  attrs = {
    postActivities: {
      embedded: 'always',
      serialize: 'records'
    },
    postMediaItems: {
      embedded: 'always',
      serialize: 'records'
    },
    linkinbioPost: {
      embedded: 'always',
      serialize: 'records'
    }
  };
}
