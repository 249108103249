import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { enqueueTask } from 'ember-concurrency';
import { flatten } from 'lodash';
import moment from 'moment';
import RSVP from 'rsvp';

import { ErrorSeverity } from 'later/services/errors';
import { convert } from 'later/utils/time-format';
import {
  sanitizeHashtagsOutsideCutoffRank,
  groupWeeklyDataByHashtag,
  fillMissingHashtagData
} from 'shared/utils/formatters/format-hashtag-trends';

import type { Task } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type SocialProfileModel from 'later/models/social-profile';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type ErrorsService from 'later/services/errors';
import type { Moment } from 'moment';
import type { UntypedService } from 'shared/types';
import type { AnalyticsError } from 'shared/types/analytics-data';
import type { LIBPost } from 'shared/types/analytics-data/linkinbio';
import type { FormattedHashtagItem, FormattedIndustryHashtags } from 'shared/types/industry-data/industry-hashtags';

type getHashtagTrendsTask = Task<
  AnalyticsError | { data: FormattedHashtagItem[]; unixStartTimes: number[] },
  [
    startMoment: Moment,
    endMoment: Moment,
    socialProfile: SocialProfileModel,
    minRank: number,
    maxRank: number,
    forceRefresh: boolean
  ]
>;
type getLinkinbioAnalyticsPostsTask = Task<LIBPost[], [forceRefresh: boolean]>;

/**
 * @class LaterAnalyticsService
 * @extends Service
 */
export default class LaterAnalyticsService extends Service {
  @service declare errors: ErrorsService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service declare industryData: UntypedService;
  @service declare intl: IntlService;
  @service('analytics/later-api') declare laterApi: UntypedService;

  /**
   * Returns Instagram posts for free users.
   *
   * @property getPosts
   */
  getLinkinbioAnalyticsPosts: getLinkinbioAnalyticsPostsTask = enqueueTask(async (forceRefresh = false) => {
    return await this.laterApi.getLinkinbioAnalyticsPosts.linked().perform(forceRefresh);
  });

  /**
   * Returns Later hashtag usage statistics over
   * time for various industries
   */
  getHashtagTrends: getHashtagTrendsTask = enqueueTask(
    async (startMoment, endMoment, socialProfile, minRank, maxRank, forceRefresh = false) => {
      assert('Must pass a valid social profile industry to getHashtagTrends', socialProfile?.industry);

      const durationSeconds = endMoment.unix() - startMoment.unix();
      const pointSpacingInS = convert.week().toSeconds();
      const numberOfPoints = Number((durationSeconds / pointSpacingInS).toFixed());

      const unixStartTimes = [...Array(numberOfPoints)].map((_, currentPointIndex) => {
        const currentPointTimeUnix = startMoment.unix() + currentPointIndex * pointSpacingInS;
        return moment(currentPointTimeUnix * 1000)
          .utc()
          .startOf('day')
          .unix();
      });
      const startDates = unixStartTimes.map((unixTimestamp) =>
        moment(unixTimestamp * 1000)
          .utc()
          .format('YYYY-MM-DD')
      );

      const promises = startDates.map((startDate) =>
        this.industryData.getIndustryHashtags.linked().perform(socialProfile, startDate, 'week', forceRefresh)
      );

      const settledPromises: RSVP.PromiseState<FormattedIndustryHashtags>[] = await RSVP.allSettled(promises);

      const rejectedPromises = settledPromises.filter(
        (settledPromise): settledPromise is RSVP.Rejected => settledPromise.state === 'rejected'
      );
      const fulfilledPromises = settledPromises.filter(
        (settledPromise): settledPromise is RSVP.Resolved<FormattedIndustryHashtags> =>
          settledPromise.state === 'fulfilled'
      );

      if (isPresent(rejectedPromises)) {
        const errors = rejectedPromises.map((settledPromise) => settledPromise.reason);
        this.errors.log(errors.get('firstObject'), { errors }, ErrorSeverity.Warning);
        return this.helpersAnalytics.formatError(
          this.intl.t('analytics.instagram.hashtag.hashtag_trends.chart.error_state')
        );
      }

      const hashtagTrends = isEmpty(fulfilledPromises)
        ? []
        : flatten(fulfilledPromises.map((settledPromise) => settledPromise.value));

      const sanitizedHashtagData = sanitizeHashtagsOutsideCutoffRank(
        groupWeeklyDataByHashtag(hashtagTrends),
        minRank,
        maxRank,
        unixStartTimes
      );

      return {
        data: fillMissingHashtagData(sanitizedHashtagData, unixStartTimes) as FormattedHashtagItem[],
        unixStartTimes
      };
    }
  );
}

declare module '@ember/service' {
  interface Registry {
    'analytics/later-analytics': LaterAnalyticsService;
  }
}
