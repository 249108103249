import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class Account extends ApplicationSerializer {
  attrs = {
    rolloutOcePhase1: 'rollout_oce_phase_1'
  };

  normalizeResponse(store, primaryModelClass, payload, ...args) {
    delete payload.clusters;

    return super.normalizeResponse(store, primaryModelClass, payload, ...args);
  }
}
