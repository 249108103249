import { isNone } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class PinterestAlbumExists extends BaseValidator {
  validate() {
    if (this.get('model.socialProfile.isPinterest')) {
      return isNone(this.get('model.album')) ? this.createErrorMessage('albumExists') : true;
    }
    return true;
  }
}

export default PinterestAlbumExists;
