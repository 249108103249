import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import { ErrorSeverity } from 'later/services/errors';
import { OauthSocialProfileType } from 'later/utils/constants';
import redirect from 'shared/utils/redirect';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { Maybe } from 'shared/types';
import type {
  CachedYoutubeSettings,
  YoutubeCategory,
  YoutubeCategoryResource,
  YoutubeOptionValue
} from 'shared/types/youtube';

const YOUTUBE_PROPERTIES_CACHE_KEY = 'youtube-shorts-settings';

export default class YoutubeService extends Service.extend(Evented) {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked showYoutubeToSModal = false;
  acceptTermsResponse = {
    resolve: 'resolveAcceptTerms',
    reject: 'rejectAcceptTerms'
  };

  get currentUser(): UserModel {
    return this.auth.currentUserModel;
  }

  get hasAcceptedTerms(): boolean {
    return Boolean(this.currentUser.receivedYoutubeEducation);
  }

  /**
   * Redirects to Youtube OAuth for the specified group or set
   *
   * @method createYoutubeWithSet
   */
  async createYoutubeWithSet(setId: Maybe<string>, group: GroupModel): Promise<void> {
    if (!this.hasAcceptedTerms) {
      try {
        await this.showTermsOfService();
      } catch (error) {
        if (!error) {
          this.errors.log('Did not accept Youtube Terms of Service', undefined, ErrorSeverity.Info);
        } else {
          this.errors.log('Error caught in Youtube Terms of Service Modal', error);
        }
        return;
      }
    }

    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Youtube,
      redirectGroupSlug: group.slug
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }

  updatePostPropertiesCache(post: GramModel): void {
    if (!post.isYoutube) {
      return;
    }

    const youtubeSettings: CachedYoutubeSettings = {
      audience: post.madeForKids ?? false,
      visibility: post.visibility ?? '',
      category: post.category ?? ''
    };

    this.cache.add(YOUTUBE_PROPERTIES_CACHE_KEY, youtubeSettings, {
      expiry: this.cache.expiry(7, 'days'),
      persist: true
    });
  }

  getCachedPostProperty(property: 'audience' | 'visibility' | 'category'): YoutubeOptionValue {
    const cachedProperties = this.cache.retrieve<CachedYoutubeSettings>(YOUTUBE_PROPERTIES_CACHE_KEY);
    return cachedProperties?.[property] ?? undefined;
  }

  async videoCategories(socialProfile: SocialProfileModel, language: string): Promise<YoutubeCategory[] | undefined> {
    const region = socialProfile.channelCountry ?? 'us';

    // Note: check cache first
    const cacheKey = this.#categoriesCacheKey(region, language);
    const cachedValue = this.cache.retrieve(cacheKey);
    if (cachedValue) {
      return cachedValue as YoutubeCategory[];
    }

    // Note: else fetch from API
    const categories = await this.fetchVideoCategories(socialProfile);
    if (!categories || !categories.length) {
      return undefined;
    }

    this.cache.add(cacheKey, categories, {
      expiry: this.cache.expiry(30, 'days'),
      persist: true
    });

    return categories;
  }

  async fetchVideoCategories(socialProfile: SocialProfileModel): Promise<YoutubeCategory[] | void> {
    const authToken = socialProfile.token;
    if (!authToken) {
      this.#alertProfileRefresh(socialProfile, this.intl.t('errors.youtube_categories_unauthorized'));
      return;
    }

    try {
      const response = await socialProfile.youtubeCategories();

      if (!response || !response.length) {
        this.errors.log('Failed to fetch Youtube categories, invalid response');
      }

      return this.#parseCategoriesResponse(response);
    } catch (error) {
      // Note: manually expire the user's token if authentication failed
      const CODE_AUTHORIZATION_FAILED = 401;
      if (error.code === CODE_AUTHORIZATION_FAILED) {
        socialProfile.set('token', undefined);
        this.#alertProfileRefresh(socialProfile, this.intl.t('errors.youtube_categories_unauthorized'));
        this.errors.log('Failed to fetch Youtube categories, authorization failed', error, ErrorSeverity.Info);
      } else {
        this.errors.log('Failed to fetch Youtube categories, error caught', error);
      }
    }
  }

  acceptTerms(): void {
    this.currentUser.receivedYoutubeEducation = true;
    this.currentUser.save();
    this.trigger(this.acceptTermsResponse.resolve);
  }

  rejectTerms(): void {
    this.trigger(this.acceptTermsResponse.reject);
  }

  async showTermsOfService(): Promise<void> {
    this.showYoutubeToSModal = true;
    return await new Promise((resolve, reject) => {
      this.on(this.acceptTermsResponse.resolve, () => resolve());
      this.on(this.acceptTermsResponse.reject, () => reject());
    });
  }

  #parseCategoriesResponse(response: YoutubeCategoryResource[]): YoutubeCategory[] {
    if (!response || !response.length) {
      return [];
    }

    const categories: YoutubeCategory[] = [];
    response.forEach((categoryResource: YoutubeCategoryResource) => {
      if (categoryResource.snippet.assignable) {
        const category = {
          id: categoryResource.id,
          title: categoryResource.snippet.title
        };

        categories.push(category);
      }
    });

    return categories;
  }

  #categoriesCacheKey(region: string, language: string): string {
    return `youtubeCategories_${region}_${language}`;
  }

  #alertProfileRefresh(socialProfile: SocialProfileModel, title: string): void {
    this.alerts.alert(
      this.intl.t('alerts.errors.auto_publish.expired_token.message', {
        account_name: socialProfile.nickname
      }),
      {
        title: title || this.intl.t('shared_phrases.refresh_your_connection'),
        action: () => this.router.transitionTo('account.groups.group.social_profiles', this.auth.currentGroup?.id),
        actionText: this.intl.t('alerts.partnerships.account_owner.opt_in.button'),
        preventDuplicates: true,
        timeout: 10000
      }
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    youtube: YoutubeService;
  }
}
