import { AttachmentName, AttachmentType, AttachmentRecordType } from 'later/utils/constants';

interface AttachmentData {
  model: string;
  modelData: ModelData;
}

interface ModelData {
  name: string;
  recordType: AttachmentRecordType;
  recordId?: number;
}

/**
 * Assembles data necessary to create each type of media attachment.
 * As well as provides the name of the attachment model to create
 *
 * @return
 */

const attachmentData = (
  recordId: number,
  additionalModelData?: { [key: string]: any }
): Record<string, AttachmentData> => ({
  [AttachmentType.LinkinbioHeaderAvatar]: {
    model: 'linkinbio-attachment',
    modelData: {
      name: AttachmentName.LinkinbioHeaderAvatar,
      recordType: AttachmentRecordType.LinkinbioHeaderBlock,
      recordId,
      ...additionalModelData
    }
  },
  [AttachmentType.LinkinbioFeaturedBannerBlockThumbnail]: {
    model: 'linkinbio-attachment',
    modelData: {
      name: AttachmentName.LinkinbioFeaturedBannerBlockThumbnail,
      recordType: AttachmentRecordType.LinkinbioFeaturedBannerBlock,
      recordId,
      ...additionalModelData
    }
  },
  [AttachmentType.LinkinbioFeaturedMediaCustomMedia]: {
    model: 'linkinbio-attachment',
    modelData: {
      name: AttachmentName.LinkinbioFeaturedMediaCustomMedia,
      recordType: AttachmentRecordType.LinkinbioFeaturedMediaBlock,
      recordId,
      ...additionalModelData
    }
  },
  [AttachmentType.LinkinbioPageBackground]: {
    model: 'linkinbio-attachment',
    modelData: {
      name: 'page_background',
      recordType: AttachmentRecordType.LinkinbioStyleBlock,
      recordId,
      ...additionalModelData
    }
  },
  [AttachmentType.LinkinbioMultiItemBannerBlockThumbnail]: {
    model: 'linkinbio-attachment',
    modelData: {
      name: AttachmentName.LinkinbioMultiItemBannerBlockThumbnail,
      recordType: AttachmentRecordType.LinkinbioMultiItemBannerBlock,
      recordId,
      ...additionalModelData
    }
  },
  [AttachmentType.PartnershipsCampaignImage]: {
    model: 'partnerships-attachment',
    modelData: {
      name: AttachmentName.PartnershipsCampaignImage,
      recordType: AttachmentRecordType.Campaign
    }
  },
  [AttachmentType.MediaKitFeaturedMedia]: {
    model: 'media-kit-attachment',
    modelData: {
      name: AttachmentName.MediaKitFeaturedMedia,
      recordType: AttachmentRecordType.MediaKit
    }
  }
});

export default attachmentData;
