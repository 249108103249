import { isBlank } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class CaptionExists extends BaseValidator {
  validate() {
    if (this.model.isText && isBlank(this.model.caption) && this.get('model.socialProfile.canTextOnly')) {
      return this.createErrorMessage('enterTextOrAddMedia');
    }
    return true;
  }
}

export default CaptionExists;
