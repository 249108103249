import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class YoutubeTagsLength extends BaseValidator {
  validate() {
    if (this.model.socialProfile.get('isYoutube')) {
      return this.model.videoTagCharactersLeft > 0;
    }
    return true;
  }
}
