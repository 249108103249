import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import loadImage from 'blueimp-load-image';
import RSVP from 'rsvp';
import { tracked, TrackedArray } from 'tracked-built-ins';

import { fetch } from 'later/utils/fetch';
import { UnknownRouteError } from 'shared/errors/unknown-route';
import retry, { STRATEGY } from 'shared/utils/retry';

import type RouterService from '@ember/routing/router-service';
import type { ImageData } from 'editor/services/editor-media';
import type MediaItemModel from 'later/models/media-item';
import type PostMediaItemModel from 'later/models/post-media-item';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type { JsonObject } from 'type-fest';

interface ImageDimensions {
  width: number;
  height: number;
}

function isMissingPublishingData(mediaItem: MediaItemModel): boolean {
  return !mediaItem.publishingUrl || !mediaItem.publishingHeight || !mediaItem.publishingWidth;
}

export default class EditorService extends Service {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare router: RouterService;
  @service declare errors: ErrorsService;

  @tracked lastPMI: PostMediaItemModel | null = null;
  @tracked snapshots = new TrackedArray([]);

  loadImage = loadImage;

  get canVideoCrop(): boolean {
    return Boolean(this.auth.currentAccount.canVideoProcessing);
  }

  /**
   * Open the editor associated with the path in schedule route
   */
  editPMICrop(postMediaItem: PostMediaItemModel): void {
    const editorRoute = this.getEditorRoute(postMediaItem);

    this.lastPMI = postMediaItem;

    // Note: Store information needed for the editor engine.
    this.cache.add('editor-post-media-item', postMediaItem as unknown as JsonObject, {
      expiry: this.cache.expiry(5, 'minutes'),
      persist: false
    });

    this.router.transitionTo(editorRoute);
  }

  /**
   * Reset the cached image snapshots of video frames
   */
  clearSnapshots(): void {
    this.snapshots = new TrackedArray([]);
  }

  getEditorRoute(postMediaItem: PostMediaItemModel): string {
    if (!this.router.currentRoute.parent) {
      this.errors.log('Unable to determine editor route. Current route has no parent.');
      throw new UnknownRouteError('Unable to determine editor route. Current route has no parent.');
    }

    const { name, localName: actionPath } = this.router.currentRoute.parent;
    const contextPath = name.includes('list') ? 'list.post' : 'calendar.post';
    const path = `${contextPath}.${actionPath}`;

    const gram = postMediaItem.get('gram');

    if (gram.get('isInstagramStory')) {
      return 'cluster.schedule.stories.editor.modal.crop';
    }

    let editorRoute = `cluster.schedule.${path}.editor.modal`;

    if (postMediaItem && postMediaItem.isVideo) {
      if (this.canVideoCrop) {
        editorRoute += '.crop';
      } else {
        editorRoute += '.trim';
      }
    } else {
      editorRoute += '.crop';
    }

    return editorRoute;
  }

  /**
   * Retries loading the image used on the FE for editing.
   */
  async retryLoadImage(data: { mediaItem: MediaItemModel; postMediaItem: PostMediaItemModel }): Promise<ImageData> {
    const imageDimensions = await this._refreshDimensions(data.mediaItem);
    const loadImageWithData = this._loadImage.bind(this, { ...data, imageDimensions });
    const numRetries = 6;
    return retry(loadImageWithData, numRetries, STRATEGY.LINEAR);
  }

  /**
   * Loads the image used on the frontend for editing which may not be the same
   * as the image used as the base for publishing. Ideally, we want to load the
   * `workingUrl` from the model for edits, and then publish with the `publishingUrl
   */
  async _loadImage(
    data: { mediaItem: MediaItemModel; postMediaItem: PostMediaItemModel; imageDimensions: ImageDimensions },
    retryCount: number,
    maxRetries: number
  ): Promise<ImageData> {
    let imageUrl = data.mediaItem.workingUrl;
    let loadedWorkingImage = true;
    const lastRetry = maxRetries - 1;
    const isLastRetry = retryCount >= lastRetry;

    if (isLastRetry) {
      // eslint-disable-next-line prefer-destructuring
      imageUrl = data.mediaItem.imageUrl;
      loadedWorkingImage = false;

      if (isMissingPublishingData(data.mediaItem)) {
        try {
          await data.mediaItem.reload();

          if (isMissingPublishingData(data.mediaItem)) {
            throw new Error('Media item was not fully processed. Missing Publishing Data.');
          }
        } catch (error) {
          return RSVP.reject(error);
        }
      }
    }

    if (!imageUrl || (!data.mediaItem.width && !data.imageDimensions?.width)) {
      return RSVP.reject(new Error('Media item was not fully processed. Missing URL.'));
    }

    return new RSVP.Promise((resolve, reject) => {
      this.loadImage(
        imageUrl,
        (img) => {
          if (img.type === 'error' || isEmpty(img.width) || img.width <= 0) {
            if (!retryCount) {
              data.mediaItem.checkImages();
            }
            reject(new Error('Failed to load image for media editor.'));
          } else {
            const imageData = img.toDataURL('image/jpeg');
            const { width, height } = img;
            const scaleFromWorking = isPresent(data.mediaItem.width)
              ? data.mediaItem.width / width
              : data.imageDimensions.width / width;

            resolve({
              imageData,
              scaleFromWorking,
              workingWidth: width,
              workingHeight: height,
              loadedWorkingImage
            });
          }
        },
        {
          canvas: true,
          orientation: true,
          crossOrigin: true
        }
      );
    });
  }

  async _getBlobOrUrl(imageUrl: string): Promise<Blob | string> {
    try {
      const response = await fetch(
        imageUrl,
        {},
        { intl: null, numRetries: 3, retryStrategy: STRATEGY.DEFAULT, raw: false }
      );
      return response.blob();
    } catch (error) {
      return imageUrl;
    }
  }

  async _refreshDimensions(mediaItem: MediaItemModel): Promise<ImageDimensions> {
    const defaultDimensions = { height: mediaItem.height, width: mediaItem.width };

    if (!mediaItem.isImage || !mediaItem.imageUrl) {
      return defaultDimensions;
    }

    try {
      const blobOrUrl = await this._getBlobOrUrl(mediaItem.imageUrl);

      return new Promise((resolve) => {
        this.loadImage(
          blobOrUrl,
          ({ height, width, type }) => {
            if (type === 'error') {
              resolve(defaultDimensions);
            } else {
              resolve({ height, width });
              mediaItem.setProperties({ height, width });
              mediaItem.save();
            }
          },
          {
            crossOrigin: true,
            meta: true,
            orientation: true
          }
        );
      });
    } catch (error) {
      return defaultDimensions;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    editor: EditorService;
  }
}
