import { isNone } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
export default class YoutubeVisibilityExists extends BaseValidator {
  validate() {
    if (this.get('model.socialProfile.isYoutube')) {
      return isNone(this.get('model.visibility')) ? this.createErrorMessage('visibilityExists') : true;
    }
    return true;
  }
}
