import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type SessionService from 'later/services/-private/session';
import type SegmentService from 'later/services/segment';
import type { Transition } from 'shared/types';

export default class MobileRoute extends Route {
  @service declare session: SessionService;
  @service declare segment: SegmentService;

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'user.login');
  }

  activate(): void {
    this.segment.track('viewed-homepage');
  }
}
