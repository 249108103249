/**
 * Parse window.location.pathname to get the current page
 *
 * @method getPage
 *
 * @return {String} Values of PAGES_MAP or null if no matches
 */

const PAGES_MAP = {
  calendar: 'Calendar',
  media: 'Media Library',
  conversations: 'Conversations',
  analytics: 'Analytics',
  collect: 'Collect',
  linkinbio: 'Linkinbio',
  account: 'Account Settings'
};

export default function getPage() {
  for (const page of Object.keys(PAGES_MAP)) {
    if (window.location.pathname?.includes(`/${page}`)) {
      return PAGES_MAP[page];
    }
  }
  return null;
}
