import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<Shared::ScheduleNavbar::Notification>\n  <p class=\"cPL--banner__desc qa--listView_review_banner\">\n    {{#if this.isApprover}}\n      {{t \"approval.list.banner.review_and_approve\"}}\n    {{else}}\n      {{t \"approval.list.banner.under_review\"}}\n    {{/if}}\n  </p>\n  <Shared::Ui::Button\n    @class=\"cPL--badgeNotification qa--listView_notification_number\"\n    @onClick={{@displayRequiresApproval}}\n    @variant=\"unstyled\"\n  >\n    {{@pendingApprovalCount}}\n  </Shared::Ui::Button>\n</Shared::ScheduleNavbar::Notification>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<Shared::ScheduleNavbar::Notification>\n  <p class=\"cPL--banner__desc qa--listView_review_banner\">\n    {{#if this.isApprover}}\n      {{t \"approval.list.banner.review_and_approve\"}}\n    {{else}}\n      {{t \"approval.list.banner.under_review\"}}\n    {{/if}}\n  </p>\n  <Shared::Ui::Button\n    @class=\"cPL--badgeNotification qa--listView_notification_number\"\n    @onClick={{@displayRequiresApproval}}\n    @variant=\"unstyled\"\n  >\n    {{@pendingApprovalCount}}\n  </Shared::Ui::Button>\n</Shared::ScheduleNavbar::Notification>","moduleName":"later/components/schedule/post-list/awaiting-approval-notification.hbs","parseOptions":{"srcName":"later/components/schedule/post-list/awaiting-approval-notification.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type UserRoleService from 'later/services/user-role';

interface AwaitingApprovalNotificationComponentSignature {
  Element: HTMLElement;
  Args: {
    displayRequiresApproval: boolean;
    pendingApprovalCount: number;
  };
}

export default class AwaitingApprovalNotificationComponent extends Component<AwaitingApprovalNotificationComponentSignature> {
  @service declare userRole: UserRoleService;

  get isApprover(): boolean {
    return this.userRole.currentUser.isApprover;
  }
}
