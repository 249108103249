import classic from 'ember-classic-decorator';

import ApplicationSerializer from 'later/serializers/application';

import type { JsonObject } from 'type-fest';

@classic
export default class CampaignImageSerializer extends ApplicationSerializer {
  serialize(snapshot: any, options: any): JsonObject {
    const json = super.serialize(snapshot, options);
    delete (json as JsonObject).preview_url;
    delete (json as JsonObject).variants;
    return json;
  }
}
