import Controller from '@ember/controller';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';

import type MutableArray from '@ember/array/mutable';
import type StoreService from '@ember-data/store';
import type ContentPillarModel from 'later/models/content-pillar';
import type AuthService from 'later/services/auth';
import type { Maybe } from 'shared/types';

export default class ContentPillarsController extends Controller {
  @service declare auth: AuthService;
  @service declare store: StoreService;

  declare model: ContentPillarModel[];

  contentPillarSorting = ['id:asc'];

  @sort('contentPillars', 'contentPillarSorting') sortedContentPillars: Maybe<ContentPillarModel[]>;

  get contentPillars(): MutableArray<ContentPillarModel> {
    return this.store
      .peekAll('content-pillar')
      .filter((contentPillar: ContentPillarModel) => contentPillar.group.get('id') === this.auth.currentGroup.id);
  }

  get displayContentPillars(): boolean {
    return Boolean(this.model?.length || this.contentPillars?.length);
  }
}
