/**
 * @module Services
 */

import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { preciseRound } from '@latermedia/ember-later-analytics/utils';

import { filterTimeSeries } from 'later/utils/array-filters';

/**
 * @class FormattersTableStoriesService
 * @extends Service
 */
export default class StoriesService extends Service {
  @service('analytics/helpers-analytics') helpersAnalytics;
  @service('analytics/formatters/table') tableFormatters;

  /**
   * Key names to be used to form table columns
   *
   * @property names
   * @type {Array}
   */
  names = ['reach', 'impressions', 'replies', 'tapsBack', 'tapsForward', 'exits'];

  /**
   * @property defaultRow
   * @type {Object}
   * @default
   */
  get defaultRow() {
    return {
      caption: '-',
      createdAtDate: '',
      createdAtTime: '',
      timestampLongFormat: '',
      createdAtUnix: null,
      id: null,
      isImage: true,
      isVideo: false,
      isDeleted: false,
      averageViewsPerUser: this.tableFormatters.buildDefaultTableObject(),
      completion: this.tableFormatters.buildDefaultTableObject(),
      impressions: this.tableFormatters.buildDefaultTableObject(),
      reach: this.tableFormatters.buildDefaultTableObject(),
      tapsBack: this.tableFormatters.buildDefaultTableObject(),
      tapsForward: this.tableFormatters.buildDefaultTableObject(),
      exits: this.tableFormatters.buildDefaultTableObject(),
      replies: this.tableFormatters.buildDefaultTableObject(0)
    };
  }

  /**
   * Takes an array of stories, formats each story for the
   * stories table according to the given story key names
   *
   * @method processStories
   * @param {Array} [stories=[]] Array of unformatted stories
   * @param {Moment} startDate
   * @param {Moment} endDate
   *
   * @returns {Array} Array of stories formatted for the stories table
   */
  processStories(stories = [], startDate, endDate) {
    if (!stories) {
      return null;
    }

    if (isEmpty(stories) || stories.error) {
      return stories;
    }

    const storiesWithCompletion = this._addCompletion(stories);

    const filteredStoriesWithCompletion = filterTimeSeries(
      storiesWithCompletion,
      startDate.unix(),
      endDate.unix(),
      'createdTime'
    );

    return filteredStoriesWithCompletion
      .map((story) => {
        const allValues = this.tableFormatters.getValues(this.names, story, filteredStoriesWithCompletion);
        const tableRow = this.tableFormatters.buildTableRow(allValues);

        const processedStory = Object.assign(
          {},
          {
            caption: this.tableFormatters.isValid(story.caption) ? story.caption : null,
            createdAtDate: this.helpersAnalytics.createMomentInTz(story.createdTime).format('MMM D, YYYY') || '-',
            createdAtTime: this.helpersAnalytics.createMomentInTz(story.createdTime).format('LT') || '-',
            timestampLongFormat:
              this.helpersAnalytics.createMomentInTz(story.createdTime).format('MMM D, YYYY hh:mm:ss A') || null,
            createdAtUnix: story.createdTime,
            id: story.id,
            url: story.imageUrl,
            isImage: story.type === 'image',
            isVideo: story.type === 'video',
            isDeleted: story.isDeleted,
            mediaUrl: story.mediaUrl,
            thumbnailUrl: story.thumbnailUrl,
            fallbackUrl: story.imageMediumUrl,
            completion: {
              value: this.tableFormatters.isValid(story.completion) ? `${preciseRound(story.completion, 2)}%` : '-',
              CSVValue: this.tableFormatters.isValid(story.completion) ? `${preciseRound(story.completion, 2)}%` : null,
              raw: this.tableFormatters.isValid(story.completion) ? Number(story.completion.toFixed(1)) : null
            },
            averageViewsPerUser: {
              value: this.tableFormatters.isValid(story.averageViewsPerUser)
                ? Number(story.averageViewsPerUser.toFixed(0))
                : '-',
              CSVValue: this.tableFormatters.isValid(story.averageViewsPerUser)
                ? Number(story.averageViewsPerUser.toFixed(0))
                : null,
              raw: this.tableFormatters.isValid(story.averageViewsPerUser)
                ? Number(story.averageViewsPerUser.toFixed(1))
                : null
            }
          },
          ...tableRow
        );
        return EmberObject.create(processedStory);
      })
      .reverse();
  }

  /**
   * Takes an array of stories, computes the completion rate
   * on each story and adds it as a value
   *
   * Completion Rate = (Impressions - Exits) / Reach
   *
   * @protected
   * @method _addEngagement
   * @param {Array} stories
   *
   * @return {Array} Array of stories including completion rate on each story
   */
  _addCompletion(stories) {
    return stories.map((story) => {
      const completion = story.impressions
        ? Number(((story.impressions - story.exits) / story.impressions) * 100)
        : null;
      const averageViewsPerUser = story.reach
        ? Number(((story.impressions - story.exits) / story.reach).toFixed(1))
        : 0;

      story.completion = completion;
      story.averageViewsPerUser = averageViewsPerUser;

      return story;
    });
  }
}
