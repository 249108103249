import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class ReferralsController extends Controller {
  @service auth;

  get account() {
    return this.get('auth.currentAccount');
  }

  get referralCode() {
    return this.get('auth.currentUserModel.referralCode');
  }

  get referralLink() {
    return `https://later.com/r/${this.referralCode}`;
  }

  get bonusPosts() {
    return this.account.bonusPosts;
  }
}
