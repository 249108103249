/**
 * Tests if the given hashtag is valid using Regex.
 *
 * @param hashtag Hashtag to be tested
 *
 * @returns Whether the hashtag given is valid
 */
const isValidHashtag = (hashtag: string): boolean => {
  const regex = /^#?[^\s!@#$%^&*()=+./,\[{\]};:'"?><]+$/gm;
  return regex.test(hashtag);
};

export { isValidHashtag };
