import { ALT_TEXT_CHARACTER_LIMIT } from 'later/utils/constants';

import type MediaItemModel from 'later/models/media-item';
import type PostMediaItemModel from 'later/models/post-media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type { Maybe } from 'shared/types';

export function getAltTextCharacterLimit(platform: string, mediaType: Maybe<string>): number {
  if (!platform || !mediaType) {
    return 0;
  }
  const platformLowerCase = platform.toLowerCase();
  const mediaTypeLowerCase = mediaType.toLowerCase();
  if (ALT_TEXT_CHARACTER_LIMIT[platformLowerCase]) {
    return ALT_TEXT_CHARACTER_LIMIT[platformLowerCase][mediaTypeLowerCase] || 0;
  }
  return 0;
}

export function canMediaItemsAltText(
  socialProfile: Maybe<SocialProfileModel>,
  mediaItems: Maybe<MediaItemModel[] | PostMediaItemModel[] | MediaItemModel | PostMediaItemModel>
): boolean {
  const profileType = socialProfile?.get('accountType');
  if (!profileType || !mediaItems) {
    return false;
  }

  return Array.isArray(mediaItems)
    ? mediaItems.some((mediaItem) => getAltTextCharacterLimit(profileType, mediaItem?.mediaType) > 0)
    : getAltTextCharacterLimit(profileType, mediaItems?.mediaType) > 0;
}
