import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Devise from 'ember-simple-auth/authenticators/devise';

import computeProofOfWork from 'later/utils/proof-of-work-hash';

export default class _Devise extends Devise {
  @service laterConfig;
  rejectWithResponse = true;

  /**
   * Authenticate using rails cookie store.  Avoid saving token on ember-simple-auth store.
   * During the makeRequest _latergram_session cookie is added by back-end.
   * Only tracks in isLoggedIn in session data to avoid saving token in cookie
   *
   * @method authenticate
   * @param {String} identification The user's identification
   * @param {String} password The user's password token
   * @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming authenticated
   * @public
   */
  authenticate(identification, password, otp_attempt) {
    return new Promise((resolve, reject) => {
      const { resourceName, identificationAttributeName, tokenAttributeName } = this;
      const data = {};
      data[resourceName] = { password };
      data[resourceName][identificationAttributeName] = identification;
      if (otp_attempt) {
        data[resourceName].otp_attempt = otp_attempt;
      }

      // call this a timestamp, but it's the counter. Mostly to confuse any looky-loos -iMack Jan 2022
      data.timestamp = computeProofOfWork(identification, password, this.laterConfig.authenticityToken);
      data.authenticity_token = this.laterConfig.authenticityToken;

      this.makeRequest(data)
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              if (this._validate(json)) {
                const cookieData = {};
                cookieData[identificationAttributeName] = identification;
                run(null, resolve, cookieData);
              } else {
                run(
                  null,
                  reject,
                  `Check that server response includes ${tokenAttributeName} and ${identificationAttributeName}`
                );
              }
            });
          } else {
            run(null, reject, response);
          }
        })
        .catch((error) => run(null, reject, error));
    });
  }

  /**
   * @method restore
   * @param {Object} data The data to restore the session from
    @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming authenticated. If authentication fails, the promise will reject.
   * @public
   */
  restore(data) {
    return data[this.identificationAttributeName] ? Promise.resolve(data) : Promise.reject();
  }
}
