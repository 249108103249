import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import getDuplicateHashtags from 'shared/utils/get-duplicate-hashtags';

@classic
class DuplicateHashtag extends BaseValidator {
  validate(value, options) {
    const { caption, firstComment } = this.model;
    const duplicateList = getDuplicateHashtags(caption, firstComment, options.field);
    if (duplicateList === '') {
      return true;
    }

    return this.createErrorMessage('duplicate_hashtags', null, { hashtags: duplicateList });
  }
}

export default DuplicateHashtag;
