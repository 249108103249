import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type GroupModel from 'later/models/group';
import type AuthService from 'later/services/auth';

export default class AnalyticsRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  redirect(): void {
    if (!this.groupSlug) {
      this.router.transitionTo('index');
      return;
    }

    this.router.transitionTo('cluster.analytics', this.groupSlug);
  }
}
