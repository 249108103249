/**
 * @module Services
 */

import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { preciseRound } from '@latermedia/ember-later-analytics/utils';

import { filterTimeSeries } from 'later/utils/array-filters';

import type HelpersAnalyticsService from '../../helpers-analytics';
import type TableService from '../table';
import type { Moment } from 'moment';
import type { AnalyticsError } from 'shared/types/analytics-data';
import type { DefaultReel, RawReel } from 'shared/types/analytics-data/table-data';

/**
 * @class FormattersTableReelsService
 * @extends Service
 */
export default class ReelsService extends Service {
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/formatters/table') declare tableFormatters: TableService;

  /**
   * Key names to be used to form table columns
   *
   * @property names
   * @type {Array}
   */
  names = ['commentCount', 'likeCount', 'savedCount', 'reach', 'plays', 'shareCount', 'totalPlays'];

  /**
   * @property defaultRow
   * @type {Object}
   * @default
   */
  get defaultRow(): DefaultReel {
    return {
      id: null,
      url: null,
      instagramLink: null,
      type: null,
      isVideo: false,
      isCarousel: false,
      isDeleted: false,
      createdAt: '',
      createdAtTime: '',
      timestampLongFormat: '',
      createdAtUnix: null,
      caption: '-',
      mediaProductType: null,
      engagement: this.tableFormatters.buildDefaultTableObject(),
      commentCount: this.tableFormatters.buildDefaultTableObject(),
      likeCount: this.tableFormatters.buildDefaultTableObject(),
      savedCount: this.tableFormatters.buildDefaultTableObject(),
      reach: this.tableFormatters.buildDefaultTableObject(),
      plays: this.tableFormatters.buildDefaultTableObject(),
      totalPlays: this.tableFormatters.buildDefaultTableObject(),
      shareCount: this.tableFormatters.buildDefaultTableObject(),
      scheduledPost: undefined
    };
  }

  /**
   * Takes an array of reels, formats each reel for the
   * reels table according to the given reel key names
   *
   * @method processReels
   * @param {Array} [reels=[]] Array of unformatted reels
   * @param {Moment} startDate
   * @param {Moment} endDate
   * @param {Boolean} analyticsStandard
   *
   * @returns {Array} Array of reels formatted for the reels table
   */
  processReels(
    reels: RawReel[] | AnalyticsError = [],
    startDate: Moment,
    endDate: Moment,
    analyticsStandard: boolean
  ): RawReel[] | DefaultReel[] | AnalyticsError {
    if (!Array.isArray(reels)) {
      return reels;
    }

    if (isEmpty(reels)) {
      return [] as DefaultReel[];
    }

    const filteredReels = analyticsStandard
      ? filterTimeSeries(reels, startDate.unix(), endDate.unix(), 'createdTime')
      : reels;

    return filteredReels
      .map((reel) => {
        const isCarousel = reel.type === 'carousel';
        const values = this.tableFormatters.getValues(this.names, reel, filteredReels);
        const tableRow = this.tableFormatters.buildTableRow(values);

        const playsSanitized = reel.type === 'video' ? reel.plays : 'N/A';
        const totalPlaysSanitized = reel.type === 'video' ? reel.totalPlays : 'N/A';

        const engagementPercent = reel.engagement * 100;

        const processedReel = Object.assign(
          {},
          {
            id: reel.id ?? reel.igId,
            url: reel.thumbUrl,
            instagramLink: reel.instagramLink,
            mediaProductType: reel.mediaProductType,
            createdAt: this.helpersAnalytics.createMomentInTz(reel.createdTime).format('MMM D, YYYY') || '-',
            createdAtTime: this.helpersAnalytics.createMomentInTz(reel.createdTime).format('LT') || '-',
            timestampLongFormat:
              this.helpersAnalytics.createMomentInTz(reel.createdTime).format('MMM D, YYYY hh:mm:ss A') || null,
            createdAtUnix: reel.createdTime,
            caption: reel.caption || null,
            type: reel.type,
            isVideo: reel.type === 'video',
            isCarousel,
            isDeleted: reel.isDeleted,
            engagement: {
              value: this.tableFormatters.isValid(reel.engagement) ? `${engagementPercent.toFixed(2)}%` : '-',
              CSVValue: this.tableFormatters.isValid(reel.engagement) ? `${preciseRound(engagementPercent, 2)}%` : null,
              raw: this.tableFormatters.isValid(reel.engagement) ? Number(reel.engagement) : null,
              scaled: this.tableFormatters.createElementWidthAttr(reel.engagement)
            },
            followersCount: reel.followersOnReel,
            plays: {
              value: isPresent(playsSanitized) ? playsSanitized : '-',
              CSVValue: this.tableFormatters.isValid(playsSanitized) ? reel.plays : null,
              raw: this.tableFormatters.isValid(playsSanitized) ? reel.plays : null,
              scaled: this.tableFormatters.scaleValue(
                this.tableFormatters.getValidValues(reels, 'plays'),
                Number(reel.plays)
              )
            },
            totalPlays: {
              value: isPresent(totalPlaysSanitized) ? totalPlaysSanitized : '-',
              CSVValue: this.tableFormatters.isValid(totalPlaysSanitized) ? reel.totalPlays : null,
              raw: this.tableFormatters.isValid(totalPlaysSanitized) ? reel.totalPlays : null,
              scaled: this.tableFormatters.scaleValue(
                this.tableFormatters.getValidValues(reels, 'totalPlays'),
                Number(reel.totalPlays)
              )
            },
            scheduledPost: reel.scheduledPost
          },
          ...tableRow
        );
        return EmberObject.create(processedReel);
      })
      .reverse();
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/formatters/table/reels': ReelsService;
  }
}
