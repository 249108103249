import { inject as service } from '@ember/service';
import { Command } from 'command-palette/command';
import command from 'command-palette/decorators/command';
import { bold } from 'command-palette/utils/command-helpers';

import { getGroup } from 'later/utils/global-search/get-group';
import { getProfile } from 'later/utils/global-search/get-profile';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type { SearchCommandQuery } from 'command-palette/types';
import type AuthService from 'later/services/auth';

@command('navigate-search-by-hashtag')
export class NavigateSearchByHashtagCommand extends Command {
  @service declare auth: AuthService;
  @service declare store: StoreService;
  @service declare router: RouterService;

  public searchTerms = [
    'navigate',
    'search',
    'hashtags',
    'tags',
    'find',
    'instagram',
    'pictures',
    'photos',
    'images',
    'videos'
  ];

  protected action(query: SearchCommandQuery): void | Promise<void> {
    const group = getGroup({ auth: this.auth });
    const profile = getProfile({ auth: this.auth, store: this.store, query });
    // const tag = query.tokens.tags[0];

    this.router.transitionTo('cluster.collect.profile', group.slug, profile.urlName || '');
    // if (tag) {
    //   this.pendingIntent.create(query);
    // }
  }

  public isSuggested(): boolean {
    return true;
  }

  public getTitle(): string {
    return `Search by Hashtag`;
  }

  public getDescription(query: SearchCommandQuery): string {
    const tag = query.tokens.tags[0];
    if (tag) {
      return `Find Instagram posts with the ${bold('#' + tag)} hashtag`;
    }
    return `Find Instagram posts with a specific hashtag`;
  }

  public getIcon(): string {
    return 'hashtag';
  }
}

export default NavigateSearchByHashtagCommand;
