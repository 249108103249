import Service from '@ember/service';
import { isTesting } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import NProgress from 'nprogress';

interface ProgressBarCounts {
  [key: string]: number;
}

export default class ProgressBarService extends Service {
  @tracked activeBars: ProgressBarCounts = {};

  // wait 2 seconds before stopping to give time for other progress bars to start
  // this prevents the progress bar from flickering when multiple progress bars are started in quick succession
  DELAY = isTesting() ? 0 : 2000;

  start(parent: string): void {
    if (!this.activeBars[parent]) {
      this.activeBars[parent] = 0;
    }
    if (this.activeBars[parent] === 0 || !NProgress.isStarted()) {
      // set showSpinner to false if parent === 'body
      const isParentVisible = document.querySelector(parent) !== null;
      const parentSelector = isParentVisible ? parent : 'body';
      NProgress.configure({ parent: parentSelector, showSpinner: parentSelector === 'body' ? false : true });
      NProgress.start();
      if (parentSelector !== 'body') {
        NProgress.configure({ parent: 'body' });
      }
    }
    this.activeBars[parent]++;
  }

  async done(parent: string, force = false): Promise<void> {
    if (this.activeBars[parent] > 0) {
      this.activeBars[parent]--;
    }

    if (this.activeBars[parent] === 0 || force) {
      await timeout(this.DELAY);
      NProgress.done();
    }
  }
}
