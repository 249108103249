import Model, { attr, belongsTo } from '@ember-data/model';

import type { AsyncBelongsTo } from '@ember-data/model';
import type UserModel from 'later/models/user';

export default class DiscoveryListTaskModel extends Model {
  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;

  @attr('string') declare identifier: string;
  @attr('number') declare readonly createdTime: number;
  @attr('number') declare completedTime: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'discovery-list-task': DiscoveryListTaskModel;
  }
}
