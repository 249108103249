import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { ActiveModelSerializer } from 'active-model-adapter';
import classic from 'ember-classic-decorator';

import type Snapshot from '@ember-data/store/snapshot';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';

type Attribute = Record<string, { readOnly: boolean }>;

@classic
export default class Application extends ActiveModelSerializer.extend(EmbeddedRecordsMixin) {
  serializeAttribute<K extends keyof ModelRegistry>(
    snapshot: Snapshot<K>,
    json: object,
    key: string,
    attribute: Attribute
  ): void {
    if (attribute.options && attribute.options.readOnly) {
      return;
    }
    super.serializeAttribute(snapshot, json, key, attribute);
  }
}
