import Service from '@ember/service';
import { isString } from 'lodash';

import { fetch } from 'later/utils/fetch';
import { decodeJWT } from 'later/utils/nexus-token';
import { timestamp } from 'later/utils/time-format';

import type { EncodedJWT } from 'later/utils/nexus-token';

/**
 * Service to handle Jolteon JWT tokens.
 */

interface JWTResponse {
  token?: EncodedJWT;
}

export default class JWTService extends Service {
  #jolteonToken: EncodedJWT | null = null;

  /*
   * Requests a valid Jolteon JWT
   */
  async fetchToken(): Promise<EncodedJWT | undefined> {
    if (this.#hasToken(this.#jolteonToken)) {
      return this.#jolteonToken;
    }

    this.clearToken();

    const { token }: JWTResponse = await fetch(`/api/tokens/jwt`);

    if (!token) {
      return;
    }

    this.#jolteonToken = token;
    return token;
  }

  /**
   * Clears the stored Jolteon JWT
   */
  clearToken(): null {
    this.#jolteonToken = null;
    return null;
  }

  /*
   * Checks whether a Jolteon JWT is locally stored
   */
  #hasToken(token: EncodedJWT | null): token is EncodedJWT {
    if (!isString(token) || this.#isExpired(token)) {
      return false;
    }

    return true;
  }

  #isExpired(token: EncodedJWT): boolean {
    const decodedToken = decodeJWT(token);
    const { payload } = decodedToken;
    return payload.exp < timestamp();
  }
}

declare module '@ember/service' {
  interface Registry {
    jwt: JWTService;
  }
}
