import classic from 'ember-classic-decorator';

import ApplicationAdapter from './application';

@classic
export default class AccountAdapter extends ApplicationAdapter {
  urlForQuery(query, ...args) {
    if (query.userId) {
      return `${this.urlPrefix()}/users/${query.userId}/accounts`;
    }
    return super.urlForQuery(query, ...args);
  }
}
