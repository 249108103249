import { A } from '@ember/array';
import { debug, assert } from '@ember/debug';
import EmberObject from '@ember/object';
import { readOnly, equal } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import classic from 'ember-classic-decorator';
import { didCancel } from 'ember-concurrency';

import { ErrorSeverity } from 'later/services/errors';

import type { AbortMultipartUploadCommandOutput, CompleteMultipartUploadCommandOutput } from '@aws-sdk/client-s3';
import type NativeArray from '@ember/array/-private/native-array';
import type IntlService from 'ember-intl/services/intl';
import type MediaItemModel from 'later/models/media-item';
import type AlertsService from 'later/services/alerts';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';
import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type MediaUploadService from 'later/services/media-upload';
import type { UntypedService } from 'shared/types';

/**
 * This service queues media files for upload into AWS S3
 */
export default class UploadBusService extends Service.extend(Evented) {
  @service declare intl: IntlService;
  @service declare localStorageManager: LocalStorageManagerService;
  @service declare errors: ErrorsService;
  @service declare alerts: AlertsService;
  @service declare laterConfig: LaterConfigService;
  @service('media-upload') declare mediaUploadService: MediaUploadService;

  /**
   * Queue of File objects to be uploaded
   */
  @tracked queue: NativeArray<MediaUpload> = A([]);

  @equal('queue.length', 0) declare isEmpty: boolean;

  addUploadToQueue(
    file: Blob | File,
    mediaItem: MediaItemModel,
    successAction?: () => void,
    failureAction?: (error: Error | string) => void
  ): void {
    if (this.laterConfig.imageUploadAvailable) {
      mediaItem.isUploading = true;
      this.#addUploadToQueue(file, mediaItem, successAction, failureAction);
    } else {
      this.alerts.clear();

      this.alerts.warning(this.intl.t('alerts.media_items.new.image_upload_not_available.message'), {
        title: this.intl.t('alerts.media_items.new.image_upload_not_available.title')
      });
    }
  }

  clearUploadQueue(): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- we need to pass a function to next()
    next(this, () => {});
  }

  #addUploadToQueue(
    file: Blob | File,
    mediaItem: MediaItemModel,
    successAction?: () => void,
    failureAction?: (error: Error | string) => void
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const uploads = this.queue;
    const upload = this.#createNewMediaUpload(this, file, mediaItem);

    upload.on('didBeginUpload', () => this.trigger('didBeginUpload'));

    upload.on('didFinishUpload', function () {
      debug('Got didFinishUpload');

      mediaItem.isUploading = false;

      next(self, () => {
        if (!self.isEmpty) {
          const nextObj = uploads.firstObject;
          nextObj?.call(self.errors, self.mediaUploadService);
        }
      });
    });

    upload.on('uploadSuccess', () => {
      if (typeof successAction === 'function') {
        //in case it's undefined
        successAction();
      }

      self.trigger('uploadSuccess');
    });

    upload.on('uploadFailure', (reason: Error) => {
      if (typeof failureAction === 'function') {
        //in case it's undefined
        failureAction(reason);
      }
    });

    if (self.isEmpty) {
      upload.call(this.errors, this.mediaUploadService);
    }

    uploads.pushObject(upload);
  }

  #createNewMediaUpload(service: UploadBusService, file: Blob | File, mediaItem: MediaItemModel): MediaUpload {
    assert('Must pass a valid flash service', service);
    assert('Must pass a valid file', file);
    assert('Must pass a valid mediaItem', mediaItem);

    // hard coding 'later-incoming' for now, until mobile ready -iMack, Aug 16, 2017
    if (!isNone(file) && file.type.match(/video.*/)) {
      mediaItem.mediaType = 'video';
      mediaItem.processing = true;
      mediaItem.processingBucket = 'later-incoming';
    } else if (!isNone(file) && file.type.match(/image\/gif/)) {
      mediaItem.mediaType = 'gif';
      mediaItem.processingBucket = 'later-incoming';
    } else {
      mediaItem.mediaType = 'image';
      mediaItem.processingBucket = 'later-incoming';
    }

    return MediaUpload.create({
      uploadBusService: service,
      localStorageManager: this.localStorageManager,
      errors: this.errors,
      file,
      mediaItem
    });
  }
}

/**
 * Wrapper for individual media item upload actions.
 * When created, it handles media item states during upload to S3
 */
@classic
class MediaUpload extends EmberObject.extend(Evented) {
  @readOnly('uploadBusService.queue') declare queue: NativeArray<MediaUpload>;

  @tracked uploadId = null;

  @tracked declare mediaUploadService: UntypedService;
  @tracked declare uploadBusService: UploadBusService;
  @tracked declare localStorageManager: LocalStorageManagerService;
  @tracked declare errors: ErrorsService;
  @tracked declare file: Blob | File;
  @tracked declare mediaItem: MediaItemModel;

  /**
   * @see {@link http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html}
   */
  async call(errors: ErrorsService, mediaUploadService: UntypedService): Promise<void> {
    // Note: we can't inject this into an Ember Object, so need to pass it -iMack June 2019
    this.errors = errors;
    this.mediaUploadService = mediaUploadService;
    this._uploadToS3();
  }

  /**
   * Uses AWS SDK V3 modular packages to setup S3 client with credentials.
   * Upload is handled by aws-sdk/lib-storage in order to track httpUploadProgress
   */
  async _uploadToS3(): Promise<void> {
    this.mediaItem.processingKey = this.mediaUploadService.generateProcessingKey(
      this.file,
      this.mediaItem.get('isVideo')
    );
    this.mediaItem.uploadProgress = 0;
    this.mediaItem.originalFilename = this.file.name;
    this.trigger('didBeginUpload');

    try {
      const data: CompleteMultipartUploadCommandOutput | AbortMultipartUploadCommandOutput =
        await this.mediaUploadService.uploadToS3.perform(
          this.file,
          this.mediaItem.get('processingBucket'),
          this.mediaItem.get('processingKey'),
          this.setUploadProgress.bind(this)
        );

      if (!this.mediaItem.get('isVideo') && 'Location' in data && data.Location) {
        this.mediaItem.imageUrl = data.Location;
      }

      try {
        if (this.mediaItem.uploadProgress) {
          await this.mediaItem.save();
          this.success();
        } else {
          await this.mediaItem.destroyRecord();
          this.failure(new Error('_uploadToS3: Media failed to upload'));
        }
      } catch (error) {
        if (error instanceof Error) {
          this.failure(error);
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (!didCancel(error)) {
          const customData = {
            fileType: this.file.type,
            fileSize: this.file.size,
            fileName: this.file.name,
            processingKey: this.mediaItem?.processingKey
          };
          const expectedErrors = ['NetworkingError', 'TimeoutError', 'RequestTimeTooSkewed'];
          const errorSeverity = expectedErrors.includes(error?.name) ? ErrorSeverity.Info : ErrorSeverity.Error;
          this.errors.log(error, customData, errorSeverity);
          this.failure(error);
        }
      }
    }
  }

  setUploadProgress(progress: number): void {
    this.mediaItem.uploadProgress = progress;
  }

  success(): void {
    if (this.queue) {
      this.queue.removeObject(this);
    }

    this.trigger('didFinishUpload');
    this.trigger('uploadSuccess');
  }

  failure(reason: Error): void {
    if (this.queue) {
      this.queue.removeObject(this);
    }

    this.trigger('didFinishUpload');
    this.trigger('uploadFailure', reason);
  }
}

declare module '@ember/service' {
  interface Registry {
    'upload-bus': UploadBusService;
  }
}
