import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { toInteger } from 'lodash';
import moment from 'moment';

import { fetch, objectToQueryString } from 'later/utils/fetch';
import createFormattedIndustryHashtags from 'later/utils/formatters/create-formatted-industry-hashtags';
import hashCode from 'later/utils/hash-code';
import { objectSnakeToCamel } from 'later/utils/object-methods';

import type SocialProfileModel from 'later/models/social-profile';
import type CacheService from 'later/services/cache';
import type { AnalyticsError } from 'shared/types/analytics-data';
import type { FormattedIndustryHashtags, RawIndustryHashtags } from 'shared/types/industry-data/industry-hashtags';
import type {
  ProfileIndustryComparison,
  ProfileIndustryComparisonRaw
} from 'shared/types/industry-data/profile-industry-comparison';

type IndustryHashtagsCacheKey = `industryHashtags_${string}`;
type IndustryProperties = Pick<SocialProfileModel, 'id' | 'industry' | 'temporaryIndustry'>;
type Duration = 'week';

export default class IndustryDataService extends Service {
  @service declare cache: CacheService;

  @tracked industryHashtagsCacheKey?: IndustryHashtagsCacheKey;

  /**
   * Gets all profile comparison data for the given industry and follower count.
   */
  getProfileIndustryComparison = task(
    async (
      socialProfile: SocialProfileModel,
      industry?: string,
      followerCount?: number,
      averageProfileEngagement?: number
    ): Promise<ProfileIndustryComparison> => {
      const endpoint = 'profile_comparison';
      const params = {
        industry,
        social_profile_id: socialProfile.id,
        follower_count: followerCount,
        eng_rate: averageProfileEngagement
      };

      const cacheKey = `profileComparison_${socialProfile.id}_${industry}_${toInteger(followerCount)}_${toInteger(
        averageProfileEngagement
      )}`;

      const cachedValue = this.cache.retrieve<string>(cacheKey);
      if (cachedValue) {
        return JSON.parse(cachedValue) as ProfileIndustryComparison;
      }

      const result: ProfileIndustryComparisonRaw = await fetch(
        `/api/v2/analytics/${endpoint}.json${objectToQueryString(params)}`
      );
      const sanitizedResult = objectSnakeToCamel(result) as ProfileIndustryComparison;

      const cacheValue = JSON.stringify(sanitizedResult);
      this.cache.add(cacheKey, cacheValue, { expiry: this.cache.expiry(30, 'days') });
      return sanitizedResult;
    }
  );

  /**
   * Returns Later hashtag usage statistics over the given
   * duration for the current social profile's industry
   */
  getIndustryHashtags = task(
    { enqueue: true },
    async (
      { id, industry, temporaryIndustry }: IndustryProperties,
      startDate: string,
      duration: Duration = 'week',
      forceRefresh = false
    ): Promise<AnalyticsError | FormattedIndustryHashtags | undefined> => {
      assert('getIndustryHashtags requires the social profile to have an industry', industry);
      assert('startDate must be a string', typeof startDate === 'string');
      assert('startDate must be formatted like YYYY-MM-DD', moment(startDate, 'YYYY-MM-DD', true).isValid());

      const encodedArgs = hashCode(
        JSON.stringify({ id, industry: temporaryIndustry ?? industry, duration, startDate })
      );
      const cacheKey: IndustryHashtagsCacheKey = `industryHashtags_${encodedArgs}`;
      this.industryHashtagsCacheKey = cacheKey;

      if (!this.cache.retrieve(cacheKey) || forceRefresh) {
        const rawIndustryHashtags: RawIndustryHashtags = await fetch(
          `/api/v2/analytics/industry_hashtags.json${objectToQueryString({
            social_profile_id: id,
            start_date: startDate,
            duration,
            industry: temporaryIndustry ?? industry
          })}`
        );

        const returnValue = createFormattedIndustryHashtags(rawIndustryHashtags);
        const cacheValue = JSON.stringify(returnValue);
        this.cache.add(cacheKey, cacheValue, {
          expiry: this.cache.expiry(1, 'day')
        });
        return returnValue;
      }

      const cachedValue = this.cache.retrieve<string>(cacheKey);
      if (!cachedValue) {
        return;
      }

      return JSON.parse(cachedValue) as FormattedIndustryHashtags;
    }
  );
}
