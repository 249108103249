import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div class=\"cNA--flyoutOverlay {{if this.showFlyout 'is--open'}}\" {{on \"click\" this.hideFlyout}}></div>\n<div class=\"cNA--flyoutContainer {{if this.showFlyout 'is--open at--collectRetention__collectFlyOut'}}\">\n  {{! Collect Media }}\n  {{#if (eq this.subnav \"collect\")}}\n    <Nav::Subnav::Collect\n      @refreshHash={{this.refreshHash}}\n      @showFlyout={{this.showFlyout}}\n      @toggleFlyout={{this.hideFlyout}}\n    />\n  {{else if (eq this.subnav \"creators\")}}\n    <Nav::Subnav::Creators @showFlyout={{this.showFlyout}} @toggleFlyout={{this.hideFlyout}} />\n  {{/if}}\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div class=\"cNA--flyoutOverlay {{if this.showFlyout 'is--open'}}\" {{on \"click\" this.hideFlyout}}></div>\n<div class=\"cNA--flyoutContainer {{if this.showFlyout 'is--open at--collectRetention__collectFlyOut'}}\">\n  {{! Collect Media }}\n  {{#if (eq this.subnav \"collect\")}}\n    <Nav::Subnav::Collect\n      @refreshHash={{this.refreshHash}}\n      @showFlyout={{this.showFlyout}}\n      @toggleFlyout={{this.hideFlyout}}\n    />\n  {{else if (eq this.subnav \"creators\")}}\n    <Nav::Subnav::Creators @showFlyout={{this.showFlyout}} @toggleFlyout={{this.hideFlyout}} />\n  {{/if}}\n</div>","moduleName":"later/components/nav/app-header-subnav.hbs","parseOptions":{"srcName":"later/components/nav/app-header-subnav.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type AppHeaderService from 'later/services/app-header';
import type { Maybe } from 'shared/types';

export default class AppHeaderSubnavComponent extends Component {
  @service declare appHeader: AppHeaderService;

  get subnav(): 'collect' | 'creators' {
    return this.appHeader.subnav;
  }

  get showFlyout(): boolean {
    return this.appHeader.showFlyout;
  }

  getRefreshHash(): Maybe<number> {
    return this.appHeader.refreshHash;
  }

  @action
  hideFlyout(): void {
    this.appHeader.hideFlyout();
  }
}
