import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class GroupSerializer extends ApplicationSerializer {
  attrs = {
    memberships: {
      embedded: 'always',
      serialize: 'id'
    },
    contributors: {
      embedded: 'always',
      serialize: 'id'
    },
    socialIdentities: {
      embedded: 'always',
      serialize: 'id'
    },
    socialProfiles: {
      embedded: 'always',
      serialize: 'id'
    }
  };
}
