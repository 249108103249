import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment';

import type { AsyncBelongsTo } from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type { Maybe } from 'shared/types';

export default class CalendarNoteModel extends Model {
  @service declare intl: IntlService;

  @attr('number') declare readonly createdTime: Maybe<number>;
  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;
  @attr('string') declare noteContent: Maybe<string>;
  @attr('number') declare scheduledTime: Maybe<number>;

  get watchProperty(): string {
    return `${this.id} :: ${this.scheduledTime} :: ${this.displayNote}`;
  }

  get displayNote(): string {
    if (isEmpty(this.noteContent) || !this.noteContent) {
      return this.intl.t('calendar_notes.default_note');
    }

    return this.noteContent;
  }

  get summary(): Maybe<string> {
    if (!this.displayNote) {
      return this.displayNote;
    }

    return this.displayNote.length > 100 ? `${this.displayNote.substring(0, 100)} ...` : this.displayNote;
  }

  get moment(): Maybe<moment.Moment> {
    if (!this.scheduledTime) {
      return null;
    }

    return moment.unix(this.scheduledTime);
  }

  get day(): Maybe<string> {
    return this.moment?.format('YYYY-MM-DD');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'calendar-note': CalendarNoteModel;
  }
}
